import { Injectable } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { RoleIdService } from 'src/app/services/role-id.service'
import { TableSharedCalendar } from './data/data-source/agenda-local-data-source.service';
@Injectable({
  providedIn: 'root'
})
export class Utils {
  constructor(
    public eventService: EventsService,
    public RoleIdService: RoleIdService,
  ) { }


  selectedCalendarUserId(CalendarName, postEvent) {

    if (this.eventService.calendarNamesType[CalendarName]?.['Oficial'] && postEvent.CalendarName == 'Oficial') {
      return this.eventService.calendarNamesType[CalendarName]['OwnerId']

    } else if (this.eventService.calendarNamesType[CalendarName]?.['Pessoal'] && postEvent.CalendarName == 'Pessoal') {

      return this.eventService.calendarNamesType[CalendarName]['OwnerId']

    } else {
      return '11:11'
    }
  }

  selectedCalendarOwner(roleDescription) {

    console.log('roleDescription', roleDescription)
    if (roleDescription) {
      if (roleDescription == "Presidente da República") {
        return 1;
      } else if (roleDescription == "Ministro e Director do Gabinete do PR") {
        return 2
      } else {
        return 3
      }
    }
  }

  findRoleIdByUserId(array, userId) {
    for (let item of array) {
      console.log('each item before',item)
      if (typeof item === 'object' && item !== null && item.OwnerUserId === parseInt(userId)) {
        console.log('each item after',item)
        return item.Role;
      } else {
        return 'other';
      }
    }

  }



  calendarCategorySeleted(calendarName) {
    var selectedCalendar = {
      'Oficial': 1,
      'Pessoal': 2
    }

    if(calendarName != 'Pessoal' &&  calendarName != 'Oficial') {
      throw('bad logic')
    }

    return selectedCalendar[calendarName];
  }

  calendarTypeSeleted(calendarName) {
    var selectedType = {
      'Meeting': 1,
      'Travel': 2,
      'Conference': 3,
      'Encontro': 1,
      'Reunião': 1,
      'Conferência': 3
    }
    return selectedType[calendarName];
  }

  documentAdded(documents: any[], addAll: Boolean) {
    console.log('added doc create event', documents)
    let listupdate = []
    documents.forEach(element => {
      let object = {
        docId: element.SourceId || element.docId || element.DocId || element.Id,
        sourceName: element.subject || element.sourceNames || element.Description || element.SourceName || element.Assunto,
        description: "",
        applicationId: element.applicationId || element.ApplicationId
      }

      listupdate.push(object)
    });


    console.log({listupdate})

    return listupdate.filter( e=> typeof e.docId == 'number' || addAll)

    /*  return documents.map((e) => {
       return {
         docId: e.docId,
         sourceName: e.subject || e.sourceNames,
         description: "",
         applicationId: e.applicationId
       };
     }); */

  }

  editDocumentAdded(documents: any[]) {
    console.log('document added', documents)
    return documents.map((element) => {
      return {
        docId: parseInt(element.SourceId),
        sourceName: element.SourceName,
        description: "",
        applicationId: parseInt(element.ApplicationId)
      };
    });

  }


  atendeesSeletedType(type) {
    var selectedType = {
      'true': 1,
      'false': 2,
      'other': 3,
    }
    return selectedType[type];
  }


  attendeesAdded(taskParticipants: any[]) {

    console.log({taskParticipants});

    return taskParticipants.map((e) => {
      return {
        name: e.Name,
        emailAddress: e.EmailAddress,
        attendeeType: this.atendeesSeletedType(JSON.stringify(e.IsRequired)),
        wxUserId: e.Id,
        userType: e.UserType,
        entity: e.Entity,
      }
    });
  }

  statusEventAproval(type) {
    var selectedType = {
      'Pending': 1,
      'Revision': 2,
      'Approved': 3,
      "Declined": 4,
      "Communicated": 5
    }
    return selectedType[type];
  }

  attendeesEdit(taskParticipants: any[]) {
    return taskParticipants.map((e) => {
      return {
        name: e.Name,
        emailAddress: e.EmailAddress,
        attendeeType: this.atendeesSeletedType(JSON.stringify(e.IsRequired)),
        wxUserId: e.wxUserId || e.Id,
        userType: e.UserType,
        entity: e.Entity,
      }
    });
  }

  eventRecurence(type) {
    var selectedType = {
      'never': 0,
      'daily': 1,
      'weekly': 2,
      "monthly": 3,
      "yearly": 4
    }
    return selectedType[type];
  }

  recurenceTypeSeleted(recurenceType) {
    var selectedType = {
      0: 'never',
      1: 'daily',
      2: 'weekly',
      3: 'monthly',
      4: 'yearly'
    }
    return selectedType[recurenceType];
  }


  addOneHourToIsoString(isoDateString) {
    let date = new Date(isoDateString);

    const tzOffset = -date.getTimezoneOffset(); // in minutes
    const diff = tzOffset >= 0 ? '+' : '-';
    const pad = (n: number) => (n < 10 ? '0' : '') + n;

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        diff + pad(Math.floor(Math.abs(tzOffset) / 60)) +
        ':' + pad(Math.abs(tzOffset) % 60);
  }



  didEventHappenToday(eventTime) {
    const eventDate = new Date(eventTime);
    const today = new Date();
    return eventDate.toDateString() === today.toDateString();
  }


  duration(date1Str, date2Str) {

    // Convert string dates to Date objects
    const date1: any = new Date(date1Str);
    const date2: any = new Date(date2Str);

    // Calculate the difference in milliseconds
    const timeDifferenceMs = date2 - date1;

    // Convert difference to days, hours, and minutes
    const totalMinutes = Math.floor(timeDifferenceMs / (1000 * 60));
    const days = Math.floor(totalMinutes / (60 * 24));
    const hours = Math.floor((totalMinutes % (60 * 24)) / 60);
    const minutes = totalMinutes % 60;

    return `${days}d ${hours}h ${minutes}m`.replace('0d', '')
  }

  getAllEvents(years: any[]): Event[] {
    const events: Event[] = [];

    years.forEach(year => {
      year.months.forEach(month => {
        month.days.forEach(day => {
          events.push(...day.events);
        });
      });
    });

    return events;
  }



  hasPrCalendar(data: TableSharedCalendar[]) {
    for(const e of data) {
      if(e.roleId == this.RoleIdService.PRES) {
        return true
      }
    }

    return false
  }
}

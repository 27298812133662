import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { TracingType, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';

@Component({
  selector: 'app-crop-image',
  templateUrl: './crop-image.page.html',
  styleUrls: ['./crop-image.page.scss'],
})
export class CropImagePage implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  @ViewChild('cropper') cropper: ImageCropperComponent;
  capturedImage: any = "";
  base64ToCroppe = "";

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
  ) {
    this.base64ToCroppe = this.navParams.get('base64ToCroppe')
    console.log('To cropp',this.base64ToCroppe)
  }

  ngOnInit() {
    console.log('To cropp',this.base64ToCroppe)
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  @XTracerAsync({name:'crop-image/imageCropped', bugPrint: true, autoFinish: true})
  imageCropped(event: ImageCroppedEvent, tracing?: TracingType) {
    this.croppedImage = event.base64;

    console.log('Croped image', event)
    console.log('Croped image 22', this.croppedImage)
    tracing.addEvent('Croped image')
    tracing.setAttribute('outcome','success')
    // event.blob can be used to upload the cropped image
  }

  imageLoaded($event) {

  }

  loadImageFailed() {
    console.log('cropp iage faile')
  }

  @XTracerAsync({name:'crop-image/save', bugPrint: true, autoFinish: true})
  save(tracing?: TracingType) {

    this.modalController.dismiss({
      base64ToCroppe: this.croppedImage
    });

    tracing.addEvent('done')

    tracing.setAttribute('outcome','success')
  }

  cancel() {
    this.modalController.dismiss()
  }

}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventInputDTO } from '../dto/eventInputDTO';
import { SessionStore } from 'src/app/store/session.service';
import { SharedCalendarListOutputDTO } from '../dto/sharedCalendarOutputDTO';
import { EventOutputDTO } from '../dto/eventDTOOutput';
import { AttendeesRemoveInputDTO } from '../dto/attendeeRemoveInputDTO';
import { EventListOutputDTO } from '../dto/eventListDTOOutput';
import { HttpService } from 'src/app/services/http.service';
import { TracingType } from 'src/app/services/monitoring/opentelemetry/tracer';
import { IGetDraftListByProcessIdOutput, IGetDraftListByProcessIdSchema } from '../../domain/usecase/getDraft-list-by-process-id.service';
import { IDraftSaveByIdInput } from '../../domain/usecase/draft-save-by-id-use-case.service';

@Injectable({
  providedIn: 'root'
})

export class AgendaDataService {
  private baseUrl = 'https://gdapi-dev.dyndns.info/stage/api/v2'; // Your base URL

  constructor(
    private http: HttpClient,
    private httpService: HttpService
  ) { }

  // Documents Endpoints
  getAttachments(subject: string, applicationType: number) {
    const params = {
      Subject: subject,
      ApplicationType: applicationType.toString()
    }

    return this.httpService.get<any>(`${this.baseUrl}/Documents/Attachments`, { params });
  }

  viewDocument(userId: number, docId: number, applicationId: number) {
    const params = new HttpParams()
      .set('userId', userId.toString())
      .set('docId', docId.toString())
      .set('applicationId', applicationId.toString());
    return this.httpService.get<any>(`${this.baseUrl}/Documents/view`, params);
  }

  // Events Endpoints
  createEvent(eventData: EventInputDTO) {
    return this.httpService.post<any>(`${this.baseUrl}/Events`, eventData);
  }

  // @APIReturn(EventListOutputDTOSchema, 'get/Events')
  getEvents({userId, startDate, endDate, status, category, type }, tracing?: TracingType) {
    let params: any = {
      UserId: userId
    }

    if(userId == null || userId == undefined) {
      throw('userId '+ userId)
    }


    if(status != null || status != undefined) {
      params.status =  status;
    }

    if (startDate !== null && startDate !== undefined) {
      params.startDate = startDate;
    }

    if (endDate !== null && endDate !== undefined) {
      params.endDate = endDate;
    }

    return this.httpService.get<EventListOutputDTO>(`${this.baseUrl}/Events`, params, tracing);
  }

  searchEvent(queryParameter: {value, status}) {
    return this.httpService.get<EventListOutputDTO>(`${this.baseUrl}/Events`, queryParameter);
  }

  getEvent(id: string, tracing?: TracingType) {
    return this.httpService.get<EventOutputDTO>(`${this.baseUrl}/Events/${id}`, {}, tracing);
  }

  updateEvent(id: string, eventData: any) {
    return this.httpService.put<any>(`${this.baseUrl}/Events/${id}`, eventData);
  }

  approveEvent(id: string) {
    return this.httpService.patch<any>(`${this.baseUrl}/Events/${id}/Approval`, {});
  }

  async deleteEvent(id: string, deleteAllEvents: boolean) {
    const params = {
      'DeleteAllEvents': deleteAllEvents.toString()
    };
    return this.httpService.delete<any>(`${this.baseUrl}/Events/${id}`, params);
  }

  updateEventStatus(id: string, statusData: Object) {
    return this.httpService.patch<any>(`${this.baseUrl}/Events/${id}/Status`, statusData);
  }

  addEventAttendee(id: string, attendeeData: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/Events/${id}/Attendee`, attendeeData);
  }


  removeEventAttendee(id: string, attendeeData: AttendeesRemoveInputDTO): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/Events/${id}/Attendee`, { body: attendeeData });
  }

  addEventAttachment(id: string, attachmentData: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/Events/${id}/Attachment`, attachmentData);
  }

  removeEventAttachment(id: string, attachmentData: any): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/Events/${id}/Attachment`, { body: attachmentData });
  }


  getDocumentAttachment(aplicationId,userId,value,PageNumber,PageSize): Observable<any> {
    const params = new HttpParams()
    .set('userId', userId)
    .set('Value', value)
    .set('PageNumber', PageNumber)
    .set('PageSize', PageSize);
    return this.http.get<any>(`${this.baseUrl}/Documents/Attachments${aplicationId}`, {params});
  }


  // @APIReturn(SharedCalendarListOutputDTOSchema, 'Users/${SessionStore.user.UserId}/ShareCalendar')
  async getSharedCalendar() {
    return await this.httpService.get<SharedCalendarListOutputDTO>(`${this.baseUrl}/Users/${SessionStore.user.UserId}/ShareCalendar`);
  }

  async getDraftListByProcessId(input: IGetDraftListByProcessIdSchema) {
    return await this.httpService.get<IGetDraftListByProcessIdOutput>(`${this.baseUrl}/Contents/FolderId/${input.processId}`);
  }

  async draftSaveById(input: IDraftSaveByIdInput) {
    return await this.httpService.put<IGetDraftListByProcessIdOutput>(`${this.baseUrl}/Contents/${input.id}`, input);
  }
}

import { Injectable } from '@angular/core';
import { localstoreService } from './localstore.service'
import { SHA1 }  from  'crypto-js'
import { customTask } from '../models/dailyworktask.model';
import { ObjectQueryService } from '../services/object-query.service';
import { momentG } from 'src/plugin/momentG';
import { v4 as uuidv4 } from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class DespachoStoreService {

  // main data
  private _list: customTask[] = []
  // local storage keyName
  private keyName: string; 
  private _count = 0

  callbacks: {
    [key: string]: {
      funx: Function
      id: string
    }
  } = {} 

  ObjectQueryService  = new  ObjectQueryService()

  newList = []

  constructor() {

    this.keyName = (SHA1("DespachoStoreService"+ 'home/eventSource')).toString()
    window['ObjectQueryService'] = this.Query()

    setTimeout(() => { 
      let restore = localstoreService.get(this.keyName, {})
      this._list = restore.list || []
      this._count = parseInt(restore.count)  || 0

      this.runCallback()
    }, 10)
    
  }

  registerCallback({funx, id = uuidv4()}) {

    this.callbacks[id] = { funx, id}

    return {
      delete: ()=> {
        delete this.callbacks[id]
      }
    }
  }

  
  runCallback() {
    for (const [key, value] of Object.entries(this.callbacks)) {
      value.funx()
    }
  }

  Query() {
    return {
      Update: (select:Function, update:Function) => {
        this.ObjectQueryService.Update(select, update, this._list )
        this.save({dynamicCount : true})
      },
      select:  (select) => {
        this.ObjectQueryService.select(select, this._list )
        this.save({dynamicCount : true})
      },
      Delete : (select) => {
        this.ObjectQueryService.Delete(select, this._list )
        this.save({dynamicCount : true})
      },
      Insert: (insert: customTask | customTask[]) =>{
        this.ObjectQueryService.Insert(insert, this._list)
        this.save({dynamicCount : true})
      }
    }
  }

  ObjectQuery() {}

  get list(): any[] {
    return this._list || []
  }

  get count() {
    return this._list.length || 0
  }
  
  set count(value: number) {
    this._count = value
    this.save({})
  }

  reset(eventsList: any) {
    this._list = eventsList
    this.save({dynamicCount:true})

    if(window['all-process-gabinete']) {
      window['all-process-gabinete']()
    }
  }

  private save = ({dynamicCount = false})=> {

    if(dynamicCount) {
      this._count = this._list.length
    }

    this.runCallback()
    setTimeout(()=>{ 
      localstoreService.set(this.keyName,{
        list: this._list,
        count: this._count
      })
    }, 10)
    
    this.updateNewCount()
  }

  
  updateNewCount() {
    this.newList = this._list.filter((e) =>{
      return this.lessthen24Hours(e.TaskReceiveDate)
    })
 
  }


  lessthen24Hours(isoDateString:string) {

    if(!isoDateString) {
      return false
    }
    
    const creationDate = new Date(isoDateString)
    return momentG(new Date(), 'dd MMMM yyyy') == momentG(new Date(creationDate), 'dd MMMM yyyy')
  }


}

export const DespachoStore = new DespachoStoreService()

import { Injectable } from '@angular/core';
import { object, z } from 'zod';
import { ValidateSchema } from 'src/app/services/decorators/validate-schema.decorator';
import { MessageRemoteDataSourceService } from '../../../data/repository/message/message-remote-data-source.service';
import { MessageSocketRepositoryService } from '../../../data/repository/message/message-live-signalr-data-source.service';


const MessageReactionInputDTOSchema = z.object({
  memberId: z.number(),
  messageId: z.string(),
  roomId: z.string(),
  reaction: z.string(),
  requestId: z.string().optional()
})

export type MessageReactionInput = z.infer< typeof MessageReactionInputDTOSchema>

@Injectable({
  providedIn: 'root'
})
export class MessageReactionUseCaseService {

  constructor(
    public repository: MessageSocketRepositoryService
  ) { }

  @ValidateSchema(MessageReactionInputDTOSchema)
  execute(input: MessageReactionInput) {
    return this.repository.reactToMessageSocket(input)
  }
}

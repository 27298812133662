import { Injectable } from '@angular/core';
import { timer, Subscription } from 'rxjs';

/**
 * Interface representing a cron job.
 */
interface CronJob {
  name: string;
  timeExecute: Date;
  callbackFunction: () => void;
  subscription: Subscription;
}

/**
 * Service to manage cron jobs using RxJS. Allows scheduling, replacing, and
 * removing jobs that execute a callback function at a specified time.
 */
@Injectable({
  providedIn: 'root',
})
export class CronJobService {
  /**
   * A map to store cron jobs with a key composed of the job name and execution time.
   */
  private jobs: Map<string, CronJob> = new Map();

  /**
   * Generates a unique key for a cron job based on its name and execution time.
   * @param name - The name of the cron job.
   * @param timeExecute - The time when the job should execute.
   * @returns A string key combining the name and timeExecute.
   */
  private generateJobKey(name: string, timeExecute: Date): string {
    return `${name}_${timeExecute.getTime()}`;
  }

  /**
   * Creates a new cron job or replaces an existing one if the job with the same
   * name and execution time already exists. The job will execute the provided
   * callback function at the specified time.
   *
   * @param name - The name of the cron job.
   * @param timeExecute - The time when the job should execute.
   * @param callbackFunction - The function to be called when the job executes.
   */
  createCronJob(name: string, timeExecute: Date, callbackFunction: () => void) {
    const now = new Date();
    const delay = timeExecute.getTime() - now.getTime();

    if (delay < 0) {
      console.error('Time to execute is in the past!');
      return;
    }

    const jobKey = this.generateJobKey(name, timeExecute);

    // If the job already exists, replace it
    if (this.jobs.has(jobKey)) {
      this.removeCronJob(name, timeExecute);
    }

    // Create a new timer and store the subscription
    const subscription = timer(delay).subscribe(() => {
      callbackFunction();
      this.removeCronJob(name, timeExecute);
    });

    this.jobs.set(jobKey, { name, timeExecute, callbackFunction, subscription });
  }

  /**
   * Removes a cron job by its name and execution time.
   *
   * @param name - The name of the cron job.
   * @param timeExecute - The time when the job was supposed to execute.
   */
  removeCronJob(name: string, timeExecute: Date) {
    const jobKey = this.generateJobKey(name, timeExecute);
    const job = this.jobs.get(jobKey);
    if (job) {
      job.subscription.unsubscribe();
      this.jobs.delete(jobKey);
    }
  }

  /**
   * Retrieves all currently scheduled cron jobs.
   *
   * @returns An array of all scheduled cron jobs.
   */
  getCronJobs(): CronJob[] {
    return Array.from(this.jobs.values());
  }

  /**
   * Removes all cron jobs with the specified name, regardless of their execution time.
   *
   * @param name - The name of the cron jobs to remove.
   */
  removeAllJobsByName(name: string) {
    for (const [key, job] of this.jobs) {
      if (job.name === name) {
        job.subscription.unsubscribe();
        this.jobs.delete(key);
      }
    }
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PublicationHolderService {

  count = 0
  PublicationFormMV: {
    id: string,
    percentage: number,
    retry: boolean,
    retryFunction: Function
  }[] = []

  constructor() {

    window['upload-header-set-percentage'] = (id: string, percentage: number) => {
      this.PublicationFormMV = this.PublicationFormMV.map((e)=> {
        if(e.id == id) {
          console.log("percentage", percentage)
          e.percentage = percentage
        }
        return e
      })


      this.uploadPercentage()
    }

    window['upload-header-set-add'] = (id: string, percentage: number, save: Function) => {
      this.remove(id)
      this.PublicationFormMV.push({id, percentage, retry: false, retryFunction: save})
      this.uploadPercentage()
    }


    window['upload-header-set-remove'] = (id: string) => {
      this.remove(id)
    }


    window['upload-header-set-retry'] = (id: string) => {

      this.PublicationFormMV = this.PublicationFormMV.map((e)=> {
        if(e.id == id) {
          e.retry = true
        }
        return e
      })

      this.uploadPercentage()
    }

    window['upload-header-remove-retry'] = (id: string) => {
      this.uploadPercentage()

      this.PublicationFormMV = this.PublicationFormMV.map((e)=> {
        if(e.id == id) {
          e.retry = false
        }
        return e
      })


      this.uploadPercentage()

    }

  }

  remove(id: string) {
    this.PublicationFormMV = this.PublicationFormMV.filter((e)=>  e.id != id)
    this.uploadPercentage()
  }

  hasPublication() {
    return this.PublicationFormMV.length >= 1
  }


  uploadPercentage = () => {

    const percentageArray = this.PublicationFormMV.map((e) => e.percentage)

    // Check if the array is not empty
    if (percentageArray.length === 0) {
      this.count = 0
    } else {
      let sum = percentageArray.reduce((acc, percentage) => acc + percentage, 0);

      // Calculate the average percentage
      let averagePercentage = sum / percentageArray.length;

      this.count =  Math.ceil(averagePercentage)
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-discart-expedient-modal',
  templateUrl: './discart-expedient-modal.page.html',
  styleUrls: ['./discart-expedient-modal.page.scss'],
})
export class DiscartExpedientModalPage implements OnInit {

  serialNumber:string;
  folderId:string;
  action:string;
  
  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  close() {
    this.modalController.dismiss('No');
  }
  
  save() {
    this.modalController.dismiss('Yes');
  }

}

import { Injectable } from '@angular/core';
import { captureAndReraiseAsync } from 'src/app/services/decorators/captureAndReraiseAsync';
import { z } from 'zod';
import { IMemberRemoteRepository } from 'src/app/core/chat/repository/member/member-remote-repository';


export const AddMemberToRoomInputDTOSchema = z.object({
  id: z.string(),
  members: z.array(z.number()),
});

export type AddMemberToRoomInputDTO = z.infer<typeof AddMemberToRoomInputDTOSchema>


@Injectable({
  providedIn: 'root'
})
export class AddMemberUseCaseService {

  constructor(
    private memberRemoteDataSourceService: IMemberRemoteRepository,
  ) { }



  @captureAndReraiseAsync('RoomRepositoryService/addMemberToRoom')
  async execute(data: AddMemberToRoomInputDTO) {

    // return this.roomLiveSignalRDataSourceService.addMemberToRoom(data)

    const result = await this.memberRemoteDataSourceService.addMemberToRoom(data)

    return result
  }

}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IAttachmentLocalRepository } from 'src/app/core/chat/repository/typing/typing-local-repository';
import { AttachmentTable, AttachmentTableSchema } from 'src/app/infra/database/dexie/instance/chat/schema/attachment';
import { chatDatabase } from 'src/app/infra/database/dexie/service';
import { DexieRepository } from 'src/app/infra/repository/dexie/dexie-repository.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentLocalDataSource extends DexieRepository<AttachmentTable, AttachmentTable> implements IAttachmentLocalRepository {

  messageSubject = new Subject();

  constructor() {
    super(chatDatabase.attachment, AttachmentTableSchema)
  }

}


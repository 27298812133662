import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import "hammerjs"; // HAMMER TIME
import { SendIntent } from "send-intent";
import { Filesystem } from '@capacitor/filesystem';
import 'src/app/services/shareIntent'
import 'src/app/services/monitoring/opentelemetry/matrix';

if (environment.production) {
  enableProdMode();
}
/*  Sentry.init(
  {
    dsn: 'https://5b345a3ae70b4e4da463da65881b4aaa@o4504340905525248.ingest.sentry.io/4504345615794176',
    // To set your release and dist versions
    release: 'gabinetedigital@1.0.0',
    dist: '1',
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', 'https://gd-api.oapr.gov.ao/api/'],
      }) as Integration,
    ]
  },
  // Forward the init method to the sibling Framework.
  SentrySibling.init
); */

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('firebase-messaging-sw.js')
    .then(registration => {
      console.log('Service Worker registrado com sucesso:', registration);


      // Send data to the service worker
      if (registration.active) {
        registration.active.postMessage({ type: 'INIT_WEBSOCKET', url: 'wss://example.com/socket' });
      } else {
        navigator.serviceWorker.ready.then(registration => {
          registration.active.postMessage({ type: 'INIT_WEBSOCKET', url: 'wss://example.com/socket' });
        });
      }
    })
    .catch(error => {
      console.error('Erro ao registrar o Service Worker:', error);
    });
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

  // Call the element loader after the platform has been bootstrapped

defineCustomElements(window);

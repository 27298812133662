import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Despacho } from 'src/app/models/despacho.model';
import { EventPerson } from 'src/app/models/eventperson.model';
import { Participant } from 'src/app/models/participant.model';
import { Folder } from 'src/app/models/folder.model';
import { ProcessesService } from 'src/app/services/processes.service';
import { DiscartExpedientModalPage } from 'src/app/pages/gabinete-digital/discart-expedient-modal/discart-expedient-modal.page';
import { ExpedienteDetailPage } from 'src/app/pages/gabinete-digital/expediente/expediente-detail/expediente-detail.page';
import { SearchList } from 'src/app/models/search-document';
import { SearchPage } from 'src/app/pages/search/search.page';
import { LoginUserRespose } from 'src/app/models/user.model';
import { AttendeesPageModal } from 'src/app/pages/events/attendees/attendees.page';
import { ToastService } from 'src/app/services/toast.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { PermissionService } from 'src/app/services/permission.service';
import { DespachoService } from 'src/app/Rules/despacho.service';
import { PedidoService } from 'src/app/Rules/pedido.service'
import { fullTask } from 'src/app/models/dailyworktask.model';
import { ThemeService } from 'src/app/services/theme.service'
import { SessionStore } from 'src/app/store/session.service';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service';
import { TaskService } from 'src/app/services/task.service'

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "YYYY-MMMM-DD HH:mm"
  },
  display: {
    dateInput: "DD MMM YYYY H:mm",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
}


@Component({
  selector: 'app-create-process',
  templateUrl: './create-process.page.html',
  styleUrls: ['./create-process.page.scss'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ]
})
export class CreateProcessPage implements OnInit {
  modalTitle: string[] = [
    'Efectuar Despacho',
    'Solicitar Parecer',
    'Solicitar Deferimento'
  ];
  taskType: string;
  task: any;
  fulltask: fullTask;

  taskParticipants: any = [];
  taskParticipantsCc: any = [];

  taskDate: Date;
  taskDescription: string;
  loadedAttachments: any;
  subjectTypes: any;
  selectedTypes: string = '';
  placeholderSubject: string;

  postData: Despacho;
  dispatchFolder: Folder;
  participants: Participant[];

  contacts = [];
  // trigger hide and show for attendee component
  showAttendees = false;

  adding: "intervenient" | "CC" = "intervenient";
  profile: string;

  Form: FormGroup;
  validateFrom = false;
  validateField = false;

  emptyTextDescription = "Selecionar intervenientes";
  showEmptyContainer = true;

  documents: SearchList[] = [];

  loggeduser: LoginUserRespose;

  toppings = new FormControl();

  toppingList: string[] = ['Economia', 'Saúde', 'Educação', 'Finanças', 'Agricultura'];

  constructor(
    private modalController: ModalController,
    private processes: ProcessesService,
    private navParams: NavParams,
    private toastService: ToastService,
    public p: PermissionService,
    private despachoService: DespachoService,
    private pedidoService: PedidoService,
    public ThemeService: ThemeService,
    private httpsErrorHanlde: HttpErrorHandle,
    public TaskService: TaskService
  ) {
    this.loggeduser = SessionStore.user;
    this.task = this.navParams.get('task');
    this.fulltask = this.navParams.get('fulltask');


    // console.log('this.fulltask', this.fulltask)


    // if(this.fulltask?.Documents) {
    //   this.documents = this.fulltask.Documents
    // }

    if (this.task.SerialNumber) {
      this.task.serialNumber = this.task.SerialNumber
    }


    this.taskType = this.navParams.get('taskAction');
    // console.log('this.taskType ',this.taskType )

    this.postData = new Despacho();
    this.participants = this.participants = new Array();
    //Initialize SubjectTypes Array with the value "Indefinido"
    if(this.loggeduser.Profile == 'PR') {
      this.selectedTypes = '99999850';
      this.placeholderSubject = 'Indefinido'
    } else {
      this.placeholderSubject = 'Selecione o tipo de assunto*'
    }


    let NumberPDPP;

    if (this.fulltask) {
      if (this.fulltask.workflowInstanceDataFields) {
        NumberPDPP = this.fulltask.workflowInstanceDataFields.DispatchNumber
      }
    }

    let SourceId;
    let SourceType;
    let SourceSecFsId;
    const taskId = this.task.workflowInstanceDataFields?.FolderID || this.task?.FolderId || this.task?.FolderID

    if (taskId) {
      SourceId = taskId
      SourceType = 'FOLDER'
      SourceSecFsId = 361
    } else {
      SourceId = this.task.workflowInstanceDataFields.SourceID
      SourceType = 'DOC'
      SourceSecFsId = 8
    }

    this.dispatchFolder = {
      Nad: 30,
      Subject: '',
      Message: '',
      SourceSecFsId: SourceSecFsId, //361
      SourceType: SourceType, //FOLDER
      SourceId: SourceId,
      DeadlineType: '',
      SubjectTypes: this.selectedTypes,
      NumberPDPP: this.task?.workflowInstanceDataFields?.DispatchNumber || NumberPDPP || this.fulltask?.workflowInstanceDataFields?.DispatchNumber
    };

    this.postData.DispatchFolder = this.dispatchFolder;
    this.postData.UsersSelected = this.participants;
    /* By Default TypeDeadline should be 'Normal' */
    this.postData.Priority = '99999861';
    /* Initialize 'Subject' with the title of the expedient */

    this.postData.DispatchFolder.Subject = this.task?.workflowInstanceDataFields?.Subject || this.fulltask?.workflowInstanceDataFields?.Subject;
    this.profile = this.navParams.get('profile');
  }

  async setAdding(type: "intervenient" | "CC") {
    this.adding = type;
  }

  ngOnInit() {
    // console.log('crete process')
    this.getSubjectType();
    /* setTimeout(() => {
      this.selectedTypes = ['99999850'];
    }, 500); */


    this.taskDate = new Date(this.task.taskStartDate);
  }

  onSelectedTypesChanged(ev: any) {
    if (ev.length > 1) {
      this.selectedTypes = ev.filter(data => data != '99999850');
    }
    if (ev.length == 0) {
      this.selectedTypes = "99999850";
    }
  }

  close() {
    this.modalController.dismiss(null);
  }

  getSubjectType() {
    this.processes.GetSubjectType().subscribe(res => {
      // console.log('subjectTypes',res)
      this.subjectTypes = res;
      this.placeholderSubject = 'Selecione o tipo de assunto*'
      this.selectedTypes = '99999844';
    });
  }

  cancelTask() {
    this.modalController.dismiss({action:'cancel'});
  }

  runValidation() {
    this.validateFrom = true
  }

  defaultParticipants() {
    if (this.p.userPermission([this.p.permissionList.Gabinete.pr_tasks])) {
      return ['MDGPR']
    }

    return null
  }
  injectValidation() {

    this.Form = new FormGroup({
      Subject: new FormControl(this.postData.DispatchFolder.Subject, [
        Validators.required,
        // Validators.minLength(4)
      ]),
      Message: new FormControl(this.postData.DispatchFolder.Message, [
        Validators.required,
      ]),
      Priority: new FormControl(this.postData.Priority, [
        Validators.required,
      ]),
      participantes: new FormControl(this.defaultParticipants() || this.taskParticipants, [
        Validators.required
      ]),
      selectedTypes: new FormControl(this.selectedTypes, [
        Validators.required,
      ]),

    })
  }

  async saveTask() {

    if (!this.p.userRole(['PR'])) {
      this.injectValidation()
      this.runValidation()
      if (this.Form.invalid) return false
    }


    if (this.postData.Priority == '99999861') {
      this.dispatchFolder.DeadlineType = 'Normal';
    }
    else if (this.postData.Priority == '99999862') {
      this.dispatchFolder.DeadlineType = 'Urgente';
    }
    else if (this.postData.Priority == '99999863') {
      this.dispatchFolder.DeadlineType = 'Muito Urgente';
    }
    else if (this.postData.Priority == '99999864') {
      this.dispatchFolder.DeadlineType = 'Urgentíssimo';
    }

    let attendees = this.taskParticipants.concat(this.taskParticipantsCc);

    attendees = attendees.map(function (val) {
      return {
        UserEmail: val.EmailAddress,
        UserType: val.IsRequired ? "I" : "CC"
      };
    });


    let docs = {
      ProcessInstanceID: "",
      Attachments: []
    }

    const DocumentToSave = this.documents.map((e: any) => {
      return {
        ApplicationId: e.ApplicationType || e.ApplicationId,
        SourceId: e.Id || e.DocId || e.SourceId
      }
    });

    docs.Attachments = DocumentToSave;

    this.dispatchFolder.SubjectTypes = this.selectedTypes;
    const loader = this.toastService.loading()

    if (this.fulltask.activityInstanceName == "Assinar Diploma") {
      this.postData = {
        DistributionType: "Paralelo",
        CountryCode: 'AO',
        Priority: this.postData.Priority,
        UserEmail: this.loggeduser.Email,
        UsersSelected: attendees,
        DispatchFolder: this.dispatchFolder,
        AttachmentList: docs
      }

      try {
        // await this.processes.postDespatcho(this.postData).toPromise();
        await this.despachoService.createDespacho(this.postData).toPromise();
        this.executado(this.dispatchFolder.Message, docs.Attachments)
        this.httpsErrorHanlde.httpsSucessMessagge('Efetuar Despacho')
        loader.remove();
      } catch (error) {
        this.httpsErrorHanlde.httpStatusHandle(error)
        loader.remove()
      }

    } else {

      if (this.loggeduser.Profile != 'PR') {
        switch (this.taskType) {
          case '0': // Despacho
            this.postData = {
              DistributionType: "Paralelo",
              CountryCode: 'AO',
              Priority: this.postData.Priority,
              UserEmail: this.loggeduser.Email,
              UsersSelected: attendees,
              DispatchFolder: this.dispatchFolder,
              AttachmentList: docs
            }

            try {
              await this.processes.postDespatcho(this.postData).toPromise();
              await this.despachoService.EfectuarDespacho({ note: this.dispatchFolder.Message, serialnumber: this.task.serialNumber, activityInstanceName: this.task.activityInstanceName }).toPromise();

              this.modalController.dismiss();
              this.httpsErrorHanlde.httpsSucessMessagge('Efetuar Despacho')
            } catch (error) {
              this.httpsErrorHanlde.httpStatusHandle(error)

            } finally {
              loader.remove()
            }

            break;
          case '1': // pedido de Parecer
            this.postData = {
              DistributionType: "Paralelo",
              CountryCode: 'AO',
              Priority: this.postData.Priority,
              UserEmail: this.loggeduser.Email,
              UsersSelected: attendees,
              DispatchFolder: this.dispatchFolder,
              AttachmentList: docs
            }

            try {
              if (this.task.activityInstanceName == 'Tarefa de Despacho' || this.task.activityInstanceName == 'Reexecutar Despacho') {
                // create parecer
                await this.despachoService.createParecer(this.postData).toPromise();
                // task complete
                await this.despachoService.solicitarParecer({ note: this.postData.DispatchFolder.Message, serialnumber: this.task.serialNumber, activityInstanceName: this.task.activityInstanceName }).toPromise();
              } else {

                await this.pedidoService.createParecer(this.postData).toPromise();
                await this.pedidoService.taskCompleteParecer({ serialNumber: this.task.serialNumber, note: this.postData.DispatchFolder.Message }).toPromise();
              }

              this.modalController.dismiss();
              this.httpsErrorHanlde.httpsSucessMessagge('Solicitar Parecer')
            }
            catch (error) {
              this.httpsErrorHanlde.httpStatusHandle(error)
            } finally {
              loader.remove()
            }

            break;
          case '2': // Pedido de Deferimento
            this.postData = {
              DistributionType: "Paralelo",
              CountryCode: 'AO',
              Priority: this.postData.Priority,
              UserEmail: this.loggeduser.Email,
              UsersSelected: attendees,
              DispatchFolder: this.dispatchFolder,
              AttachmentList: docs
            }

            try {
              await this.pedidoService.createDeferimento(this.postData).toPromise();
              await this.pedidoService.taskCompleteDeferimento({ note: this.postData.DispatchFolder.Message, serialNumber: this.task.serialNumber }).toPromise();

              this.modalController.dismiss();
              this.httpsErrorHanlde.httpsSucessMessagge('Pedido de Deferimento')
            }
            catch (error) {
              this.httpsErrorHanlde.httpStatusHandle(error)
            }
            finally {
              loader.remove()
            }
            break;
        }
      } else if (this.loggeduser.Profile == 'PR') {
        console.log('Despacho PR');
        switch (this.taskType) {
          case '0': // Despacho PR

            this.postData = {
              DistributionType: "Paralelo",
              CountryCode: 'AO',
              Priority: this.postData.Priority,
              UserEmail: this.loggeduser.Email,
              UsersSelected: attendees,
              DispatchFolder: this.dispatchFolder,
              AttachmentList: docs
            }

           /*  if (this.postData.DispatchFolder.Message) { */
              try {
                await this.despachoService.createDespacho(this.postData).toPromise();
                await this.despachoService.CompleteTask({ serialNumber: this.task.serialNumber, note: this.dispatchFolder.Message }).toPromise();

                this.modalController.dismiss();
                this.httpsErrorHanlde.httpsSucessMessagge('Efetuar Despacho')
              } catch (error) {
                this.httpsErrorHanlde.httpStatusHandle(error)
              } finally {
                loader.remove();
              }
           /*  }
            else {
              this.validateField = true;
              this.toastService._badRequest('Por favor adicione uma descrição');
            } */
            loader.remove();
            break;
          case '1': // Pedido de Parecer

            this.postData = {
              DistributionType: "Paralelo",
              CountryCode: 'AO',
              Priority: this.postData.Priority,
              UserEmail: this.loggeduser.Email,
              UsersSelected: attendees,
              DispatchFolder: this.dispatchFolder,
              AttachmentList: docs
            }

            try {

              if (this.task.activityInstanceName == 'Tarefa de Despacho' || this.task.activityInstanceName == 'Reexecutar Despacho') {
                await this.despachoService.createParecer(this.postData).toPromise();
                await this.despachoService.solicitarParecer({ note: this.postData.DispatchFolder.Message, serialnumber: this.task.serialNumber, activityInstanceName: this.task.activityInstanceName }).toPromise();
              } else {
                await this.pedidoService.createParecer(this.postData).toPromise();
                await this.pedidoService.taskCompleteParecer({ serialNumber: this.task.serialNumber, note: this.postData.DispatchFolder.Message }).toPromise();
              }

              this.modalController.dismiss();
              this.httpsErrorHanlde.httpsSucessMessagge('Solicitar Parecer')
            }
            catch (error) {
              this.httpsErrorHanlde.httpStatusHandle(error)
            } finally {
              loader.remove()
            }

            break;
          case '2': // Pedido de Deferimento

            this.postData = {
              DistributionType: "Paralelo",
              CountryCode: 'AO',
              Priority: this.postData.Priority,
              UserEmail: this.loggeduser.Email,
              UsersSelected: attendees,
              DispatchFolder: this.dispatchFolder,
              AttachmentList: docs
            }

            try {
              await this.pedidoService.createDeferimento(this.postData).toPromise();
              await this.pedidoService.taskCompleteDeferimento({ note: this.postData.DispatchFolder.Message, serialNumber: this.task.serialNumber }).toPromise();

              this.modalController.dismiss();
              this.httpsErrorHanlde.httpsSucessMessagge('Pedido de Deferimento')
            }
            catch (error) {
              this.httpsErrorHanlde.httpStatusHandle(error)
            } finally {
              loader.remove()
            }

            break;
        }
      }
    }


  }

  async executado(note: string, documents: any) {
    let body = {
      "serialNumber": this.fulltask.serialNumber,
      "action": "Conhecimento",
      "ActionTypeId": 104,
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList": documents,
    }

    const loader = this.toastService.loading()

    try {
      await this.processes.CompleteTask(body).toPromise()
      this.close();
      this.toastService._successMessage()
    } catch (error) {
      if (error.status == 0) {
        this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
      } else {

        this.toastService._badRequest()
      }
    } finally {
      loader.remove()
    }

  }


  async FinalizarDespacho(loader: HTMLDivElement, message?) {

    let body;

    if (this.task.activityInstanceName == 'Tarefa de Despacho' ||
      this.task.activityInstanceName == 'Reexecutar Despacho') {

      body = {
        "serialNumber": this.task.serialNumber,
        "action": "Conhecimento",
        "ActionTypeId": 104,
        "dataFields": {
          "ReviewUserComment": '',
        },
        "AttachmentList": null,
      }
    }
    else if (this.task.activityInstanceName == 'Concluir Despacho' ||
      this.task.activityInstanceName == 'Concluir Parecer' ||
      this.task.activityInstanceName == 'Concluir Deferimento' ||
      this.task.activityInstanceName == 'Reapreciar Deferimento' ||
      this.task.activityInstanceName == 'Tarefa de Deferimento'
    ) {

      body = {
        "serialNumber": this.task.serialNumber,
        "action": "Despacho",
        "ActionTypeId": 94,
        "dataFields": {
          "ReviewUserComment": '',
        },
        "AttachmentList": null,
      }

    } else {

    }


    try {
      await this.processes.CompleteTask(body).toPromise();
      this.httpsErrorHanlde.httpsSucessMessagge('Efetuar Despacho')
    } catch (error) {
      this.httpsErrorHanlde.httpStatusHandle(error)
    } finally {
      loader.remove()
    }

  }

  async addParticipants() {

    this.adding = "intervenient";
    this.contacts = this.taskParticipants;

    if (window.innerWidth <= 800) {

      this.showAttendees = false;

      const modal = await this.modalController.create({
        component: AttendeesPageModal,
        componentProps: {
          adding: this.adding,
          taskParticipants: this.taskParticipants,
          taskParticipantsCc: this.taskParticipantsCc,
          taskType: this.taskType
        },
        cssClass: 'modal attendee modal-desktop',
        backdropDismiss: false
      });


      modal.onDidDismiss().then((data) => {

        if (data) {
          data = data['data'];

          const newAttendees: EventPerson[] = data['taskParticipants'];
          const newAttendeesCC: EventPerson[] = data['taskParticipantsCc'];

          this.setIntervenient(newAttendees);
          this.setIntervenientCC(newAttendeesCC);
        }
      }, (error) => {
        console.log(error)
      });

      await modal.present();

    } else {
      this.showAttendees = true
    }
  }

  async addParticipantsCc() {

    this.adding = "CC";
    this.contacts = this.taskParticipantsCc;

    if (window.innerWidth <= 800) {
      this.showAttendees = false;

      const modal = await this.modalController.create({
        component: AttendeesPageModal,
        componentProps: {
          adding: this.adding,
          taskParticipants: this.taskParticipants,
          taskParticipantsCc: this.taskParticipantsCc
        },
        cssClass: 'attendee modal-desktop',
        backdropDismiss: false
      });


      modal.onDidDismiss().then((data) => {

        if (data) {
          data = data['data'];

          const newAttendees: EventPerson[] = data['taskParticipants'];
          const newAttendeesCC: EventPerson[] = data['taskParticipantsCc'];

          this.setIntervenient(newAttendees);
          this.setIntervenientCC(newAttendeesCC);
        }
      }, (error) => {
        console.log(error)
      });

      await modal.present();


    } else {
      this.showAttendees = true
    }

  }

  async distartExpedientModal() {
    const modal = await this.modalController.create({
      component: DiscartExpedientModalPage,
      componentProps: {
        serialNumber: this.task.serialNumber,
        folderId: this.task.workflowInstanceDataFields.FolderID,
        action: 'complete',
      },
      cssClass: 'discart-expedient-modal',
      backdropDismiss: false
    });


    modal.onDidDismiss().then(res => {
      if (res['data'] == 'close') {
        this.modalController.dismiss(res['data'])
      }

    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }

  async setContact(data: EventPerson[]) {

    if (this.adding == "intervenient") {
      this.taskParticipants = data;
    } else if (this.adding == "CC") {

      this.taskParticipantsCc = data;
    }

  }

  dynamicSetIntervenient({ taskParticipants, taskParticipantsCc }) {
    this.taskParticipants = taskParticipants;
    this.taskParticipantsCc = taskParticipantsCc;
  }

  async setIntervenient(data) {
    this.taskParticipants = data;
  }

  async setIntervenientCC(data) {
    this.taskParticipantsCc = data;
  }

  async closeComponent() {
    this.showAttendees = false;
  }

  async viewExpedientDetail() {

    let classs;
    if (window.innerWidth <= 800) {
      classs = 'modal modal-desktop'
    } else {
      classs = 'modal modal-desktop showAsideOptions'
    }

    const modal = await this.modalController.create({
      component: ExpedienteDetailPage,
      componentProps: {
        serialNumber: this.task.serialNumber,
        profile: this.profile,
      },
      cssClass: classs,
      backdropDismiss: false
    });

    modal.onDidDismiss().then((res) => {

    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }


  removeAttachment(index: number) {

    this.documents = this.documents.filter((e, i) => index != i);

  }


  async getDoc() {
    const modal = await this.modalController.create({
      component: SearchPage,
      cssClass: 'modal-width-100-width-background modal',
      componentProps: {
        type: 'AccoesPresidenciais & ArquivoDespachoElect',
        showSearchInput: true,
        select: true
      }
    });

    modal.onDidDismiss().then((res) => {
      if (res) {
        const data = res.data;
        this.documents.push(data.selected);
      }
    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }

}

import { Injectable } from '@angular/core';
import { Result } from 'neverthrow';
import { ValidateSchema } from 'src/app/services/decorators/validate-schema.decorator';
import { HttpService } from 'src/app/services/http.service';
import { DataSourceReturn } from 'src/app/services/Repositorys/type';
import { IMemberRemoteRepository } from 'src/app/core/chat/repository/member/member-remote-repository';
import { AddMemberToRoomInputDTO, AddMemberToRoomInputDTOSchema } from '../../../domain/use-case/member/member-add-use-case.service';
import { MemberSetAdminDTO } from '../../../domain/use-case/member/member-admin-use-case.service';
import { UserRemoveListInputDTOSchema, UserRemoveListInputDTO } from '../../../domain/use-case/room/room-leave-by-id-use-case.service';
@Injectable({
  providedIn: 'root'
})
export class MemberListRemoteRepository implements IMemberRemoteRepository {

  private baseUrl = 'https://gdapi-dev.dyndns.info/stage/api/v2/Chat'; // Your base URL

  constructor(private httpService: HttpService) { }


  @ValidateSchema(AddMemberToRoomInputDTOSchema)
  async addMemberToRoom(data: AddMemberToRoomInputDTO): DataSourceReturn<AddMemberToRoomInputDTO> {
    return await this.httpService.post<any>(`${this.baseUrl}/Room/${data.id}/Member`, { members:data.members });
  }


  @ValidateSchema(UserRemoveListInputDTOSchema)
  async removeMemberFromRoom(data: UserRemoveListInputDTO): Promise<Result<any ,any>> {
    return await this.httpService.delete<any>(`${this.baseUrl}/Room/${data.id}/Member`, {members:data.members});
  }

  async setAmin(data: MemberSetAdminDTO): Promise<Result<any ,any>> {
    return await this.httpService.patch<any>(`${this.baseUrl}/Room/${data.roomId}/Member/${data.memberId}/admin`);
  }

}

import { Directive, ElementRef, Input } from '@angular/core';
import { StopvideoService } from '../stopvideo.service';

@Directive({
  selector: '[appVisibility]'
})
export class VisibilityDirective {

  intersectionObserver: IntersectionObserver;
  @Input() appVisibility: (arg: any) => void;

  constructor(
    private elementRef: ElementRef,
    private stopvideoService: StopvideoService
  ) {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Adjust as needed
    };


    this.intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.appVisibility(true);
          this.stopvideoService.registerVideo(this.elementRef.nativeElement)
        } else {
          this.elementRef.nativeElement.pause()
          // Pause video when not visible
          this.appVisibility(false); // You can implement pause logic here
        }
      });
    }, options);

    this.intersectionObserver.observe(this.elementRef.nativeElement);
  }

}

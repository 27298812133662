import { v4 as uuidv4 } from 'uuid';
import { ZodSchema, ZodType } from 'zod';



// export interface IEntity {
//   id: string;

//   createdAt: Date;

//   updatedAt: Date;

//   deletedAt?: Date;
// }

export const BaseEntity = <T>(schema: ZodSchema) => {
  abstract class Entity  {
    readonly id: string;

    readonly createdAt: Date;

    readonly updatedAt: Date;

    deletedAt?: Date;

    static nameOf = (name: keyof T) => name;

    validate<T>(entity: T): ZodType {
      return schema.parse(entity);
    }
  }

  return Entity;
};

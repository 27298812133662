import { Participant } from './participant.model';
import { Folder } from './folder.model';

export class Despacho{
    DistributionType: string;
    CountryCode: string;
    Priority: string;
    UserEmail: string;
    UsersSelected: Participant[];
    DispatchFolder: Folder;
    AttachmentList?: {
        ProcessInstanceID: string,
        Attachments: {
            ApplicationId: string,
            SourceId: string
        }[],
    }
}
import { MessageTable } from "src/app/infra/database/dexie/instance/chat/schema/message";
import { DexieRepository } from "src/app/infra/repository/dexie/dexie-repository.service";
import { MessageEntity } from "../../entity/message";
import { Observable as  DexieObservable, PromiseExtended } from 'Dexie';
import { Observable } from "rxjs";

export abstract class IMessageLocalRepository extends DexieRepository<MessageTable, MessageEntity> {
  abstract setAllSenderToFalse(): void
  abstract getItems(roomId: string): PromiseExtended<MessageEntity[]>
  abstract getItemsLive(roomId: string): DexieObservable<MessageEntity[]>
  abstract getOfflineMessages(): Promise<MessageEntity[]>
  abstract onCreateObservable(): Observable<MessageTable>
}


import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
;
import { ThemeService } from 'src/app/services/theme.service'
import { ToastService } from 'src/app/services/toast.service';
import { Observable as  DexieObservable } from 'Dexie';
import { ChatServiceService } from 'src/app/module/chat/domain/chat-service.service';
import { MemberListLocalRepository } from 'src/app/module/chat/data/repository/member/member-list-local-repository.service'
import { MemberTable } from 'src/app/infra/database/dexie/instance/chat/schema/members';

@Component({
  selector: 'app-set-room-owner',
  templateUrl: './set-room-owner.page.html',
  styleUrls: ['./set-room-owner.page.scss'],
})
export class SetRoomOwnerPage implements OnInit {

  textSearch:string = "";
  roomId:any;
  members:any;
  roomMembers$!:  DexieObservable<MemberTable[] | undefined>

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    public ThemeService: ThemeService,
    private toastService: ToastService,
    private chatServiceService: ChatServiceService,
    private MemberListLocalRepository: MemberListLocalRepository
  ) {
    this.roomId = this.navParams.get('roomId');
    this.members = this.navParams.get('members');
  }

  ngOnInit() {
    // this.chatService.refreshtoken();
    this.roomMembers$ = this.MemberListLocalRepository.getRoomMemberNoneAdminByIdLive(this.roomId) as any
  }

  async close() {
    this.modalController.dismiss();
  }

  onChange(event: any) {
    this.textSearch = event.detail.value;
  }

  separateLetter(record:MemberTable, recordIndex: number, records:MemberTable[]) {
    if(recordIndex == 0){
      return record.wxFullName[0];
    }

    let first_prev = records[recordIndex - 1].wxFullName[0];
    let first_current = record.wxFullName[0];

    if(first_prev != first_current){
      return first_current;
    }
    return null;
  }

  async setRoomOwner(user:MemberTable) {

    const result = await this.chatServiceService.setAdmin({
      roomId: user.roomId,
      memberId: user.wxUserId.toString()
    });

    if(result.isOk()) {
      this.chatServiceService.getRoomById(this.roomId);
      // this.modalController.dismiss('success');
    } else {
      this.toastService._badRequest('Não foi possível completar a ação, por favor tente novamente.');
    }

  }

}

<ion-header class="ion-no-border">
</ion-header>

<ion-content>
  <ion-refresher  name="refresher" slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-progress-bar type="indeterminate" *ngIf="showLoader"></ion-progress-bar>
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <div class="content-container viewport-font-size">
    <div *ngIf="publication.Title != ''" class="d-flex flex-column height-100">
      <div class="title-content px-20 width-100 ">
        <div class="back-icon cursor-pointer"  (click)="goBack()">
          <ion-icon class="font-35-em" *ngIf="ThemeService.currentTheme == 'default' "  slot="end" src='assets/images/icons-arrow-arrow-left.svg'></ion-icon>
          <ion-icon class="font-35-em" *ngIf="ThemeService.currentTheme == 'doneIt' " src="assets/images/theme/doneIt/icons-calendar-arrow-left.svg"></ion-icon>
          <ion-icon class="font-35-em" *ngIf="ThemeService.currentTheme == 'gov' "  slot="end" src='assets/images/theme/gov/icons-calendar-arrow-left.svg'></ion-icon>
        </div>
        <div class="div-title">
          <p class="title font-25-em"> {{publication.Title}} </p>
          <p class="post-data font-16-em">{{publication.DatePublication | date: 'dd-MM-yyyy | h:mm'}}</p>
        </div>
      </div>

      <div class="post-item overflow-y-auto height-100 ">

        <div  style="width: 100%;">
          <app-swiper
          [publicationList]=publication
        ></app-swiper>
        </div>

       <!--  <swiper-container [pagination]="{clickable: true, dynamicBullets: true }">
          <swiper-slide *ngFor="let files of publication.Files let k = index">
            <div>
              <img *ngIf="checkFileType.checkFileType(files.FileExtension ) == 'image'" class="post-img"
                src="{{'data:image/jpg;base64,' + files.FileBase64}}">

              <video *ngIf="checkFileType.checkFileType(files.FileExtension ) == 'video'" class="post-video" controls="controls" preload="metadata"
              playsinline webkit-playsinline="webkit-playsinline" (play)="StopvideoService.registerVideoWithEvent($event)" >
                <source [src]="files.FileBase64" type="video/mp4">
              </video>

            </div>
          </swiper-slide>
        </swiper-container> -->
        <!-- <div *ngIf="publication.FileBase64.length < 30" class="post-img">
          <img src="/assets/icon/icon-no-image.svg" alt="image">
        </div> -->
        <div class="post-description px-20">
          <pre class="text font-14-rem">{{publication.Message}}</pre>
        </div>
      </div>


    </div>

    <div *ngIf="publication.Title == ''" class="d-flex flex-column">
      <div class="title-content width-100">
        <div class="back-icon cursor-pointer">
          <ion-icon class="font-35-em" *ngIf="ThemeService.currentTheme == 'default' " (click)="close()" slot="end" src='assets/images/icons-arrow-arrow-left.svg'></ion-icon>
          <ion-icon class="font-35-em" *ngIf="ThemeService.currentTheme == 'doneIt' " src="assets/images/theme/doneIt/icons-calendar-arrow-left.svg"></ion-icon>
          <ion-icon class="font-35-em" *ngIf="ThemeService.currentTheme == 'gov' " (click)="close()" slot="end" src='assets/images/theme/gov/icons-calendar-arrow-left.svg'></ion-icon>
        </div>
        <div class="div-title">
          <ion-label class="title"><ion-skeleton-text animated style="width: 60%;"></ion-skeleton-text></ion-label>
          <p class="post-data"><ion-skeleton-text animated style="width: 20%;"></ion-skeleton-text></p>
        </div>
      </div>
      <div class="post-item overflow-y-auto">
        <div class="post-img">
          <img src="/assets/icon/icon-no-image.svg" alt="image">
        </div>
        <div class="post-description">
          <p><ion-skeleton-text animated></ion-skeleton-text></p>
          <p><ion-skeleton-text animated></ion-skeleton-text></p>
        </div>
      </div>

    </div>
  </div>
</ion-content>

<ion-footer class="viewport-font-size">
  <ion-toolbar class="footer-toolbar">
    <ion-buttons *ngIf="p.userPermission([p.permissionList.Actions.deletePost])" slot="start">
      <button *ngIf="publication.ProcessId" class="btn-delete" fill="clear" color="#ffe0e0" (click)="deletePost()">
        <ion-label class="font-16-em">Eliminar</ion-label>
      </button>
    </ion-buttons>
    <ion-buttons *ngIf="p.userPermission([p.permissionList.Actions.editPost])" slot="end">
      <button *ngIf="publication.ProcessId" class="btn-ok" fill="clear" color="#fff" (click)="editPost('3')">
        <ion-label class="font-16-em">Editar</ion-label>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>


import { Injectable } from '@angular/core';
import { AnimationController, ModalController, ToastController } from '@ionic/angular';
import { BadRequestPage } from '../shared/popover/bad-request/bad-request.page';
import { SuccessMessagePage } from '../shared/popover/success-message/success-message.page';
import { ThemeService } from 'src/app/services/theme.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    public toastController: ToastController,
    public ThemeService: ThemeService,
  ) { }

  ngOnInit() {}

  async presentToast(infoMessage: string) {
    const toast = await this.toastController.create({
      message: infoMessage,
      duration: 2000
    });
    toast.present();
  }


  async _successMessage(message?: any, callback?) {

    let notification = document.createElement('div')
    notification.className = 'notification'
    notification.innerHTML = `

    <div class="main-content width-100 pa-20 cy-message">
      <p class="message d-flex align-center success">
        <ion-icon slot="end" class="title-icon pr-10" src="/assets/images/nofitication-success.svg"></ion-icon>
        <p class="text">{{ message }}</p>
      </p>

    </div>
    `

    document.body.append(notification)
    notification.querySelector('.text').innerHTML =  message || 'Processo efetuado'
    setTimeout(()=>{
      if (callback) {
        callback()
      }

      notification.style.right = "-100%"

      setTimeout(()=>{
        notification.remove()
      },1000)

    },6000)

  }

  removeBeforeHash(text) {
    const hashIndex = text.indexOf('#');
    if (hashIndex !== -1) {
        return text.substring(hashIndex);
    }
    return text;
  }

  getEverythingBeforeHash(text) {
    const hashIndex = text.indexOf('#');
    if (hashIndex !== -1) {
        return text.substring(0, hashIndex).trim();
    }
    return text;
  }


  async _badRequest(message?: string, callback?) {

    let notification = document.createElement('div')
    notification.className = 'notification'
    notification.innerHTML = `

    <div class="main-content width-100 pa-20">
      <p class="message d-flex align-center faild">
      <ion-icon slot="end" class="title-icon pr-10" src="/assets/images/notification-error.svg"></ion-icon>
        <p class="text">{{ message }}</p>
      </p>

    </div>
    `

    notification.style.animationName = 'notification-top'
    console.error(this.removeBeforeHash(message))
    document.body.append(notification)
    notification.querySelector('.text').innerHTML = this.getEverythingBeforeHash(message)  || 'Processo não efetuado'
    setTimeout(()=>{
      if (callback) {
        callback()
      }

      notification.style.right = "-100%"

      setTimeout(()=>{
        notification.remove()
      },1000)

    },6000)

  }

  async _chatMessage(message?: any, sender?:string) {

    let notification = document.createElement('div')
    notification.className = 'notification'
    notification.innerHTML = `

    <div class="main-content width-100 pa-20">
      <p class="message d-flex align-bottom success">
        <ion-icon slot="end" class="title-icon pr-10" src="/assets/images/nofitication-success.svg"></ion-icon>
        <p class="text">{{ message.message }}</p>
      </p>
      <p class="text">{{ message.sender }} </p>

    </div>
    `

    document.body.append(notification)
    notification.querySelector('.text').innerHTML =  message || 'Processo efetuado'
    setTimeout(()=>{
      /* if (callback) {
        callback()
      } */

      notification.style.right = "-100%"

      setTimeout(()=>{
        notification.remove()
      },1000)

    },6000)

  }

  async successMessage(message?: any, callback?) {

    let notification = document.createElement('div')
    notification.className = 'notification'
    notification.innerHTML = `

    <div class="main-content width-100 pa-20">
      <p class="message d-flex align-center success">
        <ion-icon slot="end" class="title-icon pr-10" src="/assets/images/nofitication-success.svg"></ion-icon>
        <p class="text">{{ message }}</p>
      </p>

    </div>
    `

    // document.body.append(notification)
    notification.querySelector('.text').innerHTML =  message || 'Processo efetuado'
    setTimeout(()=>{
      if (callback) {
        callback()
      }

      notification.style.right = "-100%"

      setTimeout(()=>{
        notification.remove()
      },1000)

    },6000)

  }

  async badRequest(message?: string, callback?) {

    let notification = document.createElement('div')
    notification.className = 'notification'
    notification.innerHTML = `

    <div class="main-content width-100 pa-20">
      <p class="message d-flex align-center faild">
      <ion-icon slot="end" class="title-icon pr-10" src="/assets/images/notification-error.svg"></ion-icon>
        <p class="text">{{ message }}</p>
      </p>

    </div>
    `

    notification.style.animationName = 'notification-top'

    // document.body.append(notification)
    notification.querySelector('.text').innerHTML =  message || 'Processo não efetuado'
    setTimeout(()=>{
      if (callback) {
        callback()
      }

      notification.style.right = "-100%"

      setTimeout(()=>{
        notification.remove()
      },1000)

    },6000)

  }

  async ViewEventOption() {
    // this.ThemeService.currentTheme == 'default'
    // this.ThemeService.currentTheme == 'gov'
    // this.ThemeService.currentTheme == 'doneIt'

    if(this.ThemeService.currentTheme == 'default') {

    } else if (this.ThemeService.currentTheme == 'gov') {}
  }

  async notificationMessage(message?: any, callback?: any,data?: any) {

    let notification = document.createElement('div')
    notification.className = 'notificationPush'
    notification.innerHTML = `

    <div class="main-content width-100 pa-20">
      <p class="message d-flex align-left">
        <p class="text">{{ message }}</p>
      </p>

    </div>
    `

    let funcx  = ()=>{
      callback(data);
      document.body.removeEventListener('click', funcx);
      notification.remove();
    }

    // document.body.append(notification)
    document.body.addEventListener('click', funcx);

    notification.querySelector('.text').innerHTML =  message || 'Processo efetuado'
    setTimeout(()=>{

      notification.style.top = "-100%"

      setTimeout(()=>{
        notification.remove()
      },1000)

    },6000)

  }


  loading() : HTMLDivElement {

    let loader: HTMLDivElement = document.createElement('div')

    let theme;

    if(document.querySelector('body').className.includes('gov')) {
      theme = 'gov'
    } else if(document.querySelector('body').className.includes('default')) {
      theme = 'blue'
    } else if(document.querySelector('body').className.includes('tribunal')) {
      theme = 'tribunal'
    } else if (document.querySelector('body').className.includes('doneIt')) {
      theme = 'doneIt'
    }
    else {
      theme = 'gov'
    }

    loader.innerHTML = `
      <div class="loading-blocker">
        <div class="loading-blocker-container">
        <img src="/assets/gif/theme/${theme}/Blocks-loader.svg" />
        </div>
      </div>
    `;

    //
    document.body.append(loader)

    loader.addEventListener('click', ()=>{
      // loader.remove()
    })
    return loader

  }

}


export const ToastsService = new ToastService(new ToastController(), new ThemeService())


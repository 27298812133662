import { DataSourceReturn } from "src/app/services/Repositorys/type";
import { IMessageGetAllByRoomIdOutPut } from "../../usecase/message/message-get-all-by-room-Id";
import { CreateRoomInputDTO, RoomOutPutDTO } from "src/app/module/chat/domain/use-case/room/room-create-use-case.service";
import { RoomByIdInputDTO, RoomByIdOutputDTO } from "src/app/module/chat/domain/use-case/room/room-get-by-id-use-case.service";
import { RoomUpdateInputDTO, RoomUpdateOutputDTO } from "src/app/module/chat/domain/use-case/room/room-update-by-id-use-case.service";
import { Result } from "neverthrow";
import { AddMemberToRoomInputDTO } from "src/app/module/chat/domain/use-case/member/member-add-use-case.service";
import { RoomListOutPutDTO } from "src/app/module/chat/domain/use-case/room/room-get-list-use-case.service";

export abstract class IRoomRemoteRepository  {
  abstract createRoom(data: CreateRoomInputDTO): DataSourceReturn<RoomOutPutDTO>
  abstract getRoomList(): Promise<DataSourceReturn<RoomListOutPutDTO>>  
  abstract getRoom(id: RoomByIdInputDTO): DataSourceReturn<RoomByIdOutputDTO>
  abstract updateRoom(data: RoomUpdateInputDTO): Promise<DataSourceReturn<RoomUpdateOutputDTO>>
  abstract deleteRoom(id: string): Promise<Result<any ,any>>
  abstract addMemberToRoomSocket(data: AddMemberToRoomInputDTO): Promise<Result<any ,any>>
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { Event } from 'src/app/models/event.model'
import { EventPerson } from 'src/app/models/eventperson.model';
import { SearchPage } from 'src/app/pages/search/search.page';
import { SearchDocumentDetails, SearchFolderDetails, SearchList } from 'src/app/models/search-document';
import { LoginUserRespose } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { AttendeesPageModal } from 'src/app/pages/events/attendees/attendees.page';
import { ToastService } from 'src/app/services/toast.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _moment from 'moment';
import * as _rollupMoment from 'moment';
import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { EventsService } from 'src/app/services/events.service';
import { EventService } from 'src/app/services/rules/event.service';
import { EventPipe } from 'src/app/pipes/event.pipe';
import { ThemeService } from 'src/app/services/theme.service';
import { SessionStore } from 'src/app/store/session.service';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service';
import { environment } from 'src/environments/environment';
import { TaskService } from 'src/app/services/task.service'
import { ContactsService } from 'src/app/services/contacts.service';
import { AgendaDataRepositoryService } from 'src/app/module/agenda/data/repository/agenda-data-repository.service';
import { TracingType, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';
import { Observable } from 'rxjs';
import { TableSharedCalendar } from 'src/app/module/agenda/data/data-source/agenda-local-data-source.service';
import { isHttpError } from 'src/app/services/http.service';
import { RoleIdService } from 'src/app/services/role-id.service';
import { Utils } from 'src/app/module/agenda/utils';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "YYYY-MMMM-DD HH:mm"
  },
  display: {
    dateInput: "DD MMM YYYY H:mm",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
}

@Component({
  selector: 'app-document-set-up-meeting',
  templateUrl: './document-set-up-meeting.page.html',
  styleUrls: ['./document-set-up-meeting.page.scss'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ]
})
export class DocumentSetUpMeetingPage implements OnInit {

  public date: any;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate = new Date();

  public stepHour = 1;
  public stepMinute = 15;
  public stepSecond = 5;

  p: any = {}

  Form: FormGroup;
  validateFrom = false
  recurringTypes = [];
  Occurrence: Date = new Date()
  EventRecurrenceType = '-1'
  showLoader = false

  @ViewChild('picker') picker: any;
  @ViewChild('fim') fim: any;
  @ViewChild('inicio') inicio: any;
  @ViewChild('picker1') picker1: any;

  taskParticipants: EventPerson[] = [];
  taskParticipantsCc: EventPerson[] = [];

  taskDocId: string;
  loadedAttachments: any;

  attachments: SearchList[] = [];

  adding: "intervenient" | "CC" = "intervenient";

  postData: Event;

  formLocationSatus: boolean = false;
  showAttendees = false;
  loggeduser: LoginUserRespose;
  emptyTextDescription = "Selecionar intervenientes";

  document: SearchFolderDetails | SearchDocumentDetails | any;
  subject: string;
  docs: any[] = [];

  eventPipe = new EventPipe()
  CalendarName;
  CalendarNameShow = true
  testeFormDefaul = "Eudes"
  environment = environment
  eventPersons: EventPerson[];
  contacts: EventPerson[];
  sharedCalendar: Observable<TableSharedCalendar[]>
  selectedUserCalendar:any;
  sessionStore = SessionStore;
  hasChangeCalendar = false
  eventRecurence = 'never';

  CalendarNamesOptions = ['Oficial', 'Pessoal']

  constructor(
    private modalController: ModalController,
    private router: Router,
    private navParams: NavParams,
    private toastService: ToastService,
    private calendarService: EventsService,
    public ThemeService: ThemeService,
    public _eventService: EventsService,
    private httpErroHandle: HttpErrorHandle,
    public TaskService: TaskService,
    private contactsService: ContactsService,
    private agendaDataRepository: AgendaDataRepositoryService,
    public RoleIdService: RoleIdService,
    public utils: Utils,
  ) {
    this.loggeduser = SessionStore.user;
    this.document = this.navParams.get('document')

    if (Array.isArray(this.document)) {
      const E = this.document
      this.document = []
      E.forEach((e) => {
        this.document.push({
          ApplicationId: e.ApplicationId || e.ApplicationType,
          Source: 1,
          SourceId: e.DocId || e.docID || e.docId || e.SourceId || e.Id,
          SourceName: e.Assunto || e.attachments,
          DocDate: e.CreateDate,
          Data: e.Data,
          Assunto: e.Description,
          ...e
        })
      })
    } else if (this.document) {

      const E = this.document
      this.document = []
      this.document.push({
        ApplicationId: E.ApplicationId || E.ApplicationType,
        Source: 1,
        SourceId: E.DocId || E.docID || E.docId || E.SourceId || E.Id,
        SourceName: E.Assunto || E.attachments,
        DocDate: E.CreateDate,
        Data: E.Data,
        Assunto: E.Description,
        ...E
      })
    } else {
      this.document = []
    }



    if (Array.isArray(this.document)) {
      this.attachments = this.document
    } else {
      this.attachments = [this.document]
    }

    this.subject = this.navParams.get('subject')

    this.postData = new Event();
    this.postData.Body = { BodyType: "1", Text: "" };

    this.postData.Subject = this.subject
    this.postData.CalendarName = "Oficial";

    this.postData.Category = 'Reunião'

    if (!this.CalendarName) {
      if (this._eventService.calendarNamesAry.includes('Meu calendario')) {
        this.CalendarName = 'Meu calendario';
      } else {
        this.CalendarName = this._eventService.calendarNamesAry[0]
      }
    }

    if (this.taskParticipants.length == 0) {
      this.taskParticipants = [
        // {
        // EmailAddress: SessionStore.user.Email,
        // IsRequired: true,
        // Name: SessionStore.user.FullName,
        // UserType: "GD"
        // }
      ]
    }

    this.changeAgenda()

    this.postData.EventRecurrence = {
      frequency: this.eventRecurence,
      until: "",
      Type: '' ,
    }

    this.postData.IsAllDayEvent = false;

  }

  ngOnInit() {
    this.adding = "intervenient";
    this.setDefaultTime()
    this.getRecurrenceTypes();
    this.fetchContacts("")
    this.setCalendarByDefault(true)
  }

  ngOnDestroy() {
    clearInterval(this.myInterval)
  }

  changeSegmentCalendar() {
    this.hasChangeCalendar = true
  }


  async changeAgenda() {

    const result = await this.agendaDataRepository.geCalendars()

    const selectedCalendar = result.find(e => e.wxUserId == this.selectedUserCalendar)

    if(selectedCalendar) {
      if(selectedCalendar.shareType == 1) {
        this.CalendarNamesOptions = ['Oficial']
      } else if(selectedCalendar.shareType == 2) {
        this.CalendarNamesOptions = ['Pessoal']
      } else if (selectedCalendar.shareType == 3) {
        this.CalendarNamesOptions = ['Oficial', 'Pessoal']
      }
    }

  }

  myInterval = setInterval(() => {
    document.querySelectorAll('.ngx-mat-timepicker input').forEach((e: any) => {
      if (e) {
        e.disabled = true;
      }
    })
  }, 1000);

  getRecurrenceTypes() {
    this.calendarService.getRecurrenceTypes().subscribe(res => {
      this.recurringTypes = res;
    });
  }

  setDefaultTime() {
    this.setStartDate()
    this.setEndDate();
  }

  setStartDate() {
    if (!this.postData.StartDate) {
      this.postData.StartDate = this.roundTimeQuarterHour();
    }
  }

  setEndDate() {
    if (!this.postData.EndDate) {
      this.postData.EndDate = this.roundTimeQuarterHourPlus15(this.postData.StartDate);
    }
  }

  close() {
    this.modalController.dismiss(null);
  }

  runValidation() {
    this.validateFrom = true
    if (new Date(this.postData.StartDate).getTime() > new Date(this.postData.EndDate).getTime()) {
      this.toastService._badRequest("A data de fim não pode ser inferior a data de início do evento")
    }
  }

  get dateValid() {
    return new Date(this.postData.StartDate).getTime() < new Date(this.postData.EndDate).getTime() ? 'ok' : null
  }

  injectValidation() {

    this.Form = new FormGroup({
      Subject: new FormControl(this.postData.Subject, [
        Validators.required,
        // Validators.minLength(4)
      ]),
      Location: new FormControl(this.postData.Location, [
        Validators.required,
      ]),
      CalendarName: new FormControl(this.postData.CalendarName, [
        // Validators.required
      ]),
      Date: new FormControl((this.dateValid), [
        Validators.required
      ]),
      participantes: new FormControl(this.taskParticipants, [
        Validators.required
      ]),
      Categories: new FormControl(this.postData.Category, [
        // Validators.required
      ]),

    })
  }

  async setCalendarByDefault(force) {
    if (!this.selectedUserCalendar || force) {

      const data = await this.agendaDataRepository.geCalendars()

      const prObject = data.find(e => e?.roleId == 100000014)
      if(prObject) {
        this.selectedUserCalendar = prObject.wxUserId
      } else {
        this.selectedUserCalendar = SessionStore.user.UserId
      }

      this.changeAgenda()

    }
  }

  @XTracerAsync({name:'document-setup-metting', bugPrint: true})
  async saveTask(tracing?: TracingType) {

    if (this.loggeduser.Profile != 'PR') {
      this.injectValidation()
      this.runValidation()
      if (this.Form.invalid) return false
    }

    this.postData.Attendees = this.taskParticipants.concat(this.taskParticipantsCc);

    const laoder = this.toastService.loading();

    const calendar = await this.agendaDataRepository.getCalendarByUserId(this.selectedUserCalendar)
    if(calendar.isOk()) {

      const value = await this.agendaDataRepository.createEvent(this.postData, this.attachments, calendar.value, tracing, true)
      if(value.isOk()) {

      this.httpErroHandle.httpsSucessMessagge('new event');
      this.modalController.dismiss()
      } else {

        if(!isHttpError(value.error)) {
          this.toastService._badRequest('Pedimos desculpa mas não foi possível executar a acção. Por favor, contacte o apoio técnico. #1')
          console.log(value.error)
        } else {
          this.httpErroHandle.httpStatusHandle(value.error.status)
        }
        console.log('create event error: ', value.error)
        tracing.setAttribute('outcome', 'failed')
      }


    } else {


      tracing.setAttribute('outcome', 'failed')
      tracing.setAttribute('no', 'this.selectedUserCalendar')
    }

    laoder.remove();

  }

  onCheckboxChange(event: any) {
    if (this.postData.IsAllDayEvent) {
      this.postData.IsAllDayEvent = this.postData.IsAllDayEvent;
      this.postData.StartDate = this.setAlldayTime(this.postData.StartDate)
      this.postData.EndDate = this.setAlldayTimeEndDate(this.postData.EndDate)

      console.log('Recurso ativado!!');
    } else {
      this.postData.IsAllDayEvent = this.postData.IsAllDayEvent;
      this.postData.EndDate = this.setAlldayTimeEndDateNotAlday(this.postData.EndDate)
      console.log('Recurso desativado');

    }
  }



  setAlldayTime(timeToReturn) {
    let date: any = new Date(timeToReturn) || new Date();
    let newdate = new Date();
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0);


    return date
  }

  setAlldayTimeEndDate(timeToReturn) {
    let date: any = new Date(timeToReturn) || new Date();
    let newdate = new Date();
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(0);


    return date
  }

  setAlldayTimeEndDateNotAlday(timeToReturn) {
    let date: any = new Date(timeToReturn) || new Date();
    let newdate = new Date();
    date.setHours(23)
    date.setMinutes(0)
    date.setSeconds(0);


    return date
  }


  async addParticipants() {

    this.adding = "intervenient";

    if (window.innerWidth <= 801) {
      const modal = await this.modalController.create({
        component: AttendeesPageModal,
        componentProps: {
          adding: this.adding,
          taskParticipants: this.taskParticipants,
          taskParticipantsCc: this.taskParticipantsCc
        },
        cssClass: 'modal modal-desktop',
        backdropDismiss: false
      });

      modal.onDidDismiss().then((data) => {

        if (data) {
          data = data['data'];

          const newAttendees: EventPerson[] = data['taskParticipants'];
          const newAttendeesCC: EventPerson[] = data['taskParticipantsCc'];

          this.setIntervenient(newAttendees);
          this.setIntervenientCC(newAttendeesCC);
        }
      }, (error) => {
        console.log(error)
      });

      await modal.present()

    } else {
      this.showAttendees = true;
    }
  }

  async addParticipantsCc() {

    this.adding = "CC";

    if (window.innerWidth <= 800) {
      const modal = await this.modalController.create({
        component: AttendeesPageModal,
        componentProps: {
          adding: this.adding,
          taskParticipants: this.taskParticipants,
          taskParticipantsCc: this.taskParticipantsCc
        },
        cssClass: 'modal modal-desktop',
        backdropDismiss: false
      });



      modal.onDidDismiss().then((data) => {

        if (data) {
          data = data['data'];

          const newAttendees: EventPerson[] = data['taskParticipants'];
          const newAttendeesCC: EventPerson[] = data['taskParticipantsCc'];

          this.setIntervenient(newAttendees);
          this.setIntervenientCC(newAttendeesCC);
        }
      }, (error) => {
        console.log(error)
      });

      await modal.present();
    } else {
      this.showAttendees = true;
    }
  }

  async getDoc() {
    const modal = await this.modalController.create({
      component: SearchPage,
      cssClass: 'modal modal-desktop modal-width-100-width-background modal-background',
      componentProps: {
        type: 'AccoesPresidenciais & ArquivoDespachoElect',
        showSearchInput: true,
        select: true
      }
    });

    modal.onDidDismiss().then((res) => {
      if (res) {
        const data = res.data;
        this.attachments.push({
          ...data.selected,
          ApplicationId: data?.selected?.ApplicationType,
          DocId: data.selected?.Id,
          SourceId: data.selected?.Id
        });
      }
    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }

  removeAttachment(index: number) {
    this.attachments = this.attachments.filter((e, i) => index != i);
  }

  validateFormInputs() {
    let formLocation = this.postData.Location.trim();
    if (!this.postData.Location && formLocation.length <= 0) {
      this.formLocationSatus = true;
    }
  }

  selectedCalendarId() {

    if (this._eventService.calendarNamesType[this.CalendarName]?.['Oficial'] && this.postData.CalendarName == 'Oficial') {
      return this._eventService.calendarNamesType[this.CalendarName]['OficialId']

    } else if (this._eventService.calendarNamesType[this.CalendarName]?.['Pessoal'] && this.postData.CalendarName == 'Pessoal') {

      return this._eventService.calendarNamesType[this.CalendarName]['PessoalId']

    } else {
      return '11:11'
    }
  }

  dynamicSetIntervenient({ taskParticipants, taskParticipantsCc }) {
    this.taskParticipants = taskParticipants;
    this.taskParticipantsCc = taskParticipantsCc;
  }

  goToGabinete() {
    this.router.navigate(['/home/gabinete-digital']);
  }

  setIntervenient(data) {
    this.taskParticipants = data;
  }

  setIntervenientCC(data) {
    this.taskParticipantsCc = data;
  }


  roundTimeQuarterHour(timeToReturn = new Date()) {
    let date = timeToReturn || new Date();
    const minutes = date.getMinutes();
    date.setSeconds(0);

    if (minutes % 15 != 0) {

      if (minutes > 45) {
        date.setMinutes(60)
      } else if (minutes > 30) {
        date.setMinutes(45)
      } else if (minutes > 15) {
        date.setMinutes(30)
      } else if (minutes > 0) {
        date.setMinutes(15)
      }

    }

    return date
  }

  roundTimeQuarterHourPlus15(date: Date) {
    const _date = new Date(date);
    const minutes = _date.getMinutes();
    _date.setMinutes(minutes + 15)
    return _date
  }

  checkRoleInArray(str) {
    return this._eventService.calendarRole.includes(str);
  }

  async fetchContacts(filter: string) {

    if (this.loggeduser.Profile == 'PR') {
      this.contactsService.getContacts(filter).subscribe(result => {
        if (this.eventPersons != null) {
          this.eventPersons.forEach(attendee => {
            const index: number = result.findIndex((cont) => {
              return cont.EmailAddress.toLocaleLowerCase() == attendee.EmailAddress.toLocaleLowerCase()
            });

            result.splice(index, 1);

          });
        }
        this.contacts = result;
        //console.log('Attendes Email', this.loggeduser.Email)
        let filterLoggedUserEmail = this.contacts.filter(item => item.RoleDescription == "Ministro e Director do Gabinete do PR")
        //console.log('Attendes Email', filterLoggedUserEmail)

        this.contacts = filterLoggedUserEmail;
        const newAttendees: EventPerson[] = this.contacts;

          this.setIntervenient(newAttendees);
        //console.log('Attendes Email', this.contacts)
      }
      );
    }
  }


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CreateProcessPageRoutingModule } from './create-process-routing.module';

import { CreateProcessPage } from './create-process.page';
import { EmptyContainerPageModule } from 'src/app/shared/empty-container/empty-container.module';
import { AttendeeModalPageModule } from 'src/app/shared/event/attendee-modal/attendee-modal.module';


import { MatNativeDateModule } from '@angular/material/core';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CreateProcessPageRoutingModule,
    EmptyContainerPageModule,
    AttendeeModalPageModule,
    // Angular material
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule,
    MatSelectModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatDialogModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt' },
  ],
  declarations: [
    CreateProcessPage
  ],
  exports: [CreateProcessPage],
  entryComponents: [CreateProcessPage]
})
export class CreateProcessPageModule {}

import { HttpHeaders } from "@angular/common/http";
import { calendarInterface } from "src/app/models/user.model";
import { SessionStore } from "src/app/store/session.service";

export function DetectCalendars(CalendarId) {
  const calendars = SessionStore.user.OwnerCalendars.concat(SessionStore.user.SharedCalendars)
  return calendars.find((e) => e.CalendarId == CalendarId)
}

export function makeHeaderForCalendar(calendar: calendarInterface) {
  let header = new HttpHeaders();;
  header = header.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
  header = header.set('CalendarId', calendar.CalendarId);
  header = header.set('CalendarRoleId', calendar.CalendarRoleId);
  header = header.set('CalendarName', calendar.CalendarName);
  return header
}

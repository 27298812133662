import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes = [
  {
    path: '',
    loadChildren: () => import('./index/index.module').then(m => m.IndexPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'empty-container',
    loadChildren: () => import('./shared/empty-container/empty-container.module').then( m => m.EmptyContainerPageModule)
  },
  {
    path: 'events-to-approve',
    loadChildren: () => import('./shared/gabinete-digital/events-to-approve/events-to-approve.module').then( m => m.EventsToApprovePageModule)
  },
  {
    path: 'expedients',
    loadChildren: () => import('./shared/gabinete-digital/expedients/expedients.module').then( m => m.ExpedientsPageModule)
  },
  {
    path: 'document-detail',
    loadChildren: () => import('./modals/document-detail/document-detail.module').then( m => m.DocumentDetailPageModule)
  },
  {
    path: 'pedidos',
    loadChildren: () => import('./shared/gabinete-digital/pedidos/pedidos.module').then( m => m.PedidosPageModule)
  },
  {
    path: 'event-list',
    loadChildren: () => import('./pages/gabinete-digital/event-list/event-list.module').then(m =>m.EventListPageModule)
  },
  {
    path: 'despachos',
    loadChildren: () => import('./shared/gabinete-digital/despachos/despachos.module').then( m => m.DespachosPageModule)
  },
  {
    path: 'create-process',
    loadChildren: () => import('./modals/create-process/create-process.module').then( m => m.CreateProcessPageModule)
  },
  {
    path: 'pendentes',
    loadChildren: () => import('./shared/gabinete-digital/pendentes/pendentes.module').then( m => m.PendentesPageModule)
  },
  {
    path: 'delegar',
    loadChildren: () => import('./modals/delegar/delegar.module').then( m => m.DelegarPageModule)
  },
  {
    path: 'add-note',
    loadChildren: () => import('./modals/add-note/add-note.module').then( m => m.AddNotePageModule)
  },
  {
    path: 'dar-parecer',
    loadChildren: () => import('./modals/dar-parecer/dar-parecer.module').then( m => m.DarParecerPageModule)
  },
  {
    path: 'opts-expediente',
    loadChildren: () => import('./shared/popover/opts-expediente/opts-expediente.module').then( m => m.OptsExpedientePageModule)
  },
  {
    path: 'despachos-pr',
    loadChildren: () => import('./shared/gabinete-digital/despachos-pr/despachos-pr.module').then( m => m.DespachosPrPageModule)
  },
  {
    path: 'diplomas',
    loadChildren: () => import('./shared/gabinete-digital/diplomas/diplomas.module').then( m => m.DiplomasPageModule)
  },
  {
    path: 'expedientes-pr',
    loadChildren: () => import('./shared/gabinete-digital/expedientes-pr/expedientes-pr.module').then( m => m.ExpedientesPrPageModule)
  },
  {
    path: 'diplomas-assinar',
    loadChildren: () => import('./shared/gabinete-digital/diplomas-assinar/diplomas-assinar.module').then( m => m.DiplomasAssinarPageModule)
  },
  {
    path: 'opts-expediente-pr',
    loadChildren: () => import('./shared/popover/opts-expediente-pr/opts-expediente-pr.module').then( m => m.OptsExpedientePrPageModule)
  },
  {
    path: 'despachos-options',
    loadChildren: () => import('./shared/popover/despachos-options/despachos-options.module').then( m => m.DespachosOptionsPageModule)
  },
  {
    path: 'despachos-pr-options',
    loadChildren: () => import('./shared/popover/despachos-pr-options/despachos-pr-options.module').then( m => m.DespachosPrOptionsPageModule)
  },
  {
    path: 'deploma-options',
    loadChildren: () => import('./shared/popover/deploma-options/deploma-options.module').then( m => m.DeplomaOptionsPageModule)
  },
  {
    path: 'pin',
    loadChildren: () => import('./shared/pin/pin.module').then( m => m.PinPageModule)
  },
  {
    path: 'fingerprint',
    loadChildren: () => import('./shared/fingerprint/fingerprint.module').then( m => m.FingerprintPageModule)
  },
  {
    path: 'new-event',
    loadChildren: () => import('./shared/agenda/new-event/new-event.module').then( m => m.NewEventPageModule)
  },
  {
    path: 'event-list',
    loadChildren: () => import('./shared/agenda/event-list/event-list.module').then( m => m.EventListPageModule)
  },
  {
    path: 'approve-event',
    loadChildren: () => import('./shared/agenda/approve-event/approve-event.module').then( m => m.ApproveEventPageModule)
  },
  {
    path: 'bad-request',
    loadChildren: () => import('./shared/popover/bad-request/bad-request.module').then( m => m.BadRequestPageModule)
  },
  {
    path: 'success-message',
    loadChildren: () => import('./shared/popover/success-message/success-message.module').then( m => m.SuccessMessagePageModule)
  },
  {
    path: 'forward',
    loadChildren: () => import('./modals/forward/forward.module').then( m => m.ForwardPageModule)
  },
  {
    path: 'edit-event-to-approve',
    loadChildren: () => import('./shared/agenda/edit-event-to-approve/edit-event-to-approve.module').then( m => m.EditEventToApprovePageModule)
  },
  {
    path: 'actions-options',
    loadChildren: () => import('./shared/popover/actions-options/actions-options.module').then( m => m.ActionsOptionsPageModule)
  },
  {
    path: 'edit-action',
    loadChildren: () => import('./shared/publication/edit-action/edit-action.module').then( m => m.EditActionPageModule)
  },
  {
    path: 'eliminate-event',
    loadChildren: () => import('./modals/eliminate-event/eliminate-event.module').then( m => m.EliminateEventPageModule)
  },
  {
    path: 'inactivity',
    loadChildren: () => import('./pages/inactivity/inactivity.module').then( m => m.InactivityPageModule)
  },
  {
    path: 'document-set-up-meeting',
    loadChildren: () => import('./modals/document-set-up-meeting/document-set-up-meeting.module').then( m => m.DocumentSetUpMeetingPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./modals/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'all-processes',
    loadChildren: () => import('./shared/gabinete-digital/all-processes/all-processes.module').then( m => m.AllProcessesPageModule)
  },
  {
    path: 'searched-document-options',
    loadChildren: () => import('./shared/popover/searched-document-options/searched-document-options.module').then( m => m.SearchedDocumentOptionsPageModule)
  },
  {
    path: 'task-list',
    loadChildren: () => import('./shared/gabinete-digital/generic/task-list/task-list.module').then( m => m.TaskListPageModule)
  },
  {
    path: 'task-list',
    loadChildren: () => import('./shared/gabinete-digital/generic/task-list/task-list.module').then( m => m.TaskListPageModule)
  },
  {
    path: 'task-detailde',
    loadChildren: () => import('./shared/gabinete-digital/generic/task-detailde/task-detailde.module').then( m => m.TaskDetaildePageModule)
  },
  {
    path: 'task-details',
    loadChildren: () => import('./shared/gabinete-digital/generic/task-details/task-details.module').then( m => m.TaskDetailsPageModule)
  },
  {
    path: 'chat-options-features',
    loadChildren: () => import('./modals/chat-options-features/chat-options-features.module').then( m => m.ChatOptionsFeaturesPageModule)
  },
  {
    path: 'document-viewer',
    loadChildren: () => import('./modals/document-viewer/document-viewer.module').then( m => m.DocumentViewerPageModule)
  },
  {
    path: 'view-document',
    loadChildren: () => import('./modals/view-document/view-document.module').then( m => m.ViewDocumentPageModule)
  },
  {
    path: 'event-details-documents-options',
    loadChildren: () => import('./shared/popover/event-details-documents-options/event-details-documents-options.module').then( m => m.EventDetailsDocumentsOptionsPageModule)
  },
  {
    path: 'preview-camera',
    loadChildren: () => import('./modals/preview-camera/preview-camera.module').then( m => m.PreviewCameraPageModule)
  },
  {
    path: 'group-icons',
    loadChildren: () => import('./modals/group-icons/group-icons.module').then( m => m.GroupIconsPageModule)
  },
  {
    path: 'video-allowed',
    loadChildren: () => import('./modals/video-allowed/video-allowed.module').then( m => m.VideoAllowedPageModule)
  },
  {
    path: 'preview-photo',
    loadChildren: () => import('./modals/preview-photo/preview-photo.module').then( m => m.PreviewPhotoPageModule)
  },
  {
    path: 'custom-image-cache',
    loadChildren: () => import('./services/file/custom-image-cache/custom-image-cache.module').then( m => m.CustomImageCachePageModule)
  },
  {
    path: 'view-media',
    loadChildren: () => import('./modals/view-media/view-media.module').then( m => m.ViewMediaPageModule)
  },
  {
    path: 'view-event',
    loadChildren: () => import('./modals/view-event/view-event.module').then( m => m.ViewEventPageModule)
  },
  {
    path: 'previewer',
    loadChildren: () => import('./modals/previewer/previewer.module').then( m => m.PreviewerPageModule)
  },
  {
    path: 'diplomas-gerar',
    loadChildren: () => import('./shared/gabinete-digital/diplomas-gerar/diplomas-gerar.module').then( m => m.DiplomasGerarPageModule)
  },
  {
    path: 'diplomas-gerar-options',
    loadChildren: () => import('./shared/popover/diplomas-gerar-options/diplomas-gerar-options.module').then( m => m.DiplomasGerarOptionsPageModule)
  },
  {
    path: 'information',
    loadChildren: () => import('./modals/information/information.module').then( m => m.InformationPageModule)
  },
  {
    path: 'task-listt-header',
    loadChildren: () => import('./shared/gabinete-digital/generic/task-listt-header/task-listt-header.module').then( m => m.TaskListtHeaderPageModule)
  },
  {
    path: 'task-list-header',
    loadChildren: () => import('./shared/gabinete-digital/generic/task-list-header/task-list-header.module').then( m => m.TaskListHeaderPageModule)
  },
  {
    path: 'tiny-mce',
    loadChildren: () => import('./tiny-mce/tiny-mce.module').then( m => m.TinyMCEPageModule)
  },
  {
    path: 'task-detail-content',
    loadChildren: () => import('./shared/gabinete-digital/generic/task-detail-content/task-detail-content.module').then( m => m.TaskDetailContentPageModule)
  },
  {
    path: 'task-detail-header',
    loadChildren: () => import('./shared/gabinete-digital/generic/task-detail-header/task-detail-header.module').then( m => m.TaskDetailHeaderPageModule)
  },
  {
    path: 'ask-modal',
    loadChildren: () => import('./modals/ask-modal/ask-modal.module').then( m => m.AskModalPageModule)
  },
  {
    path: 'popup-question',
    loadChildren: () => import('./modals/popup-question/popup-question.module').then( m => m.PopupQuestionPageModule)
  },
  {
    path: 'swiper',
    loadChildren: () => import('./shared/swiper/swiper.module').then( m => m.SwiperPageModule)
  },
   {
    path: 'not-found',
    loadChildren: () => import('./pages/not-found/not-found.module').then( m => m.NotFoundPageModule)
  },
  {
    path: 'view-document-second-options',
    loadChildren: () => import('./modals/view-document-second-options/view-document-second-options.module').then( m => m.ViewDocumentSecondOptionsPageModule)
  },
  {
    path: 'crop-image',
    loadChildren: () => import('./modals/crop-image/crop-image.module').then( m => m.CropImagePageModule)
  },
  {
    path: 'event-recurrence',
    loadChildren: () => import('./modals/event-recurrence/event-recurrence.module').then( m => m.EventRecurrencePageModule)
  },
  {
    path: 'delete-event-recurrence',
    loadChildren: () => import('./modals/delete-event-recurrence/delete-event-recurrence.module').then( m => m.DeleteEventRecurrencePageModule)
  }
 /*
    path: 'chat',
    component: ChatPage
  } */

 /*
    path: 'chat',
    component: ChatPage
  } */

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { FFMpeg } from '@awesome-cordova-plugins/ffmpeg/ngx';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';

@Injectable({
  providedIn: 'root'
})
export class VideoconvertService {

  constructor(
    private ffmpeg: FFMpeg,
    private ffmpegWeb: FFmpeg
  ) { }

  async convertVideo(inputPath, outputPath, filename, formart) {
    try {
      const ffmpegCommand = `-i "${inputPath}" -c:v copy -c:a aac -strict experimental "${outputPath}${filename}.${formart}"`;

      const result = await this.ffmpeg.exec(ffmpegCommand)

      console.log('Convert returns ', `${outputPath}${filename}.${formart}`);
    } catch (error) {
      console.error('Error during conversion:', error);
      throw error; // Re-throwing the error for handling in the component
    }
  }

  async convertVideoWeb(inputPath, outputPath, filename, format) {
    try {
        console.log('Conversion started...');

        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';
        const coreURL = await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript');
        const wasmURL = await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm');

        await this.ffmpegWeb.load();

        const inputType = inputPath.type.split('/').pop();
        console.log(inputType)
        await this.ffmpegWeb.writeFile(`input.${inputType}`, await fetchFile(inputPath));

        await this.ffmpegWeb.exec(['-i', `input.${inputType}`, 'output.mp4']);

        const outputData: any = await this.ffmpegWeb.readFile('output.mp4');
        const outputBlob = new Blob([outputData.buffer], { type: 'video/mp4' });
        const outputFile = new File([outputBlob], `${filename}.mp4`, { type: 'video/mp4' });

        console.log('Conversion completed.');
        return outputFile;
    } catch (error) {
        console.error('Error during conversion:', error);
        throw error;
    }
}


  removeTextBeforeSlash(inputString, mark) {
    if (inputString.includes(mark)) {
      const parts = inputString.split(mark);
      return parts.length > 1 ? parts[1] : inputString;
    } else {
      return inputString;
    }
  }

}

import { Injectable } from '@angular/core';
import { localstoreService } from './localstore.service'
import { AES, enc, SHA1 }  from  'crypto-js'
import { momentG } from 'src/plugin/momentG';
import { v4 as uuidv4 } from 'uuid'
@Injectable({
  providedIn: 'root'
})
export class DeplomasService {

  private _diplomasAssinadoList  = []
  private _diplomasReviewList = []
  private _diplomasGerarList = []
  private _diplomasParaAssinar = []

  private keyNameDiplomasAssinado: string;
  private keyNameDiplomasParaAssinar: string;
  private keyNameDiplomasGerar: string;
  private keyNameDiplomasReview: string;

  private _diplomasAssinadoListCount  = 0
  private _diplomasReviewCount = 0
  private _diplomasGerarListCount = 0;
  private _diplomasListCount = 0;



  diplomasAssinadoListNewCount  = []
  diplomasReviewListNewCount = []
  diplomasGerarListNewCount = []
  diplomasParaAssinarNewCount = []

  callbacks: {
    [key: string]: {
      funx: Function
      id: string
    }
  } = {} 

  constructor() {
    this.keyNameDiplomasAssinado = (SHA1("DeplomasService"+"diplomasAssinado")).toString()
    this.keyNameDiplomasReview = (SHA1("DeplomasService"+"diplomasReview")).toString()
    this.keyNameDiplomasGerar = (SHA1("DeplomasService"+"diplomasGerar")).toString()
    this.keyNameDiplomasParaAssinar = (SHA1("DeplomasService"+"diplomasParaAsinar")).toString()

    setTimeout(() => {

      let restoreDiplomasAssinado = localstoreService.get(this.keyNameDiplomasAssinado, {})
      let restoreDiplomasParaAssinar = localstoreService.get(this.keyNameDiplomasParaAssinar, {})
      let restoreDiplomasReviewList = localstoreService.get(this.keyNameDiplomasReview, {})
      let restoreDiplomasGerarList = localstoreService.get(this.keyNameDiplomasGerar, {})

      this._diplomasAssinadoList = restoreDiplomasAssinado.list || []
      this._diplomasAssinadoListCount = parseInt(restoreDiplomasAssinado.count)  || 0

      this._diplomasParaAssinar = restoreDiplomasParaAssinar.list || []
      this._diplomasListCount = parseInt(restoreDiplomasParaAssinar.count) || 0

      this._diplomasReviewList = restoreDiplomasReviewList.list  || []
      this._diplomasReviewCount = parseInt(restoreDiplomasReviewList.count)  || 0

      this._diplomasGerarList = restoreDiplomasGerarList.list  || []
      this._diplomasGerarListCount = parseInt(restoreDiplomasGerarList.count)  || 0

      this.runCallback()
    }, 10)
    
  }

  registerCallback({funx, id = uuidv4()}) {

    this.callbacks[id] = { funx, id}

    return {
      delete: ()=> {
        delete this.callbacks[id]
      }
    }
  }

  runCallback() {
    for (const [key, value] of Object.entries(this.callbacks)) {
      value.funx()
    }
  }

  get  deplomasReviewCount() {
    return this._diplomasReviewCount
  }
  set deplomasReviewCount(arg: number) {
    this._diplomasReviewCount = arg
    this.saveDiplomasReviewList()
  }

  get countDiplomasAssinadoListCount() {
    return this._diplomasAssinadoListCount || 0
  }

  set countDiplomasListCount(value) {
    this._diplomasAssinadoListCount = value
    this.saveDiplomasAssinadoList()
  }

  get diplomasParaAssinartCount() {
    return this._diplomasListCount || 0
  }

  set diplomasParaAssinarCount(value) {
    this._diplomasParaAssinar = value
    this.saveDiplomasParaAssinar()
  }

  // get list
  get diplomasParaAssinarList() {
    return this._diplomasParaAssinar
  }

  // get list
  get diplomasAssinadoList() {
    return this._diplomasAssinadoList
  }

  // get list
  get diplomasReviewList() {
    return this._diplomasReviewList
  }

  // get list 


  get DiplomaGerarList() {
    return this._diplomasGerarList
  }

  resetDiplomasAssinadoList(value: any[]) {
    
    this._diplomasAssinadoListCount = value.length
    this._diplomasAssinadoList = value
    this.saveDiplomasAssinadoList()
    if(window['all-process-gabinete']) {
      window['all-process-gabinete']()
    }

    this.runCallback()
  }

  resetDiplomasParaAssinar(value: any[]) {
    
    this._diplomasListCount = value.length
    this._diplomasParaAssinar = value
    this.saveDiplomasParaAssinar()

    if(window['all-process-gabinete']) {
      window['all-process-gabinete']()
    }

    this.runCallback()
  }

  resetDiplomasReview(value: any[]) {
    this._diplomasReviewList =value
    this._diplomasReviewCount = value.length
    this.saveDiplomasReviewList()

    if(window['all-process-gabinete']) {
      window['all-process-gabinete']()
    }

    this.runCallback()
  }

  resetDiplomaGerar(value: any[]) {

    this._diplomasGerarListCount = value.length
    this._diplomasGerarList = value

    this.savetDiplomaGerar()
    if(window['all-process-gabinete']) {
      window['all-process-gabinete']()
    }

    this.runCallback()
  }


  private saveDiplomasReviewList () {
    setTimeout(()=>{ 
      localstoreService.set(this.keyNameDiplomasReview,{
        list: this._diplomasReviewList,
        count: this._diplomasReviewCount,
      })
    }, 10)
    this.updateNewCount()
  }
  
  private saveDiplomasAssinadoList() {
    
    setTimeout(()=>{ 
      localstoreService.set(this.keyNameDiplomasAssinado,{
        list: this._diplomasAssinadoList,
        count: this._diplomasAssinadoListCount,
      })
    }, 10)   
    this.updateNewCount() 
  }

  private saveDiplomasParaAssinar () {
    setTimeout(()=>{ 
      localstoreService.set(this.keyNameDiplomasParaAssinar,{
        list: this._diplomasParaAssinar,
        count: this._diplomasListCount,
      })
    }, 10)
    this.updateNewCount()
  }

  private savetDiplomaGerar() {
    setTimeout(()=>{ 
      localstoreService.set(this.keyNameDiplomasGerar,{
        list: this._diplomasGerarList,
        count: this._diplomasGerarListCount,
      })
    }, 10)

    this.updateNewCount()
  }


  updateNewCount() {
    this.diplomasAssinadoListNewCount = this._diplomasAssinadoList.filter((e) =>{
      return this.lessthen24Hours(e.TaskReceiveDate)
    })
    this.diplomasReviewListNewCount = this._diplomasReviewList.filter((e) =>{
      return this.lessthen24Hours(e.TaskReceiveDate)
    })
    this.diplomasGerarListNewCount = this._diplomasGerarList.filter((e) =>{
      return this.lessthen24Hours(e.TaskReceiveDate)
    })
    this.diplomasParaAssinarNewCount = this._diplomasParaAssinar.filter((e) =>{
      return this.lessthen24Hours(e.TaskReceiveDate)
    })

  }


  lessthen24Hours(isoDateString:string) {

    if(!isoDateString) {
      return false
    }
    
    const creationDate = new Date(isoDateString)
    return momentG(new Date(), 'dd MMMM yyyy') == momentG(new Date(creationDate), 'dd MMMM yyyy')
  }


}

export let DeplomasStore = new DeplomasService() 


<ion-toolbar class="main-header d-flex">
    <div class="d-flex align-center">
      <div class="icon" (click)="close()">
      <ion-icon *ngIf="ThemeService.currentTheme == 'default' " style="font-size:35px;" src="assets/images/icons-arrow-arrow-left.svg"></ion-icon>
      <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " style="font-size:35px;" src="assets/images/theme/gov/icons-calendar-arrow-left.svg"></ion-icon>
      <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' "  slot="end" src='assets/images/theme/{{ThemeService.currentTheme}}/icons-calendar-arrow-left.svg'></ion-icon>
    </div>

    <div>
      <ion-title>Remetentes</ion-title>
    </div>
  </div>
</ion-toolbar>

<ion-content>
  <div class="main-container">
    <div class="input-text d-flex ion-align-items-center">
      <ion-input [(ngModel)]='sender' class="search-input" type="search" placeholder="Pesquisar" (ngModelChange)="filterContact($event)" ></ion-input>
    </div>
    <ul>
      <li *ngFor="let contact of showContacts" (click)="selectUser(contact)">
        {{ contact.Name }}
      </li>
    </ul>
  </div>
</ion-content>

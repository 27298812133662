import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { IMessageGetAllByRoomIdOutPut } from 'src/app/core/chat/usecase/message/message-get-all-by-room-Id';
import { HttpAdapter } from 'src/app/infra/http/adapter';
import { IDistributionLocalRepository } from 'src/app/core/chat/repository/distribution/distribution-local-repository'
import { z } from 'zod';
import { distributionListDetermineChanges } from '../../data/async/list/rooms/distributionListChangedetector';
import { DistributionEntity } from 'src/app/core/chat/entity/distribution';

export const DistributionOutPutDTOSchema = z.object({
  $messageIdMemberId: z.string(),
  memberId: z.number(),
  readAt: z.string().nullable(),
  deliverAt: z.string().nullable()
})
export type  DistributionOutPutDTO = z.infer<typeof DistributionOutPutDTOSchema>

@Injectable({
  providedIn: 'root'
})
export class DistributionService {

  constructor(
    private http: HttpAdapter,
    private distributionLocalRepository: IDistributionLocalRepository
  ) {}

  listenToLoadHistory() {
    return this.http.listen().pipe(
      filter((response)=> {
        if(response?.isOk()) {
          return response.value.url.endsWith('Messages')
        }
        
        return false
      }),
      map((response: any) => response.value.data as IMessageGetAllByRoomIdOutPut)
    )
    .subscribe(async (data) => {
      
      const localList = await this.distributionLocalRepository.find({roomId: data.data[0].roomId})

      data.data.map(async (message) => {
        const serverList = message.info

        if(localList.isOk() && localList.value) {

          // const localListForCurrentMessage = localList.value.filter((e) => e.messageId == message.id)

          // console.log('localListForCurrentMessage', localListForCurrentMessage)
          
          // const { distributionToInsert, distributionToUpdate, distributionToDelete } = await distributionListDetermineChanges(serverList, localListForCurrentMessage, message.id)
          // console.log({distributionToInsert, distributionToUpdate, distributionToDelete})

          // const map = distributionToInsert.map((e) => {
          //   e.
          // })

          // this.distributionLocalRepository.insertMany(distributionToInsert)

          // const distributionEntityToInsert = distributionToInsert.map((ee)=> {
          //   return new DistributionEntity({
          //     roomId: message.roomId,
          //     deliverAt: ee.deliverAt,
          //     memberId: ee.memberId,
          //     messageId: message.roomId,
          //     readAt: ee.readAt,
          //   })

          //   console.log('create')
          // })

          // if(distributionEntityToInsert.length >= 0) {
          //   // console.log('distributionEntityToInsert', distributionEntityToInsert)
          //   //this.distributionLocalRepository.insertMany(distributionEntityToInsert)
          // }

          
        }
        
      })

    })
  }
}

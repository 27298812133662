import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class AESEncrypt {

    ivArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
    constructor() { }

    encrypt(password, email) {
        // debugger;
        // console.log({password, email})
        var text = "Pro-it te espera!!!!";
        //Creating the Vector Key
        //console.log({'this.toHexString(this.ivArray)': this.toHexString(this.ivArray),'this.ivArray': this.ivArray })
        var iv = CryptoJS.enc.Hex.parse(this.toHexString(this.ivArray));
        //Encoding the Password in from UTF8 to byte array
        var Email = CryptoJS.enc.Utf8.parse(email);
        //console.log('Pass.toString()', Email.toString())
        //Encoding the Salt in from UTF8 to byte array
        var Salt = CryptoJS.enc.Utf8.parse("gabinetedigital");
        //console.log('Salt.toString()', Salt.toString())
        //Creating the key in PBKDF2 format to be used during the decryption
        var key128Bits1000Iterations = CryptoJS.PBKDF2(Email.toString(CryptoJS.enc.Utf8), Salt, { keySize: 128 / 32, iterations: 1000 });
        //console.log(key128Bits1000Iterations.toString())

        //Decrypting the string contained in cipherParams using the PBKDF2 key
        var decrypted = CryptoJS.AES.encrypt(password, key128Bits1000Iterations, { mode: CryptoJS.mode.CBC, iv: iv, padding: CryptoJS.pad.Pkcs7 });
        //
        return decrypted.toString();
    }

    decrypt(deceyptData,pass) {

        //Creating the Vector Key
        var iv = CryptoJS.enc.Hex.parse(this.toHexString(this.ivArray));
        //Encoding the Password in from UTF8 to byte array
        var Pass = CryptoJS.enc.Utf8.parse(pass);
        //Encoding the Salt in from UTF8 to byte array
        var Salt = CryptoJS.enc.Utf8.parse("gabinetedigital");
        //Creating the key in PBKDF2 format to be used during the decryption
        var key128Bits1000Iterations = CryptoJS.PBKDF2(Pass.toString(CryptoJS.enc.Utf8), Salt, { keySize: 128 / 32, iterations: 1000 });
        //Enclosing the test to be decrypted in a CipherParams object as supported by the CryptoJS libarary
        var cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Base64.parse(deceyptData)
        })

        //Decrypting the string contained in cipherParams using the PBKDF2 key
        var decrypted = CryptoJS.AES.decrypt(cipherParams, key128Bits1000Iterations, { mode: CryptoJS.mode.CBC, iv: iv, padding: CryptoJS.pad.Pkcs7 });

        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    toHexString(byteArray) {
        return Array.from(byteArray, (value: any) => {
            return ('0' + (value & 0xFF).toString(16)).slice(-2);
        }).join('')
    }

}



import { Injectable } from '@angular/core';
import Dexie, { PromiseExtended } from 'Dexie';
import { IUserPhotoLocalRepository } from 'src/app/core/chat/repository/user-photo/user-photo-local-repository';
import { UserPhotoTable, UserPhotoTableSchema } from 'src/app/infra/database/dexie/instance/chat/schema/user-foto';
import { chatDatabase } from 'src/app/infra/database/dexie/service';
import { DexieRepository } from 'src/app/infra/repository/dexie/dexie-repository.service';

@Injectable({
  providedIn: 'root'
})
export class UserPhotoLocalRepository extends DexieRepository<UserPhotoTable, UserPhotoTable> implements IUserPhotoLocalRepository  {
  constructor() {
    super(chatDatabase.userPhoto, UserPhotoTableSchema)
  }
}

import { Injectable } from '@angular/core';
import { EventList } from '../models/agenda/AgendaEventList';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  deferenceBetweenDays(start: any, end: any) {
    const diffTime = Math.abs(end - start);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  notSameDate(start: any, end: any): boolean {
    return new Date(start).toLocaleDateString() != new Date(end).toLocaleDateString()
  }
  isSameDate(start: any, end: any): boolean {
    return !this.notSameDate(start, end)
  }

  EventEndDateTreatment({startTime, endTime}) {

    const startTimeSamp = new Date(startTime).toLocaleDateString()
    const endTimeSamp = new Date(endTime).toLocaleDateString()

    const endMinutes = new Date(endTime).getMinutes()
    const endHours = new Date(endTime).getHours()


    if (startTimeSamp < endTimeSamp  && (endMinutes + endHours) == 0) {
      endTime = new Date(endTime);
      return new Date(endTime)
    } else {
      return new Date(endTime)
    }
  }



  getDay(date) {
    return (((new Date (date)).getDate())).toString().padStart(2,'0')
  }


  fixDate(res: EventList): any {
    if(res.IsAllDayEvent && this.deferenceBetweenDays(new Date(res.StartDate), new Date(res.EndDate)) >= 1) {

      const date = new Date(res.EndDate);

      date.setDate(date.getDate() -1);

      const _date = String(date.getDate()).padStart(2,'0');
      const month = String(date.getMonth() + 1).padStart(2,'0');
      const fullYear = date.getFullYear();
      const formattedDate = `${fullYear}-${month}-${_date} 23:59`;
      res.EndDate = new Date(formattedDate).toISOString();

    }

    return res as any
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoleIdService {

  constructor() { }

  /**
   * @description President role id
   */
  PV: number  = 100000011
  MD: number  = 100000011
  /**
   * @description Vice president role id
   */
  PRES: number  = 100000014
  /**
   * @description Consultant role id
   */
  Consultant: number = 99999872

}

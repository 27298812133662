<ion-content class="options-container width-100 ">
  <div class="arrow-right" (click)="close()">
    <button class="btn-no-color">
      <ion-icon *ngIf="ThemeService.currentTheme == 'default' "slot="end" class="arrow-right-icon" src='assets/images/icons-arrow-arrow-right.svg'></ion-icon>
      <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " slot="end" class="arrow-right-icon" src='assets/images/theme/gov/icons-calendar-arrow-right.svg'></ion-icon>
    </button>
  </div>
  <div class="width-100">
    <div class="d-flex width-100">
      <div class="flex-grow-1 flex-wrap d-flex">
        <button *ngIf="p.userPermission([p.permissionList.Gabinete.md_tasks])"  class="btn-cancel deskButtonText" shape="round"> Enviar para o {{ environment.PR}} </button>
        <button *ngIf="p.userPermission([p.permissionList.Gabinete.md_tasks])" (click)="openAddNoteModal('Aprovar')" class="btn-cancel" shape="round" >Solicitar consideração superior</button>
        <button *ngIf="p.userPermission([p.permissionList.Gabinete.md_tasks])" class="btn-cancel deskButtonText" shape="round"> Solicitar revisão </button>
        <button *ngIf="p.userPermission([p.permissionList.Gabinete.md_tasks])" (click)="openAddNoteModal('Revisão')" class="btn-cancel" shape="round" >Submeter para revisão</button>
        <button *ngIf="p.userPermission([p.permissionList.Gabinete.md_tasks])" class="btn-cancel deskButtonText" shape="round">Outras opções </button>
        <button (click)="openExpedientActionsModal('0',fulltask)" class="btn-cancel" shape="round" >Efetuar Despacho</button>
        <!-- <button (click)="close()" full class="btn-cancel" shape="round" >Cancelar</button> -->
        <button (click)="openExpedientActionsModal('1',fulltask)" class="btn-cancel" shape="round" >Solicitar Parecer</button>
        <button *ngIf="!p.userRole(['PR']) && !p.userPermission([p.permissionList.Gabinete.pr_tasks])" (click)="openExpedientActionsModal('2',fulltask)" class="btn-cancel" shape="round" >Pedido de Deferimento</button>
        <button *ngIf="p.userPermission([p.permissionList.Agenda.access])" (click)="openBookMeetingModal()" class="btn-cancel" shape="round" >Marcar Reunião</button>
        <button *ngIf="p.userPermission([p.permissionList.Gabinete.md_tasks])" (click)="attachDocument()" class="btn-cancel" shape="round" >Anexar Documentos</button>
        <button (click)="distartExpedientModal('descartar')" class="btn-cancel" shape="round" >Arquivar</button>
        <button (click)="sendExpedienteToPending()" *ngIf="(task.Status || '') != 'Pending'" full class="btn-cancel" shape="round"  >Enviar para Pendentes</button>
        <button *ngIf="p.userPermission([p.permissionList.Chat.access])" (click)="openNewGroupPage()" class="btn-cancel" shape="round" >Iniciar Conversa</button>
        <button (click)="close()" full class="btn-cancel" shape="round" >Cancelar</button>
      </div>
    </div>

  </div>
</ion-content>

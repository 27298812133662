import { Injectable } from '@angular/core';
import { ProcessesService } from '../services/processes.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class ProcessDocumentService {

  constructor(
    private processes: ProcessesService,
    private iab: InAppBrowser,
  ) {}

  viewDocument({DocId, ApplicationId}) {
    this.processes.GetDocumentUrl(DocId, ApplicationId).subscribe( (res) => {
      const url: string = res.replace("webTRIX.Viewer","webTRIX.Viewer.Branch1");
      const browser = this.iab.create(url,"_blank");
      browser.show();
    });
  }

}

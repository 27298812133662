import { Injectable } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';
import { StorageService} from 'src/app/services/storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {

  callBacks: {
    type: 'Offline' | 'Online' | 'Notification',
    object?: string
    funx: Function
  }[] = []

  status:  'online'| 'offline'  = 'online'

  constructor(
    private themeservice: ThemeService,
    private storageservice: StorageService,
    private http: HttpClient,
  ) {

    window.addEventListener('focus', (event) => {
      if(this.status == 'offline') {
        this.tryToReachTheServer()
      }
    });
  }

  online() {
    if(this.status == 'online') {
      return false
    }
    this.status = 'online'

    this.paint()
    this.callBacks.forEach((e) => {
      if (e.type == 'Online') {
        e.funx()
      }
    })
  }

  paint() {
    if (this.themeservice.currentTheme == 'gov') {
      document.body.style.setProperty(`--color`, "#d9d9d9");
      document.body.style.setProperty(`--color2`, "#f0f0f0");
      document.body.style.setProperty(`--color3`, "#d9d9d9");
      document.body.style.setProperty(`--color4`, "#d9d9d9ee");
      document.body.style.setProperty(`--color5`, "#ececec");
      this.storageservice.store('networkCheckStore','online');

    } else if (this.themeservice.currentTheme == 'default') {
      document.body.style.setProperty(`--color`, "#0782C9");
      document.body.style.setProperty(`--color2`, "#45BAFF");
      document.body.style.setProperty(`--color3`, "#0782C9");
      document.body.style.setProperty(`--color4`, "#0782c9f0");
      document.body.style.setProperty(`--color5`, "#45BAFF");
      this.storageservice.store('networkCheckStore','online');
    } else if (this.themeservice.currentTheme == 'doneIt') {
      document.body.style.setProperty(`--color`, "#69B3E7");
      document.body.style.setProperty(`--color2`, "#A5D1F1");
      document.body.style.setProperty(`--color3`, "#69B3E7");
      document.body.style.setProperty(`--color4`, "#69B3E7");
      document.body.style.setProperty(`--color5`, "#A5D1F1");
    }
  }

  async offline() {

    if(this.status == 'offline') {
      return true
    }

    const hasReachedTheServer = await this.tryToReachTheServer()

    if(!hasReachedTheServer) {
      this.status = 'offline'
      document.body.style.setProperty(`--color`, "#ffb703");
      document.body.style.setProperty(`--color2`, "#ffb703");
      document.body.style.setProperty(`--color3`, "#ffb703");
      document.body.style.setProperty(`--color4`, "#ffb703");
      document.body.style.setProperty(`--color5`, "#ffb703");
      this.storageservice.store('networkCheckStore','offline');
      this.callBacks.forEach((e) => {
        if (e.type == 'Offline') {
          e.funx()
        }
      })

      return true
    }

    return false
  }


  async tryToReachTheServer() {
    let opts = {
      headers: {},
    }

    try {
      await this.http.get("assets/images/theme/gov/governoangola_A.png").toPromise();

      return true
    } catch (error) {

      if(error.status === 0) {
        return false
      }

      return true
    }
  }

  registerBackService(type: 'Offline' | 'Online' | 'Notification', funx: Function, object = '') {
    this.callBacks.push({
      type,
      funx,
      object
    })
  }

}

<ion-header class="ion-no-border width-100">
  <div class="header-content pb-20 width-100">
    <div class="header-title width-100">
      <label>Adicionar comentário</label>
    </div>
  </div>
  <div class="ion-input-class-no-height">
    <ion-textarea autocomplete="on" autocorrect="on" spellcheck="true" [(ngModel)]="note" placeholder="Comentário" rows="6" cols="20"></ion-textarea>
  </div>

  <div *ngIf="hideThisFeature" class="ion-item-container-no-border">
    <ion-label class="attach-wrapper d-flex align-center cursor-pointer" (click)="getDoc()">
      <button class="btn-no-color">
      <div class="attach-icon">
        <ion-icon *ngIf="ThemeService.currentTheme == 'default' " src="assets/images/icons-attach-doc.svg"></ion-icon>
        <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " src="assets/images/theme/gov/icons-attach-doc.svg"></ion-icon>
                <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " src="assets/images/theme/{{ThemeService.currentTheme}}/icons-attach-doc.svg"></ion-icon>
        <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' "  slot="icon-only"  src="assets/images/theme/{{ThemeService.currentTheme}}/icons-attach-doc.svg"></ion-icon>
      </div>
      <div class="attach-document pl-10 ">
        <ion-label>Anexar Documentos</ion-label>
      </div>
    </button>
    </ion-label>
    <ion-label *ngIf="documents.length > 0">Documentos anexados</ion-label>
  </div>

</ion-header>

<ion-content>
  <div class="content width-100 overflow-y-auto height-100">
    <div class="list">
      <ion-list  *ngFor="let document of documents; let i = index">
        <ion-item>
        <ion-label>
          <p class="d-flex ion-justify-content-between">
            <span class="attach-title-item">{{document.Assunto}}</span>
            <span class="app-name" *ngIf="!document.appName">{{ TaskService.attachmentAppName(document)}}</span>
            <span class="app-name" *ngIf="document.appName">{{document.appName}}</span>
            <span class="close-button text-black cursor-pointer" (click)="removeAttachment(i)" >
              <ion-icon class="font-20" src="assets/images/icons-delete-25.svg"></ion-icon>
            </span>
          </p>
          <p><span class="span-left">{{(document.EntidadeOrganicaNome || document.Sender)}}</span><span class="span-right"> {{document.Data | date: 'dd-MM-yyyy HH:mm'}} </span></p>
        </ion-label>
      </ion-item>
      </ion-list>
    </div>
  </div>
</ion-content>
<ion-footer class="ion-no-border">
  <div class="buttons width-100">
    <button class="btn-ok cursor-pointer" shape="round" (click)="save()">Enviar</button>
    <button class="btn-cancel cursor-pointer" shape="round" (click)="close()">Cancelar</button>
    <!-- <button *ngIf="actionName == 'Executado'" class="btn-ok cursor-pointer" shape="round" (click)="save()">Executado</button>
    <button *ngIf="actionName == 'Gerar Diploma'" class="btn-ok cursor-pointer" shape="round" (click)="save()">Gerar</button>
    <button *ngIf="actionName == 'Arquivar'" class="btn-ok cursor-pointer" shape="round" (click)="save()">Arquivar</button>
    <button *ngIf="actionName == 'Arquivo'" class="btn-ok cursor-pointer" shape="round" (click)="save()">Arquivar</button>
    <button *ngIf="actionName == 'Reexecução'" class="btn-ok cursor-pointer" shape="round" (click)="save()">Enviar</button>
    <button *ngIf="actionName == 'Concluido'" class="btn-ok cursor-pointer" shape="round" (click)="save()">Concluído</button>
    <button *ngIf="actionName == 'Solicitar assinatura'" class="btn-ok cursor-pointer" shape="round" (click)="save()">Solicitar</button>
    <button *ngIf="actionName == 'Solicitar alteração'" class="btn-ok cursor-pointer" shape="round" (click)="save()">Solicitar</button>
    <button *ngIf="actionName == 'Concluir diploma'" class="btn-ok cursor-pointer" shape="round" (click)="save()">Concluir</button>
    <button *ngIf="actionName == 'Aprovar'" class="btn-ok cursor-pointer" shape="round" (click)="save()">Aprovar</button>
    <button *ngIf="actionName == 'Revisão'" class="btn-ok cursor-pointer" shape="round" (click)="save()">Enviar</button>
    <button *ngIf="actionName == 'Solicitar Reapreciação'" class="btn-ok cursor-pointer" shape="round" (click)="save()">Solicitar</button>
    <button *ngIf="actionName == 'Assinar Diploma'" class="btn-ok cursor-pointer" shape="round" (click)="save()">Assinado</button> -->
    
    
    
    
    
  </div>
</ion-footer>

import { Injectable } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { BackgroundService } from 'src/app/services/background.service';
import { HttpErrorResponse } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandle {

  constructor(
    private toastService: ToastService,
    private backgroundService: BackgroundService
  ) { }

  async httpStatusHandle(error: any) {
    switch (error.status
    ) {
      case 0:
        const result = await this.backgroundService.offline()
        if (result) {
          this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
        } else {

        }

        break;
      case 400:
        this.toastService._badRequest('Lamentamos, mas houve um problema com sua solicitação. Por favor, tente novamente')
        break;
      case 401:
        this.toastService._badRequest('Lamento, parece que não tem acesso a essas informações. Entre em contacto com o administrador.');
        break;
      case 403:
        this.toastService._badRequest('Lamentamos, você não tem permissão para acessar este serviço.')
        break;
      case 404:
        this.toastService._badRequest('Lamentamos, não encontramos o que procura.')
        break;
      case 500:
        this.toastService._badRequest('Lamentamos, mas houve um erro interno do servidor. Por favor, tente novamente mais tarde.')
        break;
      case 502:
        this.toastService._badRequest('Lamentamos, houve um problema com o servidor. Por favor, tente novamente mais tarde.')
        break;
      case 503:
        this.toastService._badRequest('Lamentamos, o serviço está indisponível no momento. Por favor, tente novamente mais tarde.')
        break
      default:
        break;
    }
  }

  async httpStatusHandleUploadFile(error) {
    switch (error.status
    ) {
      case 403:
        this.toastService._badRequest('O ficheiro que tentou enviar contém possível script malicioso')
        break;
      default:
        break;
    }
  }

  async loginHttpStatusHandle(error) {
    switch (error.status
    ) {
      case 0:
        const result = await this.backgroundService.offline()
        if (result) {
          this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
        } else {

        }

        break;
      case 400:
        this.toastService._badRequest('Não foi possível fazer login. Por favor, verifique as suas credenciais.')
        /* this.toastService._badRequest('Lamentamos, mas houve um problema com sua solicitação. Por favor, tente novamente') */
        break;
      case 401:
        this.toastService._badRequest('Não foi possível fazer login. Por favor, verifique as suas credenciais.');
        break;
      case 403:
        this.toastService._badRequest('Por questões de segurança, a sua conta foi bloqueada. Por favor, contacte o suporte.')
        break;
      case 404:
        this.toastService._badRequest('Lamentamos, não encontramos o que procura.')
        break;
      case 500:
        this.toastService._badRequest('Lamentamos, mas houve um erro interno do servidor. Por favor, tente novamente mais tarde.')
        break;
      case 502:
        this.toastService._badRequest('Lamentamos, houve um problema com o servidor. Por favor, tente novamente mais tarde.')
        break;
      case 503:
        this.toastService._badRequest('Lamentamos, o serviço está indisponível no momento. Por favor, tente novamente mais tarde.')
        break
      default:
        break;
    }
  }

  validationMessagge(service: string, callback?: any) {
    switch (service) {
      case 'diplomaAsDraft':
        this.toastService._badRequest('Este diploma não contem um rascunho para ser assinado!')
        break;
      case 'filetype':
        this.toastService._badRequest('Para a segurança da informação, não é permitido carregar ficheiros deste tipo de formato.')
        break;
      case 'filessize':
        this.toastService._badRequest('Excedeu o limite de 20 MB!')
        break;
      case 'noFileSelected':
        this.toastService._badRequest('Selecione uma imagem ou vídeo')
        break;

      default:
        this.toastService._badRequest('')
        break;
    }
  }

  httpsSucessMessagge(service: string, callback?: any) {
    switch (service) {
      case 'new event':
        this.toastService._successMessage('Evento criado!')
        break;
      case 'Editar evento':
        this.toastService._successMessage('Evento editado!')
        break
      case 'delete event':
        this.toastService._successMessage('Evento eliminado!')
        break;
      case 'Executado':
        this.toastService._successMessage('Despacho executado!')
        break;
      case 'Gerar Diploma':
        this.toastService._successMessage('Enviado com sucesso!')
        break;
      case 'Efetuar Despacho':
        this.toastService._successMessage('Despacho efetuado!')
        break;
      case 'Solicitar Parecer':
        this.toastService._successMessage('Pedido de parecer solicitado!')
        break;
      case 'Delegar':
        this.toastService._successMessage('Tarefa delegada!')
        break;
      case 'Marcar Reunião':
        this.toastService._successMessage('Evento criado!')
        break;
      case 'Enviar para Pendentes':
        this.toastService._successMessage('Enviado com sucesso!')
        break;
      case 'Aprovar':
        this.toastService._successMessage('Evento aprovado!')
        break;
      case 'Rever':
        this.toastService._successMessage('Evento enviado para revisão!')
        break;
      case 'Avaliação Superiror':
        this.toastService._successMessage('Enviado para avaliação!')
        break;
      case 'Mandar para Revisão':
        this.toastService._successMessage('Enviado para revisão!')
        break;
      case 'Pedido de Deferimento':
        this.toastService._successMessage('Enviado com sucesso!')
        break;
      case 'Arquivar':
        this.toastService._successMessage('Tarefa Arquivada!')
        break
      case 'Concluir Despacho':
        this.toastService._successMessage('Despacho concluido')
        break;
      case 'Concluir':
        this.toastService._successMessage('Diploma concluido')
        break;
      case 'Reexecução':
        this.toastService._successMessage('Enviado para reexecução!')
        break;
      case 'Assinado':
        this.toastService._successMessage('Diploma assinado!')
        break;
      case 'Solicitar Assinatura':
        this.toastService._successMessage('Solicitação enviada!')
        break;
      case 'Solicitar alteração':
        this.toastService._successMessage('Solicitação enviada!')
        break;
      case 'Evento aprovação':
        this.toastService._successMessage('Evento aprovado!')
        break;
      case 'Rejeitar':
        this.toastService._successMessage('Evento Eliminado!')
        break;
      case 'Dar o meu Parecer':
        this.toastService._successMessage('Parecer enviado!')
        break;
      case 'Reencaminhar':
        this.toastService._successMessage('Reencaminhado!')
        break;
      case 'Eliminar publicação':
        this.toastService._successMessage('Publicação Eliminada!')
        break;
      case 'Editar publicação':
        this.toastService._successMessage('Publicação Editada!')
        break;
      case 'Criar publicação':
        this.toastService._successMessage('Publicação criada!')
        break;
      case 'Acção criada':
        this.toastService._successMessage('Acção criada!')
        break;
      case 'Editar Acção':
        this.toastService._successMessage('Acção editada!')
        break;
      case 'Eliminar Acção':
        this.toastService._successMessage('Acção eliminada!')
        break;
      case 'Solicitar Reapreciação':
        this.toastService._successMessage('Solicitação enviada!')
        break;
      case 'Aprovar exp':
        this.toastService._successMessage('Expediente enviado!');
        break;
      case 'Draft Save':
        this.toastService._successMessage('Rascunho salvo!');
        break;
      case 'Draft Concluído':
        this.toastService._successMessage('Documento Concluído!');
        break;
      case 'new event to aprove':
        this.toastService._successMessage('Enviado para os eventos para aprovação!');
        break;
      case 'sessonExpired':
        this.toastService._successMessage('Sessão expirada, por favor efectuar login novamente!');
        break;
      default:
        this.toastService._successMessage('Processo efetuado!')
        break;
    }
  }
}

import { Injectable } from '@angular/core';
import * as signalR  from "@microsoft/signalr"
import { SessionStore } from '../store/session.service';
import { v4 as uuidv4 } from 'uuid'
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { CMAPIService } from '../shared/repository/CMAPI/cmapi.service';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { ok, err as Err, Result } from 'neverthrow';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SocketConnectionMCRService {
  // private  callbacks: Function[] = []
  // private  onDisconnect: Function[] = []
  // private  onConnect: Function[] = []

  constructor(private http: HttpClient, private _CMAPIService: CMAPIService) {
    window["http"] = this.http
  }

  // connect() {

  //   var connection = new signalR.HubConnectionBuilder()
  //   .withUrl("https://gdcmapi-dev.dyndns.info/FileHub", {
  //     accessTokenFactory: () => "Bearer "+SessionStore.user.Authorization
  //   }).configureLogging(signalR.LogLevel.Information)
  //   .build();

  //   connection.on("ReceiveMessage", (message) => {
  //     console.log("ReceiveMessage", message)
  //   })

  //   connection.onreconnected((connectionId) => {
  //     console.assert(connection.state === signalR.HubConnectionState.Connected);
  //     console.log(`Reconnected with connectionId: ${connectionId}`);
  //   });

  //   connection.start()
  //   .then(() => {
  //       console.log("SignalR connection started.");
  //   })
  //   .catch((error) => {
  //       console.error("Error starting SignalR connection:", error);
  //   });

  //   connection.onclose((error) => {
  //     connection.start()
  //     console.log("SignalR connection closed:", error);
  //   });

  // }

  // subscribe(callback) {
  //   this.callbacks.push(callback);
  // }

  // unsubscribe(callback) {
  //   this.callbacks = this.callbacks.filter(cb => cb !== callback);
  // }

  // onDisconnectCallback(callback) {
  //   this.onDisconnect.push(callback)
  // }
  // onConnectCallback(callback) {
  //   this.onConnect.push(callback)
  // }

}

class ReconnectingWebSocketSignalR {

  private connection: any
  isOpen: boolean = false
  private  callbacks: Function[] = []
  private  onDisconnect: Function[] = []
  private  onConnect: Function[] = []
  private whenConnected: Function[] = []
  stop = true

  constructor() {}

  connect() {
    console.log("try to connect=================================")
    this.stop = false;

    // Limpar a conexão anterior, se existir

    if (this.connection && this.connection.state !== signalR.HubConnectionState.Disconnected) {
      this.connection.stop();
    }

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl( environment.fileHub, {
        transport: signalR.HttpTransportType.LongPolling,
        accessTokenFactory: () => SessionStore.user.Authorization
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.connection.start()
      .then(() => {
        this.isOpen = true;
        console.log('WebSocket connection established');
        this.onConnect.forEach(callback => callback());
        this.whenConnected.forEach(callback => callback());

      }).catch((error) => {

        console.error("Error starting SignalR connection:", error);
        // Adicione tratamento de erros detalhado conforme necessário
        // Exemplo: Verificar se o erro é devido à perda de conexão com a internet

        if (error.message.includes("Failed to fetch")) {
          console.error("Erro de conexão com a internet");
        }
        // Tentar reconectar após um atraso
        if (!this.stop) {
          setTimeout(() => {
            this.connect();
          }, 1000); // Ajuste o atraso conforme necessário
        }
      });

    this.connection.on("ReceiveMessage", (message) => {

      const data = JSON.parse(message);

      console.log("ReceiveMessage", data);

      this.callbacks.forEach(callback => callback(data));

    });

    this.connection.onclose((error) => {
      console.log('WebSocket connection closed..');
      this.isOpen = false;
      this.onDisconnect.forEach(callback => callback());
      // Tentar reconectar após um atraso
      if (!this.stop && (!error || error.message !== "Connection stopped by client.")) {
        setTimeout(() => {
          this.connect();
        }, 3000); // Ajuste o atraso conforme necessário

      }

    });
  }

  commit(path): Promise<Result<true, false>>  {
    console.log('committing')
    return new Promise((resolve, reject) => {
      if(this.isOpen) {
        console.log('open')
        try {
          console.log('this.connection.invoke', this.connection)
          this.connection.invoke("CommitUpload", path).then((e) => {
            console.log("commit message", e)
            resolve(ok(true))
          }).catch(err => {
            console.error('upload catch commit error')
            resolve(Err(false))
            console.error(err.toString())
          });
        } catch(error) {
          resolve(Err(false))
          console.error('upload commit error')
          console.error(error)
        }

      } else {
        console.log('wait')
        this.onConnect.push(()=> {
          resolve(this.commit(path))
        })
      }
    })
  }

  disconnect() {
    this.stop = true
    if(this.isOpen == true) {
      this.connection.stop()
      .then(() => {
        console.log('WebSocket connection was closed by client');
        this.isOpen = false;
        this.onDisconnect.forEach(callback => callback());
        console.log("SignalR connection stopped.");
      })
      .catch((error) => {
        console.error("Error stopping SignalR connection by client:", error);
      });
    }
  }

  subscribe(callback) {
    this.callbacks.push(callback);
  }

  unsubscribe(callback) {
    this.callbacks = this.callbacks.filter(cb => cb !== callback);
  }

  onDisconnectCallback(callback) {
    this.onDisconnect.push(callback)
  }
  onConnectCallback(callback) {
    this.onConnect.push(callback)
  }

  registerWhenConnected(f: Function) {
    if(this.isOpen) {
      f();
    } else {
      this.whenConnected.push(f);
    }

  }
}

interface socketResponse {
  index: string
  Guid: string
  IsCompleted: Boolean
}
// class ReconnectingWebSocket {

//   private url: string
//   private  socket
//   isOpen: boolean
//   private  callbacks: Function[] = []
//   private  onDisconnect: Function[] = []
//   private  onConnect: Function[] = []
//   private whenConnected: Function[] = []
//   private stop = true
//   http: HttpClient = window["http"]

//   constructor(url) {
//     this.url = url;
//     this.socket = null;
//     this.isOpen = false;
//   }

//   connect() {
//     this.socket = new WebSocket(this.url);

//     this.socket.addEventListener('open', (event) => {
//       this.isOpen = true;
//       console.log('WebSocket connection established');

//       // Example: Send a message to the server
//       this.socket.send('Hello, WebSocket Server!');
//       this.onConnect.forEach(callback => callback());
//       this.whenConnected.forEach(callback => callback())
//     });

//     this.socket.addEventListener('message', (event) => {
//       const data: socketResponse = JSON.parse(event.data)
//       this.callbacks.forEach(callback => callback(data));
//     });

//     this.socket.addEventListener('close', (event) => {
//       console.log('WebSocket connection closed');
//       this.isOpen = false;
//       this.onDisconnect.forEach(callback => callback());
//       // Attempt to reconnect after a delay
//       if(this.stop == false) {
//         setTimeout(() => {
//           this.connect();
//         }, 1000); // Adjust the delay as needed
//       }
//     });
//   }

//   send(message) {
//     if (this.isOpen) {
//       this.socket.send(message);
//     } else {
//       console.error('WebSocket connection is not open. Unable to send message.');
//     }
//   }

//   disconnect() {
//     this.stop = true
//     if (this.isOpen) {
//       this.isOpen = false;
//       this.socket.close();
//     }
//   }

//   subscribe(callback) {
//     this.callbacks.push(callback);
//   }

//   unsubscribe(callback) {
//     this.callbacks = this.callbacks.filter(cb => cb !== callback);
//   }

//   onDisconnectCallback(callback) {
//     this.onDisconnect.push(callback)
//   }
//   onConnectCallback(callback) {
//     this.onConnect.push(callback)
//   }

//   registerWhenConnected(f: Function) {
//     if(this.isOpen) {
//       f();
//     } else {
//       this.whenConnected.push(f);
//     }

//   }
// }

// export class ObjectMergeNotification{

//   socket = new ReconnectingWebSocket('ws://localhost:3002');
//   callbacks: {[GUID: string]: Function} = {}
//   runWatch =  true
//   CMAPIService:  CMAPIService = window["CMAPIAPIRepository"]
//   watchCount  = 0

//   constructor() {
//     this.socket.onDisconnectCallback(()=> {
//       console.log("run watch")
//       this.runWatch = true
//       this.watch()
//     })

//     this.socket.onConnectCallback(()=> {
//       console.log("open trigger")
//       this.runWatch = false
//     })

//     this.socket.subscribe((data: socketResponse) => {
//       if(data.IsCompleted == true) {
//         console.log("==================!!!====================")
//         try {
//           this.callbacks[data.Guid](data)
//           delete this.callbacks[data.Guid]
//         } catch (error) {}
//       } else {
//         console.log("else", data)
//       }
//     })

//     this.watch()
//   }

//   connect() {
//     this.socket.connect()
//   }

//   async watch() {

//     this.watchCount = 0;

//     if(this.runWatch) {
//       setTimeout(async () => {
//         for(const [key, funx] of Object.entries(this.callbacks)) {

//           const request = await this.CMAPIService.getVideoHeader(key)

//           if(request.isOk()) {
//             funx()
//             delete this.callbacks[key]
//           }
//         }

//         this.watchCount++
//         if(this.watchCount <= 15) {
//           this.watch()
//         } else {
//           this.runWatch = false
//         }

//       }, 1000)


//     } else {
//       console.log("end loop============================")
//     }
//   }

//   close() {
//     this.socket.disconnect();
//     this.watchCount = 0;
//     this.runWatch = false
//   }

//   subscribe(GUID, callback:Function) {
//     this.callbacks[GUID] = callback;
//   }

//   unsubscribe(GUID) {
//     delete this.callbacks[GUID]
//   }

// }


export class ObjectMergeNotification{

  socket = new ReconnectingWebSocketSignalR()
  callbacks: {[GUID: string]: Function} = {}
  runWatch =  true
  CMAPIService:  CMAPIService = window["CMAPIAPIRepository"]
  watchCount  = 0

  constructor() {
    this.socket.onDisconnectCallback(()=> {
      //console.log("run watch")
      this.runWatch = true
      //this.watch()
    })

    this.socket.onConnectCallback(() => {
      console.log("open trigger")
      this.runWatch = false
    })

  }

  connect() {
    this.socket.connect();
  }

  close() {
    this.socket.disconnect();
    this.watchCount = 0;
    this.runWatch = false
  }

  async watch() {

    this.watchCount = 0;

    if(this.runWatch) {
      setTimeout(async () => {
        for(const [key, funx] of Object.entries(this.callbacks)) {

          const request = await this.CMAPIService.getVideoHeader(key)

          if(request.isOk()) {
            funx()
            delete this.callbacks[key]
          }
        }

        this.watchCount++
        if(this.watchCount <= 15) {
          this.watch()
        } else {
          this.runWatch = false
        }

      }, 1000)


    } else {
      console.log("end loop============================")
    }
  }

  subscribe(GUID, callback:Function) {
    this.callbacks[GUID] = callback;
  }

  unsubscribe(GUID) {
    delete this.callbacks[GUID]
  }

}

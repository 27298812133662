import { Injectable } from '@angular/core';
import { ContactsDataSourceService } from '../data-source/contacts-data-source.service';
import { ListEventMapper } from '../mapper/contactCombinedMapper';


@Injectable({
  providedIn: 'root'
})
export class ContactRepositoryService {

  constructor(
    private constactsDataSourceService: ContactsDataSourceService,
  ) {}

  async getUsersMap() {
    const result = await this.constactsDataSourceService.getCombinedList();
    return result.map((result) => {
      return ListEventMapper.toDomain(result).filter(e => e.EmailAddress != null)
    });
  }

  async getUsers() {
    const result = await this.constactsDataSourceService.getUsers();
    return result;
  }
}

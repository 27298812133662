import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EmptyContainerPage } from './empty-container.page';

const routes: Routes = [
  {
    path: '',
    component: EmptyContainerPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmptyContainerPageRoutingModule {}

import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceService {

  constructor(private http: HttpClient) {}

  put<T>(url: string, body: any | null, options: Options) {
    return this.http.put<T>(url, body, options as any)
  }

  post<T>(url: string, body, option = null) {
    return this.http.post<T>(url, body, option)
  }

  get<T>(url: string, options: Options) {
    return this.http.get<T>(url, options )
  }

}

interface Options {
  headers?: HttpHeaders
  params?: HttpParams
}

import { HttpErrorResponse } from '@angular/common/http';
import { Result, err } from 'neverthrow';
import { z, ZodError } from 'zod';
import * as Sentry from '@sentry/capacitor';
import { TracingType } from '../monitoring/opentelemetry/tracer';

export function APIReturn(schema: z.ZodTypeAny, path: string) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args: any[]) {
      const result: Result<any, HttpErrorResponse> = await originalMethod.apply(this, args);

      const tracing: TracingType = args[args.length - 1];

      if(result.isOk()) {
        try {
          // Validate the result using the provided schema
          schema.parse(result.value);

        } catch (error) {
          if (error instanceof ZodError) {
            // If validation fails, throw an error with the details
            console.error('unexpected data structure', path)
            // Capture the Zod validation error with additional context
            console.error('Validation failed:', error.errors);
            console.log(result.value)

            tracing?.setAttribute?.('APIReturn.error', 'true')

            let i = 0;
            for(const schema of error.errors) {
              tracing?.setAttribute?.('map.error.schema-'+i, JSON.stringify(schema))
            }

            tracing.log('API return '+ path, {
              data: result?.value,
              issues: error?.errors
            })

          } else {
            // Throw any other unexpected errors
            // throw error;
            console.log(error)
          }
        }
      } else {
        // console.log()
        // result.error.
      }

      return result;
    }
    return descriptor;
  }
}


export function APINODReturn(schema: z.ZodTypeAny, data , path: string, tracing?: TracingType) {

  try {
    // Validate the result using the provided schema
    schema.parse(data);

  } catch (error) {
    if (error instanceof ZodError) {
      // If validation fails, throw an error with the details
      //
      console.error('unexpected data structure', path)
      // Capture the Zod validation error with additional context
      console.error('Validation failed:', error.errors);
      console.log(data)

      tracing?.setAttribute('APIReturn.error', 'true')
      tracing?.setAttribute('path', path)

      let i = 0;
      for(const schema of error.errors) {
        tracing?.setAttribute('map.error.schema-'+i, JSON.stringify(schema))
      }

      tracing.log('API return '+ path, {
        data,
        issues: error?.errors
      })

    } else {
      console.log(error)
      // Throw any other unexpected errors
      // throw error;
    }
  }

}

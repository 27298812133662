<ion-header>
  <ion-toolbar>
    <ion-title class="title"  *ngIf="roomData$ | async as roomData"> {{ roomData.roomName }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="px-20">

  <div class=px-20> Membros </div>

  <ion-list class="header-bottom-contacts px-20"  *ngIf="roomMembers$ | async as memberList">
    <div *ngFor="let user of memberList; let i = index">
      <div class="py-1 d-flex align-center">
        <div class="image-container mr-10">
          <img *ngIf="ObjectURL[user.wxUserId]"  [src]="ObjectURL[user.wxUserId]">
        </div>

        {{ user.wxFullName }} <span class="admin" *ngIf="user.isAdmin">(admin do group)</span>
      </div>
    </div>
  </ion-list>
</ion-content>

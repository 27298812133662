import { z } from "zod";
import { EEventOwnerType, EEventType, EEventCategory, EEventStatus } from "./enums";

const OwnerSchema = z.object({
  wxUserId: z.number(),
  wxFullName: z.string(),
  wxeMail: z.string(),
  userPhoto: z.string(),
});



const EventToApproveList =  z.array(z.object({
  id: z.string().uuid(),
  owner: OwnerSchema,
  ownerType: z.nativeEnum(EEventOwnerType),
  subject: z.string(),
  body: z.string().nullable().optional(),
  location: z.string().nullable(),
  startDate: z.string().datetime({ offset: true }),
  endDate: z.string().datetime({ offset: true }),
  type: z.nativeEnum(EEventType),
  category: z.nativeEnum(EEventCategory),
  isRecurring: z.boolean(),
  eventRecurrence: z.any().nullable(),
  hasAttachments: z.boolean(),
  isPrivate: z.boolean(),
  isAllDayEvent: z.boolean(),
  status: z.nativeEnum(EEventStatus)
}))

export const EventToApproveDataOutputDTOSchema = z.object({
  success: z.boolean(),
  message: z.string(),
  data: EventToApproveList,
}).nullable();


export type EventToApproveListOutputDTO = z.infer<typeof EventToApproveDataOutputDTOSchema>;

<ion-content class="height-100">
  <div class="main-content d-flex height-100">
    <div class="content d-flex flex-column width-100">
      <ion-item  lines="none">
        <ion-label class="title">{{modalTitle[taskType]}}</ion-label> <br>
      </ion-item>
      <ion-item  lines="none">
        <i style="margin-top: -3px; font-size: 15px;" > Campos marcados com * são obrigatórios</i>
      </ion-item>

      <div class="ion-item-container width-100" [class.input-error]="Form?.get('Subject')?.invalid && validateFrom ">
        <ion-input autocomplete="on" autocorrect="on" spellcheck="true" placeholder="Assunto*" [(ngModel)]="postData.DispatchFolder.Subject"></ion-input>
      </div>

      <div *ngIf="Form && validateFrom" >
        <div *ngIf="Form.get('Subject').invalid " class="input-errror-message">
          <div *ngIf="Form.get('Subject').errors?.required">

          </div>
          <div *ngIf="Form.get('Subject').errors?.minlength">
            O campo deve ter pelo menos 4 caracteres.
          </div>
        </div>
      </div>

      <!-- Add scrolls -->
      <div class="d-flex flex-column height-100 overflow-y-auto">

        <div class="container-div width-100">
          <div class="ion-item-class-2">
            <div class="ion-icon-class">
              <ion-icon slot="start" src="assets/images/icons-description.svg"></ion-icon>
            </div>
            <div class="ion-input-class flex-grow-1" [class.input-error]="Form?.get('Location')?.invalid  && validateFrom || validateField ">
              <ion-textarea autoGrow="true" autocomplete="on" autocorrect="on" spellcheck="true" class="add-border heigh-200" placeholder="Descrição*" [(ngModel)]="postData.DispatchFolder.Message"></ion-textarea>
            </div>
          </div>
        </div>

        <!-- <div *ngIf="Form && validateFrom" >
          <div *ngIf="Form.get('Location').invalid " class="input-errror-message">
            <div *ngIf="Form.get('Location').errors?.required">

            </div>
            <div *ngIf="Form.get('Location').errors?.minlength">
              O campo deve ter pelo menos 4 caracteres.
            </div>
          </div>
        </div> -->

        <div class="container-div width-100">
          <div class="ion-item-class-2 width-100">
            <div class="ion-icon-class">
              <ion-icon class="icon-time" slot="start" name="time-outline"></ion-icon>
            </div>
            <div class="ion-input-class" [class.input-error]="Form?.get('Priority')?.invalid  && validateFrom">
              <!-- <ion-select
                placeholder="Prazo*"
                [(ngModel)]="postData.Priority"
                interface="action-sheet"
                Cancel-text="Cancelar"
                required
                class="d-block d-md-none"
                >
                <ion-select-option value="99999861"><b>Normal</b> (4 dias para a execução da tarefa)</ion-select-option>
                <ion-select-option value="99999862"><b>Urgente</b> (2 dias para a execução da tarefa)</ion-select-option>
                <ion-select-option value="99999863"><b>Muito Urgente</b> (1 dia para a execução da tarefa)</ion-select-option>
                <ion-select-option value="99999864"><b>Urgentíssimo</b> (8 horas para a execução da tarefa)</ion-select-option>
              </ion-select> -->

              <mat-form-field appearance="fill" class="width-100" floatLabel="never" appearance="none">
                <mat-select placeholder="Prazo*" [(ngModel)]="postData.Priority">
                  <mat-option value="99999861"><b>Normal</b> (4 dias para a execução da tarefa)</mat-option>
                  <mat-option  value="99999862"><b>Urgente</b> (2 dias para a execução da tarefa)</mat-option>
                  <mat-option  value="99999863"><b>Muito Urgente</b> (1 dia para a execução da tarefa)</mat-option>
                  <mat-option value="99999864"><b>Urgentíssimo</b> (8 horas para a execução da tarefa)</mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>
        </div>

        <div *ngIf="Form && validateFrom" >
          <div *ngIf="Form.get('Priority').invalid " class="input-errror-message">
            <div *ngIf="Form.get('Priority').errors?.required">

            </div>
            <div *ngIf="Form.get('Priority').errors?.minlength">
              O campo deve ter pelo menos 4 caracteres.
            </div>
          </div>
        </div>

        <div class="container-div">
          <div class="ion-item-class-2">
            <div class="ion-icon-class">
              <ion-icon slot="start" src="assets/images/icons-description.svg"></ion-icon>
            </div>
            <div class="ion-input-class" [class.input-error]="Form?.get('selectedTypes')?.invalid  && validateFrom || validateField ">
              <!-- <ion-item
                class="ion-no-border ion-no-padding ion-no-margin d-block d-md-none">
                <ion-select
                [(ngModel)]="selectedTypes"
                (ngModelChange)="onSelectedTypesChanged($event)"
                multiple="true" interface="alert" Cancel-text="Cancelar" required>
                  <ion-select-option *ngFor="let type of subjectTypes" value="{{type.Code}}">{{type.Description}}</ion-select-option>
                </ion-select>
              </ion-item> -->
              <mat-form-field
                floatLabel="never"
                appearance="fill"
                class="width-100 d-block"
                appearance="none">
                <mat-select [multiple]="false" [(ngModel)]="selectedTypes" placeholder="{{placeholderSubject}}">
                  <mat-option *ngFor="let type of subjectTypes" value="{{type.Code}}">{{type.Description}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div *ngIf="Form && validateFrom" >
          <div *ngIf="Form.get('selectedTypes').invalid " class="input-errror-message">
            <div *ngIf="Form.get('selectedTypes').errors?.required">

            </div>
            <div *ngIf="Form.get('selectedTypes').errors?.minlength">
              O campo deve ter pelo menos 4 caracteres.
            </div>
          </div>
        </div>

        <div class="container-div" *ngIf="!p.userPermission([p.permissionList.Gabinete.pr_tasks])">
          <div class="ion-item-class-2">
            <div class="ion-icon-class">
              <ion-icon slot="start" src="assets/images/icons-person.svg"></ion-icon>
            </div>
            <div class="ion-input-class-no-height cursor-pointer" (click)="addParticipants();" [class.input-error]="Form?.get('participantes')?.invalid  && validateFrom ">
              <div class="list-people">
                <ion-item lines="none">
                  <ion-list>
                    <ion-label *ngIf="taskParticipants?.length < 1" class="list-people-title">Adicionar Intervenientes*</ion-label>
                    <ion-label *ngFor="let participant of taskParticipants">{{participant.Name}}</ion-label>
                  </ion-list>
                </ion-item>
              </div>
              <div class="add-people" >
                <ion-icon *ngIf="ThemeService.currentTheme == 'default' "  slot="start" src="assets/images/icons-arrow-forward.svg"></ion-icon>
                <ion-icon *ngIf="ThemeService.currentTheme == 'gov' "  slot="start" src="assets/images/theme/gov/icons-arrow-forward.svg"></ion-icon>
                <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' "  slot="start" src="assets/images/theme/{{ThemeService.currentTheme}}/icons-arrow-forward.svg"></ion-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="container-div" *ngIf="!p.userPermission([p.permissionList.Gabinete.pr_tasks])">
          <div class="ion-item-class-2">
            <div class="ion-icon-class">
              <ion-icon slot="start" src="assets/images/icons-people-cc.svg"></ion-icon>
            </div>
            <div class="ion-input-class-no-height cursor-pointer" (click)="addParticipantsCc();">
              <div class="list-people">
                <ion-item lines="none">
                  <ion-list>
                    <ion-label *ngIf="taskParticipantsCc?.length < 1" class="list-people-title">Com conhecimento</ion-label>
                    <ion-label *ngFor="let participant of taskParticipantsCc">{{participant.Name}}</ion-label>
                  </ion-list>
                </ion-item>
              </div>
              <div class="add-people" >
                <ion-icon *ngIf="ThemeService.currentTheme == 'default' " slot="start" src="assets/images/icons-arrow-forward.svg"></ion-icon>
                <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " slot="start" src="assets/images/theme/gov/icons-arrow-forward.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " slot="start" src="assets/images/theme/{{ThemeService.currentTheme}}/icons-arrow-forward.svg"></ion-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="ion-item-container-no-border cursor-pointer" (click)="getDoc()">
          <ion-label class="d-flex align-center">
            <div class="attach-icon d-flex align-center">
              <ion-icon *ngIf="ThemeService.currentTheme == 'default' " src="assets/images/icons-attach-doc.svg"></ion-icon>
              <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " src="assets/images/theme/gov/icons-attach-doc.svg"></ion-icon>
                <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " src="assets/images/theme/{{ThemeService.currentTheme}}/icons-attach-doc.svg"></ion-icon>
            </div>
            <div class="attach-document pl-10">
              <ion-label>Anexar Documentos</ion-label>
            </div>
          </ion-label>
        </div>

        <div class="list "  *ngFor="let document of documents; let i = index" >
          <ion-list>
            <ion-item>
            <ion-label>
              <p class="d-flex ion-justify-content-between">
                <span class="attach-title-item">{{document.Assunto}}</span>
                <span class="app-name" *ngIf="!document.appName">{{ TaskService.attachmentAppName(document)}}</span>
                <span class="app-name" *ngIf="document.appName">{{document.appName}}</span>
                <span class="close-button text-black" (click)="removeAttachment(i)" >
                  <ion-icon class="font-20" src="assets/images/icons-delete-25.svg"></ion-icon>
                </span>
              </p>
              <p><span class="span-left">{{(document.EntidadeOrganicaNome || document.Sender)}}</span><span class="span-right"> {{(document.Data || document.DocDate)  | date: 'dd-MM-yyyy HH:mm'}} </span></p>
            </ion-label>
          </ion-item>
          </ion-list>
        </div>
      </div>
    </div>

    <div *ngIf="postData && loggeduser.Profile != 'PR'" class="aside-right flex-column height-100">
      <app-empty-container
          [texto]="emptyTextDescription"
          *ngIf="!showAttendees"
          class="d-flex height-100 flex-column">
      </app-empty-container>
      <app-attendee-modal
      *ngIf="showAttendees"
        class="d-flex flex-column height-100 hide-footer"
        [adding]="adding"
        [footer]="false"
        [taskType]="taskType"
        [taskParticipants]="taskParticipants"
        [taskParticipantsCc]="taskParticipantsCc"
        (closeComponent)="closeComponent()"
        (setIntervenient)="setIntervenient($event)"
        (setIntervenientCC)="setIntervenientCC($event)"
        (setContact)="setContact($event)"
        (dynamicSetIntervenient)="dynamicSetIntervenient($event)"
      ></app-attendee-modal>
    </div>

  </div>
</ion-content>
<ion-footer>
  <div class="buttons">
    <button class="btn-ok" shape="round" (click)="saveTask()">Concluir</button>
    <button class="btn-cancel" shape="round" (click)="cancelTask()">Cancelar</button>
  </div>
</ion-footer>

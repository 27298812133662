import { Pipe, PipeTransform } from '@angular/core';
import { ExpedienteTask } from '../models/dailyworktask.model';
import { ExpedienteFullTask } from '../models/Expediente';

@Pipe({
  name: 'expedienteTask'
})
export class ExpedienteTaskPipe implements PipeTransform {

  transform(fullTask: ExpedienteFullTask): ExpedienteTask {
    let date = new Date(fullTask.taskStartDate);
    date.setMonth(date.getMonth());

    return {
      "SerialNumber": fullTask.serialNumber,
      "TaskStartDate": fullTask.taskStartDate,
      "Subject": fullTask.workflowInstanceDataFields.Subject,
      "Folio": fullTask.workflowInstanceDataFields.Subject,
      "Senders": fullTask.workflowInstanceDataFields.Sender || "SEM ENTIDADE",
      "CreateDate": date,
      "DocumentsQty": fullTask.totalDocuments,
      "WorkflowName": fullTask.workflowDisplayName,
      "activityInstanceName": fullTask.activityInstanceName,
      "Status": fullTask.workflowInstanceDataFields.Status,
      "Deadline": fullTask.deadline,
      "TaskStatus": fullTask.taskStatus,
      "TaskReceiveDate": fullTask.taskReceiveDate,
      "Note": fullTask.workflowInstanceDataFields.TaskMessage,
      "InstanceID": fullTask.workflowInstanceDataFields.InstanceID
    }
  }

}
  
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ThemeService } from 'src/app/services/theme.service'

@Component({
  selector: 'app-btn-modal-dismiss',
  templateUrl: './btn-modal-dismiss.page.html',
  styleUrls: ['./btn-modal-dismiss.page.scss'],
})
export class BtnModalDismissPage implements OnInit {

  constructor(
    private modalController: ModalController, 
    public ThemeService: ThemeService
  ) { 

  }

  ngOnInit() {

  }
  close(){
    this.modalController.dismiss();
  }

}

import { Injectable } from '@angular/core';
import { Attachment } from '../models/attachment.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginUserRespose } from '../models/user.model';
import { SessionStore } from '../store/session.service';
import { File } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';
import { ChangeProfileService } from './change-profile.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  loggeduser: LoginUserRespose;
  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private platform: Platform,
    private file: File,
    private changeProfileService: ChangeProfileService) {

    this.changeProfileService.registerCallback(() => {
      this.setHeader()
    });
    this.setHeader()

  }

  setHeader() {
    this.loggeduser = SessionStore.user
    this.headers = new HttpHeaders();;
    this.headers = this.headers.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
  }

  uploadFile(formData: any) {



    //const geturl = environment.apiURL + 'Tasks/DelegateTask';
    const geturl = environment.apiURL + 'ObjectServer/UploadFiles';

    let options = {
      headers: this.headers
    };

    return this.http.post(`${geturl}`, formData, options);
  }

  getFile(guid: any) {
    const geturl = environment.apiURL + 'lakefs/StreamFile';
    let params = new HttpParams();

    params = params.set("path", guid);

    this.headers = this.headers.set('responseType', 'blob');
    this.headers = this.headers.set('Content-Type', 'application/octet-stream');

    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  downloadFile(guid: any) {

    let downloadUrl = environment.apiURL + 'objectserver/streamfiles?path=' + guid;
    var name = new Date().getTime();
    return this.http.get(downloadUrl, {
      responseType: "arraybuffer",
      reportProgress: true, observe: 'events'
    })

  }

  downloadFileAndStore(guid: any) {
    var name = new Date().getTime();
    const downloadPath = (
      this.platform.is('android')
    ) ? this.file.externalDataDirectory : this.file.documentsDirectory;


    let vm = this;

    /** HttpClient - @angular/common/http */
    this.http.get(
      environment.apiURL + 'objectserver/streamfiles?path=' + guid,
      {
        responseType: 'arraybuffer',
      }
    ).subscribe((fileBlob: Uint8Array) => {
      /** File - @ionic-native/file/ngx */
      vm.file.writeFile(downloadPath, "YourFileName.pdf", fileBlob, { replace: true });


    });
  }

  getAttachmentsBySerial(serialNumber: string): Observable<Attachment[]> {
    let geturl = environment.apiURL + 'attachments/GetAttachments';
    let params = new HttpParams();

    params = params.set("SerialNumber", serialNumber);

    let options = {
      headers: this.headers,
      params: params
    };

    return this.http.get<Attachment[]>(`${geturl}`, options);
  }

  getAttachments(source: number, sourceid: string): Observable<Attachment[]> {
    let geturl = environment.apiURL + 'attachments/GetSourceName';
    let params = new HttpParams();

    params = params.set("Source", source.toString());
    /* params = params.set("SourceId", sourceid); */

    let options = {
      headers: this.headers,
      params: params
    };

    return this.http.get<Attachment[]>(`${geturl}`, options);
  }

  getAttachmentsById(eventId: string): Observable<Attachment[]> {
    let geturl = environment.apiURL + 'attachments/GetAttachmentsByEventId';
    let params = new HttpParams();

    params = params.set("ParentId", eventId);
    /* params = params.set("SourceId", sourceid); */

    let options = {
      headers: this.headers,
      params: params
    };

    return this.http.get<Attachment[]>(`${geturl}`, options);
  }

  setEventAttachmentById(body: any) {

    let geturl = environment.apiURL + 'Attachments/Create';
    let options = {
      headers: this.headers,
    }
    return this.http.post(`${geturl}`, body, options);
  }

  deleteEventAttachmentById(attachmentId) {

    let geturl = environment.apiURL + 'Attachments/Delete';

    let params = new HttpParams();

    params = params.set("attachmentId", attachmentId);

    let options = {
      headers: this.headers,
      params: params
    }

    return this.http.delete(`${geturl}`, options);
  }

  AddAttachment(body: any) {

    let geturl = environment.apiURL + 'Attachments/CreateAttachmentProcess';
    let options = {
      headers: this.headers,
    }
    return this.http.post(`${geturl}`, body, options);
  }

  addUserProfilePhoto(formData: any) {
    const geturl = environment.apiURL + 'UserAuthentication/AddPhoto';
    
    this.headers = this.headers.set('content-type', "application/json");
    this.headers = this.headers.set('accept', "application/json");
    let options = {
      headers: this.headers
    };

    return this.http.post(`${geturl}`, formData, options);
  }

  getUserProfilePhoto(guid) {
    const geturl = environment.apiURL + 'UserAuthentication/GetPhoto';

    let params = new HttpParams();
    params = params.set("UserPhoto", guid);

    let options = {
      headers: this.headers,
      params: params
    };

    return this.http.get(`${geturl}`, options);
  }

}

import { Injectable } from '@angular/core';
import { MessageLocalDataSourceService } from '../../../data/repository/message/message-local-data-source.service';
import { MessageSocketRepositoryService } from '../../../data/repository/message/message-live-signalr-data-source.service';
import { InstanceId } from '../../chat-service.service';
import { MessageMapper } from '../../mapper/messageMapper';
import { v4 as uuidv4 } from 'uuid'
import { AttachmentLocalDataSource } from '../../../data/repository/attachment/attachment-local-repository.service';
import { RoomLocalRepository } from '../../../data/repository/room/room-local-repository.service';
import { MemberListLocalRepository } from 'src/app/module/chat/data/repository/member/member-list-local-repository.service'
import { Result } from 'neverthrow';
import { RoomType } from 'src/app/core/chat/entity/group';
import { SessionStore } from 'src/app/store/session.service';
import { MessageTable } from 'src/app/infra/database/dexie/instance/chat/schema/message';
import { MessageOutPutDataDTO } from 'src/app/core/chat/repository/dto/messageOutputDTO';

@Injectable({
  providedIn: 'root'
})
export class SendLocalMessagesUseCaseService {

  constructor(
    private MessageSocketRepositoryService: MessageSocketRepositoryService,
    private messageLocalDataSourceService: MessageLocalDataSourceService,
    private AttachmentRepositoryService: AttachmentLocalDataSource,
    private roomLocalDataSourceService: RoomLocalRepository,
    private MemberListLocalRepository: MemberListLocalRepository,
    private messageSocketRepositoryService: MessageSocketRepositoryService,
  ) { }

  async execute() {

    const allRooms = await this.roomLocalDataSourceService.findAll()
    const messages = await this.messageLocalDataSourceService.getOfflineMessages()

    if(allRooms.isOk()) {
      if(allRooms.value.length == 0) {

      } else {

        for(const message of messages) {
          const room = allRooms.value.find(e => e.id == message.roomId)

          if(room) {

            const attachments =  await this.AttachmentRepositoryService.find({$messageId: message.$id})

            if(attachments.isOk()) {

              message.attachments = attachments.value.map(e => ({
                fileType: e.fileType,
                source: e.source,
                fileName: e.fileName,
                applicationId: e.applicationId,
                docId: e.docId,
                id: e.id,
                mimeType: e.mimeType,
                description: e.description,
                file: e.base64.split(',')[1]
              }))
              console.log('to upload', messages)
              const requestId = InstanceId +'@'+ uuidv4();

              await this.messageLocalDataSourceService.update(message.$id, { sending: true })

              let sendMessageResult: Result<MessageOutPutDataDTO, any>
              if(room.roomType == RoomType.Group) {
                const DTO = MessageMapper.fromDomain(message, message.requestId)
                sendMessageResult = await this.MessageSocketRepositoryService.sendGroupMessage(DTO)
              } else {

                if(message.receiverId) {
                  const DTO = MessageMapper.fromDomain(message, message.requestId)
                  sendMessageResult = await this.messageSocketRepositoryService.sendDirectMessage(DTO)
                } else {
                  const getRoomMembers = await this.MemberListLocalRepository.directMember({
                    roomId:message.roomId,
                    currentUserId: SessionStore.user.UserId
                  })
                  if(getRoomMembers.isOk() && getRoomMembers.value) {
                    message.receiverId = getRoomMembers.value.wxUserId
                    const DTO = MessageMapper.fromDomain(message, message.requestId)
                    sendMessageResult = await this.messageSocketRepositoryService.sendGroupMessage(DTO)
                  } else {
                    console.error('direct users not found', getRoomMembers)
                  }
                }

              }

              if(sendMessageResult?.isOk()) {

                if(sendMessageResult.value.sender == undefined || sendMessageResult.value.sender == null) {

                  delete sendMessageResult.value.sender
                }

                let clone: MessageTable = {
                  ...sendMessageResult.value,
                  id: sendMessageResult.value.id,
                  $id : message.$id
                }

                clone.sending = false
                console.log({clone})
                console.log('send message local '+ messages.length)

                this.messageLocalDataSourceService.update(message.$id, {id: sendMessageResult.value.id, sending: false})
              } else {
                this.messageLocalDataSourceService.update(message.$id, {sending: false})
              }
            }
          }

        }
      }

    }
  }

}

import { Injectable, ErrorHandler } from '@angular/core';
import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { LoginUserRespose, UserForm, UserSession } from '../models/user.model';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, of } from 'rxjs';
import { AlertController, Platform } from '@ionic/angular';
import { SessionStore } from '../store/session.service';
import { AESEncrypt } from '../services/aesencrypt.service';
// import { RochetChatConnectorService } from 'src/app/services/chat/rochet-chat-connector.service';
import { Router } from '@angular/router';
// import { NfService } from 'src/app/services/chat/nf.service';
//import { MessageService } from 'src/app/services/chat/message.service';
import { ProcessesService } from 'src/app/services/processes.service';
import { AttachmentsService } from 'src/app/services/attachments.service';
//import { RoomService } from './chat/room.service';
import { Storage } from '@ionic/storage';
import { InitialsService } from './functions/initials.service';
import { PermissionService } from './permission.service';
// import { ChatSystemService } from 'src/app/services/chat/chat-system.service';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service';
import { captureException } from '@sentry/angular';
import { catchError, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData$ = new BehaviorSubject<any>('');
  userId$ = new BehaviorSubject<any>('');
  headers: HttpHeaders = new HttpHeaders();
  public wsValidatedUserChat: any;
  public isWsAuthenticated: boolean = false;
  opts: any;

  tabIsActive = true

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    public alertController: AlertController,
    private aesencrypt: AESEncrypt,
    // private RochetChatConnectorService: RochetChatConnectorService,
    private router: Router,
    // private NfService: NfService,
    private processesService: ProcessesService,
    private AttachmentsService: AttachmentsService,
    private storage: Storage,
    private initialsService: InitialsService,
    public p: PermissionService,
    // public ChatSystemService: ChatSystemService,
    private httpErroHandle: HttpErrorHandle,
    private errorHandler: ErrorHandler,
    private platform: Platform,) {

    if (SessionStore.exist) {
      if (this.p.userPermission(this.p.permissionList.Chat.access) == true) {
        this.loginToChatWs()
      }

    }

    window.addEventListener('focus', (event) => {
      if (!this.tabIsActive) {
        this.tabIsActive = true
        const data = SessionStore.getDataFromLocalStorage();

        if (!data?.user?.Authorization && SessionStore?.user?.Authorization) {
          window.location.reload();
        }

        if (window['all-process-gabinete']) {
          window['all-process-gabinete']()
        }
      }
    });

    window.addEventListener('blur', (event) => {
      this.tabIsActive = false
    });

  }

  async login(user: UserForm, { saveSession = true }): Promise<LoginUserRespose> {
    user.BasicAuthKey = btoa(user.username + ':' + this.aesencrypt.encrypt(user.password, user.username));

    this.headers = this.headers.set('Authorization', user.BasicAuthKey);

    this.opts = {
      /* headers: this.headers, */
      "Content-Type": "application/json",
      "Accept": "application/json",
    }
    let channelId;
    if ( this.platform.is('desktop') || this.platform.is("mobileweb")){
      channelId = 2
    } else {
      channelId = 1
    }

    let body = {
      "Auth": user.BasicAuthKey,
      "ChannelId": channelId
    }

    let response: any;

    try {
      response = await this.http.post<LoginUserRespose>(environment.apiURL + "UserAuthentication/Login", body, this.opts).toPromise();


      if (saveSession) {
        this.SetSession(response, user)
      }
    } catch (error) {
      this.errorHandler.handleError(error);
      this.httpErroHandle.loginHttpStatusHandle(error)
      captureException(error);
    } finally {
      return response
    }

  }

  async loginContenteProduction(user: UserForm, { saveSession = true }): Promise<LoginUserRespose> {
    user.BasicAuthKey = 'Basic ' + btoa(user.username + ':' + this.aesencrypt.encrypt(user.password, user.username));

    this.headers = this.headers.set('Authorization', user.BasicAuthKey);
    this.opts = {
      headers: this.headers,
    }

    let response: any;

    try {
      response = await this.http.post<LoginUserRespose>(environment.apiURL + "UserAuthentication/Login", '', this.opts).toPromise();
      console.log('JWT', response)

      if (saveSession) {
        /* this.SetSession(response, user) */
        console.log('teste', response);

        return response

      }
    } catch (error) {
      this.httpErroHandle.httpStatusHandle(error)
    } finally {
      return response
    }

  }

  // async UpdateLogin() {}

  SetSession(response: LoginUserRespose, user: UserForm) {
    const session: UserSession = Object.assign(SessionStore.user, response)

    if (response) {
      if (session.RoleID == 100000014) {
        session.Profile = 'PR'
      } else if (session.RoleID == 100000011) {
        session.Profile = 'MDGPR'
      } else if (session.RoleID == 99999872) {
        session.Profile = 'Consultant'
      } else if (session.RoleID == 99999886) {
        session.Profile = 'SGGPR'
      } else {
        session.Profile = 'Unknown'
      }

      session.Password = user.password
      session.BasicAuthKey = user.BasicAuthKey
      SessionStore.reset(session)

      return true;
    }

    this.initialsService.getInitials(session.FullName);
  }

  loginToChatWs() {
    setTimeout(() => {

      if (SessionStore.user.ChatData?.data) {
        // this.RochetChatConnectorService.logout();
        // this.RochetChatConnectorService.connect();
        // this.RochetChatConnectorService.login().then((message: any) => {
        //   console.log('Chat login', message)

        //   SessionStore.user.RochetChatUserId = message.result.id
        //   SessionStore.save()

        //   this.ChatSystemService.loadChat()
        //   this.RochetChatConnectorService.setStatus('online')
        //   window['RochetChatConnectorService'] = this.RochetChatConnectorService
        //   setTimeout(() => {
        //     this.ChatSystemService.getAllRooms();
        //     this.RochetChatConnectorService.setStatus('online')
        //   }, 200);


        // }).catch((error) => {
        //   console.error(SessionStore.user.ChatData, 'web socket login', error)

        //   if(window.location.pathname.includes('/home/')) {
        //     setTimeout(() => {
        //       this.loginToChatWs();
        //     }, 4000)
        //   }

        // })
      }



      // before sending a message with a attachment
8
      // this.NfService.downloadFileMsg = async (message: MessageService, room?: RoomService) => {

      //   //
      //   let downloadFile = "";
      //   if (message.file.type == "application/img") {
      //     const event: any = await this.AttachmentsService.downloadFile(message.file.guid).toPromise();

      //     if (event.type === HttpEventType.DownloadProgress) {
      //       //this.downloadProgess = Math.round((100 * event.loaded) / event.total);
      //       return true
      //     } else if (event.type === HttpEventType.Response) {
      //       downloadFile = 'data:image/jpeg;base64,' + btoa(new Uint8Array(event.body).reduce((data, byte) => data + String.fromCharCode(byte), ''));

      //       message.file = {
      //         guid: message.file.guid,
      //         image_url: downloadFile,
      //         type: message.file.type
      //       }

      //       return true
      //     }
      //     return false
      //   }
      // };

    }, 1)
  }

  autologout(expirationDate: number) {
    setTimeout(() => {
      this.logout();
    }, expirationDate)
  }

  logout() {

    SessionStore.setInativity(false)
    SessionStore.setUrlBeforeInactivity(this.router.url);
    setTimeout(() => {
      this.router.navigateByUrl('/', { replaceUrl: true });
    }, 100)

  }

  logoutChat() {
  }

  async presentAlert(message: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Mensagem do sistema',
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  async logoutUser() {

    this.headers = this.headers.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);

    this.opts = {
      headers: this.headers,
    }

    let response: any;
    try {
      response = await this.http.delete<LoginUserRespose>(environment.apiURL + "userauthentication/Logout", this.opts).toPromise();
      SessionStore.user.Authorization = "";
      SessionStore.user.RefreshToken = "";
    } catch (error) {
      this.errorHandler.handleError(error);
      this.httpErroHandle.loginHttpStatusHandle(error)
      captureException(error);
    } finally {
      return response
    }
  }

  refreshToken() {

    return this.http
      .put<any>(environment.apiURL + "UserAuthentication/RefreshToken", {
        refreshToken: SessionStore.user.RefreshToken,
      },)
      .pipe(
        tap((tokens) => {
          console.log(tokens)
          SessionStore.user.Authorization = tokens.Authorization;
          SessionStore.user.RefreshToken = tokens.refreshToken;

        }),
        catchError((error) => {
          this.logoutUser();
          return of(false);
        })
      );
  }
}

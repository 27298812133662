import { z } from 'zod';
import { EEventCategory, EEventOwnerType, EEventType } from './enums';


const recurrenceSchema = z.object({
  frequency: z.number(),
  until: z.string().nullable().optional()
});

export const EventUpdateInputDTOSchema = z.object({
  userId: z.number(),
  ownerType: z.nativeEnum(EEventOwnerType),
  subject: z.string(),
  body: z.string().optional(),
  location: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  type: z.nativeEnum(EEventType),
  category: z.nativeEnum(EEventCategory),
  recurrence: recurrenceSchema,
  isAllDayEvent: z.boolean(),
  updateAllEvents: z.boolean()
});

export type EventUpdateInputDTO = z.infer<typeof EventUpdateInputDTOSchema>

import { Injectable } from '@angular/core';
import { AgendaDataService } from '../data-source/agenda-data.service';
import { map } from 'rxjs/operators';
import { ListEventMapper } from '../../domain/mapper/EventListMapper';
import { EventMapper } from '../../domain/mapper/EventDetailsMapper';
import { Event } from 'src/app/models/event.model';
import { SessionStore } from 'src/app/store/session.service';
import { EventListToApproveMapper } from '../../domain/mapper/eventToApproveListMapper';
import { err, ok } from 'neverthrow';
import { HttpErrorResponse } from '@angular/common/http';
import { EventToApproveDetailsMapper } from '../../domain/mapper/EventToApproveDetailsMapper';
import { AgendaLocalDataSourceService, TableSharedCalendar } from '../data-source/agenda-local-data-source.service';
import { EEventFilterStatus } from '../dto/enums';
import { EventToApproveDataOutputDTOSchema } from '../dto/eventToApproveListOutputDTO';
import { EventOutputDTOSchema } from '../dto/eventDTOOutput';
import { SharedCalendarListDetectChanges } from '../async/change/shareCalendarChangeDetector';
import { SharedCalendarListItemOutputDTO } from '../dto/sharedCalendarOutputDTO';
import { EventUpdateInputDTOSchema } from '../dto/eventUpdateInputDtO';
import { AttachInputDTOSchema } from '../dto/addAttachmentDTOInput';
import { EventListDataOutputDTOSchema } from '../dto/eventListDTOOutput';
import { EventSearchMapper } from '../../domain/mapper/EventSearchMapper';
import { select, Store } from '@ngrx/store';
import { CalendarState, pushEvent, removeRangeForCalendar, selectEventsInRange } from '../data-source/agenda-memory-source.service';
import { NativeNotificationService } from 'src/app/services/native-notification.service';
import { ListBoxService } from 'src/app/services/agenda/list-box.service';
import { EventListStore } from 'src/app/models/agenda/AgendaEventList';
import { AttendeeInputDTOSchema } from '../dto/attendeeInputDTO';
import { EventInputDTOSchema } from '../dto/eventInputDTO';
import { Utils } from 'src/app/module/agenda/utils';
import { APINODReturn } from 'src/app/services/decorator/api-validate-schema.decorator';
import { TracingType } from 'src/app/services/monitoring/opentelemetry/tracer';
import { isHttpError } from 'src/app/services/http.service';
@Injectable({
  providedIn: 'root'
})
export class AgendaDataRepositoryService {

  constructor(
    private agendaDataService: AgendaDataService,
    private utils: Utils,
    private agendaLocalDataSourceService: AgendaLocalDataSourceService,
    private memoryStore: Store<CalendarState>,
    private NativeNotificationService: NativeNotificationService,
    public listBoxService: ListBoxService,
  ) { }

  createOwnCalendar(): SharedCalendarListItemOutputDTO {
    const currentUserCalendar = {
      wxUserId: SessionStore.user.UserId,
      wxFullName: SessionStore.user.FullName,
      wxeMail: SessionStore.user.Email,
      role: SessionStore.user.RoleDescription,
      roleId: SessionStore.user.RoleID,
      shareType: 3,
      date: '',
    }

    return currentUserCalendar
  }

  async getEventById(id: string, tracing?: TracingType) {

    const result = await this.agendaDataService.getEvent(id, tracing)

    if(result.isOk()) {
      APINODReturn(EventOutputDTOSchema, result.value, `get/Events/${id}`, tracing)

      return result.map(e => EventMapper.toDomain(e))
    }

    return result

  }

  async getEventToApproveById(id: string, tracing?: TracingType) {

    const result = await this.agendaDataService.getEvent(id)

    if(result.isOk()) {
      APINODReturn(EventOutputDTOSchema, result.value, `get/Events/${id}`, tracing)

      return result.map(e => EventToApproveDetailsMapper.toDomain(e))
    }

    return result

  }

  async searchEvent(queryParameters: {value, status}, tracing?: TracingType) {
    const result = await this.agendaDataService.searchEvent(queryParameters)
    return result.map( response => {
      APINODReturn(EventListDataOutputDTOSchema, response, 'get/Events', tracing)
      return EventSearchMapper.toDomain(response, "calendarOwnerName", "userId")
    })
  }

  async EventList({ userId, startDate, endDate, status = EEventFilterStatus.Approved, category = null, type = null, calendarOwnerName = '' }, tracing?: TracingType) {

    const result = await this.agendaDataService.getEvents({userId, startDate, endDate, status, category, type}, tracing)

    if(result.isOk()) {

      return result.map(response => {
        APINODReturn(EventListDataOutputDTOSchema, response, 'get/Events', tracing)


        let profile;

        if (SessionStore.user.Profile == 'PR') {
          profile = "pr"
        } else if (userId == SessionStore.user.UserId as any) {
          profile = 'md'
        } else {
          profile = "pr"
        }
        const listToPresent = ListEventMapper.toDomain(response, calendarOwnerName, userId)

        const map : EventListStore[] = listToPresent.map( element => {
          return {
            startTime: new Date(element.StartDate),
            endTime: new Date(element.EndDate),
            allDay: false,
            event: element,
            calendarName: element.CalendarName,
            profile: profile,
            id: element.EventId,
            CalendarId: userId
          }
        }) as any


        const year = this.listBoxService.list(map, 'md', startDate, endDate, { selectedDate: new Date() })
        const events = this.utils.getAllEvents(year)
        this.NativeNotificationService.scheduleNotifications(events)

        this.memoryStore.pipe(
          select(selectEventsInRange(startDate, endDate, userId))
        ).subscribe((localList)=> {
          // console.log({localList})
        });

        this.memoryStore.dispatch(removeRangeForCalendar({ startDate, endDate, userId }));
        this.memoryStore.dispatch(pushEvent({ eventsList:listToPresent as any, userId, profile }));

        return listToPresent
      })
    }

    return result

  }

  async eventToApproveList({ userId, startDate = null, endDate = null, status = EEventFilterStatus.Pending, category = null, type = null, calendarOwnerName = '' }, tracing?: TracingType) {

    const result = await this.agendaDataService.getEvents({userId, startDate : null, endDate: null, status, category: null, type: null}, tracing)

    return result.map(response => {
      APINODReturn(EventToApproveDataOutputDTOSchema, response, 'get/ApproveList', tracing)
      return EventListToApproveMapper.toDomain(response, calendarOwnerName, userId)
    })

  }

  createEvent(eventData: Event, documents, calendar: TableSharedCalendar, tracing: TracingType, addAll =false) {

    console.log('eventData', eventData);

    let eventInput = {
      userId: calendar.wxUserId,
      ownerType: this.utils.selectedCalendarOwner(calendar.role),
      subject: eventData.Subject,
      body: eventData.Body.Text,
      location: eventData.Location,
      startDate: this.utils.addOneHourToIsoString(eventData.StartDate.toISOString()),
      endDate: this.utils.addOneHourToIsoString(eventData.EndDate.toISOString()),
      type: this.utils.calendarTypeSeleted(eventData.Category),
      category: this.utils.calendarCategorySeleted(eventData.CalendarName),
      attendees: this.utils.attendeesAdded(eventData.Attendees),
      attachments: this.utils.documentAdded(documents, addAll),
      recurrence: {
        frequency: this.utils.eventRecurence(eventData.EventRecurrence.frequency),
        until:((eventData.EventRecurrence.until === "") ?   this.utils.addOneHourToIsoString(eventData.EndDate.toISOString()) : eventData.EventRecurrence.until),
      },
      organizerId: SessionStore.user.UserId,
      isAllDayEvent: eventData.IsAllDayEvent,
    }

    APINODReturn(EventInputDTOSchema, eventInput, 'post/Events', tracing)
    return this.agendaDataService.createEvent(eventInput)
  }

  updateEvent(eventId, eventData, editAllEvent, calendar: TableSharedCalendar, tracing: TracingType) {

    let body;
    if(typeof eventData?.Body == 'object') {
      body =  eventData?.Body?.Text
    } else {
      body =  eventData?.Body
    }

    let eventInput = {
      userId: calendar.wxUserId,
      ownerType: this.utils.selectedCalendarOwner(calendar.role),
      subject: eventData.Subject,
      body: eventData?.Body?.Text,
      location: eventData.Location,
      startDate: this.utils.addOneHourToIsoString(eventData.StartDate),
      endDate: this.utils.addOneHourToIsoString(eventData.EndDate),
      isAllDayEvent: eventData.IsAllDayEvent,
      updateAllEvents: editAllEvent,
      type: this.utils.calendarTypeSeleted(eventData.Category),
      category: this.utils.calendarCategorySeleted(eventData.CalendarName || eventData.Agenda),
      recurrence: {
        frequency: this.utils.eventRecurence(eventData.EventRecurrence.frequency),
        until: ((eventData.EventRecurrence.until === "") ?   this.utils.addOneHourToIsoString(eventData.EndDate.toISOString()) : eventData.EventRecurrence.until),
      }
    }

    console.log({eventData})
    console.log({eventInput})

    APINODReturn(EventUpdateInputDTOSchema, eventInput, 'PUT/Events', tracing)
    return this.agendaDataService.updateEvent(eventId, eventInput)
  }

  addEventAttendee(id, attendeeData, tracing?: TracingType) {
    console.log(attendeeData)
    console.log(this.utils.attendeesEdit(attendeeData))


    const data = { attendees: this.utils.attendeesAdded(attendeeData) }
    APINODReturn(AttendeeInputDTOSchema, data.attendees, `PUT/Events/${id}/Attendee`, tracing)
    return this.agendaDataService.addEventAttendee(id, { attendees: this.utils.attendeesAdded(attendeeData) });
  }


  removeEventAttendee(id, attendeeData: {Id : string}[]) {

    return this.agendaDataService.removeEventAttendee(id, { attendees: attendeeData.map(e => e.Id || e['id']) } );
  }

  addEventAttachment(id, attachmentData, tracing: TracingType) {
    console.log(attachmentData)

    const attachments = { attachments: this.utils.documentAdded(attachmentData, false) }
    APINODReturn(AttachInputDTOSchema, attachments, `POST/${id}/Attendee`, tracing)
    return this.agendaDataService.addEventAttachment(id, attachments);
  }

  deleteEvent(eventId,deleteAll) {
    return this.agendaDataService.deleteEvent(eventId, deleteAll)
  }

  removeEventAttachment(eventId, attachmentData) {
    return this.agendaDataService.removeEventAttachment(eventId, attachmentData);
  }

  async deleteEvent1(eventId) {

    return await this.agendaDataService.deleteEvent(eventId, false)

  }

  eventToaprovalStatus(eventId, status, comment: string) {
    let statusObject = {
      status: this.utils.statusEventAproval(status),
      comment: comment
    }
    return this.agendaDataService.updateEventStatus(eventId, statusObject)
  }

  getDocumentAttachments(applicationId, userId, subject, pageNumber, pageSize) {
    return this.agendaDataService.getDocumentAttachment(applicationId, userId, subject, pageNumber, pageSize)
  }

  async getSharedCalendar() {

    const result = await this.agendaDataService.getSharedCalendar()
    const localList = await  this.agendaLocalDataSourceService.geCalendars()

    if (result.isOk()) {

      if(!result.value?.data) {
        result.value.data = [this.createOwnCalendar()]
      } else {
        result.value.data.push(this.createOwnCalendar())
      }

      const { remove, insert, update } = SharedCalendarListDetectChanges(localList, result.value.data)

      for(const item of insert) {
        this.agendaLocalDataSourceService.createCalendar(item)
      }

      for(const item of remove) {
        this.agendaLocalDataSourceService.removeCalendar(item)
      }

    } else {

      if(isHttpError(result.error)) {
        if (result.error.status == 404) {
          const remove = localList.filter(e => e.wxUserId != SessionStore.user.UserId)

          for(const item of remove) {
            this.agendaLocalDataSourceService.removeCalendar(item)
          }

        }
      }

      const item = this.createOwnCalendar()
      this.agendaLocalDataSourceService.createCalendar(item)
    }

  }


  async clearSharedCalendar() {
    return await this.agendaLocalDataSourceService.clearSharedCalendar()
  }


  getShareCalendarItemsLive() {
    return this.agendaLocalDataSourceService.getShareCalendarItemsLive()
  }

  getShareCalendarItemsLiveWithOrder() {
    // Define the role priorities
    const rolePriorities: { [key: number]: number } = {
      100000014: 1, // Presidente da República
      100000011: 2, // Vice Presidente (example role ID)
      // Add other roles with their priorities here
    };

    return this.getShareCalendarItemsLive().pipe(
      map(data => data.sort((a, b) => {
        console.log('Raw data:', data); // Debug line
        const priorityA = rolePriorities[a.roleId] || Infinity;
        const priorityB = rolePriorities[b.roleId] || Infinity;
        return priorityA - priorityB;
      }))
    )

  }

  async geCalendars() {
    return await this.agendaLocalDataSourceService.geCalendars()
  }

  approveEvent(eventId) {
    return this.agendaDataService.approveEvent(eventId);
  }


  async getCalendarByUserId(wxUserId: number) {
    return await this.agendaLocalDataSourceService.getCalendarByUserId(wxUserId)
  }
}

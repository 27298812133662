import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { Despacho } from 'src/app/models/despacho.model';
import { EventPerson } from 'src/app/models/eventperson.model';
import { Participant } from 'src/app/models/participant.model';
import { Folder } from 'src/app/models/folder.model';
import { AuthService } from 'src/app/services/auth.service';
import { ProcessesService } from 'src/app/services/processes.service';
import { AttachmentsService } from 'src/app/services/attachments.service';
import { ExpedienteDetailPage } from '../expediente-detail/expediente-detail.page';
import { AlertService } from 'src/app/services/alert.service';
import { SearchPage } from 'src/app/pages/search/search.page';
import { SearchList } from 'src/app/models/search-document';
import { LoginUserRespose } from 'src/app/models/user.model';
import { ToastService } from 'src/app/services/toast.service';
import { AttendeesPageModal } from 'src/app/pages/events/attendees/attendees.page';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { task } from 'src/app/models/ExpedientTaskModalPage';
import { DespachoService } from 'src/app/Rules/despacho.service';
import { SearchDocumentPipe } from 'src/app/pipes/search-document.pipe';
import { ThemeService } from 'src/app/services/theme.service'
import { SessionStore } from 'src/app/store/session.service';
import { PermissionService } from 'src/app/services/permission.service';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service';
import { RouteService } from 'src/app/services/route.service';
import { TaskService } from 'src/app/services/task.service'
import { z } from 'zod';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "YYYY-MMMM-DD HH:mm"
  },
  display: {
    dateInput: "DD MMM YYYY H:mm",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
}


const createProcessFromFileSchema = z.object({
  applicationId: z.any(),
  docId: z.any(),
});


@Component({
  selector: 'app-expedient-task-modal',
  templateUrl: './expedient-task-modal.page.html',
  styleUrls: ['./expedient-task-modal.page.scss'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ]
})
export class ExpedientTaskModalPage implements OnInit {

  modalTitle: string[] = [
    'Efectuar Despacho',
    'Solicitar Parecer',
    'Solicitar Deferimento'
  ];
  taskType:string;
  task: task;
  aplicationId = null
  docId!: string
  createProcessFromFile: false

  taskParticipants: any = [];
  taskParticipantsCc: any = [];

  taskDate: Date;
  taskDescription: string;

  loadedAttachments:any;
  documents:SearchList[] = [];

  subjectTypes:any;
  selectedTypes:  string = ''
  SearchFolder: any = []

  postData: Despacho;
  dispatchFolder: Folder;
  participants: Participant[];

  contacts= [];
  // trigger hide and show for attendee component
  showAttendees= false;

  adding: "intervenient" | "CC" = "intervenient";
  profile: string;

  emptyTextDescription = "Selecionar intervenientes";
  showEmptyContainer = true;

  taskResult: any = {}

  loggeduser: LoginUserRespose;
  toppings = new FormControl();

  Form: FormGroup;
  validateFrom = false;
  validateField = false;
  document: any
  placeholderSubject: string;

  get toppingsValues() {
    return this.toppings.value;
  }

  private searchDocumentPipe = SearchDocumentPipe

  constructor(
    private modalController: ModalController,
    private processes:ProcessesService,
    private attachmentsService: AttachmentsService,
    private navParams: NavParams,
    private toastService: ToastService,
    private despachoService: DespachoService,
    public ThemeService: ThemeService,
    public p: PermissionService,
    private httpErroHandle: HttpErrorHandle,
    private RouteService: RouteService,
    public TaskService: TaskService
    ) {

      this.loggeduser = SessionStore.user;
      this.task = this.navParams.get('task');

      this.SearchFolder = this.navParams.get('SearchFolder');
      this.aplicationId = this.navParams.get('aplicationId') | this.navParams.get('applicationId')
      this.docId = this.navParams.get('docId')
      this.createProcessFromFile = this.navParams.get('createProcessFromFile') || false;

      this.document = this.navParams.get('document');
      this.taskType = this.navParams.get('taskAction');


      if(this.createProcessFromFile) {
        try {
          createProcessFromFileSchema.parse({
            applicationId: this.aplicationId,
            docId: this.docId
          });

          this.task.workflowInstanceDataFields.SourceSecFsID = this.aplicationId; //361
          this.task.workflowInstanceDataFields.SourceType = 'DOC'; //FOLDER
          this.task.workflowInstanceDataFields.SourceID = this.docId; //FolderId

        } catch (error) {
          console.error('Validation failed params, expediente-task-modal:', error.errors);
        }
      }

      // try {
      //   if(this.document) {
      //     const doc: any = this.document
      //     this.documents.push({
      //       ApplicationId: (doc.ApplicationType || doc.ApplicationId),
      //       ApplicationType: (doc.ApplicationType || doc.ApplicationId),
      //       SourceId: (doc.Id || doc.DocId || doc.SourceId),
      //       Id: (doc.Id || doc.DocId || doc.SourceId),
      //       EntidadeOrganicaNome: (doc.sender || doc.SourceName || doc. EntidadeOrganicaNome),
      //       Sender: (doc.sender || doc.SourceName || doc. EntidadeOrganicaNome),
      //       Data: (doc.DocDate || doc.Data),
      //       DocDate: (doc.DocDate || doc.Data),
      //       Assunto: doc.Assunto,
      //     } as any)
      //   }
      // } catch (error) {
      //   console.log(error)
      // }

      // console.log('this.document', this.document)




      this.placeholderSubject = 'Indefinido'


      this.postData = new Despacho();
      this.participants = this.participants = new Array();
      this.dispatchFolder = {
        Nad: 30,
        Subject: '',
        Message: '',
        SourceSecFsId: this.task.workflowInstanceDataFields.SourceSecFsID, //361
        SourceType: this.task.workflowInstanceDataFields.SourceType, //FOLDER
        SourceId: this.task.workflowInstanceDataFields.SourceID, //FolderId
        DeadlineType: '',
        SubjectTypes: this.selectedTypes,
        NumberPDPP: this.task.workflowInstanceDataFields.DispatchNumber,
      };

      this.postData.DispatchFolder = this.dispatchFolder;
      this.postData.UsersSelected = this.participants;
      /* By Default TypeDeadline should be 'Normal' */
      this.postData.Priority = '99999861';
      /* Initialize 'Subject' with the title of the expedient */
      this.postData.DispatchFolder.Subject = this.task.workflowInstanceDataFields.Subject;
      this.profile = this.navParams.get('profile');


  }

  async setAdding(type:  "intervenient" | "CC") {
    this.adding = type;
  }

  ngOnInit() {


    this.taskDate = new Date(this.task.taskStartDate);
    if(!this.task.hasOwnProperty('isEvent')) {
      this.getAttachments();
    }
    this.getSubjectType();
  }

  onSelectedTypesChanged(ev:any) {

    if(ev.length > 1){

      this.selectedTypes = ev.filter(data => data != '99999850');
    }
    if(ev.length == 0){
      this.selectedTypes = "99999850";
    }
  }

  close() {
    this.RouteService.goBack();
    this.modalController.dismiss(null);
  }

  getSubjectType() {
    this.processes.GetSubjectType().subscribe(res=>{

      this.subjectTypes = res;

      this.placeholderSubject = 'Selecione o tipo de assunto*'
      this.selectedTypes = '99999844';
    });
  }

  cancelTask() {
    this.modalController.dismiss(null);
  }

  runValidation() {
    this.validateFrom  = true;
  }

  defaultParticipants () {
    if(this.p.userPermission([this.p.permissionList.Gabinete.pr_tasks])) {
      return ['MDGPR']
    }

    return null
  }

  injectValidation() {

    this.Form = new FormGroup({
      Subject: new FormControl(this.postData.DispatchFolder.Subject, [
        Validators.required,
        // Validators.minLength(4)
      ]),
      Location: new FormControl(this.postData.DispatchFolder.Message, [
        Validators.required,
      ]),
      selectedTypes: new FormControl(this.selectedTypes, [
        Validators.required,
      ]),
      Priority: new FormControl(this.postData.Priority, [
        Validators.required,
      ]),
      participantes: new FormControl(this.defaultParticipants() || this.taskParticipants, [
        Validators.required
      ]),

    })
  }
  //
  async saveTask() {

    if(this.loggeduser.Profile != 'PR') {
      this.injectValidation()
      this.runValidation()

      if(this.Form.invalid) return false
    }

    if(this.postData.Priority=='99999861') {
      this.dispatchFolder.DeadlineType = 'Normal';
    }
    else if(this.postData.Priority=='99999862') {
      this.dispatchFolder.DeadlineType = 'Urgente';
    }
    else if(this.postData.Priority=='99999863') {
      this.dispatchFolder.DeadlineType = 'Muito Urgente';
    }
    else if(this.postData.Priority=='99999864') {
      this.dispatchFolder.DeadlineType = 'Urgentíssimo';
    }

    let attendees = this.taskParticipants.concat(this.taskParticipantsCc);
    attendees = attendees.map(function(val) {
      return {
        UserEmail: val.EmailAddress,
        UserType:  val.IsRequired?"I": "CC"
      };
    })

    let docs = {
      ProcessInstanceID: "",
      Attachments: []
    }

    const DocumentToSave = this.documents.map((e) => {
      return {
        ApplicationId: e.ApplicationType,
        SourceId: e.Id
      }
    });
    docs.Attachments = DocumentToSave;


    if(this.SearchFolder) {
      if(this.SearchFolder.Documents) { // 361
        this.SearchFolder.Documents.forEach( (ele) => {
          docs.Attachments.push({
            ApplicationId: 361,
            Source: 1,
            SourceId: ele.docID || ele.docId || ele.DocId,
            SourceName: ele.Assunto
          })
        });


      } else { // 8
        docs.Attachments.push({
          ApplicationId: 8,
          Source: 1,
          SourceId: this.SearchFolder.docID || this.SearchFolder.docId || this.SearchFolder.DocId,
          SourceName: this.SearchFolder.Assunto
        })

      }
    }




    this.dispatchFolder.SubjectTypes = this.selectedTypes;
    const loader = this.toastService.loading()

      if(this.loggeduser.Profile != 'PR') {
        if(this.taskParticipants.length > 0) {
          switch (this.taskType) {
            case '0': // Despacho
              this.postData = {
                DistributionType: "Paralelo",
                CountryCode: 'AO',
                Priority: this.postData.Priority,
                UserEmail: this.loggeduser.Email,
                UsersSelected: attendees,
                DispatchFolder: this.dispatchFolder,
                AttachmentList: docs,
              }

              let action_despacho = {
                "serialNumber": this.task.serialNumber,
                "action": "Tratado",
                "ActionTypeId": 94,
                "dataFields": {
                  "Note": this.postData.DispatchFolder.Message,
                }
              }

              try {

                this.taskResult = await this.processes.postDespatcho(this.postData).toPromise();
                await this.httpErroHandle.httpsSucessMessagge('Efetuar Despacho')
                this.modalController.dismiss(action_despacho);

              } catch (error) {
                this.httpErroHandle.httpStatusHandle(error)
              } finally {
                loader.remove()
              }


              break;

            case '1': // Parecer
              this.postData = {
                DistributionType: "Paralelo",
                CountryCode: 'AO',
                Priority: this.postData.Priority,
                UserEmail: this.loggeduser.Email,
                UsersSelected: attendees,
                DispatchFolder: this.dispatchFolder,
                AttachmentList: docs
              }

              let action_parecer = {
                "serialNumber": this.task.serialNumber,
                "action": "Tratado",
                "ActionTypeId": 92,
                "dataFields": {
                  "Note": this.postData.DispatchFolder.Message,
                }
              }

              try {
                this.taskResult = await this.processes.postParecer(this.postData).toPromise();
                await this.httpErroHandle.httpsSucessMessagge('Solicitar Parecer')

                this.modalController.dismiss(action_parecer);

              } catch (error) {
                this.httpErroHandle.httpStatusHandle(error)
              } finally {
                loader.remove()
              }
              break;
            case '2': // Deferimento
              this.postData = {
                DistributionType: "Paralelo",
                CountryCode: 'AO',
                Priority: this.postData.Priority,
                UserEmail: this.loggeduser.Email,
                UsersSelected: attendees,
                DispatchFolder: this.dispatchFolder,
                AttachmentList: docs
              }
              //
              let action_deferimento = {
                "serialNumber": this.task.serialNumber,
                "action": "Tratado",
                "ActionTypeId": 93,
                "dataFields": {
                  "Note": this.postData.DispatchFolder.Message,
                }
              }

            try {

              this.taskResult = await this.processes.postDeferimento(this.postData).toPromise();
              this.httpErroHandle.httpsSucessMessagge('Pedido de Deferimento')
              this.modalController.dismiss(action_deferimento);
            } catch (error) {
              this.httpErroHandle.httpStatusHandle(error)
            } finally {
              loader.remove()
            }


          break;
        }
      }
      } else if (this.loggeduser.Profile == 'PR')  {
        switch (this.taskType) {
          case '0': // despacho
            this.postData = {
              DistributionType: "Paralelo",
              CountryCode: 'AO',
              Priority: this.postData.Priority,
              UserEmail: this.loggeduser.Email,
              UsersSelected: attendees,
              DispatchFolder: this.dispatchFolder,
              AttachmentList: docs,
            }


            let action_despacho_pr = {
              "serialNumber": this.task.serialNumber,
              "action": "Tratado",
              "ActionTypeId": 99999881 ,
              "dataFields": {
                "Note": this.postData.DispatchFolder.Message,
              }
            }

            /* if(this.postData.DispatchFolder.Message){
              if(this.selectedTypes.length > 0) { */
                try {
                  this.taskResult = await this.despachoService.createDespacho(this.postData).toPromise();
                  await this.httpErroHandle.httpsSucessMessagge('Efetuar Despacho')
                  this.modalController.dismiss(action_despacho_pr);
                } catch (error) {
                  this.httpErroHandle.httpStatusHandle(error)
                } finally {
                  //loader.remove()
                }
              /* } else {
                this.validateField = true;
              this.toastService._badRequest('Por favor selecione um assunto');
              } */

            /* }
            else{
              this.validateField = true;
              this.toastService._badRequest('Por favor adicione uma descrição');
            } */
            loader.remove();
            break;

          case '1': // parecer
            this.postData = {
              DistributionType: "Paralelo",
              CountryCode: 'AO',
              Priority: this.postData.Priority,
              UserEmail: this.loggeduser.Email,
              UsersSelected: attendees,
              DispatchFolder: this.dispatchFolder,
              AttachmentList: docs
            }

            let action_parecer_pr = {
              "serialNumber": this.task.serialNumber,
              "action": "Tratado",
              "ActionTypeId": 99999881,
              "dataFields": {
                "Note": this.postData.DispatchFolder.Message,
              }
            }

            try {
              this.taskResult = await this.processes.postParecerPr(this.postData).toPromise();
              await this.httpErroHandle.httpsSucessMessagge('Solicitar Parecer')
              this.modalController.dismiss(action_parecer_pr);
            } catch (error) {
              this.httpErroHandle.httpStatusHandle(error)
            } finally {
                loader.remove()
            }

            break;
        }
      }

    }



  getAttachments() {
    this.attachmentsService.getAttachmentsBySerial(this.task.serialNumber).subscribe(res=>{
      this.loadedAttachments = res;

    });

  }

  async openAttendees() {

    if(window.innerWidth <= 1024) {
      const modal = await this.modalController.create({
        component: AttendeesPageModal,
         componentProps: {
          adding: this.adding,
          taskParticipants: this.taskParticipants,
          taskParticipantsCc: this.taskParticipantsCc,
          taskType: this.taskType
        },
        cssClass: 'attendee modal modal-desktop',
        backdropDismiss: false
      });



      modal.onDidDismiss().then((data) => {

        if(data){
          data = data['data'];

          const newAttendees: EventPerson[] = data['taskParticipants'];
          const newAttendeesCC: EventPerson[] = data['taskParticipantsCc'];

          this.setIntervenient(newAttendees);
          this.setIntervenientCC(newAttendeesCC);
        }
      }, (error) => {
        console.log(error)
      });

      await modal.present();
    } else {
      this.showAttendees = true
    }
  }

  async setIntervenient(data) {
    this.taskParticipants = data;
  }

  async setIntervenientCC(data) {
    this.taskParticipantsCc = data;
  }

  async addParticipants() {
    this.adding = 'intervenient'
    this.openAttendees();
  }

  async addParticipantsCc() {
    this.adding = "CC";
    this.openAttendees();
  }

  dynamicSetIntervenient({taskParticipants, taskParticipantsCc}){
    this.taskParticipants = taskParticipants;
    this.taskParticipantsCc = taskParticipantsCc;
  }

  async setContact(data:EventPerson[]) {

    if(this.adding == "intervenient"){
      this.taskParticipants = data;
    } else if (this.adding == "CC") {

      this.taskParticipantsCc = data;
    }

  }

  async closeComponent() {
    this.showAttendees = false;
  }

  async viewExpedientDetail() {


    let classs;
    if( window.innerWidth <= 800){
      classs = 'modal  modal-desktop'
    } else  {
      classs = 'modal modal-desktop showAsideOptions'
    }

    const modal = await this.modalController.create({
      component: ExpedienteDetailPage,
      componentProps:{
        serialNumber: this.task.serialNumber,
        profile: this.profile,
      },
      cssClass: classs,
      backdropDismiss: false
    });

    modal.onDidDismiss().then((res)=>{
    });
    await modal.present();
  }


  async getDoc(){
    const modal = await this.modalController.create({
      component: SearchPage,
      cssClass: 'modal-width-100-width-background modal',
      componentProps: {
        type: 'AccoesPresidenciais & ArquivoDespachoElect',
        showSearchInput: true,
        select: true
      }
    });

      modal.onDidDismiss().then((res)=>{
        if(res){
          const data = res.data;
          this.documents.push({
            ...data.selected,
            ApplicationId: data?.selected?.ApplicationType,
            DocId: data.selected?.Id,
            SourceId: data.selected?.Id
          });
        }
      }, (error) => {
        console.log(error)
      });

      await modal.present();
  }

  removeAttachment(index: number){

    this.documents = this.documents.filter( (e, i) =>  index != i);

  }

}

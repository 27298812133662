import { Component, OnInit } from '@angular/core';
import { AnimationController, ModalController, NavParams } from '@ionic/angular';
import { SearchList } from 'src/app/models/search-document';
import { SearchPage } from 'src/app/pages/search/search.page';
import { ThemeService } from 'src/app/services/theme.service'
import { TaskService } from 'src/app/services/task.service'
@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.page.html',
  styleUrls: ['./add-note.page.scss'],
})
export class AddNotePage implements OnInit {
  note: string = '';
  documents: SearchList[] = [];
  loadedAttachments:any;
  hideThisFeature: boolean = true;
  actionName: string;

  constructor(
    private modalController: ModalController,
    private animationController: AnimationController,
    private navParams: NavParams,
    public ThemeService: ThemeService,
    public TaskService: TaskService
  ) {
    this.note = '';
    this.hideThisFeature = this.navParams.get('showAttachmentBtn');
    this.actionName = this.navParams.get('actionName');
   }

  ngOnInit() {

  }

  close() {
    this.modalController.dismiss('');
  }

  save() {
    let body = {
      "note":this.note,
      "documents":this.documents,
    }
    this.modalController.dismiss(body);
  }

  async getDoc() {

    const modal = await this.modalController.create({
      component: SearchPage,
      cssClass: 'modal-width-100-width-background modal',
      componentProps: {
        type: 'AccoesPresidenciais & ArquivoDespachoElect',
        showSearchInput: true,
        select: true
      }
    });

      modal.onDidDismiss().then((res)=>{
        if(res){
          const data = res.data;
          this.documents.push(data.selected);
        }
      }, (error) => {
        // console.log(error)
      });

      await modal.present();

  }

  removeAttachment(index: number){

    this.documents = this.documents.filter( (e, i) =>  index != i);
  }


}

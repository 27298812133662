<ion-header  class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <div class="main-header">
      <div class="title-content width-100">
        <div class="left">
          <button class="btn-no-color" (click)="close()">
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' " slot="end" src='assets/images/icons-arrow-arrow-left.svg'></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " slot="end" src='assets/images/theme/gov/icons-calendar-arrow-left.svg'></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " slot="end" src='assets/images/theme/{{ThemeService.currentTheme}}/icons-calendar-arrow-left.svg'></ion-icon>
          </button>
        </div>
        <div class="middle">
          <ion-label class="title">Alterar assunto</ion-label>
        </div>
        <app-btn-seguinte *ngIf="groupName" (click)="changeGroupName()"></app-btn-seguinte>
      </div>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="main-content">
    <div class="item-container">
      <ion-input [(ngModel)]="groupName" placeholder="Título"></ion-input>
    </div>
    <div *ngIf="false" class="item-container-no-border">
      <ion-checkbox (ionChange)="_ionChange($event)" color="primary"></ion-checkbox>
      <ion-label>Grupo Ultra-secreto</ion-label>
    </div>

    <div *ngIf="showDuration" class="container-div">
      <div class="ion-item-class-2">
        <div class="ion-icon-class">
          <ion-icon slot="start" src="assets/images/icons-duration.svg"></ion-icon>
        </div>
        <div class="ion-input-class">
          <ion-input (click)="showPicker()" [(ngModel)]="displayDuration" placeholder="Duração"></ion-input>
        </div>
      </div>
    </div>
    
  </div>    
</ion-content>


import { Injectable } from '@angular/core';
import { ProcessesService } from '../services/processes.service';
import { PermissionService } from '../services/permission.service';
import { LoaderService } from 'src/app/store/loader.service'

@Injectable({
  providedIn: 'root'
})
export class PedidoService {

  loader = this.LoaderService.initialize("PedidoService")

  constructor(
    private processes: ProcessesService,
    public p: PermissionService,
    public LoaderService: LoaderService,
  ) { }

  createParecer(body: any) {
    if(this.p.userRole(['PR'])) {
      return this.processes.postParecerPr(body)
    } else {
      return this.processes.postParecer(body)
    }
  }

  createDeferimento(body: any) {

    if(this.p.userRole(['PR'])) {
      throw('PR cant create Deferimento')
    }

    return this.processes.postDeferimento(body)
  }

  taskCompleteParecer({serialNumber, note}) {
    const body = {
      "serialNumber": serialNumber,
      "action": "Parecer",
      "ActionTypeId": 92, // Pedido de parece
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList" :null,
    }
    return this.processes.CompleteTask(body)
  }

  taskCompleteDeferimento({serialNumber, note}) {

    const body = {
      "serialNumber": serialNumber,
      "action": "Deferimento",
      "ActionTypeId": 93, // Pedido de deferimento
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList" :null,
    }
    return this.processes.CompleteTask(body)
  }

  arquivar({note = "", serialNumber, documents= [] }) {
    let body = {
      "serialNumber": serialNumber,
      "action": "Arquivo",
      "ActionTypeId": 95,
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList" :documents,
    }

    return this.processes.CompleteTask(body)
  }


}

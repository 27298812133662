import { Injectable } from '@angular/core';
import { z } from 'zod';
import { ValidateSchema } from 'src/app/services/decorators/validate-schema.decorator';
import { MemberListLocalRepository } from 'src/app/module/chat/data/repository/member/member-list-local-repository.service'

export const MemberListUPdateStatus = z.object({
  key: z.string(),
  value: z.object({
    userId: z.number(),
    userName: z.string()
  })
}).array();
export type MemberListUPdateStatusInputDTO = z.infer<typeof MemberListUPdateStatus>


@Injectable({
  providedIn: 'root'
})
export class MemberListUpdateStatusUseCaseService {

  constructor(
    private MemberListLocalRepository: MemberListLocalRepository
  ) { }


  @ValidateSchema(MemberListUPdateStatus)
  execute(input: MemberListUPdateStatusInputDTO) {
    return this.MemberListLocalRepository.updateMembersStatus(input)
  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { AddNotePage } from 'src/app/modals/add-note/add-note.page';
import { expedienteTask } from 'src/app/models/dailyworktask.model';
import { SearchList } from 'src/app/models/search-document';
import { DiscartExpedientModalPage } from 'src/app/pages/gabinete-digital/discart-expedient-modal/discart-expedient-modal.page';
import { BookMeetingModalPage } from 'src/app/pages/gabinete-digital/expediente/book-meeting-modal/book-meeting-modal.page';
import { ExpedientTaskModalPage } from 'src/app/pages/gabinete-digital/expediente/expedient-task-modal/expedient-task-modal.page';
import { SearchPage } from 'src/app/pages/search/search.page';
import { SearchDocumentPipe } from 'src/app/pipes/search-document.pipe';
import { AttachmentsService } from 'src/app/services/attachments.service';
import { ProcessesService } from 'src/app/services/processes.service';
import { ToastService } from 'src/app/services/toast.service';
import { ExpedienteService } from 'src/app/Rules/expediente.service';
import { PermissionService } from 'src/app/services/permission.service';
import { ThemeService } from 'src/app/services/theme.service'
import { NewGroupPage } from 'src/app/ui/chat/modal/new-group/new-group.page';
import { RouteService } from 'src/app/services/route.service';
import { SessionStore } from 'src/app/store/session.service';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service';
import { environment } from 'src/environments/environment';
import { TaskService } from 'src/app/services/task.service';

@Component({
  selector: 'app-opts-expediente',
  templateUrl: './opts-expediente.page.html',
  styleUrls: ['./opts-expediente.page.scss'],
})
export class OptsExpedientePage implements OnInit {

  task: expedienteTask
  fulltask: any;
  profile:string;
  caller:string;

  showEnviarPendentes = false
  searchDocumentPipe = new SearchDocumentPipe()
  documents:SearchList[] = [];

  SessionStore = SessionStore;
  environment = environment

  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController,
    private processes: ProcessesService,
    private toastService: ToastService,
    public p: PermissionService,
    private attachmentsService: AttachmentsService,
    private RouteService: RouteService,
    private expedienteService: ExpedienteService,
    public ThemeService: ThemeService,
    private httpErrorHanlde: HttpErrorHandle,
    public TaskService: TaskService,
  ) {}

  ngOnInit() {}

  async openNewGroupPage() {

    // this.dataService.set("newGroup", true);
    // this.dataService.set("task", this.task);
    // this.dataService.set("newGroupName", this.task.Folio);
    // this.dataService.set("documents", this.fulltask.Documents);
    // this.dataService.set("link", window.location.pathname);

    const modal = await this.modalController.create({
      component: NewGroupPage,
      cssClass: 'modal modal-desktop',
      componentProps: {
        name: this.task.Folio,
        task: this.task,
        documents: this.fulltask.Documents,
      },
    });

    modal.onDidDismiss().then(
      ()=>{},
      (error) => {
        console.log(error)
      })

      await modal.present();
  }

  close() {
    if( window.innerWidth < 801) {
      this.popoverController.dismiss();
    }
    else{
      this.popoverController.dismiss();
    }
  }

  goBack() {
    this.TaskService.loadExpedientes()
    this.RouteService.goBack()
  }

  attachDocument(){
    // alert('click herer!!s')
    this.getDoc();
  }

  async getDoc() {
    const modal = await this.modalController.create({
      component: SearchPage,
      cssClass: 'modal-width-100-width-background modal',
      componentProps: {
        type: 'Correspondencia',
        showSearchInput: true,
        select: true
      }
    });

      modal.onDidDismiss().then( async (res)=>{

        console.log({res});

        if(res){



          const data = res.data;
          this.documents.push(data.selected);
          this.documents.forEach((element: any) =>{

            let body = {
              "InstanceID": this.task.InstanceID,
              "WorkflowDisplayName": this.task.WorkflowName,
              "FolderID": this.task.FolderId,
              "DispatchNumber": this.task.DispatchNumber,
              "AttachmentsProcessLastInstanceID": this.task.AttachmentsProcessLastInstanceID,
              "Attachments": []
            }

            const Attachments = this.searchDocumentPipe.transformToAttachment(element)
            body.Attachments = Attachments;

            const loader = this.toastService.loading()

            // alert('done select attachment')

            this.attachmentsService.AddAttachment(body).subscribe((res)=> {

              // alert('@')
              window['attachments-expediente-update']();
              this.toastService._successMessage()
              this.popoverController.dismiss()
              loader.remove()
            }, (error) => {
              this.httpErrorHanlde.httpStatusHandle(error)
              loader.remove()
            },()=> {
              loader.remove()
            });

        });



      }
    }, (error) => {
      console.log(error)
    });

    await modal.present();

  }

  async openAddNoteModal(actionName:string) {
    let classs;
    if( window.innerWidth < 801) {
      classs = 'modal modal-desktop'
    } else  {
      classs = 'add-note-modal'
    }
    const modal = await this.modalController.create({
      component: AddNotePage,
      componentProps:{
        showAttachmentBtn: false,
        actionName:actionName
      },
      cssClass: classs,
      //backdropDismiss: true
    });

    modal.onDidDismiss().then( async (res) => {

      if(res.data){
        const DocumentToSave = res.data.documents.map((e) => {
          return {
            ApplicationId: e.ApplicationType,
            SourceId: e.Id,
          }
        });

        let docs = {
          ProcessInstanceID: "",
          Attachments: DocumentToSave,
        }

        if(actionName == 'Aprovar') {
          await this.approve(res.data.note, docs);
        }
        else if(actionName == 'Revisão') {
          await this.sendToReview(res.data.note, docs);
        }
        this.popoverController.dismiss();
        this.goBack();
      }
    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }

  async sendToReview(note:string, documents:any) {
    let body = {
      "serialNumber": this.task.SerialNumber,
      "action": "Retificar",
      "ActionTypeId": 99999877,
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList" :documents,
    }

    const loader = this.toastService.loading()

    try {
      await this.processes.CompleteTask(body).toPromise()
      this.TaskService.loadExpedientes()
      this.close();
      this.toastService._successMessage()
    } catch(error) {
      if(error.status == 0) {
        this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
      } else {

        this.toastService._badRequest()
      }
    } finally {
      loader.remove()
    }
  }

  sendExpedienteToPending() {
    this.close();
    this.processes.SetTaskToPending(this.task.SerialNumber).subscribe(res=>{
      this.TaskService.loadExpedientes()
      this.goBack();
    },(error) => {
      if(error.status == 0) {
        this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
      } else {

        this.toastService._badRequest('Processo não encontrado')
      }
    });
  }

  async approve(note:string, documents:any){
    let body = {
      "serialNumber": this.task.SerialNumber,
      "action": "Aprovar",
      "ActionTypeId": 100000004 ,
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList" :documents,
    }



    const loader = this.toastService.loading()

    try {
      await this.processes.CompleteTask(body).toPromise();
      this.TaskService.loadExpedientes()
      this.close();
      this.httpErrorHanlde.httpsSucessMessagge('Avaliação Superiror')
    } catch(error) {
      this.httpErrorHanlde.httpStatusHandle(error)
    }  finally {
      loader.remove()
    }

  }

  async openBookMeetingModal() {
    this.close();
    let classs;
    if( window.innerWidth <= 800){
      classs = 'book-meeting-modal modal modal-desktop'
    } else  {
      classs = 'modal modal-desktop showAsideOptions'
    }
    const modal = await this.modalController.create({
      component: BookMeetingModalPage,
      componentProps: {
        task: this.task,
        fulltask: this.fulltask
      },
      cssClass: classs,
      backdropDismiss: false
    });

    modal.onDidDismiss().then(
      ()=>{},
      (error) => {
        console.log(error)
      }
    )

    await modal.present();
  }

  async openExpedientActionsModal(taskAction: any, task: any) {



    this.close();
    let classs;
    if( window.innerWidth <= 800){
      classs = 'modal modal-desktop'
    } else  {
      classs = 'modal modal-desktop showAsideOptions'
    }
    const modal = await this.modalController.create({
      component: ExpedientTaskModalPage,
      componentProps: {
        taskAction: taskAction,
        task: task,
      },
      cssClass: classs,
    });

    modal.onDidDismiss().then(async res=>{

      let body = res['data'];
      if(res['data']){
        const loader = this.toastService.loading()
        try {
          await this.processes.CompleteTask(body).toPromise();
          this.TaskService.loadExpedientes()
            this.goBack();
          } catch (error) {
            if(error.status == 0) {
              this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
            } else {

              this.toastService._badRequest('Processo não descartado')
            }
          } finally {
            loader.remove()
        }
      }
      else{

      }
    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }

  async distartExpedientModal(body:any){
    this.close();
    const modal = await this.modalController.create({
      component: DiscartExpedientModalPage,
       componentProps: {
      },
      cssClass: 'discart-expedient-modal',
      backdropDismiss: false
    });


    modal.onDidDismiss().then( async (res) => {


      if(body == 'descartar') {
        if(res['data']== 'Yes') {

          const loader = this.toastService.loading()

          try {
            await this.expedienteService.discard({SerialNumber: this.task.SerialNumber}).toPromise()
            this.TaskService.loadExpedientes()
            this.toastService._successMessage('Processo descartado');
            this.goBack();
          } catch (error) {
            if(error.status == 0) {
              this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
            } else {

              this.toastService._badRequest('Processo não descartado')
            }
          } finally {
            loader.remove()
          }


        }
        else if(res['data'] == 'No'){
          //Do nothing
        }
      }
      else{
        if(res['data']== 'Yes') {
          const loader = this.toastService.loading()

          try {
            await this.processes.CompleteTask(body).toPromise();
            this.toastService._successMessage('Processo descartado');
            this.TaskService.loadExpedientes()
            this.goBack();
          } catch (error) {
            if(error.status == 0) {
              this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
            } else {

            this.toastService._badRequest('Processo não descartado')
            }
          }     finally {
            loader.remove()
          }
          this.goBack();
        }
        else if(res['data'] == 'No'){

          const loader = this.toastService.loading()

          try {
            await this.processes.UpdateTaskStatus(this.task.FolderId).toPromise();
            this.TaskService.loadExpedientes()
            this.toastService._successMessage();
            this.goBack();
          } catch (error) {
          if(error.status == 0) {
            this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
          } else {
            this.toastService._badRequest()
          }
          }     finally {
            loader.remove()
          }
        }
      }
    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }

}

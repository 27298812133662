import { EntityTable } from 'Dexie';
import { z } from 'zod';

export const DistributionTableSchema = z.object({
  $messageIdMemberId: z.string().optional(),
  messageId: z.string(),
  memberId: z.number(),
  readAt: z.string().nullable(),
  deliverAt: z.string().nullable(),
  roomId: z.string(),
})

export type DistributionTable = z.infer<typeof DistributionTableSchema>
export type DexieDistributionTable = EntityTable<DistributionTable, '$messageIdMemberId'>;
export const DistributionTableColumn = '$messageIdMemberId, messageId, memberId, readAt, deliverAt, roomId'
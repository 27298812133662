import { Injectable } from '@angular/core';
import { IUserPhotoLocalRepository } from 'src/app/core/chat/repository/user-photo/user-photo-local-repository';
import { IUserPhotoRemoteRepository } from 'src/app/core/chat/repository/user-photo/user-photo-remote-repository';
import { UserPhotoTableSchema } from 'src/app/infra/database/dexie/instance/chat/schema/user-foto';
import { z } from 'zod';

export const UserPhotoGetByIdInputSchema = UserPhotoTableSchema.pick({
  attachmentId: true,
  wxUserId: true,
})

export type IUserPhotoGetByIdInput = z.infer<typeof UserPhotoGetByIdInputSchema>

@Injectable({
  providedIn: 'root'
})
export class UserPhotoGetByIdUseCase {

  constructor(
    private userPhotoLocalRepository: IUserPhotoLocalRepository,
    private userPhotoRemoteRepository: IUserPhotoRemoteRepository
  ) { }

  async execute(input: IUserPhotoGetByIdInput) {

    const result = await this.userPhotoLocalRepository.findOne({wxUserId: input.wxUserId})
    if(result.isOk() && result.value) {
      return result.map(e => {
        return e.file
      })
    } else if(result.isOk() && !result.value) {
      const remoteResult = await this.userPhotoRemoteRepository.getUserPhotoByAttachmentId({attachmentId: input.attachmentId})

      if(remoteResult.isOk()) {
        this.userPhotoLocalRepository.insert({
          wxUserId: input.wxUserId,
          file: remoteResult.value.data,
          attachmentId: input.attachmentId
        })
      }

      return remoteResult.map(e => {
        return e.data
      })

    }

  }
}

import { Injectable } from '@angular/core';
import { PermissionList } from '../models/permission/permissionList';
import { SessionStore } from '../store/session.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  permissionList = new PermissionList();
  SessionStore = SessionStore

  constructor() { }

  userRole(args) {

    if(!Array.isArray(args)) {
      args = [args]
    }


    for(const arg of args) {
      if(arg == this.SessionStore.user.Profile) {
        return true
      }
    }

    return false
  }

  userPermission(args) {
    if(!Array.isArray(args)) {
      args = [args]
    }


    for(let permission of (this.SessionStore.user.UserPermissions || [])) {
      for( const arg of args ) {
        if (arg == permission) {
          return true;
        }
      }
    }
    return false;
  }

  userPermissionCount(args) {
    if(!Array.isArray(args)) {
      args = [args]
    }

    let count = 0

    for(let permission of this.SessionStore.user?.UserPermissions || [] ) {
      if (args.includes(permission)) {
        count++;
      }
    }


    return count;
  }

  role(args: any) {

    let UserRoleIsValid = this.userRole(args)

    return {
      permissionAnyOf(role) {

        if(!Array.isArray(role)) {
          role = [role]
        }

        if(!UserRoleIsValid) { return false }

        return true

      }
    }

  }

}

import { Injectable } from '@angular/core';
import { FileType } from 'src/app/models/fileType';


interface createInput  {
  type?: string
  accept: typeof FileType[]
}

@Injectable({
  providedIn: 'root'
})
export class FileLoaderService {

  nice : typeof FileType
  constructor() { }


  createInput({accept, type = 'file'}:createInput): HTMLInputElement {

    let input = document.createElement('input');
    input.type = type || 'file';
    input.accept = accept.join(', ')

    // input.onchange = () => {
    //   // you can use this method to get file and perform respective operations
    //   let files =   Array.from(input.files);
    //
    // };


    input.click();

    return input


  }

  getFirstFile(input: HTMLInputElement) {
    let files =   Array.from(input.files);
    return files[0]
  }

}

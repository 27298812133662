import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { HttpAdapter } from 'src/app/infra/http/adapter';
import { RoomByIdOutputDTO } from '../../../domain/use-case/room/room-get-by-id-use-case.service';
import { IMemberLocalRepository } from 'src/app/core/chat/repository/member/member-local-repository';
import { MemberListMapper } from '../../../domain/mapper/memberLIstMapper';
import { roomMemberListDetermineChanges } from '../../../data/async/list/rooms/roomMembersChangeDetector';
@Injectable({
  providedIn: 'root'
})
export class MemberListHttpSyncUseCase {

  constructor(
    private http: HttpAdapter,
    private memberLocalRepository: IMemberLocalRepository
  ) {

    this.http.listen().pipe(
      filter((response)=> {
        if(response?.isOk()) {
          return response.value.url.includes('/Room/') && typeof response.value?.data?.data?.roomName == 'string'
        }

        return false
      }),
      map((response: any) => response.value.data as RoomByIdOutputDTO)
    )
    .subscribe(async (data) => {

      this.syncMembers(data)
    })
  }

  private async syncMembers(roomData: RoomByIdOutputDTO): Promise<void> {
    const roomId = roomData.data.id;

    // Fetch local members and determine changes
    const localList = await this.memberLocalRepository.getRoomMemberById(roomId);
    const { membersToInsert, membersToUpdate, membersToDelete } = roomMemberListDetermineChanges(roomData.data.members, localList, roomId);

    // Sync members
    // for (const user of membersToInsert) {
    //   await this.memberLocalRepository.addMember(MemberListMapper(user, roomId));
    // }
    // for (const user of membersToUpdate) {
    //   await this.memberLocalRepository.updateMemberRole(MemberListMapper(user, roomId));
    // }
    // for (const user of membersToDelete) {
    //   await this.memberLocalRepository.removeMemberFromRoom(user.$roomIdUserId);
    // }

    // console.log({membersToInsert, membersToUpdate, membersToDelete})

    await Promise.all([
      ...membersToInsert.map(user => this.memberLocalRepository.addMember(MemberListMapper(user, roomId))),
      ...membersToUpdate.map(user => this.memberLocalRepository.updateMemberRole(MemberListMapper(user, roomId))),
      ...membersToDelete.map(user => this.memberLocalRepository.removeMemberFromRoom(user.$roomIdUserId))
    ]);

  }
}

import { HubConnection } from "@microsoft/signalr";
import { Result } from "neverthrow";
import { MessageCreateOutPutDataDTO, MessageInputDTO } from "src/app/module/chat/domain/use-case/message/message-create-use-case.service";
import { MessageMarkAsReadInput } from "src/app/module/chat/domain/use-case/message/message-mark-as-read-use-case.service";
import { MessageDeleteInputDTO } from "src/app/module/chat/domain/use-case/message/message-delete-by-id-live-use-case.service";
import { MessageOutPutDataDTO } from "../dto/messageOutputDTO";
import { Observable } from "rxjs";
import { MessageReactionInput } from "src/app/module/chat/domain/use-case/message/message-reaction-by-id-use-case.service";
import { MessageUpdateInput } from "src/app/module/chat/domain/use-case/message/message-update-by-id-use-case.service";
import { SocketMessage } from "src/app/infra/socket/signalR/signalR";

export abstract class IMessageSocketRepository {

  abstract connect(): Promise<Result<HubConnection, false>>
  abstract sendGroupMessage(data: MessageInputDTO): Promise<Result<MessageCreateOutPutDataDTO, any>>
  abstract sendDirectMessage(data: MessageInputDTO): Promise<Result<MessageCreateOutPutDataDTO, any>>

  // abstract sendDeliverAt(): Promise<Result<any, any>>
  abstract sendReadAt(data: MessageMarkAsReadInput): Promise<Result<any, any>>
  abstract sendDelete(data: MessageDeleteInputDTO): Promise<Result<any, any>>

  abstract listenToMessages(): Observable<MessageOutPutDataDTO>
  abstract listenToDeleteMessages(): Observable<MessageOutPutDataDTO>
  abstract listenToUpdateMessages(): Observable<MessageOutPutDataDTO>

  abstract listenToSendMessage(): Observable<SocketMessage<MessageInputDTO>>

  abstract reactToMessageSocket(data: MessageReactionInput): void
  abstract updateMessage(input: MessageUpdateInput): void
  abstract sendMessageDelete(data: MessageDeleteInputDTO): Promise<Result<any, any>>
}

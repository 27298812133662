import { Component, ElementRef, Input, OnInit, ViewChild, OnChanges, ViewChildren, QueryList } from '@angular/core';
import { checkFileTypeService } from 'src/app/services/checkFileType.service';
import { StopvideoService } from 'src/app/services/stopvideo.service';
import { v4 as uuidv4 } from 'uuid'
@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.page.html',
  styleUrls: ['./swiper.page.scss'],
})
export class SwiperPage implements OnInit {

  @Input() publicationList: any;
  @Input() navigation: boolean;
  @Input() pagination: boolean;
  swiperIndex: number = 0;

  @ViewChildren('videoElement') videoElements: QueryList<ElementRef>;
  @ViewChild('swipers')
  swiperRef: ElementRef | undefined;

  showControls: boolean = false;
  previousIndex: number | undefined;
  componentId = uuidv4()

  constructor(
    public checkFileType: checkFileTypeService,
    public stopvideoService: StopvideoService,
  ) {}

  ngOnInit() {

    setTimeout(() => {
      var videos:NodeListOf<HTMLVideoElement> = document.querySelectorAll(`#C${this.componentId} video`);

      try {
        // Pause each video
        videos.forEach(function (video) {
          video.load();
        })
      } catch(e) {}

    }, 100)
  }

  ngOnChanges() {
    this.onSlideChange()
  }

  onSlideChange() {

    if (this.swiperRef) {

      const swiper = this.swiperRef.nativeElement.swiper;
      this.swiperIndex = swiper.activeIndex;

      // Check if the currentIndex is different from the previousIndex
      if (this.swiperIndex !== this.previousIndex) {
        console.log('Active index changed. New index:', this.swiperIndex);
        // Update the previousIndex with the currentIndex
        this.previousIndex = this.swiperIndex;
        this.loadVideo()

      } else {
        console.log(this.swiperIndex)
      }

    }
  }

  goToSlide(index: number) {
    this.swiperIndex = this.swiperRef?.nativeElement.swiper.activeIndex;
    this.swiperRef?.nativeElement.swiper.slideTo(index);
    console.log('index slide', index)
  }

  onVisibilityChange = (e: boolean) => {
    if (!e) {
      this.stopVideo()
    }
  }

  stopVideo() {
    var videos = document.querySelectorAll('video');

    try {
      // Pause each video
      videos.forEach(function (video) {
        video.pause();
      })

      this.videoElements.forEach(videoElement => {
        // You can access the native HTML video element using videoElement.nativeElement
        const video: HTMLVideoElement = videoElement.nativeElement;

        video.pause()
        // Do something with each video element
        // console.log(video);
      });


    } catch (error) {
      console.log(error)
    }
  }

  click() {
    this.onSlideChange()
  }
  //This method does the refresh of all video(it was implement beacouse of the erro displaing the controls of the video while swipe)
  //If you can find a better solution, please remove
  loadVideo() {

    try {

      const file = this.publicationList.Files[this.swiperIndex]
      if(this.checkFileType.checkFileType(file.FileExtension ) == 'video') {

        const video = document.querySelector(`#C${this.componentId} .swiper-slide-active video`) as HTMLVideoElement
        if(video) {

          // video.load();
          video.removeAttribute('controls');
          setTimeout(() => {
            video.setAttribute('controls', 'controls');
          }, 500)
        }

      }

    } catch (error) {
      console.log(error)
    }
  }

}



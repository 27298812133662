import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StopvideoService {

  video: HTMLVideoElement[] = []

  constructor(
    private router: Router
  ) {

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd && !event.url.includes('/home/publications')) {
        this.stopAndRemoveAllVideos();
        // Get all video elements in the document
        var videoElements = document.querySelectorAll('video');

        // Pause each video element
        videoElements.forEach(function(video) {
          video.pause();
        });

      }
    });
  }


  registerVideo(tagVideo: HTMLVideoElement) {
    this.video.push(tagVideo);
  }

  registerVideoWithEvent(event: any) {
    const videoElement: HTMLVideoElement = event.target as HTMLVideoElement;
    this.video.push(videoElement);
  }

  stopAndRemoveAllVideos() {
    for (let i = 0; i < this.video.length; i++) {
      const video = this.video[i];

      // Pause the video
      video.pause();

      // Optionally, you can also reset the current time to start from the beginning
      video.currentTime = 0;

      // Remove the video from the array
      this.video.splice(i, 1);

      // Decrement the index to properly continue the loop
      i--;
    }
  }
}

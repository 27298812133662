import { Injectable } from '@angular/core';
import { MiddlewareServiceService } from "src/app/shared/API/middleware/middleware-service.service";
import { CMAPIAPIService } from "src/app/shared/API/CMAPI/cmapi-api.service";
import { ok, err, Result } from 'neverthrow';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IuploadFileLK } from '../../API/middleware/interface';

@Injectable({
  providedIn: 'root'
})
export class CMAPIService {

  constructor(
    public MiddlewareServiceService: MiddlewareServiceService,
    private CMAPIAPIService: CMAPIAPIService) {
    window["CMAPIAPIRepository"] = this
  }

  async FileContent({length, path, index, base64}): Promise<Result<IuploadFileLK, "badRequest" | "other">> {
    try {
      const result = await this.MiddlewareServiceService.CMAPIFileContent({length, path, index, base64}).toPromise();
      return ok(result)
    } catch (error) {
      if(error.status >= 400 && error.status >= 499) {
        return err("badRequest")
      }

      return err("other")
    }
  }


  async RequestUpload(): Promise<Result<string, any>> {
    try {
      const result = await this.MiddlewareServiceService.CMAPIRequestUpload().toPromise();
      return ok(result)

    } catch (error) {
      return err(error)
    }
  }

  async UploadStatus() {
    try {
      const result = await this.MiddlewareServiceService.CMAPIUploadStatus().toPromise();
      return ok(result)

    } catch (error) {
      return err(error)
    }
  }

  async ping() {
    try {
      await this.MiddlewareServiceService.tryToReachTheServer().toPromise();
      return ok(true)

    } catch (error) {

      if(error.status == 0) {
        return err(false)
      }

      return ok(true)
    }

  }

  async getVideoHeader(url: string) {
    try {
      await this.CMAPIAPIService.getVideoHeader(url).toPromise()
      return ok(true)
    } catch(error) {
      if(error.status == 405) {
        return ok(true)
      }
      return err(false)
    }

  }

}

import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { ThemeService } from 'src/app/services/theme.service';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';
import { register } from 'swiper/element/bundle';
import { DomSanitizer } from '@angular/platform-browser';
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "YYYY-MMMM-DD HH:mm"
  },
  display: {
    dateInput: "DD MMM YYYY H:mm",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
}
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ]
})
export class AppComponent {
  constructor(
    private platform: Platform,
      private statusBar: StatusBar,
      public ThemeService: ThemeService,
      private storage: Storage,
      private sanitizer: DomSanitizer,
      private screenOrientation: ScreenOrientation,
  ) {

    window["sanitizer"] = this.sanitizer
    this.initializeApp();
    this.storage.set('version', environment.version).then(() => {})

  }

/*   requestPermission() {
    this.afMessaging.requestToken.subscribe(
      (token) => {
        // Save the token to your server for sending notifications
        console.log('Permission granted! Token:', token);
      },
      (error) => {
        console.error('Permission denied:', error);
      }
    );
  }

  receiveMessages() {
    this.afMessaging.messages.subscribe((message) => {
      console.log('Received message:', message);
      // Handle the received message, e.g., show a notification
    });
  } */

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();

      if (this.platform.is("tablet")) {
        window.screen.orientation.unlock();
      } else if( this.platform.is("mobile")) {
        if(this.platform.is('ios')){
          this.screenOrientation.lock('portrait')
        } else {
          (window.screen.orientation as any).lock('portrait');
        }
      }
    });
  }
}



window.onerror = function(message, source, lineno, colno, error) {
  if (error) message = error.stack;
  console.log('send', 'event', 'window.onerror', message, navigator.userAgent);
}
// ============================================================================

import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { SignalRService } from 'src/app/infra/socket/signalR/signal-r.service';
import { MessageSocketRepositoryService } from 'src/app/module/chat/data/repository/message/message-live-signalr-data-source.service'
import { z } from 'zod';

export const ListenMessageDeleteByRoomIdInputDTOSchema = z.object({
  roomId: z.string(),
});
export type ListenMessageDeleteByRoomIdInputDTO = z.infer<typeof ListenMessageDeleteByRoomIdInputDTOSchema>


@Injectable({
  providedIn: 'root'
})
export class ListenMessageDeleteByRoomIdService {

  constructor(
    private MessageSocketRepositoryService: MessageSocketRepositoryService,
  ) { }

  execute({roomId}: ListenMessageDeleteByRoomIdInputDTO) {
    return this.MessageSocketRepositoryService.listenToDeleteMessages().pipe(
      filter((message) => {
        return roomId == message?.roomId
      } )
    )
  }
}

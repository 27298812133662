export const Cy  = {
  p: {
    login: {
      b: {
        enter: 'loginbutton'
      }
    },
    gabinete: {
      aside: {
        despacho: 'cypress-despacho-item'
      },
    },
    search: {
      input: {
        subject: 'cy-subject-input'
      },
      b: {
        search:'cy-search-button'
      }
    }
  },
  header: {
    b: {
      search: 'cy-search-button'
    }
  },
  footer: {
    gabinete: 'cypress-gabinete-tab',
  }
}

import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { z } from 'zod';
import { IMessageSocketRepository } from 'src/app/core/chat/repository/message/message-socket-repository';

export const ListenMessageUpdateByRoomIdInputDTOSchema = z.object({
  roomId: z.string(),
});
export type ListenMessageUpdateByRoomIdInputDTO = z.infer<typeof ListenMessageUpdateByRoomIdInputDTOSchema>



@Injectable({
  providedIn: 'root'
})
export class ListenMessageUpdateByRoomIdUseCase {

  constructor(
    private messageLiveSignalRDataSourceService: IMessageSocketRepository,
  ) { }

  execute(input: ListenMessageUpdateByRoomIdInputDTO) {
    return this.messageLiveSignalRDataSourceService.listenToUpdateMessages().pipe(
      filter((message) => input.roomId == message?.roomId )
    )
  }
}

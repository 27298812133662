import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { EventBody } from 'src/app/models/eventbody.model';
import { EventPerson } from 'src/app/models/eventperson.model';
import { SearchList } from 'src/app/models/search-document';
import { LoginUserRespose } from 'src/app/models/user.model';
import { EventsService } from 'src/app/services/events.service';
import { ToastService } from 'src/app/services/toast.service';
import { Event } from '../../../models/event.model';
import { AttendeesPageModal } from '../../events/attendees/attendees.page';
import { SearchPage } from '../../search/search.page';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemeService } from 'src/app/services/theme.service';
import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { SessionStore } from 'src/app/store/session.service';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service';
import { environment } from 'src/environments/environment';
import { ProcessesService } from 'src/app/services/processes.service';
import { TaskService } from 'src/app/services/task.service'
import { ContactsService } from 'src/app/services/contacts.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, ThemePalette } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { AgendaDataRepositoryService } from 'src/app/module/agenda/data/repository/agenda-data-repository.service';
import { Observable } from 'rxjs';
import { TableSharedCalendar } from 'src/app/module/agenda/data/data-source/agenda-local-data-source.service';
import { map } from 'rxjs/operators';
import { RoleIdService } from 'src/app/services/role-id.service'
import { TracingType, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "YYYY-MMMM-DD HH:mm"
  },
  display: {
    dateInput: "DD MMM YYYY H:mm",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
}
const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
@Component({
  selector: 'app-new-event',
  templateUrl: './new-event.page.html',
  styleUrls: ['./new-event.page.scss'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT }
  ]
})

export class NewEventPage implements OnInit {
  // date picker
  public date: any;
  public disabled = false;
  public showSpinners = false;
  public showSeconds = true;
  public touchUi = false;
  public enableMeridian = false;
  public stepHour = 1;
  public stepMinute = 15;
  public stepSecond = 15;
  public color: ThemePalette = 'primary';

  Form: FormGroup;
  validateFrom = false

  postEvent: Event;
  eventBody: EventBody;
  segment: string = "true";
  profile: string;
  eventAttendees: EventPerson[];
  selectedSegment: string;
  selectedDate: Date;
  CalendarDate: Date;
  selectedRecurringType: any;

  adding: "intervenient" | "CC";

  taskParticipants: any = [];
  taskParticipantsCc: any = [];

  documents: SearchList[] = [];

  loggeduser: LoginUserRespose;
  members: any;
  CalendarName;
  SessionStore = SessionStore;

  public listColors = ['primary', 'accent', 'warn'];
  public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];
  public stepSeconds = [1, 5, 10, 15, 20, 25];

  autoStartTime;
  autoEndTime;
  CalendarNamesOptions = ['Oficial', 'Pessoal']
  roomId: string;
  globalEnd = new Date('1999')
  environment = environment
  eventPersons: EventPerson[];
  contacts: EventPerson[];
  allDayCheck: boolean = false;
  eventRecurence = 'never';
  sharedCalendar: Observable<TableSharedCalendar[]>
  selectedUserCalendar:any;

  hasChangeCalendar  = false


  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    public eventService: EventsService,
    private toastService: ToastService,
    public ThemeService: ThemeService,
    private platform: Platform,
    private hhtpErrorHandle: HttpErrorHandle,
    private processeService: ProcessesService,
    public TaskService: TaskService,
    private contactsService: ContactsService,
    private agendaDataRepository: AgendaDataRepositoryService,
    public RoleIdService: RoleIdService,
  ) {
    this.loggeduser = SessionStore.user;
    this.postEvent = new Event();
    this.postEvent.EventRecurrence = { Type: '-1' };
    this.eventBody = { BodyType: "1", Text: "" };
    this.postEvent.Body = this.eventBody;

    this.roomId = this.navParams.get('roomId');
    this.selectedSegment = this.navParams.get('segment');
    this.selectedDate = this.navParams.get('eventSelectedDate');
    this.taskParticipants = this.navParams.get('attendees');
    this.CalendarDate = this.navParams.get('CalendarDate')

    // Define the role priorities
    const rolePriorities: { [key: number]: number } = {
      100000014: 1, // Presidente da República
      100000011: 2, // Vice Presidente (example role ID)
      // Add other roles with their priorities here
    };

    this.agendaDataRepository.getSharedCalendar()

    this.sharedCalendar = this.agendaDataRepository.getShareCalendarItemsLive().pipe(
      map(data => data.sort((a, b) => {
        console.log('Raw data:', data); // Debug line
        const priorityA = rolePriorities[a.roleId] || Infinity;
        const priorityB = rolePriorities[b.roleId] || Infinity;
        return priorityA - priorityB;
      }))
    )


    this.setCalendarByDefault(true)
  }


  hasPrCalendar(data: TableSharedCalendar[]) {
    for(const e of data) {
      if(e.roleId == this.RoleIdService.PRES) {
        return true
      }
    }

    return false
  }
  changeSegmentCalendar() {
    this.hasChangeCalendar = true
  }
  async setCalendarByDefault(force) {
    if (!this.selectedUserCalendar || force) {

      const data = await this.agendaDataRepository.geCalendars()

      const prObject = data.find(e => e?.roleId == 100000014)
      if(prObject) {
        this.selectedUserCalendar = prObject.wxUserId
      } else {
        this.selectedUserCalendar = SessionStore.user.UserId
      }

    }
  }

  ngOnInit() {
    if (this.platform.is('desktop')) {
      // this.taskParticipants = [];
    }

    if (!this.CalendarName) {
      // console.log('true', this.eventService.calendarNamesAry.includes('Meu calendario'))
      if (this.eventService.calendarNamesAry.includes('Meu calendario')) {
        this.CalendarName = 'Meu calendario';
        // console.log(this.eventService.calendarNamesAry)
      } else {
        this.CalendarName = this.eventService.calendarNamesAry[0]
      }
    }


    this.selectedRecurringType = "-1";

    if (this.selectedSegment != "Combinada") {
      this.postEvent = {
        EventId: '',
        Subject: '',
        Body: this.eventBody,
        Location: '',
        CalendarId: '',
        CalendarName: 'Oficial',
        StartDate: this.autoStartTime,
        EndDate: this.autoEndTime,
        EventType: 'Reunião',
        Attendees: null,
        IsMeeting: false,
        IsRecurring: false,
        AppointmentState: 0,
        TimeZone: '',
        Organizer: '',
        Category: 'Meeting',
        HasAttachments: false,
        EventRecurrence: { frequency: this.eventRecurence, until: "",
          Type: ''  },
      };
    }
    else {
      this.postEvent = {
        EventId: '',
        Subject: '',
        Body: this.eventBody,
        Location: '',
        CalendarId: '',
        CalendarName: 'Oficial',
        StartDate: this.autoStartTime,
        EndDate: this.autoEndTime,
        EventType: 'Reunião',
        Attendees: null,
        IsMeeting: false,
        IsRecurring: false,
        AppointmentState: 0,
        TimeZone: '',
        Organizer: '',
        Category: 'Meeting',
        HasAttachments: false,
        EventRecurrence: { frequency: this.eventRecurence, until: "",
          Type: ''  },
      };
    }

    window.onresize = (event) => {
      if (window.innerWidth >= 1024) {
        this.modalController.dismiss();
      }
    };

    this.setDefaultTime()

    this.checkRoleInArray()
    this.changeAgenda()

    this.fetchContacts("")
  }

  ngOnDestroy() {
    clearInterval(this.myInterval)
  }

  myInterval = setInterval(() => {
    document.querySelectorAll('.ngx-mat-timepicker input').forEach((e: any) => {
      if (e) {
        e.disabled = true;
      }
    })
  }, 1000);


  setDefaultTime() {
    console.log('defalt call')
    this.postEvent.StartDate = this.roundTimeQuarterHour(this.CalendarDate);
    this.postEvent.EndDate = this.roundTimeQuarterHourPlus15(this.postEvent.StartDate);
    console.log('defalt call', this.postEvent.StartDate)
  }

  close() {
    this.modalController.dismiss();
  }

  roundTimeQuarterHour(timeToReturn = new Date()) {
    let date: any = new Date(timeToReturn) || new Date();
    let newdate = new Date();
    const minutes = newdate.getMinutes();
    date.setHours(newdate.getHours())
    date.setSeconds(0);

    if (minutes % 15 != 0) {

      if (minutes > 45) {
        date.setMinutes(60)
      } else if (minutes > 30) {
        date.setMinutes(45)
      } else if (minutes > 15) {
        date.setMinutes(30)
      } else if (minutes > 0) {
        date.setMinutes(15)
      }

    }

    return date
  }

  roundTimeQuarterHourPlus15(date: Date) {
    const _date = new Date(date);
    const minutes = _date.getMinutes();
    _date.setMinutes(minutes + 15)
    return _date
  }

  setStartDate() {
    if (!this.postEvent.StartDate) {
      this.postEvent.StartDate = this.roundTimeQuarterHour();
    }
  }

  setEndDate() {
    if (!this.postEvent.EndDate) {
      this.postEvent.EndDate = this.postEvent.StartDate;
    }
  }


  onDateChange(e) {

    const cloneDateStartDate = new Date(this.postEvent.StartDate);
    const cloneDateEndDate = new Date(this.postEvent.EndDate);

    if(cloneDateStartDate.getTime() >= cloneDateEndDate.getTime()) {
      cloneDateStartDate.setHours(cloneDateStartDate.getHours() + 1);
      this.postEvent.EndDate = cloneDateStartDate
    }

  }

  onSelectedRecurringChanged(ev: any) {

    this.calculetedLastOccurrence(ev);

    if (ev.length > 1) {

      this.selectedRecurringType = ev.filter(data => data != '-1');
    }
    if (ev.length == 0) {
      this.selectedRecurringType = "-1";
    }
  }

  calculetedLastOccurrence(type: number) {
    // console.log(type);
    var valor;
    var opcao: boolean;
    if (type == 0) {
      valor = 7;
      opcao = true;
    } else if (type == 1) {
      valor = 30;
      opcao = true;
    } else if (type == 2) {
      valor = 1;
      opcao = false;
    } else if (type == 3) {
      valor = 5;
      opcao = false;
    }
    this.defineLastOccurrence(valor, opcao);
  }


  defineLastOccurrence(valor: number, opcao: boolean) {
    var time = new Date(this.postEvent.EndDate);
    if (opcao == true) {
      time.setDate(time.getDate() + valor);
      this.postEvent.EventRecurrence.LastOccurrence = time;
    } else {
      time = new Date(
        time.getFullYear() + valor,
        time.getMonth(),
        time.getDate(),
        time.getHours(),
        time.getMinutes()
      );
      this.postEvent.EventRecurrence.LastOccurrence = time;
    }

  }


  runValidation() {
    this.validateFrom = true;
    if (new Date(this.postEvent.StartDate).getTime() > new Date(this.postEvent.EndDate).getTime()) {
      this.toastService._badRequest("A data de fim não pode ser inferior a data de início do evento")
    }
  }


  get dateValid() {
    if (window.innerWidth <= 800) {
      return this.postEvent.StartDate < this.postEvent.EndDate ? ['ok'] : []
    } else {
      return ['ok']
    }
  }

  injectValidation() {

    this.Form = new FormGroup({
      Subject: new FormControl(this.postEvent.Subject, [
        Validators.required,
        // Validators.minLength(4)
      ]),
      Location: new FormControl(this.postEvent.Location, [
        Validators.required,
      ]),
      CalendarName: new FormControl(this.postEvent.CalendarName, [
        Validators.required
      ]),
      Date: new FormControl(new Date(this.postEvent.StartDate).getTime() < new Date(this.postEvent.EndDate).getTime() ? 'ok' : null, [
        Validators.required
      ]),
      Categories: new FormControl(this.postEvent.Category, [
        Validators.required
      ]),
      participantes: new FormControl(this.taskParticipants, [
        Validators.required
      ]),
      /* dateOccurrence: new FormControl(this.postEvent.EventRecurrence.Type.toString() == '-1' ? ['ok'] : this.postEvent.EventRecurrence.LastOccurrence && new Date(this.postEvent.EventRecurrence.LastOccurrence).getTime() > new Date(this.postEvent.EndDate).getTime() ? 'ok' : null, [
        Validators.required
      ]), */


    })
  }

  openInicio() {
    let input: any = document.querySelector('#new-inicio')
    if (input) {

      input.click()
    }
  }

  openFim() {
    let input: any = document.querySelector('#new-fim')
    if (input) {
      input.click()
    }
  }

  openLastOccurrence() {
    let input: any = document.querySelector('#last-occurrence')
    if (input) {
      input.click()
    }
  }


  @XTracerAsync({name:'Mobile/create-event', bugPrint: true})
  async save_v2(tracing?: TracingType) {
    this.injectValidation()
    this.runValidation()

    console.log(new Date(this.postEvent.StartDate).getTime())
    console.log(new Date(this.postEvent.EndDate).getTime())
    console.log(new Date(this.postEvent.StartDate).getTime() < new Date(this.postEvent.EndDate).getTime() ? 'ok' : null)
    if (this.Form.invalid) {

      /* if (new Date(this.postEvent.StartDate).getTime() < new Date(this.postEvent.EndDate).getTime()) {
        this.toastService._badRequest("Data de inicio  menor que a data de fim")
      } */

      return false
    }

    const calendar = await this.agendaDataRepository.getCalendarByUserId(this.selectedUserCalendar)
    if(calendar.isOk()) {
      const loader = this.toastService.loading()
      this.postEvent.Attendees = this.taskParticipants.concat(this.taskParticipantsCc);
      this.postEvent.IsAllDayEvent = this.allDayCheck;

      const value = await this.agendaDataRepository.createEvent(this.postEvent, this.documents, calendar.value, tracing)
      if(value.isOk()) {
        console.log(value.value)
        loader.remove()
        this.hhtpErrorHandle.httpsSucessMessagge('new event')
        let data = Object.assign(this.postEvent)
        this.modalController.dismiss(data);
        tracing.setAttribute('outcome', 'success');
      } else {
        tracing.setAttribute('outcome', 'failed');
        console.log('create event error: ', value.error)
        loader.remove()
      }


    } else {

    }

  }


  //This method return calendar onwner user id
  selectedCalendarUserId() {

    if (this.eventService.calendarNamesType[this.CalendarName]?.['Oficial'] && this.postEvent.CalendarName == 'Oficial') {
      return this.eventService.calendarNamesType[this.CalendarName]['OwnerId']

    } else if (this.eventService.calendarNamesType[this.CalendarName]?.['Pessoal'] && this.postEvent.CalendarName == 'Pessoal') {

      return this.eventService.calendarNamesType[this.CalendarName]['OwnerId']

    } else {
      return '11:11'
    }
  }

  async changeAgenda() {

    const result = await this.agendaDataRepository.geCalendars()

    const selectedCalendar = result.find(e => e.wxUserId == this.selectedUserCalendar)

    if(selectedCalendar) {
      if(selectedCalendar.shareType == 1) {
        this.CalendarNamesOptions = ['Oficial']
      } else if(selectedCalendar.shareType == 2) {
        this.CalendarNamesOptions = ['Pessoal']
      } else if (selectedCalendar.shareType == 3) {
        this.CalendarNamesOptions = ['Oficial', 'Pessoal']
      }
    }

  }

  async openAttendees() {

    const modal = await this.modalController.create({
      component: AttendeesPageModal,
      componentProps: {
        hideExternalDomain: false,
        adding: this.adding,
        taskParticipants: this.taskParticipants,
        taskParticipantsCc: this.taskParticipantsCc
      },
      cssClass: 'attendee modal modal-desktop',
      backdropDismiss: false
    });



    modal.onDidDismiss().then((data) => {

      if (data) {
        data = data['data'];
        if (data) {

          const newAttendees: EventPerson[] = data['taskParticipants'];
          const newAttendeesCC: EventPerson[] = data['taskParticipantsCc'];

          this.setIntervenient(newAttendees);
          this.setIntervenientCC(newAttendeesCC);

        }

      }
    });

    await modal.present();
  }

  setIntervenient(data) {
    this.taskParticipants = [];
    this.taskParticipants = data;
    this.postEvent.Attendees = data;
  }

  setIntervenientCC(data) {
    this.taskParticipantsCc = [];
    this.taskParticipantsCc = data;
  }

  addParticipants() {
    this.adding = 'intervenient'
    this.openAttendees();
  }

  addParticipantsCC() {
    this.adding = 'CC'
    this.openAttendees();
  }


  async getDoc() {
    const modal = await this.modalController.create({
      component: SearchPage,
      cssClass: 'modal-width-100-width-background modal',
      componentProps: {
        type: 'AccoesPresidenciais & ArquivoDespachoElect',
        showSearchInput: true,
        eventAgenda: true,
        select: true
      }
    });

    modal.onDidDismiss().then((res) => {
      if (res) {
        const data = res.data;
        this.documents.push(data.selected);
      }
    });

    await modal.present();
  }

  removeAttachment(index: number) {

    this.documents = this.documents.filter((e, i) => index != i);

  }

  checkRoleInArray() {
    for (let index = 0; index < this.eventService.calendarNamesAry.length; index++) {
      // console.log('ROLE1', this.eventService.calendarNamesAry[index])

    }

    for (let index = 0; index < this.eventService.calendarRole.length; index++) {
      // console.log('ROLE2', this.eventService.calendarRole[index])

    }
  }

  // Deve ser removido posteriormente
  eventToaproveBody(event, calendarId, role, userId, attachments) {
    let toAproveObject = {
      "EventProcess": {
        "Body": event.Body,
        "Location": event.Location,
        "Subject": event.Subject,
        "StartDate": event.StartDate,
        "EndDate": event.EndDate,
        "Status": "Active",
        "IsAllDayEvent": event.IsRecurring,
        "EventType": event.EventType,
        "ParticipantsList": event.Attendees,
        "EventRecurrence": event.EventRecurrence,
        "HasAttachments": event.HasAttachments,
        "CalendarId": calendarId,
        "Role": role,
        "wxUserID": userId,
        "TimeZone": "W. Central Africa Standard Time"
      },
      "Attachments": attachments,
      "InstanceID": "AGD_" + this.loggeduser.UserName + "_" + this.processeService.generateInstaceFormatDate()
    }

    return toAproveObject;
  }

  async fetchContacts(filter: string) {

    if (this.loggeduser.Profile == 'PR') {
      this.contactsService.getContacts(filter).subscribe(result => {
        if (this.eventPersons != null) {
          this.eventPersons.forEach(attendee => {
            const index: number = result.findIndex((cont) => {
              return cont.EmailAddress.toLocaleLowerCase() == attendee.EmailAddress.toLocaleLowerCase()
            });

            result.splice(index, 1);

          });
        }
        this.contacts = result;
        //console.log('Attendes Email', this.loggeduser.Email)
        let filterLoggedUserEmail = this.contacts.filter(item => item.RoleDescription == "Ministro e Director do Gabinete do PR")
        //console.log('Attendes Email', filterLoggedUserEmail)

        if(filterLoggedUserEmail.length >= 1) {
          filterLoggedUserEmail[0].IsRequired = true
        }

        this.contacts = filterLoggedUserEmail;
        const newAttendees: EventPerson[] = this.contacts;

        this.setIntervenient(newAttendees);
        //console.log('Attendes Email', this.contacts)
      }
      );
    }
  }

  onCheckboxChange(event: any) {
    if (this.allDayCheck) {
      this.postEvent.IsAllDayEvent = this.allDayCheck;
      this.postEvent.StartDate = this.setAlldayTime(this.postEvent.StartDate)
      this.postEvent.EndDate = this.setAlldayTimeEndDate(this.postEvent.EndDate)

      console.log('Recurso ativado!!');
    } else {
      this.postEvent.IsAllDayEvent = this.allDayCheck;
      this.postEvent.EndDate = this.setAlldayTimeEndDateNotAlday(this.postEvent.EndDate)
      console.log('Recurso desativado');

    }
  }

  setAlldayTime(timeToReturn) {
    let date: any = new Date(timeToReturn) || new Date();
    let newdate = new Date();
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0);


    return date
  }

  setAlldayTimeEndDate(timeToReturn) {
    let date: any = new Date(timeToReturn) || new Date();
    let newdate = new Date();
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(0);


    return date
  }

  setAlldayTimeEndDateNotAlday(timeToReturn) {
    let date: any = new Date(timeToReturn) || new Date();
    let newdate = new Date();
    date.setHours(23)
    date.setMinutes(0)
    date.setSeconds(0);


    return date
  }
}

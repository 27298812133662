import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { HttpResult } from './type';
import { Result } from 'neverthrow';
import { Observable } from 'rxjs';


export abstract  class HttpAdapter {
  abstract post<T>(url: string, body: any): Promise<Result<HttpResult<T>, HttpErrorResponse>>
  abstract get<T>(url: string, options?: Object): Promise<Result<HttpResult<T>, HttpErrorResponse>>
  abstract put<T>(url: string, body: any): Promise<Result<HttpResult<T>, HttpErrorResponse>>
  abstract patch<T>(url: string, body?: Object): Promise<Result<HttpResult<T>, HttpErrorResponse>> 
  abstract delete<T>(url: string, body?: Object): Promise<Result<HttpResult<T>, HttpErrorResponse>>
  abstract listen():Observable<Result<HttpResult<any>, HttpErrorResponse>>
}
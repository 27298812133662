<ion-header  class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <div class="main-header">
      <div class="title-content width-100">
        <div class="middle">
          <ion-label class="title" style="font-size: 21.1px">Novo Evento</ion-label> <br>
          <i style="margin-top: -3px; font-size: 15px;" > Campos marcados com * são obrigatórios</i>
        </div>
      </div>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="main-content">
    <!-- <input type="text" ngbDatepicker #d="ngbDatepicker"/> -->

    <div class="ion-item-container" [class.input-error]="Form?.get('Subject')?.invalid && validateFrom ">
      <ion-input autocomplete="on" autocorrect="on" spellcheck="true" placeholder="Assunto*" [(ngModel)]="postEvent.Subject"></ion-input>
    </div>

    <div *ngIf="(sharedCalendar | async) as calendarData" class="container-div" >
      <div class="ion-item-class-2 d-flex">
        <div class="ion-icon-class">
          <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
        </div>
        <div class="ion-input-class flex-grow-1 justify-center align-center material-inputs " [class.input-error]="Form?.get('CalendarName')?.invalid  && validateFrom ">

          <mat-form-field appearance="none"  class="width-100" color="ion-color-secondary"  placeholder="Selecione agenda">
            <div *ngIf="hasPrCalendar(calendarData) && !hasChangeCalendar  " style="margin-bottom: -20px;">PR </div>
            <div *ngIf="selectedUserCalendar == SessionStore.user.UserId && !hasChangeCalendar  && SessionStore.user.Profile != 'PR'" style="margin-bottom: -20px;">
              Minha agenda
            </div>
            <mat-select [(value)]="selectedUserCalendar" (selectionChange)="changeAgenda();changeSegmentCalendar()">

              <mat-option *ngFor="let calendars of calendarData" value="{{calendars.wxUserId}}">

                <div *ngIf="calendars.roleId == RoleIdService.PRES">PR </div>
                <div *ngIf="calendars.roleId == RoleIdService.MD && calendars.roleId != SessionStore.user.RoleID">AGENDA DO MDGPR</div>
                <div *ngIf="calendars.roleId != RoleIdService.MD && calendars.roleId != RoleIdService.PRES && calendars.wxFullName && calendars.wxUserId != SessionStore.user.UserId"> Agenda {{calendars.wxFullName}} </div>
                <div *ngIf="calendars.wxUserId == SessionStore.user.UserId && SessionStore.user.Profile != 'PR'">
                  Minha agenda
                </div>

              </mat-option>

            </mat-select>
          </mat-form-field>

        </div>
      </div>
    </div>
    <div class="container-div">
      <div class="ion-item-class-2">
        <div class="ion-icon-class">
          <ion-icon slot="start" src="assets/images/icons-location.svg"></ion-icon>
        </div>
        <div class="ion-input-class" [class.input-error]="Form?.get('Location')?.invalid  && validateFrom ">
          <ion-input autocomplete="on" autocorrect="on" spellcheck="true" placeholder="Localização*" [(ngModel)]="postEvent.Location"></ion-input>
        </div>
      </div>
    </div>

    <div class="container-div">
      <div class="ion-item-class-2 d-flex">
        <div class="ion-icon-class">
          <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
        </div>
        <div class="ion-input-class flex-grow-1 justify-center align-center material-inputs " [class.input-error]="Form?.get('CalendarName')?.invalid  && validateFrom ">

          <mat-form-field appearance="none"  class="width-100" color="ion-color-secondary"  placeholder="Selecione agenda">
            <mat-select [(value)]="postEvent.CalendarName" >
              <mat-option *ngFor="let calendars of CalendarNamesOptions" value="{{calendars}}">
                Agenda {{ calendars }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </div>
    </div>

    <div class="container-div">
      <div class="ion-item-class-2 d-flex">
        <div class="ion-icon-class">
          <ion-icon slot="start" src="assets/images/icons-description.svg"></ion-icon>
        </div>
        <div class="ion-input-class flex-grow-1 justify-center align-center material-inputs">

          <mat-form-field appearance="none" class="width-100" placeholder="Sample Type" required>
            <!-- <input matInput type="text" > -->
            <mat-select [(value)]="postEvent.Category" >
              <mat-option value="Meeting">
                Reunião
              </mat-option>
              <mat-option value="Travel">
                Viagem
              </mat-option>
              <mat-option value="Conference">
                Conferência
              </mat-option>
              <mat-option value="Encontro">
                Encontro
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </div>
    </div>

    <div class="container-div">
        <div>
          <ion-item>
            <ion-label>Todo dia</ion-label>
            <ion-checkbox [(ngModel)]="allDayCheck" (ionChange)="onCheckboxChange($event)"></ion-checkbox>
          </ion-item>
        </div>
    </div>

    <div class="container-div">
      <div class="ion-item-class-2 d-flex">
        <div class="ion-icon-class">
          <ion-icon slot="start" src="assets/images/icons-reapet.svg"></ion-icon>
        </div>
        <div class="ion-input-class flex-grow-1 justify-center align-center material-inputs">

          <mat-form-field appearance="none" class="width-100" placeholder="Sample Type" required>
            <!-- <input matInput type="text" > -->
            <mat-select [(value)]="postEvent.EventRecurrence.frequency" >
              <mat-option value="never">
                Nunca
              </mat-option>
              <mat-option value="daily">
                Diário
              </mat-option>
              <mat-option value="weekly">
                Semanalmente
              </mat-option>
              <mat-option value="monthly">
                Mensal
              </mat-option>
              <mat-option value="yearly">
                Anual
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </div>
    </div>

    <div *ngIf="postEvent.EventRecurrence.frequency != 'never'" class="container-div">
      <div class="ion-item-class-2 d-flex" >
        <div class="ion-icon-class">
          <ion-icon slot="start" src="assets/images/icons-reapet.svg"></ion-icon>
        </div>

        <div  class="ion-input-class flex-grow-1 justify-center align-center material-inputs materia-top" >

          <mat-form-field appearance="none" class="date-hour-picker">
            <input matInput [ngxMatDatetimePicker]="picker1"
              placeholder="Data Fim de Recorrência*"
              [(ngModel)]="postEvent.EventRecurrence.until"
              [disabled]="disabled"
            >
            <mat-datepicker-toggle id="new-inicio" matSuffix [for]="picker1" ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker1
              [showSpinners]="showSpinners"
              [showSeconds]="showSeconds"
              [stepHour]="stepHour" [stepMinute]="stepMinute"
              [stepSecond]="stepSecond"
              [touchUi]="touchUi"
              [hideTime]="true"
              >
            </ngx-mat-datetime-picker>
          </mat-form-field>

        </div>
      </div>
    </div>



    <div class="container-div">
      <div class="ion-item-class-2 d-flex" >
        <div class="ion-icon-class">
          <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
        </div>

        <div *ngIf="allDayCheck"  class="ion-input-class flex-grow-1 justify-center align-center material-inputs materia-top" [class.input-error]="Form?.get('Date')?.invalid && validateFrom ">

          <mat-form-field appearance="none" class="date-hour-picker">

            <input matInput [ngxMatDatetimePicker]="picker1"
              placeholder="Data Inicio*"
              [(ngModel)]="postEvent.StartDate"
              [disabled]="disabled"
              (ngModelChange)="onDateChange($event)"
            >
            <mat-datepicker-toggle id="new-inicio" matSuffix [for]="picker1" ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker1

              [stepHour]="1"
              [stepMinute]="15"
              [hideTime]="true"


            >
            </ngx-mat-datetime-picker>
          </mat-form-field>

        </div>

        <div *ngIf="!allDayCheck"  class="ion-input-class flex-grow-1 justify-center align-center material-inputs materia-top" [class.input-error]="Form?.get('Date')?.invalid && validateFrom ">

          <mat-form-field appearance="none" class="date-hour-picker">


            <input matInput [ngxMatDatetimePicker]="picker1"
              placeholder="Data Inicio*"
              [(ngModel)]="postEvent.StartDate"
              [disabled]="disabled"
              (ngModelChange)="onDateChange($event)"
            >
            <mat-datepicker-toggle id="new-inicio" matSuffix [for]="picker1" ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker1

              [stepHour]="1"
              [stepMinute]="15"
              [hideTime]="false"


            >
            </ngx-mat-datetime-picker>
          </mat-form-field>

        </div>
      </div>
    </div>
    <div *ngIf="!allDayCheck" class="container-div">
      <div class="ion-item-class-2 d-flex">
        <div class="ion-icon-class">
          <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
        </div>
        <!--
          [className]="Form?.get('Subject')?.invalid ? 'input-error ion-input-class  flex-grow-1' : 'ion-input-class  ion-input-class  flex-grow-1' "
         -->
        <div class="ion-input-class flex-grow-1 justify-center align-center materia-top" [class.input-error]="Form?.get('Date')?.invalid && validateFrom ">

          <mat-form-field appearance="none" class="date-hour-picker" >
            <input matInput [ngxMatDatetimePicker]="fim"
              placeholder="Data de fim*"
              [(ngModel)]="postEvent.EndDate"
              [disabled]="disabled"
              [min]="postEvent.StartDate"

            >
            <mat-datepicker-toggle id="new-fim" matSuffix [for]="fim" ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #fim

              [stepHour]="1" [stepMinute]="15"
              >
            </ngx-mat-datetime-picker>
          </mat-form-field>

        </div>
      </div>
    </div>
    <div *ngIf="allDayCheck" class="container-div">
      <div class="ion-item-class-2 d-flex">
        <div class="ion-icon-class">
          <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
        </div>
        <!--
          [className]="Form?.get('Subject')?.invalid ? 'input-error ion-input-class  flex-grow-1' : 'ion-input-class  ion-input-class  flex-grow-1' "
         -->
        <div class="ion-input-class flex-grow-1 justify-center align-center materia-top" [class.input-error]="Form?.get('Date')?.invalid && validateFrom ">

          <mat-form-field appearance="none" class="date-hour-picker" >
            <input matInput [ngxMatDatetimePicker]="fim"
              placeholder="Data de fim*"
              [(ngModel)]="postEvent.EndDate"
              [disabled]="disabled"
              [min]="postEvent.StartDate"

            >
            <mat-datepicker-toggle id="new-fim" matSuffix [for]="fim" ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #fim

              [stepHour]="1" [stepMinute]="15"
              [hideTime]="true"
              >
            </ngx-mat-datetime-picker>
          </mat-form-field>

        </div>
      </div>
    </div>

    <div class="container-div">
      <div class="ion-item-class-2">
        <div class="ion-icon-class">
          <ion-icon slot="start" src="assets/images/icons-person.svg"></ion-icon>
        </div>
        <div  class="ion-input-class-no-height cursor-pointer"  (click)="addParticipants()" [class.input-error]="Form?.get('participantes')?.invalid  && validateFrom ">
          <div class="list-people">
            <ion-item lines="none">
              <ion-list>
                <ion-label  *ngIf="taskParticipants?.length < 1"  class="list-people-title">Adicionar Intervenientes*</ion-label>
                <ion-label *ngFor="let participant of taskParticipants">{{participant.Name}}</ion-label>
              </ion-list>
            </ion-item>
          </div>
          <div  class="add-people cursor-pointer">
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' " slot="start" src="assets/images/icons-arrow-forward.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " slot="start" src="assets/images/theme/gov/icons-arrow-forward.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " slot="start" src="assets/images/theme/{{ThemeService.currentTheme}}/icons-arrow-forward.svg"></ion-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="container-div">
      <div class="ion-item-class-2">
        <div class="ion-icon-class">
          <ion-icon slot="start" src="assets/images/icons-people-cc.svg"></ion-icon>
        </div>
        <div class="ion-input-class-no-height cursor-pointer" (click)="addParticipantsCC()">
          <div class="list-people">
            <ion-item lines="none">
              <ion-list>
                <ion-label *ngIf="taskParticipantsCc?.length < 1" class="list-people-title">Com conhecimento</ion-label>
                <ion-label *ngFor="let participant of taskParticipantsCc">{{participant.Name}}</ion-label>
              </ion-list>
            </ion-item>
          </div>
          <div  class="add-people cursor-pointer">
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' " slot="start" src="assets/images/icons-arrow-forward.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " slot="start" src="assets/images/theme/gov/icons-arrow-forward.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " slot="start" src="assets/images/theme/{{ThemeService.currentTheme}}/icons-arrow-forward.svg"></ion-icon>
          </div>
        </div>
      </div>
    </div>

    <!-- <div style="align-items: flex-start; display: flex; height: fit-content; margin-bottom: 15px">
      <ion-icon style="height: 30px; width: 30px; margin-right: 10px; padding-left: 5px;" slot="start" src="assets/images/icons-description.svg"></ion-icon>
      <ion-textarea class="textarea" autocomplete="on" autocorrect="on" spellcheck="true" [(ngModel)]="postEvent.Body.Text"  placeholder="Detalhes" autoGrow="true" ></ion-textarea>
    </div> -->

    <div class="container-div">
      <div class="ion-item-class-2">
        <div class="ion-icon-class">
          <ion-icon slot="start" src="assets/images/icons-description.svg"></ion-icon>
        </div>
        <div class="ion-input-class-no-height flex-grow-1">

          <ion-textarea class="textarea heigh-200" autocomplete="on" autocorrect="on" spellcheck="true" [(ngModel)]="postEvent.Body.Text"  placeholder="Detalhes" autoGrow="true" ></ion-textarea>

        </div>
      </div>
    </div>

   <div  class="ion-item-container-no-border cursor-pointer"  (click)="getDoc()">
      <ion-label>
        <div class="attach-icon">
          <ion-icon *ngIf="ThemeService.currentTheme == 'default' " src="assets/images/icons-attach-doc.svg"></ion-icon>
          <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " src="assets/images/theme/gov/icons-attach-doc.svg"></ion-icon>
                <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " src="assets/images/theme/{{ThemeService.currentTheme}}/icons-attach-doc.svg"></ion-icon>
        </div>
        <div class="attach-document">
          <ion-label>Adicionar documentos</ion-label>
        </div>
      </ion-label>
    </div>

    <div  *ngFor="let document of documents; let i = index" >
      <ion-list class="list">
        <ion-item>
        <ion-label>
          <p class="d-flex ion-justify-content-between">
            <span class="attach-title-item">{{document.subject}}</span>
            <span class="app-name">
              <span class="app-name" *ngIf="document.applicationId  == 8 || document.ApplicationId  == 8"> Correspondência </span>
              <span class="app-name" *ngIf="document.applicationId == 386 || document.ApplicationId == 386"> Acções Presidenciais </span>
              <span class="app-name" *ngIf="document.applicationId == 361 || document.ApplicationId == 361 "> Arquivo Despacho Electrónico </span>
            </span>
            <span class="close-button text-black cursor-pointer" (click)="removeAttachment(i)" >
              <ion-icon class="font-20" src="assets/images/icons-delete-25.svg"></ion-icon>
            </span>
          </p>
          <p><span class="span-left">{{(document.EntidadeOrganicaNome || document.Sender)}}</span><span class="span-right"> {{(document.dateEntry || document.DocDate)  | date: 'dd-MM-yyyy HH:mm'}} </span></p>
        </ion-label>
      </ion-item>
      </ion-list>
    </div>
<!--
    <div  >
      <ion-list class="list">
        <ion-item>
        <ion-label>
          <p class="d-flex ion-justify-content-between">
            <span class="attach-title-item">Assunto</span>
            <span class="app-name">appName</span>
            <span class="close-button text-black cursor-pointer" (click)="removeAttachment(i)" >
              <ion-icon class="font-20" src="assets/images/icons-delete-25.svg"></ion-icon>
            </span>
          </p>
          <p><span class="span-left">EntidadeOrganicaNome</span><span class="span-right"> document </span></p>
        </ion-label>
      </ion-item>
      </ion-list>
    </div> -->

  </div>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar class="footer-toolbar px-20">
    <ion-buttons slot="start">
      <button class="btn-ok" fill="clear" color="#fff" (click)="save_v2()">
        <ion-label>Gravar</ion-label>
      </button>
    </ion-buttons>
    <ion-buttons  slot="end">
      <button class="btn-cancel" fill="clear" color="#061b52" (click)="close()">
        <ion-label>Cancelar</ion-label>
      </button>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>

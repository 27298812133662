import { ActionsPermission } from "./actions.permission";
import { AgendaPermission } from "./agenda-permission";
import { ChatPermission } from "./chat-permission";
import { GabinetePermission } from "./gabinete-permission";

export class PermissionList{
  Agenda = new AgendaPermission();
  Gabinete = new GabinetePermission();
  Actions = new ActionsPermission();
  Chat = new ChatPermission();
}

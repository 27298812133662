<ion-header  class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <div class="main-header">
      <div class="title-content width-100">
        <div class="back-icon">
          <ion-icon *ngIf="ThemeService.currentTheme == 'default' " slot="end" src='assets/images/Theme/doneIt/icons-arrow-arrow-left.svg'></ion-icon>
          <button class="btn-no-color cursor-pointer" (click)="close()">
            <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " slot="end" src='assets/images/theme/{{ThemeService.currentTheme}}/icons-arrow-arrow-left.svg'></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' " slot="end" src='assets/images/icons-arrow-arrow-left.svg'></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " slot="end" src='assets/images/theme/gov/icons-calendar-arrow-left.svg'></ion-icon>
          </button>
        </div>
        <div class="div-title">
          <ion-label class="title">Nomear novo administrador</ion-label>
        </div>
      </div>
    </div>
  </ion-toolbar>
  <ion-toolbar class="toolbar-search">
    <div class="search">
      <ion-searchbar search-icon="undefined" debounce="500" (ionChange)="onChange($event)" placeholder="Pesquisar por contacto" ></ion-searchbar>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="main-content" *ngIf="roomMembers$ | async as memberList">
    <ion-virtual-scroll [items]="memberList | filter:textSearch: 'wxFullName'" approxItemHeight="70px" [headerFn]="separateLetter">

      <div class="item-divider" *virtualHeader="let header">
        <ion-label>{{header}}</ion-label>
      </div>

      <div *virtualItem="let user" class="item-checkbox">
        <div class="cursor-pointer d-flex width-100" (click)="setRoomOwner(user)" (ionChange)="setRoomOwner(user)">
          <p class="flex-1">{{user.wxFullName}}</p>
          <ion-icon slot="end" class="{{user.status}}" name="ellipse"></ion-icon>
        </div>
      </div>

    </ion-virtual-scroll>

  </div>
</ion-content>


import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { HttpAdapter } from "src/app/infra/http/adapter";
import { z } from "zod";
import { IMessageGetAllByRoomIdOutPut } from "./message-get-all-by-room-Id";

const HttpListenToMessageLoadHistoryUseCaseInputSchema = z.object({
  roomId: z.string()
})

export type HttpListenToMessageLoadHistoryUseCaseInput = z.infer<typeof HttpListenToMessageLoadHistoryUseCaseInputSchema>

@Injectable({
  providedIn: 'root'
})
export class HttpListenToMessageLoadHistoryUseCase{

  constructor(
    private http: HttpAdapter
  ) {}

  execute(input: HttpListenToMessageLoadHistoryUseCaseInput): Observable<IMessageGetAllByRoomIdOutPut>  {
    return this.http.listen().pipe(
      filter((response)=> response.isOk()),
      filter((response: any)=> {
        return response.value.url.includes(`Room/${input.roomId}/Messages`)
      }),
      map((response: any) => response.value.data as IMessageGetAllByRoomIdOutPut)
    )
  }
}

import { MemberTable } from "src/app/infra/database/dexie/instance/chat/schema/members";
import { RoomByIdMemberItemOutputDTO } from "src/app/module/chat/domain/use-case/room/room-get-by-id-use-case.service";

export function roomMemberListDetermineChanges(____serverRooms: RoomByIdMemberItemOutputDTO[], localRooms: MemberTable[], roomId: string) {

  const PServerRooms: (RoomByIdMemberItemOutputDTO & {$roomIdUserId: string})[] = ____serverRooms.map( e=> {

    return {
      ...e,
      $roomIdUserId: roomId + e.user.wxUserId
    }
  })

  const serverRoomMap = new Map(PServerRooms.map(room => [room.$roomIdUserId, room]));
  const localRoomMap = new Map(localRooms.map(room => [room.$roomIdUserId, room]));

  const membersToInsert = PServerRooms.filter(room => !localRoomMap.has(room.$roomIdUserId));
  const membersToUpdate = PServerRooms.filter(room => {
  const localRoom = localRoomMap.get(room.$roomIdUserId);
    return localRoom && (
      room.user.wxUserId !== localRoom.wxUserId ||
      room.user.userPhoto !== localRoom.userPhoto ||
      room.joinAt  !== localRoom.joinAt,
      room.isAdmin !== localRoom.isAdmin
    )
  });

  const membersToDelete = localRooms.filter(room => !serverRoomMap.has(room.$roomIdUserId));

  return { membersToInsert, membersToUpdate, membersToDelete };
}

import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { InstanceId } from '../../chat-service.service';
import { MessageEntity } from '../../../../../core/chat/entity/message';
import { z } from 'zod';
import { IMessageSocketRepository } from 'src/app/core/chat/repository/message/message-socket-repository';


export const ListenMessageByRoomIdNewInputDTOSchema = z.object({
  roomId: z.string(),
});
export type ListenMessageByRoomIdNewInputDTO = z.infer<typeof ListenMessageByRoomIdNewInputDTOSchema>


@Injectable({
  providedIn: 'root'
})
export class ListenMessageByRoomIdNewUseCase {

  constructor(
    private MessageSocketRepositoryService: IMessageSocketRepository
  ) { }

  execute(data: ListenMessageByRoomIdNewInputDTO) {

    return this.MessageSocketRepositoryService.listenToMessages().pipe(
      filter((message) => !message?.requestId?.startsWith(InstanceId) && message?.roomId == data.roomId),
      map(message => Object.assign(new MessageEntity(), message))
    )

  }
}

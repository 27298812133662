import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { SearchService } from "../../services/search.service";
import { formatDate } from '@angular/common';
import { SenderPage } from 'src/app/pages/search/sender/sender.page';
import { OrganicEntityPage } from 'src/app/pages/search/organic-entity/organic-entity.page';
import WordCloud from 'src/plugin/wordcloud2.js';
import { ViewEventPage } from 'src/app/pages/agenda/view-event/view-event.page';
import { PublicationDetailPage } from '../publications/view-publications/publication-detail/publication-detail.page';
import { DocumentDetailPage } from 'src/app/modals/document-detail/document-detail.page';
import { SearchCategory, SearchList } from 'src/app/models/search-document';
import { ThemeService } from 'src/app/services/theme.service'
import { ViewDocumentPage } from 'src/app/modals/view-document/view-document.page';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { momentG } from 'src/plugin/momentG';
import { Cy } from 'cypress/enum'
import { AgendaDataRepositoryService } from 'src/app/module/agenda/data/repository/agenda-data-repository.service';
import { SessionStore } from 'src/app/store/session.service';
import { TracingType, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';
import { isHttpError } from 'src/app/services/http.service';
import { ToastService } from 'src/app/services/toast.service';
import { EEventFilterStatus } from 'src/app/module/agenda/data/dto/enums';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "YYYY-MMMM-DD"
  },
  display: {
    dateInput: "DD MMM YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
}

@Component({
  selector: 'app-search',
  templateUrl: './search.page.html',
  styleUrls: ['./search.page.scss'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ]
})
export class SearchPage implements OnInit {

  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public stepHour = 1;
  public stepMinute = 15;
  public stepSecond = 15;
  //

  // https params
  searchSubject: string;
  searchDocumentDate: string;
  searchDocumentDateEnd: string;
  searchSenderdescription: string;
  searchSenderId: number;
  searchOrganicEntiryDescription: string;
  searchOrganicEntiryCode: number;
  searchDocTypeId: string;
  ordinance: string;

  searchCategories: SearchCategory[] = [];
  showSearchDocuments: SearchList[] = [];
  showCategory: string;
  searchDocuments: SearchList[] = [];

  showDocuments = false;
  showAdvanceSearch = false;

  showLoader: boolean;

  currentPath: string
  searchResult = "Assuntos mais recebidos";

  // See http://idangero.us/swiper/api/ for valid options.
  slideOpts = {
    slidesPerView: parseInt(`${window.innerWidth / 147}`, 10),
    initialSlide: 0,
    speed: 400,
  }

  loadedAttachments: any;

  list = []

  windowWidth: number;

  type: "Agenda" | "Correspondencia" | "AccoesPresidenciais" | "ArquivoDespachoElect" | "AccoesPresidenciais & ArquivoDespachoElect" = "Agenda";
  select: boolean = false;

  showSearchInput = false
  eventAgenda = false;
  checkRoutPublication: boolean;
  checkDateEnd: boolean;
  datePickerPlaceholder: string;
  pageNumber = 1;
  pageSize = 10;
  totalPage: number;


  @ViewChild('picker1') picker1: any;
  checkAgenda: boolean;

  Cy = Cy

  constructor(private modalController: ModalController,
    private search: SearchService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public ThemeService: ThemeService,
    private router: Router,
    private httpErrorhandle: HttpErrorHandle,
    private agendaDataRepository: AgendaDataRepositoryService,
    private toastService: ToastService,
  ) {
    this.ordinance = "recent";
    this.currentPath = window.location.pathname;

    this.type = this.navParams.get('type');

    this.type = this.navParams.get('type');
    this.select = this.navParams.get('select');

    this.showSearchInput = this.navParams.get('showSearchInput');
    this.eventAgenda = this.navParams.get('eventAgenda')

    if (this.type == null || this.type == undefined) {

      if (this.currentPath.includes('/home/agenda')) {
        this.type = "Agenda"

      } else if (this.currentPath.includes('/home/gabinete-digital')) {
        this.type = "AccoesPresidenciais & ArquivoDespachoElect"


      } else if (this.currentPath.includes('/home/publications')) {
        this.type = "AccoesPresidenciais"

      } else {

      }
    }
  }

  ngOnDestroy() {
    clearInterval(this.myInterval)
  }

  myInterval = setInterval(() => {
    document.querySelectorAll('.ngx-mat-timepicker input').forEach((e: any) => {
      if (e) {
        e.disabled = true;
      }
    })
  }, 1000);

  selectItem(item: SearchList) {
    if (this.select) {
      this.modalController.dismiss({
        selected: item
      });
    }
  }

  async view(item: SearchList) {
    console.log('doc item',item)

    const ApplicationType = item.applicationId.toString()
    const Id = item.docId

    if (ApplicationType == '8') {

      // this.viewDocumentDetail(Id, ApplicationType);

      const modal = await this.modalController.create({
        component: ViewDocumentPage,
        componentProps: {
          trustedUrl: '',
          file: {
            title: item.subject,
            url: '',
            title_link: '',
          },
          applicationId: ApplicationType,
          docId: item.docId,
        },
        cssClass: 'modal-width-100-width-background modal'
      });
      await modal.present();

    } else if (ApplicationType == '361') {

      const modal = await this.modalController.create({
        component: ViewDocumentPage,
        componentProps: {
          trustedUrl: '',
          file: {
            title: item.subject,
            url: '',
            title_link: '',
          },
          applicationId: ApplicationType,
          docId: item.docId,
        },
        cssClass: 'modal-width-100-width-background modal'
      });
      await modal.present();
    }

  }

  ngOnInit() {

    if (this.currentPath.includes('/home/publications')) {
      this.checkRoutPublication = false;
      this.checkDateEnd = true;
      this.datePickerPlaceholder = 'Data Inicio*'
      console.log(this.checkRoutPublication)
    } else {
      this.checkRoutPublication = true;
      this.checkDateEnd = false;
      this.datePickerPlaceholder = 'Data*'
      console.log(this.checkRoutPublication)
    }

    this.wordCloud();

    window.onresize = (event) => {
      this.windowWidth = window.innerWidth
      this.loadWordCloud()
    };

    this.windowWidth = window.innerWidth

    window['dynamicSearch'] = (search: string) => {
      this.searchSubject = search;
    }

    window['searchTriger'] = () => {
      this.basicSearch();
    }

  }

  wordCloud() {

    this.search.mostSeachWord("15").subscribe(res => {

      const highest = res[0].Hits;
      const lowest = res[res.length - 1].Hits;
      const range = highest - lowest;
      const perPercent = range / 100;
      let list = [];

      // minimum font sixe
      // Editable -----------------------------------
      const minimumSize = 9;
      // Editable ------------------------------------
      // 64
      const maximum = 64;

      res.forEach(e => {
        const array: any = Object.values(e);

        array[1] = (((array[1] - lowest) / perPercent) * (maximum - minimumSize) / 100) + minimumSize;
        list.push(array)
      });

      this.list = list

      const elem = document.documentElement.querySelector('.most-searched-word-container');

      setTimeout(() => {
        WordCloud(
          elem,
          {
            list: this.list,
            Family: 'Times, serif',
            gridSize: 15
          },
        );
        // console.log('set visualy')
      }, 1000)

    });
  }


  roundTimeQuarterHour(timeToReturn = new Date()) {
    let date: any = new Date(timeToReturn) || new Date();
    const minutes = date.getMinutes();
    date.setSeconds(0);

    if (minutes % 15 != 0) {

      if (minutes > 45) {
        date.setMinutes(60)
      } else if (minutes > 30) {
        date.setMinutes(45)
      } else if (minutes > 15) {
        date.setMinutes(30)
      } else if (minutes > 0) {
        date.setMinutes(15)
      }

    }

    return date
  }

  clickDate() {
    if (!this.searchDocumentDate) {
      this.searchDocumentDate = this.roundTimeQuarterHour()
    }
  }

  clearInputDocumentDate() {
    this.searchDocumentDate = "";
  }

  loadWordCloud() {

    setTimeout(() => {
      const elem = document.documentElement.querySelector('.most-searched-word-container');

      WordCloud(
        elem,
        {
          list: this.list,
          Family: 'Times, serif',
          gridSize: 15
        },
      );

    }, 100);

  }

  close() {
    this.modalController.dismiss();
  }


  reorderList(orderBy: string) {

    this.ordinance = orderBy;

    if (this.ordinance == 'recent') {
      this.showSearchDocuments = this.sortArrayISODate(this.searchDocuments).reverse();
    } else {
      this.showSearchDocuments = this.sortArrayISODate(this.searchDocuments)
    }
  }

  sortArrayISODate(myArray: any) {
    return myArray.sort(function (a, b) {
      return (a.Data < b.Data) ? -1 : ((a.Data > b.Data) ? 1 : 0);
    });
  }

  /**
   * @description Basic search
   */
  @XTracerAsync({name:'search/basicSearch', bugPrint: true})
  async basicSearch(tracing?: TracingType) {

    let searchDocumentDate
    let searchDocumentDateEnd;

    if (this.searchDocumentDate) {
      searchDocumentDate = momentG(new Date(this.searchDocumentDate), 'yyyy-MM-dd')
    }
    if (this.searchDocumentDateEnd) {
      searchDocumentDateEnd = momentG(new Date(this.searchDocumentDate), 'yyyy-MM-dd')
    }

    searchDocumentDate;

    if (this.searchSubject == undefined || this.searchSubject == null) {
      this.searchSubject = ""
    }

    if (this.type == "Agenda") {
      this.showLoader = true


      if (this.searchSubject.trim() == "" && searchDocumentDate == null && searchDocumentDateEnd == null && this.searchSenderId == null && this.searchOrganicEntiryCode == null && this.searchDocTypeId == null) {
        this.searchResult = "Campo de pesquisa está vazio, entre com assunto que deseja pesquisa";
        console.log('enter text');

      } else {

        const searchEvents = await this.agendaDataRepository.searchEvent({
          value: this.searchSubject.trim(),
          status: EEventFilterStatus.AllToCommunicate

        }, tracing)
        if(searchEvents.isOk()) {

          this.showDocuments = true;
          this.searchDocuments = searchEvents.value

          this.searchDocuments = this.sortArrayISODate(this.searchDocuments);
          this.reorderList(this.ordinance);

          if (this.searchDocuments.length >= 1) {
            this.showDocuments = true;
          } else {
            this.searchResult = "Registo não encontrado"
            this.showDocuments = false
          }

          this.loadWordCloud();
          tracing.setAttribute('outcome', 'success');
        } else {

          this.loadWordCloud();
          this.showDocuments = true;

          if(!isHttpError(searchEvents.error)) {
            this.toastService._badRequest('Pedimos desculpa mas não foi possível executar a acção. Por favor, contacte o apoio técnico.')

          } else if (isHttpError(searchEvents.error)) {
            this.httpErrorhandle.httpStatusHandle(searchEvents.error)
          }

          tracing.setAttribute('outcome', 'failed');
          console.log('create event error: ', searchEvents.error)
        }

        // this.showLoader = true;
        // this.search.basicSearch(this.searchSubject, searchDocumentDate, searchDocumentDateEnd, this.searchSenderId
        //   , this.searchOrganicEntiryCode, this.searchDocTypeId, '0').subscribe(res => {
        //     console.log(res)

        //     if (!res.Categories.length) {
        //       this.searchResult = "Não encontramos o que procura";
        //     }

        //     res.Categories.forEach(e => {
        //       e['Active'] = false;
        //     });

        //     // bind respose
        //     this.searchCategories = res.Categories;

        //     this.searchDocuments = this.sortArrayISODate(res.Documents);

        //     this.reorderList(this.ordinance);

        //     // hide show document
        //     if (this.searchDocuments.length >= 1) {
        //       this.showDocuments = true;
        //     } else {
        //       this.showDocuments = false
        //     }

        //     this.showLoader = false;
        //     this.loadWordCloud();

        //   }, error => {
        //     this.showLoader = false;
        //     // this.searchResult = "Registo não encontrado"
        //     this.httpErrorhandle.httpStatusHandle(error)
        //     // console.log(error)
        //   });
      }

      this.showLoader = false
    }
    else if (this.type == "AccoesPresidenciais & ArquivoDespachoElect" && this.eventAgenda == true) {
      this.showLoader = true;
        this.agendaDataRepository.getDocumentAttachments(361, SessionStore.user.UserId, this.searchSubject, this.pageNumber, this.pageSize).subscribe((values) => {
          this.totalPage = values.data.total / this.pageSize;
          this.totalPage = Math.round(this.totalPage)
          console.log(values.data.result)
          this.searchDocuments = this.sortArrayISODate(values.data.result);
          this.reorderList(this.ordinance);

          // hide show document
          if (this.searchDocuments.length >= 1) {
            this.showDocuments = true;
          } else {
            this.showDocuments = false
          }

          this.showLoader = false;
          this.loadWordCloud();
        }, error => {
          this.showLoader = false;
          // this.searchResult = "Registo não encontrado"
          this.httpErrorhandle.httpStatusHandle(error)
          // console.log(error)
        })

    }
    else if (this.type == "AccoesPresidenciais & ArquivoDespachoElect") {

      this.searchCategories = [];
      this.searchDocuments = [];
      this.showSearchDocuments = [];

      if (this.select) {

        let counter = 0;
        if (this.searchSubject.trim() == "" && searchDocumentDate == null && searchDocumentDateEnd == null && this.searchSenderId == null && this.searchOrganicEntiryCode == null && this.searchDocTypeId == null) {
          this.searchResult = "Campo de pesquisa esta vazio, entre com assunto que deseja pesquisa";
          console.log('enter text');

        } else {

          this.search.searchForDoc(this.searchSubject, searchDocumentDate, this.searchSenderId
            , this.searchOrganicEntiryCode, this.searchDocTypeId, '8').subscribe(res => {
              this.showLoader = true;
              if (!res.Categories.length) {
                this.searchResult = "Não encontramos o que procura";
              }

              res.Categories.forEach(e => {
                e['Active'] = false;
                this.searchCategories.push(e)
              });

              // bind respose

              this.sortArrayISODate(res.Documents).forEach(e => {
                e['appName'] = 'Correspondencia'
                this.searchDocuments.push(e)
              });

              this.reorderList(this.ordinance);

              // hide show document
              if (this.searchDocuments.length >= 1) {
                this.showDocuments = true;
              } else {
                this.showDocuments = false
              }
              counter++;

              if (counter == 2) {
                this.showLoader = false;
              }

              this.loadWordCloud();
            }, error => {
              this.searchResult = "Registo não encontrado"
              counter++;
              console.log('application 8 failed', error)
              if (counter == 2) {
                this.showLoader = false;
              }
            });
        }

        if (this.searchSubject.trim() == "" && searchDocumentDate == null && searchDocumentDateEnd == null && this.searchSenderId == null && this.searchOrganicEntiryCode == null && this.searchDocTypeId == null) {
          this.searchResult = "Campo de pesquisa esta vazio, entre com assunto que deseja pesquisa";
          console.log('enter text');

        } else {
          this.showLoader = true;
          this.search.searchForDoc(this.searchSubject, searchDocumentDate, this.searchSenderId
            , this.searchOrganicEntiryCode, this.searchDocTypeId, '361').subscribe(res => {
              if (!res.Categories.length) {
                this.searchResult = "Não encontramos o que procura";
              }

              res.Categories.forEach(e => {
                e['Active'] = false;
                this.searchCategories.push(e)
              });


              this.sortArrayISODate(res.Documents).forEach(e => {
                e['appName'] = 'ArquivoDespachoElect'
                this.searchDocuments.push(e)
              });

              this.reorderList(this.ordinance);

              // hide show document
              if (this.searchDocuments.length >= 1) {
                this.showDocuments = true;
              } else {
                this.showDocuments = false
              }

              this.loadWordCloud();
              counter++;

              if (counter == 2) {
                this.showLoader = false;
              }
            }, error => {
              counter++;

              if (counter == 2) {
                this.showLoader = false;
              }
              this.searchResult = "Registo não encontrado"

              console.log('application 361 failed', error);
            });
        }

      } else {
        let counter = 0;

        if (this.searchSubject.trim() == "" && searchDocumentDate == null && searchDocumentDateEnd == null && this.searchSenderId == null && this.searchOrganicEntiryCode == null && this.searchDocTypeId == null) {
          this.searchResult = "Campo de pesquisa esta vazio, entre com assunto que deseja pesquisa";
          console.log('enter text');

        } else {
          this.showLoader = true;
          this.search.basicSearch(this.searchSubject, searchDocumentDate, searchDocumentDateEnd, this.searchSenderId
            , this.searchOrganicEntiryCode, this.searchDocTypeId, '8').subscribe(res => {
              if (!res.Categories.length) {
                this.searchResult = "Não encontramos o que procura";
              }
              res.Categories.forEach(e => {
                e['Active'] = false;
                this.searchCategories.push(e)
              });

              // bind respose

              this.sortArrayISODate(res.Documents).forEach(e => {
                e['appName'] = 'Correspondencia'
                this.searchDocuments.push(e)
              });

              this.reorderList(this.ordinance);

              // hide show document
              if (this.searchDocuments.length >= 1) {
                this.showDocuments = true;
              } else {
                this.showDocuments = false
              }

              counter++;

              if (counter == 2) {
                this.showLoader = false;
              }

              this.loadWordCloud();
            }, error => {
              console.log('application 8 failed', error)
              this.searchResult = "Registo não encontrado"
              counter++;

              if (counter == 2) {
                this.showLoader = false;
              }
            });
        }
        if (this.searchSubject.trim() == "" && searchDocumentDate == null && searchDocumentDateEnd == null && this.searchSenderId == null && this.searchOrganicEntiryCode == null && this.searchDocTypeId == null) {
          this.searchResult = "Campo de pesquisa esta vazio, entre com assunto que deseja pesquisa";
          console.log('enter text');

        } else {
          this.showLoader = true;
          this.search.searchForDoc(this.searchSubject, searchDocumentDate, this.searchSenderId
            , this.searchOrganicEntiryCode, this.searchDocTypeId, '361').subscribe(res => {
              if (!res.Categories.length) {
                this.searchResult = "Não encontramos o que procura";
              }

              res.Categories.forEach(e => {
                e['Active'] = false;
                this.searchCategories.push(e)
              });


              this.sortArrayISODate(res.Documents).forEach(e => {
                e['appName'] = 'ArquivoDespachoElect'
                this.searchDocuments.push(e)
              });

              this.reorderList(this.ordinance);

              // hide show document
              if (this.searchDocuments.length >= 1) {
                this.showDocuments = true;
              } else {
                this.showDocuments = false
              }


              counter++;

              if (counter == 2) {
                this.showLoader = false;
              }
              this.loadWordCloud();
            }, error => {
              this.searchResult = "Registo não encontrado"
              counter++;

              console.log('application 361 faield', error);
              if (counter == 2) {
                this.showLoader = false;
              }
            });
        }
      }
    }


    else if (this.type == "Correspondencia") {

      this.searchCategories = [];
      this.searchDocuments = [];
      this.showSearchDocuments = [];

      if (this.select) {

        let counter = 0;
        if (this.searchSubject.trim() == "" && searchDocumentDate == null && searchDocumentDateEnd == null && this.searchSenderId == null && this.searchOrganicEntiryCode == null && this.searchDocTypeId == null) {
          this.searchResult = "Campo de pesquisa esta vazio, entre com assunto que deseja pesquisa";
          console.log('enter text');

        } else {
          this.showLoader = true;
          this.search.searchForDoc(this.searchSubject, searchDocumentDate, this.searchSenderId
            , this.searchOrganicEntiryCode, this.searchDocTypeId, '8').subscribe(res => {
              if (!res.Categories.length) {
                this.searchResult = "Não encontramos o que procura";
              }

              res.Categories.forEach(e => {
                e['Active'] = false;
                this.searchCategories.push(e)
              });

              // bind respose

              this.sortArrayISODate(res.Documents).forEach(e => {
                e['appName'] = 'Correspondencia'
                this.searchDocuments.push(e)
              });

              this.reorderList(this.ordinance);

              // hide show document
              if (this.searchDocuments.length >= 1) {
                this.showDocuments = true;
              } else {
                this.showDocuments = false
              }
              counter++;

              if (counter == 2) {
                this.showLoader = false;
              }
              this.showLoader = false;
              this.loadWordCloud();
            }, error => {
              this.searchResult = "Registo não encontrado"
              console.log(error)
            });
        }

      } else {
        let counter = 0;
        if (this.searchSubject.trim() == "" && searchDocumentDate == null && searchDocumentDateEnd == null && this.searchSenderId == null && this.searchOrganicEntiryCode == null && this.searchDocTypeId == null) {
          this.searchResult = "Campo de pesquisa esta vazio, entre com assunto que deseja pesquisa";
          console.log('enter text');

        } else {
          this.showLoader = true;
          this.search.basicSearch(this.searchSubject, searchDocumentDate, searchDocumentDateEnd, this.searchSenderId
            , this.searchOrganicEntiryCode, this.searchDocTypeId, '8').subscribe(res => {
              if (!res.Categories.length) {
                this.searchResult = "Não encontramos o que procura";
              }

              res.Categories.forEach(e => {
                e['Active'] = false;
                this.searchCategories.push(e)
              });

              // bind respose

              this.sortArrayISODate(res.Documents).forEach(e => {
                e['appName'] = 'Correspondencia'
                this.searchDocuments.push(e)
              });

              this.reorderList(this.ordinance);

              // hide show document
              if (this.searchDocuments.length >= 1) {
                this.showDocuments = true;
              } else {
                this.showDocuments = false
              }

              counter++;

              if (counter == 2) {
                this.showLoader = false;
              }

              this.loadWordCloud();
            }, error => {
              counter++;

              if (counter == 2) {
                this.showLoader = false;
              }
              this.searchResult = "Registo não encontrado"
              console.log(error)
            });
        }
      }
    }

    else if (this.type == "AccoesPresidenciais") {

      let counter = 0;
      this.searchCategories = []
      this.searchDocuments = []

      // this.search.basicSearchPublication(this.searchSubject, searchDocumentDate, this.searchSender
      //   ,this.searchOrganicEntiry, this.searchDocTypeId, '386').subscribe(res=>{


      //   res.Categories.forEach( e => {
      //     e['Active'] = false;
      //     e['Name'] = "Publicações"
      //   });

      //   // bind respose
      //   for ( const a of res.Categories) {
      //     this.searchCategories.push(a);
      //   }

      //   for ( const a of this.sortArrayISODate(res.Documents)) {
      //     a.DocTypeDesc = "Publicações"
      //     this.searchDocuments.push(a);
      //   }

      //   this.reorderList(this.ordinance);

      //   // hide show document
      //   if(this.searchDocuments.length >= 1){
      //     this.showDocuments = true;
      //   } else {
      //     this.showDocuments = false
      //   }

      //   counter++;

      //   if(counter ==2){
      //     this.showLoader = false;
      //   }

      //   this.loadWordCloud();
      // }, error => {
      //   counter++;

      //   if(counter == 2) {
      //     this.showLoader = false;
      //   }
      //   this.searchResult = "Registo não encontrado"
      //   console.log(error)
      // });


      if (this.searchSubject.trim() == "" && searchDocumentDate == null && searchDocumentDateEnd == null && this.searchSenderId == null && this.searchOrganicEntiryCode == null && this.searchDocTypeId == null) {
        this.searchResult = "Campo de pesquisa esta vazio, entre com assunto que deseja pesquisa";
        console.log('enter text');

      } else {
        this.showLoader = true;
        this.search.basicSearchPublication(this.searchSubject, searchDocumentDate, searchDocumentDateEnd, this.searchSenderId
          , this.searchOrganicEntiryCode, this.searchDocTypeId, '386').subscribe(res => {
            if (!res.Categories.length) {
              this.searchResult = "Não encontramos o que procura";
            }


            res.Categories.forEach(e => {
              e['Active'] = false;
              e['Name'] = "Acções"
            });

            if (!environment.presidential) {
              // bind respose
              for (const a of res.Categories) {
                this.searchCategories.push(a);
              }
            }



            for (const a of this.sortArrayISODate(res.Documents)) {
              a.DocTypeDesc = "Acções"
              this.searchDocuments.push(a);
            }

            this.reorderList(this.ordinance);

            // hide show document
            if (this.searchDocuments.length >= 1) {
              this.showDocuments = true;
            } else {
              this.showDocuments = false
            }

            counter++;

            this.showLoader = false;
            this.loadWordCloud();
          }, error => {
            counter++;

            this.showLoader = false;

            this.searchResult = "Registo não encontrado"
            console.log(error)
          });
      }

    }

  }

  /**
   * @description set empty value to searchSubject
   */
  clearSearchInput() {
    this.searchSubject = ""

    this.searchCategories = [];
    this.searchDocuments = [];
    this.showDocuments = false;

    this.wordCloud();
  }

  /**
   * @param isoDate String Iso date
   * @returns date in format  dd/MM/yyyy
   * @description convert Iso date to dd/MM/yyyy
   */
  formateIsoDate(isoDate: string): string {
    const date = new Date(isoDate);
    return formatDate(date, 'dd/MM/yyyy', 'pt');
  }

  activeCategoty(i) {

    this.searchCategories.forEach((e, j) => {
      if (i != j) {
        e['Active'] = false;
      }
    })

    if (this.searchCategories[i]['Active']) {
      this.searchCategories[i]['Active'] = false;
    } else {
      this.searchCategories[i]['Active'] = true
    }
  }

  clearInputRemetente() {
    this.searchSenderdescription = "";
    this.searchSenderId = null;
  }



  clearInputOrganicEntity() {
    this.searchOrganicEntiryDescription = "";
    this.searchOrganicEntiryCode = null;
  }

  /**
   * @description Clean inputs
   */
  showHideAdvanceSearch(show: boolean) {
    this.showAdvanceSearch = show;
  }

  async openAdvanceSearchSelection() {

    let classs;
    if (window.innerWidth <= 1024) {
      classs = 'modal modal-desktop'
    } else {
      classs = 'search-desktop-modal search-submodal'
    }

    const modal = await this.modalController.create({
      component: SenderPage,
      cssClass: classs,
      componentProps: {
      }
    });



    modal.onDidDismiss().then((data) => {
      this.searchSenderdescription = data.data.Name;
      this.searchSenderId = data.data.Id
    });
    await modal.present();

  }


  async openOrganicEntitySelection() {

    let classs;
    if (window.innerWidth <= 1024) {
      classs = 'modal modal-desktop'
    } else {
      classs = 'search-desktop-modal search-submodal'
    }

    const modal = await this.modalController.create({
      component: OrganicEntityPage,
      cssClass: classs,
      componentProps: {
      }
    });


    modal.onDidDismiss().then((data) => {
      console.log('SerachEntity', data.data)
      this.searchOrganicEntiryDescription = data.data.Description;
      this.searchOrganicEntiryCode = data.data.Code;
    });
    await modal.present();


  }

  TodosCategory = true
  async filterDocList(categoryName: string) {

    // show all category
    if (this.showCategory == categoryName) {

      this.showSearchDocuments = this.searchDocuments;

    } else { // filter category
      this.showSearchDocuments = this.searchDocuments.filter((e) => e.DocTypeDesc == categoryName);
    }

    this.showCategory = categoryName;

    for (let category of this.searchCategories) {
      if (category['Active']) {
        this.TodosCategory = false
        return false
      }
    }

    this.TodosCategory = true

  }


  noFilter() {
    this.TodosCategory = true
    let n = 0
    for (let category of this.searchCategories) {
      this.searchCategories[n]['Active'] = false
      n++
    }
    this.showSearchDocuments = this.searchDocuments;
  }


  itemIcons(): string {
    return this.type
  }

  viewDocument() {
    const url: string = this.loadedAttachments.DocumentURL.replace("webTRIX.Viewer", "webTRIX.Viewer.Branch1");
    // const browser = this.iab.create(url,"_blank");
    // browser.show();
  }


  async viewDetail(searchDocument: SearchList) {

    const ApplicationType = searchDocument?.ApplicationType?.toString()
    const Id = searchDocument.Id
    const CalendarId = searchDocument?.ApplicationName?.split(':')[1]
    const DocTypeDesc = searchDocument.DocTypeDesc

    if (this.select == false) {
      if (this.type == "Agenda") {

        const modal = await this.modalCtrl.create({
          component: ViewEventPage,
          componentProps: {
            eventId: Id,
            CalendarId: CalendarId,
            isModal: true,
            header: false
          },
          cssClass: 'modal modal-desktop',
        });

        modal.onDidDismiss().then((res) => { });
        await modal.present();
      } else if (this.type == "AccoesPresidenciais") {

        this.viewPublicationDetail(Id, DocTypeDesc);
      }
      else if (this.type == "AccoesPresidenciais & ArquivoDespachoElect") {

        // 361
        if (ApplicationType == '8') {
          this.viewDocumentDetail(Id, ApplicationType);
        } else if (ApplicationType == '361') {
          this.viewDocumentModal(searchDocument)
        }
      }
    }
  }

  async viewPublicationDetail(publicationId: string, DocTypeDesc) {
    // validation my friend

    if (DocTypeDesc == 'Acções') {
      this.router.navigate(['/home/publications', publicationId]);
    } else if (DocTypeDesc == 'Publicações') {
      const modal = await this.modalController.create({
        component: PublicationDetailPage,
        componentProps: {
          publicationId: publicationId,
          isModal: true
        },
        cssClass: 'publication-detail modal modal-desktop ',
        //backdropDismiss: false
      });


      modal.onDidDismiss().then(() => { });
      await modal.present();
    }



  }

  async viewDocumentDetail(docId: string, applicationId: string) {
    let classs;

    if (window.innerWidth < 701) {
      classs = 'modal modal-desktop'
    } else {
      classs = 'modal modal-desktop showAsideOptions'
    }

    const modal = await this.modalController.create({
      component: DocumentDetailPage,
      componentProps: {
        docId: docId,
        applicationId: applicationId,
      },
      cssClass: classs,
    });

    modal.onDidDismiss().then((res) => { });
    await modal.present();


  }


  async viewDocumentModal(LoadedDocument) {

    let task = {
      serialNumber: '',
      taskStartDate: '',
      isEvent: true,
      workflowInstanceDataFields: {
        FolderID: '',
        Subject: LoadedDocument.SourceName || LoadedDocument.Assunto,
        SourceSecFsID: LoadedDocument.ApplicationId || LoadedDocument.ApplicationType,
        SourceType: 'DOC',
        SourceID: LoadedDocument.Id || LoadedDocument.Id,
        DispatchNumber: ''
      }
    }

    const modal = await this.modalController.create({
      component: ViewDocumentPage,
      componentProps: {
        trustedUrl: '',
        file: {
          title: task.workflowInstanceDataFields.Subject,
          url: '',
          title_link: '',
        },
        Document: LoadedDocument,
        applicationId: task.workflowInstanceDataFields.SourceSecFsID,
        docId: task.workflowInstanceDataFields.SourceID,
        folderId: '',
        task: task
      },
      cssClass: 'modal modal-desktop'
    });
    await modal.present();
  }

  nextButton(event) {
    event.preventDefault();
    this.pageNumber++;
    this.basicSearch()
  }

  previeweButton(event) {
    event.preventDefault();
    if (this.pageNumber > 1) {
      this.pageNumber--;
      this.basicSearch()
    }
  }

}

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable, from, of, forkJoin } from 'rxjs';
import { switchMap, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { BackgroundService } from 'src/app/services/background.service';
import { EventsService } from 'src/app/services/events.service';


@Injectable({
  providedIn: 'root'
})
export class OfflineManagerService {
  /* eventservice: EventsService */


  constructor(
    private storage: Storage,
    /* private backgroundservice: BackgroundService */) { }

  synchnize() {
   /*  this.backgroundservice.registerBackService('Online', () => {
      this.storage.get('eventEdit').then((req) => {
        JSON.parse(req).forEach(element => {
          this.eventservice.editEvent(element, 2, 3).subscribe((res) => {
            this.storage.remove('eventEdit')
            
          })
        });
      })
    }) */
  }

  storeRequestData(key, data) {
    return this.storage.set(key, JSON.stringify(data));
  }

  sendRequestData(key) {
    return this.storage.get(key)
  }

}
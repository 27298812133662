import { Injectable } from '@angular/core';
import { customTask, fullTaskList } from '../models/dailyworktask.model';
import { AttachmentList } from '../models/Excludetask';
import { CustomTaskPipe } from '../pipes/custom-task.pipe';
import { ProcessesService } from '../services/processes.service';
import { DespachoStore } from '../store/despacho-store.service';
import { LoaderService } from 'src/app/store/loader.service'
import { SessionStore } from '../store/session.service';
import { PermissionService } from '../services/permission.service';
import { SortService } from '../services/functions/sort.service';


@Injectable({
  providedIn: 'root'
})
export class DespachoService {
  /**
   * @description para terminar o
   * 95 - signfica
   * 98 - significa
   */
  ActionTypeId: 95 | 98

  action: "Arquivo" | "Reencaminhar"

  actions: {
    'Tarefa do Despacho': 'Tarefa de Despacho' | 'Concluir' | 'rexecucao'
  }

  customTaskPipe = new CustomTaskPipe()
  despachoStore = DespachoStore;
  loader = this.LoaderService.initialize("DespachoService")

  constructor(
    private processes: ProcessesService,
    public p: PermissionService,
    public LoaderService: LoaderService,
    private sortService: SortService,
  ) { }

  arquivar(note: string, documents: AttachmentList, serialnumber) {

    let body = {
      "serialNumber": serialnumber,
      "action": "Arquivo",
      "ActionTypeId": 95,
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList": documents,
    }

    return this.processes.CompleteTask(body)
  }

  executado(note: string, documents: any, serialnumber) {

    let body = {
      "serialNumber": serialnumber,
      "action": "Conhecimento",
      "ActionTypeId": 104,
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList": documents,
    }

    return this.processes.CompleteTask(body)
  }

  solicitarParecer({ note, documents = [], serialnumber, activityInstanceName }) {
    let body;
    if (activityInstanceName == 'Concluir Despacho'
      || activityInstanceName == 'Concluir Parecer'
      || activityInstanceName == 'Concluir Deferimento'
      || activityInstanceName == 'Tarefa de Deferimento'
    ) {
      body = {
        "serialNumber": serialnumber,
        "action": "Parecer",
        "ActionTypeId": 92,
        "dataFields": {
          "ReviewUserComment": note,
        },
        "AttachmentList": documents,
      }

    }
    else {
      body = {
        "serialNumber": serialnumber,
        "action": "Conhecimento",
        "ActionTypeId": 92,
        "dataFields": {
          "ReviewUserComment": note,
        },
        "AttachmentList": documents,
      }

    }

    return this.processes.CompleteTask(body)
  }

  EfectuarDespacho({ note, documents = [], serialnumber, activityInstanceName }) {
    console.log('activityInstanceName', activityInstanceName)
    let body;
    if (activityInstanceName == 'Concluir Despacho'
      || activityInstanceName == 'Concluir Parecer'
      || activityInstanceName == 'Concluir Deferimento'
      || activityInstanceName == 'Tarefa de Deferimento'
      || activityInstanceName == 'Diploma Assinado'
    ) {
      body = {
        "serialNumber": serialnumber,
        "action": "Despacho",
        "ActionTypeId": 94,
        "dataFields": {
          "ReviewUserComment": note,
        },
        "AttachmentList": documents,
      }
    } else if (activityInstanceName == 'Revisar Diploma') {
      body = {
        "serialNumber": serialnumber,
        "action": "Aprovar",
        "ActionTypeId": 94,
        "dataFields": {
          "ReviewUserComment": note,
        },
        "AttachmentList": documents,
      }
    } else {
      body = {
        "serialNumber": serialnumber,
        "action": "Conhecimento",
        "ActionTypeId": 94,
        "dataFields": {
          "ReviewUserComment": note,
        },
        "AttachmentList": documents,
      }
    }


    return this.processes.CompleteTask(body)
  }

  reexecute({ note, documents, serialnumber }) {
    let body = {
      "serialNumber": serialnumber,
      "action": "Reexecução",
      "ActionTypeId": 100000010,
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList": documents,
    }

    return this.processes.CompleteTask(body)
  }

  /**
   * @description efetuar despacho
   */
  CompleteTask({ serialNumber, note }) {

    const body = {
      "serialNumber": serialNumber,
      "action": "Despacho",
      "ActionTypeId": 94,
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList": null,
    }

    return this.processes.CompleteTask(body)
  }


  // Finalizar({serialNumber}) {
  //   const body = {
  //     "serialNumber": serialNumber,
  //     "action": "Conhecimento",
  //     "ActionTypeId": 104,
  //     "dataFields": {
  //       "ReviewUserComment": '',
  //     },
  //     "AttachmentList" :null,
  //   }
  // }

  sendExpedienteToPending(serialnumber) {
    return this.processes.SetTaskToPending(serialnumber)
  }

  /**
   * @param body any
   * @returns promise
   * @description for both profile PR and MDGPR
   */
  createDespacho(body: any) {
    if (this.p.userRole(['PR'])) {
      return this.processes.postDespatchoPr(body)
    } else {
      return this.processes.postDespatcho(body)
    }
  }

  createParecer(body: any) {
    if (this.p.userRole(['PR'])) {
      return this.processes.postParecerPr(body)
    } else {
      return this.processes.postParecer(body)
    }
  }

  generateDiploma({ note = '', documents = [], serialnumber }) {
    let body = {
      "serialNumber": serialnumber,
      "action": "Reencaminhar",
      "ActionTypeId": 99999839,
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList": documents,
    }

    return this.processes.CompleteTask(body)
  }


  async getList({ updateStore = false }) {

    if (this.LoaderService.loading) {
      return this.despachoStore.list
    }

    this.LoaderService.push({})
    // this.loader.push()

    let result: fullTaskList[] = []
    let despachoList: customTask[] = [];

    try {
      result = await this.processes.GetTasksList("Despacho", false).toPromise();
      result = result.filter((data: fullTaskList) => data.workflowInstanceDataFields.Status == "Active")
      result.forEach((element, index) => {

        let task: customTask = this.customTaskPipe.transform(element);
        despachoList.push(task);

      })

      despachoList = this.sortService.sortDate(despachoList, 'CreateDate')

      if (updateStore) {
        this.despachoStore.reset(despachoList);
      }

    } catch (error) {

    } finally {
      this.LoaderService.pop({})
      // this.loader.pop()
      return this.despachoStore.list
    }

  }

  async getListPr() {

    // this.loader.push()
    let result
    try {
      result = await this.processes.GetTasksList("Despacho do Presidente da República", false).toPromise();

    } catch (error) {
      // this.loader.pop()
    }

    // this.loader.pop()

    let despachosPr;
    let despachos = this.sortService.sortArrayByDate(result);

    if (SessionStore.user.Profile != 'PR') {
      despachosPr = despachos.reverse().filter(data => data.activityInstanceName == "Tarefa de Despacho");
    } else if (SessionStore.user.Profile == 'PR') {
      despachosPr = despachos.reverse().filter(data => data.activityInstanceName == "Concluir Despacho");
    }

  }

}

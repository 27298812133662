import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';


export type EventDeleteRecurrenceComponentReturn = 'DeleteAll' | 'DeleteOne' | 'Cancel'

@Component({
  selector: 'app-delete-event-recurrence',
  templateUrl: './delete-event-recurrence.page.html',
  styleUrls: ['./delete-event-recurrence.page.scss'],
})
export class DeleteEventRecurrencePage implements OnInit {

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }



  close(data: EventDeleteRecurrenceComponentReturn) {
    this.modalController.dismiss(data)
  }
}

import { Component, OnInit } from '@angular/core';
import {PopoverController } from '@ionic/angular';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-searched-document-options',
  templateUrl: './searched-document-options.page.html',
  styleUrls: ['./searched-document-options.page.scss'],
})
export class SearchedDocumentOptionsPage implements OnInit {

  task: any
  LoadedDocument: any

  constructor(
    private popoverController: PopoverController,
    public p: PermissionService
  ) {}

  ngOnInit() {}

  close() {
    this.popoverController.dismiss();
  }

  goBack() {
    this.popoverController.dismiss();
  }

  async openBookMeetingModal() {
    this.popoverController.dismiss({component:'openBookMeetingModal', taskAction: ''});
  }

  async openExpedientActionsModal( taskAction: any) {
    this.popoverController.dismiss({component:'openExpedientActionsModal', taskAction});
  }

  async distartExpedientModal(body:any) {}

}

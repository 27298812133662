
<ion-content>
  <div class="main-content d-flex height-100">
    <div *ngIf="LoadedDocument" class="content d-flex flex-column">
      <div class="main-header">
        <div class="title-content width-100 d-flex align-center">
          <app-btn-modal-dismiss></app-btn-modal-dismiss>
          <div class="middle flex-grow-1">
            <ion-label class="title">{{LoadedDocument.Assunto}}</ion-label>
          </div>
          <div class="div-icon" (click)="openOptions()">
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' " class="font-25 cursor-pointer" src="assets/images/icons-menu.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " class="font-25 cursor-pointer" src="assets/images/theme/gov/icons-menu.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " class="font-25 cursor-pointer" src="assets/images/theme/{{ThemeService.currentTheme}}/icons-menu.svg"></ion-icon>
            <!-- <ion-icon class="font-25 cursor-pointer" src="assets/images/theme/gov/icons-menu.svg"></ion-icon> -->
          </div>
        </div>
      </div>
      <div class="upper-content">
        <div class="content-details">
          <ion-label>
            <p><span class="date">{{customDate}}</span><span class="label" *ngIf="LoadedDocument.Nature">{{LoadedDocument.Nature}}</span></p>
          </ion-label>
        </div>
      </div>
      <div class="line"></div>
      <div class="middle-content">
        <h5 *ngIf="LoadedDocument.ApplicationId == 361 || LoadedDocument.ApplicationID == 361" >Intervenientes</h5>
        <h5 *ngIf="LoadedDocument.ApplicationId == 8 || LoadedDocument.ApplicationID == 8" >Remetente</h5>

        <ion-item class="ion-no-margin ion-no-padding">
          <ion-label>
            <div>
              <p>{{LoadedDocument.Interveners || LoadedDocument.Sender}}</p>
            </div>
          </ion-label>
        </ion-item>
        <h5 *ngIf="LoadedDocument.ApplicationId == 361 || LoadedDocument.ApplicationID == 361">Status</h5>
        <ion-item *ngIf="LoadedDocument.ApplicationId == 361 || LoadedDocument.ApplicationID == 361" class="ion-no-margin ion-no-padding">
          <p >{{LoadedDocument.Status}}</p>
        </ion-item>
      </div>
      <div class="bottom-content width-100" *ngIf="LoadedDocument">
        <ion-list>
          <h5 class="font-17-rem">Documentos Anexados</h5>
          <ion-item class="ion-no-margin ion-no-padding">

          <ion-label
            (click)="viewDocument()"
            *ngIf="LoadedDocument.ApplicationId == 8 || LoadedDocument.ApplicationID == 8"
          >

            <div class="attach-title-item">
              {{LoadedDocument.Assunto || 'Sem título'}}
            </div>
            <div class="d-flex justify-between" style="justify-content: space-between">
              <span class="span-left">{{ LoadedDocument.Sender }}</span>
              <span class="span-right">{{ LoadedDocument.DocDate | date: 'dd-MM-yyyy HH:mm' }}</span>
            </div>

          </ion-label>

          <ion-label
            *ngIf="LoadedDocument.ApplicationId == 361 || LoadedDocument.ApplicationID == 361"
          >
            <div *ngFor="let Document of LoadedDocument.Documents; let i = index">
              <div class="attach-title-item" (click)="docIndex(i);viewDocumentModal()">
                {{Document.Assunto}}
              </div>
              <div *ngIf="Document.Sender" class="d-flex justify-between" style="justify-content: space-between">
                <span class="span-left">{{ Document.Sender }}</span>
                <span class="span-right">{{ Document.DocDate | date: 'dd-MM-yyyy HH:mm' }}</span>
              </div>
            </div>
        </ion-label>

        </ion-item>
        </ion-list>
      </div>
    </div>
    <div *ngIf="LoadedDocument" class="aside-right flex-column height-100">
      <div class="buttons">
        <button (click)="openExpedientActionsModal('0')" class="btn-cancel" shape="round" >Efetuar Despacho</button>
        <div class="solid"></div>
        <button (click)="openExpedientActionsModal('1')"  class="btn-cancel" shape="round" >Solicitar Parecer</button>
        <button *ngIf="!p.userRole(['PR']) && !p.userPermission([p.permissionList.Gabinete.pr_tasks])" (click)="openExpedientActionsModal('2')" class="btn-cancel" shape="round" >Pedido de Deferimento</button>
        <button (click)="openBookMeetingModal()" class="btn-cancel" shape="round" >Marcar Reunião</button>
      </div>
    </div>

    <div hidden>
      <ion-list>
        <ion-list-header>
          <ion-label>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          </ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
            </h3>
            <p><ion-skeleton-text animated style="width: 80%"></ion-skeleton-text></p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
        <button color="medium" shape="round" expand="block">
          <!-- <ion-icon color="medium" name="attach" slot="start"></ion-icon> -->
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </button>
      </ion-list>
    </div>
  </div>
</ion-content>

import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { meter, RequestCounter } from '../services/monitoring/opentelemetry/matrix';


@Injectable()
export class MetricsInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // Capture the status code and check protocol
          if (req.method !== 'GET' && !req.urlWithParams.includes('metrics')) {

            console.log('response', event.body)
            const path = req.urlWithParams;
            const url = new URL(path);
            if (window.location.protocol !== 'https:') {
              let attributes = { path: url.pathname, method: req.method };
              const statusCode = event.status;
              const extendedAttributes = { ...attributes, status: statusCode };
              RequestCounter.add(1, extendedAttributes);
            }
          }
        }
      })
    );
  }
}

export const metricsInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: MetricsInterceptor,
  multi: true
};

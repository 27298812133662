import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef, } from '@angular/core';
import { ModalController, NavParams, PopoverController, Platform } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { GroupContactsPage } from './group-contacts/group-contacts.page';
import { Router } from '@angular/router'
import { EditGroupPage } from '../edit-group/edit-group.page';
import { TimeService } from 'src/app/services/functions/time.service';
import { FileService } from 'src/app/services/functions/file.service';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from 'src/environments/environment';
import { EventPerson } from 'src/app/models/eventperson.model';
import { ViewDocumentPage } from 'src/app/modals/view-document/view-document.page';
import { ThemeService } from 'src/app/services/theme.service'
// import { ViewEventPage } from 'src/app/modals/view-event/view-event.page';
import { ViewEventPage } from 'src/app/modals/view-event/view-event.page';
import { FileType } from 'src/app/models/fileType';
import { Storage } from '@ionic/storage';

import { CameraService } from 'src/app/services/camera.service';
import { SearchPage } from 'src/app/pages/search/search.page';
import { VoiceRecorder, RecordingData, GenericResponse } from 'capacitor-voice-recorder';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { DomSanitizer } from '@angular/platform-browser';
// import { MessageService } from 'src/app/services/chat/message.service';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { SessionStore } from 'src/app/store/session.service';
import { ViewMediaPage } from 'src/app/modals/view-media/view-media.page';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { RouteService } from 'src/app/services/route.service';
import { FileValidatorService } from "src/app/services/file/file-validator.service"
import { sanitize } from "sanitize-filename-ts";
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { NewEventPage } from 'src/app/pages/agenda/new-event/new-event.page';
import { ChatPopoverPage } from '../chat-popover/chat-popover.page';
import { ChatOptionsPopoverPage } from '../chat-options-popover/chat-options-popover.page';

@Component({
  selector: 'app-group-messages',
  templateUrl: './group-messages.page.html',
  styleUrls: ['./group-messages.page.scss'],
})
export class GroupMessagesPage implements OnInit, AfterViewInit, OnDestroy {

  showLoader: boolean;
  isGroupCreated: boolean;
  loggedUser: any;

  room: any;
  roomName: any;
  members: any = []
  contacts: string[] = [" Ana M.", "Andre F.", "Bruno G.", "Catarina T", "Tiago"];

  roomId: string;
  loggedUserChat: any;
  eventSelectedDate: Date = new Date();

  scrollingOnce: boolean = true;
  private scrollChangeCallback: () => void;
  currentPosition: any;
  startPosition: number;

  capturedImage: any;
  capturedImageTitle: any;
  attendees: EventPerson[] = [];
  scrollToBottomBtn = false;

  longPressActive = false;
  showMessageOptions = false;
  selectedMsgId: string;
  roomCountDownDate: any;
  downloadFile: any;
  groupNameFormart = "";

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  recording = false;
  allowTyping = true;
  storedFileNames = [];
  lastAudioRecorded = '';
  audioRecorded: any = "";
  audioDownloaded: any = "";
  durationDisplay = '';
  duration = 0;
  showAvatar = true;
  audioPermissionStatus: 'granted' | 'denied' | 'prompt' | null = null
  sessionStore = SessionStore
  isAdmin = false;
  constructor(
    private modalController: ModalController,
    public popoverController: PopoverController,
    private timeService: TimeService,
    private fileService: FileService,
    private toastService: ToastService,
    public ThemeService: ThemeService,
    private changeDetectorRef: ChangeDetectorRef,
    private platform: Platform,
    //public ChatSystemService: ChatSystemService,
    private storage: Storage,
    private sanitiser: DomSanitizer,
    private file: File,
    private fileOpener: FileOpener,
    public RouteService: RouteService,
    private FileValidatorService: FileValidatorService,
  ) {
    // this.ChatSystemService.getUser()

    // this.loggedUserChat = SessionStore.user.ChatData['data'];
    // this.isGroupCreated = true;
    // this.roomId = this.navParams.get('roomId');
    // window.onresize = (event) => {
    //   if (window.innerWidth > 701) {
    //     this.modalController.dismiss();
    //   }
    // };

    // this.open();

    // setTimeout(() => {
    //   this.scrollToBottomClicked()
    // }, 50)


    // this.showAvatar = false

    // setTimeout(() => {
    //   this.scrollToBottomClicked()
    //   this.showAvatar = true
    // }, 150)

  }



  open() {
    // try {
    //   this.ChatSystemService.getGroupRoom(this.roomId).loadHistory({});
    //   this.ChatSystemService.getGroupRoom(this.roomId).scrollDown = this.scrollToBottomClicked;
    //   this.ChatSystemService.openRoom(this.roomId)
    //   this.groupNameFormart = this.ChatSystemService.getGroupRoom(this.roomId).name.split('-').join(' ')
    // } catch (error) {
    //   setTimeout(() => {
    //     this.open()
    //   }, 3000)
    // }
  }


  ngOnInit() {

    // this.loggedUser = this.loggedUserChat;
    // this.getRoomInfo();
    // this.scrollToBottom();
    // this.serverLongPull();
    // this.setStatus('online');
    // this.getChatMembers();

    // // this.getRoomMessageDB(this.roomId);
    // this.getGroupContacts({});

  }

  setStatus(status: string) {
    let body = {
      message: '',
      status: status,
    }
    // this.chatService.setUserStatus(body).subscribe(res => {
    //
    // })
  }

  deleteMessage(msgId: string) {
    // const room = this.ChatSystemService.getGroupRoom(this.roomId)
    // this.alertService.confirmDeleteMessage(msgId, room);
  }

  ngAfterViewInit() {
    this.scrollChangeCallback = () => this.onContentScrolled(event);
    window.addEventListener('scroll', this.scrollChangeCallback, true);

    if (this.room?.customFields?.countDownDate) {
      this.roomCountDownDate = this.timeService.countDownDate(this.room.customFields.countDownDate, this.room._id);
    }
  }

  handlePress(id?: string) {
    this.selectedMsgId = id;
    this.showMessageOptions = true;
  }

  handleClick() {
    this.showMessageOptions = false;
    this.selectedMsgId = "";
  }

  onContentScrolled(e) {
    this.startPosition = e.srcElement.scrollTop;
    let scroll = e.srcElement.scrollTop;
    let windowHeight = e.srcElement.scrollHeight;
    let containerHeight = windowHeight - e.srcElement.clientHeight;

    if (scroll > this.currentPosition) {
    } else {
      this.scrollingOnce = false;
    }
    if ((containerHeight - 100) > scroll) {
      this.scrollToBottomBtn = true;
    }
    else {
      this.scrollToBottomBtn = false;
    }
    this.currentPosition = scroll;
  }

  calculateDuration() {
    if (!this.recording) {
      this.duration = 0;
      this.durationDisplay = '';
      return;
    }
    this.duration += 1;
    const minutes = Math.floor(this.duration / 60);
    const seconds = (this.duration % 60).toString().padStart(2, '0');
    this.durationDisplay = `${minutes}:${seconds}`;

    setTimeout(() => {
      this.calculateDuration();
    }, 1000)
  }

  async getFile(fileName?: any) {
    const audioFile = await Filesystem.readFile({
      path: fileName,
      directory: Directory.Data
    })
    //
    const base64sound = audioFile.data;

    //Converting base64 to blob
    const base64 = await fetch(base64sound);
    //

    const base64Response = await fetch(`data:audio/ogg;base64,${base64sound}`);
    //

    this.audioRecorded = base64Response.url;



  }

  async loadFiles() {

    this.storage.get('fileName').then((fileName) => {
      this.lastAudioRecorded = fileName;
    })

    try {
      this.storage.get('recordData').then((recordData) => {

        if (recordData?.value?.recordDataBase64.includes('data:audio')) {
          this.audioRecorded = this.sanitiser.bypassSecurityTrustResourceUrl(recordData?.value?.recordDataBase64);
        }
        else if (recordData?.value?.mimeType && recordData?.value?.recordDataBase64) {
          this.audioRecorded = this.sanitiser.bypassSecurityTrustResourceUrl(`data:${recordData.value.mimeType};base64,${recordData?.value?.recordDataBase64}`);
        }
      });
    } catch (error) { }


  }

  stopRecording() {
    this.deleteRecording();
    this.allowTyping = false;

    if (!this.recording) {
      return;
    }
    this.recording = false;
    VoiceRecorder.stopRecording().then(async (result: RecordingData) => {


      this.recording = false;
      if (result.value && result.value.recordDataBase64) {
        const recordData = result.value.recordDataBase64;
        //
        const fileName = new Date().getTime() + ".mp3";
        //Save file
        await this.storage.set('fileName', fileName)
        this.storage.set('recordData', result).then(() => {


          setTimeout(async () => {
            this.loadFiles();
          }, 1000);
        })
      }
    })

  }

  async startRecording() {
    VoiceRecorder.requestAudioRecordingPermission();
    if (await VoiceRecorder.canDeviceVoiceRecord().then((result: GenericResponse) => { return result.value })) {
      if (await VoiceRecorder.requestAudioRecordingPermission().then((result: GenericResponse) => { return result.value })) {
        //if(await this.hasAudioRecordingPermission()){
        if (this.recording) {
          return;
        }
        this.recording = true;
        VoiceRecorder.startRecording();
        this.calculateDuration();
        //}
      }
      else {
        this.toastService._badRequest('Para gravar uma mensagem de voz, permita o acesso do Gabinete Digital ao seu microfone.');
      }
    }
    else {
      this.toastService._badRequest('Este dispositivo não tem capacidade para gravação de áudio!');
    }
  }



  async deleteRecording() {
    this.storage.remove('fileName');
    this.storage.remove('recordData');

    this.allowTyping = true;
    this.lastAudioRecorded = '';
    this.loadFiles();
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollChangeCallback, true);
  }

  scrollToBottom(): void {
    try {
      if (this.scrollingOnce) {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        //this.scrollingOnce = false;
      }
    } catch (err) { }
  }

  scrollToBottomClicked = () => {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  async getRoomInfo() {
    this.showLoader = true;
    // this.chatService.getRoomInfo(this.roomId).subscribe(room => {
    //   this.room = room['room'];
    //   this.roomName = this.room.name.split('-').join(' ');
    //   if (this.room.customFields.countDownDate) {
    //     this.roomCountDownDate = this.timeService.countDownDateTimer(this.room.customFields.countDownDate, this.room._id);
    //   }
    //   this.getGroupContacts(this.room);
    //   this.showLoader = false;
    // });
    // this.ChatSystemService.getGroupRoom(this.roomId).loadHistory({});
    // let room = await this.chatService.getRoomInfo(this.roomId).toPromise();
    // // console.log('ROOM',room)
    // this.room = room['room'];
    // if (this.room.name) {
    //   try {
    //     this.roomName = this.room.name.split('-').join(' ');
    //   } catch (error) {
    //     this.roomName = this.room.name;
    //   }

    // }


    // if (SessionStore.user.ChatData.data.userId == this.room.u._id) {
    //   this.isAdmin = true
    // } else {
    //   this.isAdmin = false
    // }

    // if (this.room.customFields.countDownDate) {
    //   this.roomCountDownDate = this.room.customFields.countDownDate;
    // }
  }

  async getChatMembers() {
    // if(this.ChatSystemService.users.length == 0) {
    //   this.ChatSystemService.getUser()
    // }
  }

  /* load(){
    this.getGroupContacts();
    this.loadGroupMessages();
  } */

  close() {
    this.modalController.dismiss();
    this.deleteRecording();
  }

  doRefresh(ev: any) {
    this.getRoomInfo();
    ev.target.complete();
  }

  getGroupContacts(room: any) {
    // this.showLoader = true;
    // //If group is private call getGroupMembers
    // if (this.room.t === 'p') {
    //   this.chatService.getGroupMembers(this.roomId).subscribe(res => {

    //     this.members = res['members'];
    //     this.showLoader = false;
    //   });
    // }
    // //Otherwise call getChannelMembers for públic groups
    // else {
    //   this.chatService.getChannelMembers(this.roomId).subscribe(res => {

    //     this.members = res['members'];
    //     this.showLoader = false;
    //   });
    // }

    // this.members = this.ChatSystemService.getGroupRoom(this.roomId).members
  }


  showDateDuration(start: any) {
    return this.timeService.showDateDuration(start);
  }
  countDownDate(date: any, roomId: string) {
    this.roomCountDownDate = this.timeService.countDownDate(date, roomId);
    return this.timeService.countDownDateTimer(date, roomId);
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  sendMessage() {
    // this.ChatSystemService.getGroupRoom(this.roomId).send({})
  }


  async sendAudio(fileName) {

    const roomId = this.roomId
    let audioFile;
    this.storage.get('recordData').then(async (recordData) => {

      audioFile = recordData;
      if (recordData?.value?.recordDataBase64.includes('data:audio')) {
        this.audioRecorded = recordData?.value?.recordDataBase64;
      }
      else if (recordData?.value?.mimeType && recordData?.value?.recordDataBase64) {
        this.audioRecorded = `data:${recordData.value.mimeType};base64,${recordData?.value?.recordDataBase64}`;
      }

      //Converting base64 to blob
      const encodedData = btoa(this.audioRecorded);
      const blob = this.fileService.base64toBlob(encodedData, recordData.value.mimeType)

      const formData = new FormData();
      formData.append("blobFile", blob);

      // this.ChatSystemService.getGroupRoom(roomId).send({
      //   file: {
      //     "type": "application/audio",
      //     "msDuration": audioFile.value.msDuration,
      //     "mimeType": audioFile.value.mimeType,
      //   },
      //   attachments: [{
      //     "title": sanitize(fileName),
      //     "title_link_download": true,
      //     "type": "audio"
      //   }],
      //   temporaryData: formData,
      //   attachmentsModelData: {
      //     fileBase64: encodedData,
      //   }
      // })

    });
    this.deleteRecording();
  }


  async openOptions() {
    const modal = await this.popoverController.create({
      component: ChatPopoverPage,
      cssClass: 'popover-bottom',
      componentProps: {
        roomId: this.roomId,
        members: this.members,
        isAdmin: this.isAdmin,
        isCreated: this.isGroupCreated,
        room: this.room,
        name: this.room.name,
      },
    });
    await modal.present();
    modal.onDidDismiss().then(res => {
      if (res.data == 'leave') {
        this.close()
        // this.ChatSystemService.deleteRoom(this.roomId)
        //this.ChatSystemService.subscribeToRoomUpdate(this.roomId, this.room);
      }
      else if (res.data == 'cancel') {

      }
      else if (res.data == 'edit') {
        this.editGroup(this.roomId);
      } else if (res.data == 'addUser') {

        this.addContacts();
      } else if (res.data == 'delete') {
        this.close()
        // this.ChatSystemService.deleteRoom(this.roomId)
      }
    });
  }


  viewDocument(file: any, url?: string) {

    if (file.type == "application/webtrix") {
      this.openViewDocumentModal(file);
    }
    else {
      let fullUrl = "https://www.tabularium.pt" + url;
      this.fileService.viewDocumentByUrl(fullUrl);
    }
  }

  async openViewDocumentModal(file: any) {

    let task = {
      serialNumber: '',
      taskStartDate: '',
      isEvent: true,
      workflowInstanceDataFields: {
        FolderID: '',
        Subject: file.Assunto,
        SourceSecFsID: file.ApplicationId,
        SourceType: 'DOC',
        SourceID: file.DocId,
        DispatchNumber: ''
      }
    }

    let doc = {
      "Id": "",
      "ParentId": "",
      "Source": 1,
      "ApplicationId": file.ApplicationId,
      "CreateDate": "",
      "Data": null,
      "Description": "",
      "Link": null,
      "SourceId": file.DocId,
      "SourceName": file.Assunto,
      "Stakeholders": "",
    }

    const modal = await this.modalController.create({
      component: ViewDocumentPage,
      componentProps: {
        trustedUrl: '',
        file: {
          title: file.Assunto,
          url: '',
          title_link: '',
        },
        Document: doc,
        applicationId: file.ApplicationId,
        docId: file.DocId,
        folderId: '',
        task: task
      },
      cssClass: 'modal modal-desktop'
    });
    await modal.present();

  }

  async bookMeeting() {

    this.getGroupContacts({});
    this.attendees = this.members.map((val) => {
      return {
        Name: val.name,
        EmailAddress: val.username + "@" + environment.domain,
        IsRequired: "true",
      }
    });

    // this.popoverController.dismiss();
    const modal = await this.modalController.create({
      component: NewEventPage,
      componentProps: {
        attendees: this.attendees,
      },
      cssClass: 'modal  modal-desktop',
      backdropDismiss: false
    });

    modal.onDidDismiss().then((data) => {
      if (data) {

      }
    });

    await modal.present();
  }



  async takePicture() {
    const roomId = this.roomId

    const file = await Camera.getPhoto({
      quality: 90,
      // allowEditing: true,
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera
    });

    var imageBase64 = 'data:image/jpeg;base64,' + file.base64String

    const compressedImage = await this.compressImageBase64(
      imageBase64,
      800, // maxWidth
      800, // maxHeight
      0.9  // quality
    ).then((picture) => {
      // console.log('Selected: ', picture)
      imageBase64 = picture
    });

    const blob = this.dataURItoBlob(imageBase64)

    // console.log(imageBase64)

    const formData = new FormData();
    formData.append("blobFile", blob);

    // this.ChatSystemService.getGroupRoom(roomId).send({
    //   file: {
    //     "type": "application/img",
    //     "guid": ''
    //   },
    //   attachments: [{
    //     "title": "file.jpg",
    //     "text": "description",
    //     "title_link_download": false,
    //   }],
    //   temporaryData: formData,
    //   attachmentsModelData: {
    //     fileBase64: imageBase64,
    //   }
    // })

  }

  async addImage() {
    this.addFileToChat(['image/apng', 'image/jpeg', 'image/png'])
  }

  async addFile() {
    this.addFileToChat(['.doc', '.docx', '.pdf'])
  }

  async addImageMobile() {
    this.addFileToChatMobile(['image/apng', 'image/jpeg', 'image/png'])
  }


  async addFileToChatMobile(types: typeof FileType[]) {
    const roomId = this.roomId

    const file = await Camera.getPhoto({
      quality: 90,
      // allowEditing: true,
      resultType: CameraResultType.Base64,
      source: CameraSource.Photos
    });

    //const imageData =  await this.fileToBase64Service.convert(file)
    //

    var imageBase64 = 'data:image/jpeg;base64,' + file.base64String

    const compressedImage = await this.compressImageBase64(
      imageBase64,
      800, // maxWidth
      800, // maxHeight
      0.9  // quality
    ).then((picture) => {
      // console.log('Selected: ', picture)
      imageBase64 = picture
    });


    const response = await fetch(imageBase64);
    const blob = await response.blob();

    console.log(imageBase64)

    const formData = new FormData();
    formData.append("blobFile", blob);

    // this.ChatSystemService.getGroupRoom(roomId).send({
    //   file: {
    //     "type": "application/img",
    //     "guid": ''
    //   },
    //   temporaryData: formData,
    //   attachments: [{
    //     "title": file.path,
    //     "text": "description",
    //     "title_link_download": false,
    //   }],
    //   attachmentsModelData: {
    //     fileBase64: imageBase64,
    //   }
    // })

  }

  async addFileWebtrix() {
    const modal = await this.modalController.create({
      component: SearchPage,
      cssClass: 'group-messages modal-desktop search-modal search-modal-to-desktop',
      componentProps: {
        type: 'AccoesPresidenciais & ArquivoDespachoElect',
        select: true,
        showSearchInput: true,
      }
    });
    await modal.present();
    modal.onDidDismiss().then(async res => {
      const data = res.data;

      if (data.selected) {

        // this.ChatSystemService.getGroupRoom(this.roomId).send({
        //   file: {
        //     "name": res.data.selected.Assunto,
        //     "type": "application/webtrix",
        //     "ApplicationId": res.data.selected.ApplicationType,
        //     "DocId": res.data.selected.Id,
        //     "Assunto": res.data.selected.Assunto,
        //   },
        //   temporaryData: res,
        //   attachments: [{
        //     "title": res.data.selected.Assunto,
        //     "description": res.data.selected.DocTypeDesc,
        //     // "title_link": url_no_options,
        //     "title_link_download": true,
        //     //"thumb_url": "assets/images/webtrix-logo.png",
        //     // "message_link": url_no_options,
        //     "type": "webtrix",
        //     //"thumb_url": "assets/images/webtrix-logo.png",
        //     "text": res.data.selected.DocTypeDesc,
        //     "thumb_url": "https://static.ichimura.ed.jp/uploads/2017/10/pdf-icon.png",
        //   }]
        // })

      }
    });
  }


  _getBase64(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result)
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    })
  }

  async addFileToChat(types: typeof FileType[]) {



    const roomId = this.roomId

    if(this.platform.is('ios')) {
      console.log('ios add file ')

      const resultt = await FilePicker.pickFiles({
        types: ['application/pdf'],
        multiple: false,
        readData: true,
      });

      console.log('RESULT', resultt.files[0])

      const blobb = this.fileService.base64toBlob(resultt.files[0].data, resultt.files[0].mimeType)

      const formDataa = new FormData();
      formDataa.append('blobFile', blobb);
      /*  console.log('add file', fileBase64) */

      // this.ChatSystemService.getGroupRoom(roomId).send({
      //   file: {
      //     "type": resultt.files[0].mimeType,
      //     "guid": '',
      //   },
      //   attachments: [{
      //     "title": sanitize(resultt.files[0].name),
      //     "name": sanitize(resultt.files[0].name),
      //     // "text": "description",
      //     "title_link_download": false,
      //   }],
      //   temporaryData: formDataa,
      //   attachmentsModelData: {
      //     fileBase64: '',
      //   }
      // })
      return

    }

    const file: any = await this.fileService.getFileFromDevice(types);


    const fileName = file.name

    const validation = this.FileValidatorService.fileNameValidation(fileName)

    if(validation.isOk) {

      if (file.type != "application/img" && file.type != "image/png" && file.type != "image/jpeg" && file.type != "image/gif") {

        const encodedData = btoa(JSON.stringify(await this.getBase64(file).catch((error) => {
          console.error(error);
        })));
        const blob = this.fileService.base64toBlob(encodedData, file.type)

        const formData = new FormData();
        formData.append('blobFile', blob);


        // this.ChatSystemService.getGroupRoom(roomId).send({
        //   file: {
        //     "type": file.type,
        //     "guid": '',
        //   },
        //   attachments: [{
        //     "title": sanitize(fileName),
        //     "name": sanitize(fileName),
        //     // "text": "description",
        //     "title_link_download": false,
        //   }],
        //   temporaryData: formData,
        //   attachmentsModelData: {
        //     fileBase64: encodedData
        //   }
        // });

      } else {

      }
    } else {
      this.toastService._badRequest("Ficheiro inválido")
    }

  }

  getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
  }

  getBase64(file) {
    var reader = this.getFileReader();
    reader.readAsDataURL(file);
    return new Promise(resolve => {
      reader.onload = function () {
        resolve(reader.result)
      };
      reader.onerror = function (error) {

      };
    });

  }

  async openChatOptions(ev?: any) {

    const roomId = this.roomId;

    const popover = await this.popoverController.create({
      component: ChatOptionsPopoverPage,
      cssClass: 'chat-options-popover',
      event: ev,
      componentProps: {
        room: this.room,
        members: this.members,
        eventSelectedDate: new Date(),
      },
      translucent: true
    });
    await popover.present();
    await popover.onDidDismiss().then(async (res) => {

      if (res['data'] == 'meeting') {
        this.bookMeeting();
      }
      else if (res['data'] == 'take-picture') {
        this.takePicture()
      }
      else if (res['data'] == 'add-picture') {
        this.addImageMobile()

      }
      else if (res['data'] == 'add-document') {
        this.addFile()
      }
      else if (res['data'] == 'documentoGestaoDocumental') {

        this.addFileWebtrix()
      }
    });
  }

  async addContacts() {
    if (this.isAdmin) {

      const modal = await this.modalController.create({
        component: GroupContactsPage,
        componentProps: {
          isCreated: this.isGroupCreated,
          room: this.room,
          //members: this.ChatSystemService.getGroupRoom(this.roomId).members,
          members: [],
          name: this.room.name,
        },
        cssClass: 'contacts',
        backdropDismiss: false
      });



      modal.onDidDismiss().then(() => {
        this.getRoomInfo();
      });
      await modal.present();
    }

  }

  async editGroup(roomId) {
    const modal = await this.modalController.create({
      component: EditGroupPage,
      cssClass: 'modal modal-desktop',
      componentProps: {
        roomId: roomId,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {

      this.getRoomInfo();
      //this.modalController.dismiss(res.data);
    });
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return "";
    }
    return JSON.parse(str);
  }

  transformDataMSG(res) {
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
    } else {
      res.forEach(element => {

        let chatmsg = {
          _id: element._id,
          attachments: element.attachments,
          channels: element.channels,
          file: element.file,
          mentions: element.mentions,
          msg: element.msg,
          rid: element.rid,
          ts: element.ts,
          u: element.u,
          _updatedAt: element._updatedAt,
          /* image_url: {
            name: name,
            path: `${IMAGE_DIR}/${name}`,
            data: `data:image/jpeg;base64,${readFile.data}`,
          }, */
        }

        // this.sqlservice.addChatMSG(chatmsg)

      });
    }

  }

  async serverLongPull() {

  }

  sliderOpts = {
    zoom: false,
    slidesPerView: 1.5,
    spaceBetween: 20,
    centeredSlides: true
  };
  zoomActive = false;
  zoomScale = 1;

  sliderZoomOpts = {
    allowSlidePrev: false,
    allowSlideNext: false,
    zoom: {
      maxRatio: 5
    },
    on: {
      zoomChange: (scale, imageEl, slideEl) => {
        this.zoomActive = true;
        this.zoomScale = scale / 5;
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  touchStart(card) {
    // Make card appear above backdrop
    card.el.style['z-index'] = 11;
  }

  b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  async openFile(pdfString, filename, type) {
    /* const modal = await this.modalController.create({
      component: ViewDocumentSecondOptionsPage,
      componentProps: {
        fileUrl: pdfString,
        filename: filename
      },
      cssClass: 'modal modal-desktop'
    });
    await modal.present(); */
    const blob = this.b64toBlob(pdfString, type)
    let pathFile = ''
    const fileName = filename
    const contentFile = blob
    if (this.platform.is('ios')) {
      pathFile = this.file.documentsDirectory
    } else {
      pathFile = this.file.externalRootDirectory
    }

    console.log('file data', pdfString)
    console.log(pathFile)

    let removePre = this.removeTextBeforeSlash(pdfString,',')
    console.log('file data remove ', removePre)

    await Filesystem.writeFile({
      path: fileName,
      data: removePre,
      directory: Directory.Cache,
    }).then((dir) => {
      console.log('DIR ', dir)
      this.fileOpener
        .open(dir.uri, type)
        .then(() => console.log())
        .catch(e => console.error(e))
    });
  }

  downloadFileMsg(msg: any) {
    msg.downloadFileMsg()
  }

  downloadFileFromBrowser(fileName: string, data: any): void {
    const linkSource = data;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  async openPreview(msg: any) {

    if (msg?.file?.type === "application/webtrix") {
      this.viewDocument(msg.file, msg.attachments.image_url)
    } else {
      if (!msg.attachments[0].image_url || msg.attachments[0].image_url === null || msg.attachments[0].image_url === '') {
        this.downloadFileMsg(msg)

      } else {

        var str = msg.attachments[0].image_url;
        str = str.substring(1, ((str.length) - 1));

        if (this.platform.is('desktop') || this.platform.is('mobileweb')) {

          if (msg?.file?.type == "application/img") {

            const modal = await this.modalController.create({
              component: ViewMediaPage,
              cssClass: 'modal modal-desktop',
              componentProps: {
                image: msg.attachments[0].image_url,
                type: msg.file.type,
                username: msg.u.name,
                _updatedAt: msg._updatedAt
              }
            });
            modal.present();
          } else {
            this.downloadFileFromBrowser("file", str)
            this.downloadFileFromBrowser(msg.attachments[0].title, str)
          }

        } else {
          if (msg.file.type == "application/img") {
            const modal = await this.modalController.create({
              component: ViewMediaPage,
              cssClass: 'modal modal-desktop',
              componentProps: {
                image: msg.attachments[0].image_url,
                type: msg.file.type,
                username: msg.u.name,
                _updatedAt: msg._updatedAt
              }
            });
            modal.present();
          } else {
            this.openFile(msg.attachments[0].image_url, msg.attachments[0].title, msg.file.type);
          }
        }
      }
    }

  }

  async audioPreview(msg) {

    if (!msg.attachments[0].title_link || msg.attachments[0].title_link === null || msg.attachments[0].title_link === '') {
      this.downloadFileMsg(msg)
    } else { }
  }


  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

  }

  async compressImageBase64(base64String: string, maxWidth: number, maxHeight: number, quality: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const image = new (window as any).Image();
      image.src = base64String;

      image.onload = async () => {
        const canvas = document.createElement('canvas');
        let newWidth = image.width;
        let newHeight = image.height;

        if (newWidth > maxWidth) {
          newHeight *= maxWidth / newWidth;
          newWidth = maxWidth;
        }

        if (newHeight > maxHeight) {
          newWidth *= maxHeight / newHeight;
          newHeight = maxHeight;
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        const context = canvas.getContext('2d');
        context?.drawImage(image, 0, 0, newWidth, newHeight);

        const compressedBase64 = canvas.toDataURL('image/jpeg', quality);
        resolve(compressedBase64);
      };

      image.onerror = (error) => {
        reject(error);
      };
    });

  }

  removeTextBeforeSlash(inputString, controlString) {
    if (inputString.includes(controlString)) {
      const parts = inputString.split(controlString);
      return parts.length > 1 ? parts[1] : inputString;
    } else {
      return inputString;
    }
  }
}


import { Injectable } from '@angular/core';
import { HttpServiceService } from 'src/app/services/http/http-service.service';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CMAPIAPIService {

  constructor(    private HttpServiceService: HttpServiceService,
    private http: HttpClient,) { }


  getVideoHeader(url: string) {
    //return this.http.head('http://localhost:3001/static/'+url, { observe: 'response' })
    return this.http.head(environment.apiURL+'ObjectServer/StreamFiles?path='+url, { observe: 'response' })
  }
}

<ion-content>
  <!-- Edit event for Inicio -->
  <div class="main-content height-100">

    <div class="content d-flex flex-column width-md-100 height-100">

      <div class="main-header pt-30 px-20 background-white pb-15">
        <ion-header>
          <div class="title-content">
            <div class="middle">
              <ion-label id="vsdfajnl" class="title" style="font-size: 21.1px">Editar Evento</ion-label>
            </div>
          </div>
        </ion-header>
      </div>
      <ion-item-sliding class="overflow-y-auto">
        <div class="px-20">
          <div class="ion-item-container width-100" [class.input-error]="Form?.get('Subject')?.invalid && validateFrom ">
            <ion-input autocomplete="on" autocorrect="on" spellcheck="true" placeholder="Assunto*" [(ngModel)]="postEvent.Subject"></ion-input>
          </div>


          <div *ngIf="Form && validateFrom" >
            <div *ngIf="Form.get('Subject').invalid " class="input-errror-message">
              <!-- <div *ngIf="Form.get('Subject').errors?.required">

              </div> -->
              <div *ngIf="Form.get('Subject').errors?.minlength">
                O campo deve ter pelo menos 4 caracteres.
              </div>
            </div>
          </div>




          <div *ngIf="(sharedCalendar | async) as calendarData" class="container-div width-100" >
            <div class="ion-item-class-2 d-flex">
              <div class="ion-icon-class">
                <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
              </div>
              <div class="ion-input-class flex-grow-1 justify-center align-center material-inputs " [class.input-error]="Form?.get('CalendarName')?.invalid  && validateFrom ">

                <mat-form-field appearance="none"  class="width-100" color="ion-color-secondary"  placeholder="Selecione agenda">

                  <div *ngIf="selectedUserCalendar == SessionStore.user.UserId && !hasChangeCalendar && SessionStore.user.Profile != 'PR' " style="margin-bottom: -20px;">
                    Minha agenda
                  </div>
                  <div *ngIf="!(selectedUserCalendar == SessionStore.user.UserId && SessionStore.user.Profile != 'PR' ) && !hasChangeCalendar" style="margin-bottom: -20px;">
                    PR
                  </div>

                  <mat-select [(value)]="selectedUserCalendar" (selectionChange)="changeAgenda();changeSegmentCalendar()">

                    <mat-option *ngFor="let calendars of calendarData" value="{{calendars.wxUserId}}">

                      <div *ngIf="calendars.roleId == RoleIdService.PRES">PR </div>
                      <div *ngIf="calendars.roleId == RoleIdService.MD && calendars.roleId != SessionStore.user.RoleID">AGENDA DO MDGPR</div>
                      <div *ngIf="calendars.roleId != RoleIdService.MD && calendars.roleId != RoleIdService.PRES && calendars.wxFullName && calendars.wxUserId != SessionStore.user.UserId"> Agenda do {{calendars.wxFullName}} </div>
                      <div *ngIf="calendars.wxUserId == SessionStore.user.UserId && SessionStore.user.Profile != 'PR'">
                        Minha agenda
                      </div>

                    </mat-option>

                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="container-div width-100">
            <div class="ion-item-class-2">
              <div class="ion-icon-class">
                <ion-icon slot="start" src="assets/images/icons-location.svg"></ion-icon>
              </div>
              <div class="ion-input-class" [class.input-error]="Form?.get('Location')?.invalid  && validateFrom ">
                <ion-input autocomplete="on" autocorrect="on" spellcheck="true" placeholder="Localização*" [(ngModel)]="postEvent.Location"></ion-input>
              </div>
            </div>
          </div>

          <div *ngIf="Form && validateFrom" >
            <div *ngIf="Form.get('Location').invalid " class="input-errror-message">
              <div *ngIf="Form.get('Location').errors?.required">

              </div>
              <div *ngIf="Form.get('Location').errors?.minlength">
                O campo deve ter pelo menos 4 caracteres.
              </div>
            </div>
          </div>


          <div class="container-div width-100">
            <div class="ion-item-class-2 width-100 d-flex">
              <div class="ion-icon-class">
                <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
              </div>
              <div class="ion-input-class flex-grow-1 width-100">

                <mat-form-field appearance="none" floatLabel="never" class="width-100 " >

                  <mat-select [(value)]="postEvent.CalendarName" class="width-100" [disabled]=false>
                    <mat-option *ngFor="let calendars of CalendarNamesOptions" value="{{calendars}}">
                      Agenda {{ calendars }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>
            </div>
          </div>

          <div *ngIf="Form && validateFrom" >
            <div *ngIf="Form.get('CalendarName').invalid " class="input-errror-message">
              {{ postEvent.Category }}
              <div *ngIf="Form.get('CalendarName').errors?.required">

              </div>
            </div>
          </div>

          <div class="container-div width-100">
            <div class="ion-item-class-2 width-100 d-flex">
              <div class="ion-icon-class">
                <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
              </div>
              <div class="ion-input-class flex-grow-1" [class.input-error]="Form?.get('Categories')?.invalid  && validateFrom ">

                <mat-form-field appearance="none" floatLabel="never" class="width-100" required>
                  <!-- <input matInput type="text" > -->
                  <mat-select placeholder="Selecione o tipo de evento*" matInput [(ngModel)]="postEvent.Category" >
                    <mat-option value="Meeting">
                      Reunião
                    </mat-option>
                    <mat-option value="Travel">
                      Viagem
                    </mat-option>
                    <mat-option value="Conference">
                      Conferência
                    </mat-option>
                    <mat-option value="Encontro">
                      Encontro
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>
            </div>
          </div>

          <div *ngIf="Form && validateFrom" >
            <div *ngIf="Form.get('Categories').invalid " class="input-errror-message">
              {{ postEvent.Category}}
              <div *ngIf="Form.get('Categories').errors?.required">

              </div>
            </div>
          </div>

          <div class="container-div width-100">
            <div>
              <ion-item>
                <ion-label>Todo dia</ion-label>
                <ion-checkbox [(ngModel)]="allDayCheck" (ionChange)="onCheckboxChange($event)"></ion-checkbox>
              </ion-item>
            </div>
        </div>

        <div class="container-div" *ngIf="postEvent?.EventRecurrence?.frequency">
          <div class="ion-item-class-2 d-flex">
            <div class="ion-icon-class">
              <ion-icon slot="start" src="assets/images/icons-reapet.svg"></ion-icon>
            </div>
            <div class="ion-input-class flex-grow-1 justify-center align-center material-inputs">

              <mat-form-field appearance="none" class="width-100" placeholder="Sample Type" required>
                <!-- <input matInput type="text" > -->
                <mat-select [(value)]="postEvent.EventRecurrence.frequency" >
                  <mat-option value="never">
                    Nunca
                  </mat-option>
                  <mat-option value="daily">
                    Diário
                  </mat-option>
                  <mat-option value="weekly">
                    Semanalmente
                  </mat-option>
                  <mat-option value="monthly">
                    Mensal
                  </mat-option>
                  <mat-option value="yearly">
                    Anual
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>
        </div>

        <div *ngIf="postEvent?.EventRecurrence?.frequency != 'never'" class="container-div">
          <div class="ion-item-class-2 d-flex" >
            <div class="ion-icon-class">
              <ion-icon slot="start" src="assets/images/icons-reapet.svg"></ion-icon>
            </div>

            <div  class="ion-input-class flex-grow-1 justify-center align-center material-inputs materia-top" >

              <mat-form-field appearance="none" class="date-hour-picker">
                <input matInput [ngxMatDatetimePicker]="picker1"
                  placeholder="Data Fim de Recorrência*"
                  [(ngModel)]="postEvent.EventRecurrence.until"
                  [disabled]="disabled"
                >
                <mat-datepicker-toggle id="new-inicio" matSuffix [for]="picker1" ></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker1
                  [showSpinners]="showSpinners"
                  [showSeconds]="showSeconds"
                  [stepHour]="stepHour" [stepMinute]="stepMinute"
                  [stepSecond]="stepSecond"
                  [touchUi]="touchUi"
                  [hideTime]="true"
                  >
                </ngx-mat-datetime-picker>
              </mat-form-field>

            </div>
          </div>
        </div>

          <div *ngIf="allDayCheck" class="container-div width-100">
            <div class="ion-item-class-2 width-100">
              <div class="ion-icon-class">
                <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
              </div>
              <div class="ion-input-class" [class.input-error]="Form?.get('Date')?.invalid  && validateFrom ">

                <mat-form-field appearance="none" floatLabel="never" class="date-hour-picker">
                  <input matInput [ngxMatDatetimePicker]="picker1"
                    placeholder="Choose a date"
                    [(ngModel)]="postEvent.StartDate"
                    [max]="maxDate"
                    [disabled]="disabled"
                    (ngModelChange)="onDateChange($event)"
                  >
                  <mat-datepicker-toggle id="new-inicio" matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #picker1
                    [showSpinners]="showSpinners"
                    [showSeconds]="showSeconds"
                    [stepHour]="stepHour" [stepMinute]="stepMinute"
                    [stepSecond]="stepSecond"
                    [touchUi]="touchUi"
                    [hideTime]="true">

                  </ngx-mat-datetime-picker>
                </mat-form-field>

              </div>
            </div>
          </div>

          <div *ngIf="!allDayCheck" class="container-div width-100">
            <div class="ion-item-class-2 width-100">
              <div class="ion-icon-class">
                <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
              </div>
              <div class="ion-input-class" [class.input-error]="Form?.get('Date')?.invalid  && validateFrom ">

                <mat-form-field appearance="none" floatLabel="never" class="date-hour-picker">
                  <input matInput [ngxMatDatetimePicker]="picker1"
                    placeholder="Choose a date"
                    [(ngModel)]="postEvent.StartDate"
                    [max]="maxDate"
                    [disabled]="disabled"
                    (ngModelChange)="onDateChange($event)"
                  >
                  <mat-datepicker-toggle id="new-inicio" matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #picker1
                    [showSpinners]="showSpinners"
                    [showSeconds]="showSeconds"
                    [stepHour]="stepHour" [stepMinute]="stepMinute"
                    [stepSecond]="stepSecond"
                    [touchUi]="touchUi"
                    [hideTime]="false">
                  </ngx-mat-datetime-picker>
                </mat-form-field>

              </div>
            </div>
          </div>



          <div *ngIf="allDayCheck" class="container-div width-100">
            <div  class="ion-item-class-2 width-100 d-flex">
              <div class="ion-icon-class">
                <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
              </div>
              <div class="ion-input-class  flex-grow-1" [class.input-error]="Form?.get('Date')?.invalid  && validateFrom ">

                <mat-form-field appearance="none" floatLabel="never" floatLabel="never" class="date-hour-picker">
                  <input matInput [ngxMatDatetimePicker]="fim"
                    placeholder="Choose a date"
                    [(ngModel)]="postEvent.EndDate"
                    [min]="postEvent.StartDate"
                    [max]="maxDate"
                    [disabled]="disabled"
                  >
                  <mat-datepicker-toggle id="new-fim" matSuffix [for]="fim"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #fim
                    [showSpinners]="showSpinners"
                    [showSeconds]="showSeconds"
                    [stepHour]="stepHour" [stepMinute]="stepMinute"
                    [stepSecond]="stepSecond"
                    [hideTime]="true">
                  >
                  </ngx-mat-datetime-picker>
                </mat-form-field>

              </div>
            </div>
          </div>

          <div *ngIf="!allDayCheck" class="container-div width-100">
            <div  class="ion-item-class-2 width-100 d-flex">
              <div class="ion-icon-class">
                <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
              </div>
              <div class="ion-input-class  flex-grow-1" [class.input-error]="Form?.get('Date')?.invalid  && validateFrom ">

                <mat-form-field appearance="none" floatLabel="never" floatLabel="never" class="date-hour-picker">
                  <input matInput [ngxMatDatetimePicker]="fim"
                    placeholder="Choose a date"
                    [(ngModel)]="postEvent.EndDate"
                    [min]="postEvent.StartDate"
                    [max]="maxDate"
                    [disabled]="disabled"
                  >
                  <mat-datepicker-toggle id="new-fim" matSuffix [for]="fim"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #fim
                    [showSpinners]="showSpinners"
                    [showSeconds]="showSeconds"
                    [stepHour]="stepHour" [stepMinute]="stepMinute"
                    [stepSecond]="stepSecond"
                    [hideTime]="false">
                  </ngx-mat-datetime-picker>
                </mat-form-field>

              </div>
            </div>
          </div>


          <div *ngIf="selectedRecurringType != '-1'" class="container-div width-100">
            <div class="ion-item-class-2 d-flex">
              <div class="ion-icon-class">
                <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
              </div>
              <div (click)="openLastOccurrence()" class="ion-input-class flex-grow-1">

                <mat-form-field class="date-hour-picker">
                  <input matInput [ngxMatDatetimePicker]="occurrrence"
                    placeholder="Choose a date"
                    [(ngModel)]="postEvent.EventRecurrence.LastOccurrence"
                    [disabled]="disabled"
                    [min]="postEvent.EndDate"
                  >
                  <mat-datepicker-toggle id="last-occurrence" matSuffix [for]="occurrrence"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #occurrrence
                    [showSpinners]="showSpinners"
                    [showSeconds]="showSeconds"
                    [stepHour]="stepHour" [stepMinute]="stepMinute"
                    [stepSecond]="stepSecond"
                    [touchUi]="touchUi"
                    >
                  </ngx-mat-datetime-picker>
                </mat-form-field>

              </div>
            </div>
          </div>

          <div class="container-div width-100">
            <div (click)="addParticipants()" class="ion-item-class-2 cursor-pointer">
              <div class="ion-icon-class">
                <ion-icon slot="start" src="assets/images/icons-person.svg"></ion-icon>
              </div>
              <div class="ion-input-class-no-height d-flex" [class.input-error]="Form?.get('participantes')?.invalid  && validateFrom">
                <div class="list-people flex-grow-1">
                  <ion-item lines="none">
                    <ion-list>
                      <ion-label *ngIf="taskParticipants?.length < 1" class="list-people-title">Adicionar Intervenientes*</ion-label>
                      <ion-label *ngFor="let participant of taskParticipants">{{participant.Name}}</ion-label>
                    </ion-list>
                  </ion-item>
                </div>
                <div class="add-people">
                  <ion-icon *ngIf="ThemeService.currentTheme == 'default' " slot="start" src="assets/images/icons-arrow-forward.svg"></ion-icon>
                  <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " slot="start" src="assets/images/theme/gov/icons-arrow-forward.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " slot="start" src="assets/images/theme/{{ThemeService.currentTheme}}/icons-arrow-forward.svg"></ion-icon>
                </div>
              </div>
            </div>
          </div>

          <div class="container-div width-100">
            <div (click)="addParticipantsCC()" class="ion-item-class-2 cursor-pointer">
              <div class="ion-icon-class">
                <ion-icon slot="start" src="assets/images/icons-people-cc.svg"></ion-icon>
              </div>
              <div class="ion-input-class-no-height d-flex">
                <div class="list-people flex-grow-1">
                  <ion-item lines="none">
                    <ion-list>
                      <ion-label *ngIf="taskParticipantsCc?.length < 1" class="list-people-title">Com Conhecimento</ion-label>
                      <ion-label *ngFor="let participant of taskParticipantsCc">{{participant.Name}}</ion-label>
                    </ion-list>
                  </ion-item>
                </div>
                <div class="add-people">
                  <ion-icon *ngIf="ThemeService.currentTheme == 'default' "  slot="start" src="assets/images/icons-arrow-forward.svg"></ion-icon>
                  <ion-icon *ngIf="ThemeService.currentTheme == 'gov' "  slot="start" src="assets/images/theme/gov/icons-arrow-forward.svg"></ion-icon>
                  <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' "  slot="start" src="assets/images/theme/{{ThemeService.currentTheme}}/icons-arrow-forward.svg"></ion-icon>
                </div>
              </div>
            </div>
          </div>


          <div class="container-div width-100">
            <div class="ion-item-class-2 d-flex width-100">
              <div class="ion-icon-class d-flex">
                <ion-icon slot="start" src="assets/images/icons-description.svg"></ion-icon>
              </div>
              <div class="ion-input-class-no-height width-100 flex-grow-1">
                <!-- rows="6" cols="20" -->
                <ion-textarea class="heigh-200" autocomplete="on" autocorrect="on" spellcheck="true" [(ngModel)]="postEvent.Body.Text"  placeholder="Detalhes" ></ion-textarea>
              </div>
            </div>
          </div>

          <div class="d-flex container-div width-100 ion-item-container-no-border" >
            <ion-label (click)="getDoc()" class="cursor-pointer">
              <div class="attach-icon">
                <ion-icon *ngIf="ThemeService.currentTheme == 'default' "src="assets/images/icons-attach-doc.svg"></ion-icon>
                <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " src="assets/images/theme/gov/icons-attach-doc.svg"></ion-icon>
                <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " src="assets/images/theme/{{ThemeService.currentTheme}}/icons-attach-doc.svg"></ion-icon>
              </div>
              <div class="attach-document">
                <ion-label>Adicionar documentos</ion-label>
              </div>
            </ion-label>
          </div>

          <div class="d-flex container-div width-100"  *ngFor="let document of loadedEventAttachments; let i = index" >
            <ion-list class="width-100 list">
              <ion-item class="width-100 ion-no-border ion-no-padding">
              <ion-label class="width-100">
                <p class="p-item-title d-flex ion-justify-content-between">
                  <span class="attach-title-item">{{document.subject || document.SourceName || document.sourceName}}</span>
                  <span class="close-button text-black" (click)="deleteAttachment(document.Id, i)" >
                    <ion-icon class="font-20" src="assets/images/icons-delete-25.svg"></ion-icon>
                  </span>
                </p>
                <!-- <p *ngIf="document.Stakeholders" ><span class="span-left" >{{document.Stakeholders}}</span></p>  -->
                <p>
                  <span class="app-name span-left" *ngIf="document.applicationId  == 8 || document.ApplicationId  == 8"> Correspondência </span>
                  <span class="app-name span-left" *ngIf="document.applicationId == 386 || document.ApplicationId == 386"> Acções Presidenciais </span>
                  <span class="app-name span-left" *ngIf="document.applicationId == 361 || document.ApplicationId == 361 "> Arquivo Despacho Electrónico </span>

                </p>
              </ion-label>
            </ion-item>
            </ion-list>
          </div>


        </div>



      </ion-item-sliding>

    </div>

    <app-attendee-modal class="aside-right flex-column height-100 d-none d-md-flex pt-10"
      *ngIf="showAttendees"
      [footer]="false"
      [taskParticipants]="taskParticipants"
      [taskParticipantsCc]="taskParticipantsCc"
      [adding]="adding"
      [footer]="false"
      (dynamicSetIntervenient)="dynamicSetIntervenient($event)"
    ></app-attendee-modal>

  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="footer-toolbar px-20">
    <ion-buttons slot="start">
      <button class="btn-ok" fill="clear" color="#fff" (click)="save_v2()">
        <ion-label>Gravar</ion-label>
      </button>
    </ion-buttons>
    <ion-buttons  slot="end">
      <button class="btn-cancel" fill="clear" color="#061b52" (click)="cancel()">
        <ion-label>Cancelar</ion-label>
      </button>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>

import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { EventPerson } from 'src/app/models/eventperson.model';
import { SearchList } from 'src/app/models/search-document';
import { NewEventPage } from 'src/app/pages/agenda/new-event/new-event.page';
import { SearchPage } from 'src/app/pages/search/search.page';
import { environment } from 'src/environments/environment';
import { ThemeService } from 'src/app/services/theme.service'
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-chat-options-popover',
  templateUrl: './chat-options-popover.page.html',
  styleUrls: ['./chat-options-popover.page.scss'],
})
export class ChatOptionsPopoverPage implements OnInit {

  documents:SearchList[] = [];
  members: any;
  attendees: EventPerson[] = [];

  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController,
    private navParams: NavParams,
    public ThemeService: ThemeService,
    public p: PermissionService,
  ) {

    this.members = this.navParams.get('members');

    if(this.members) {
      this.attendees = this.navParams.get('members').map((val)=>{
        return {
          Name: val.name,
          EmailAddress: val.username+"@"+environment.domain,
          IsRequired: "true",
        }
      });
    }

  }

  ngOnInit() {
    

  }

  takePicture(){
    if( window.innerWidth < 701){
      this.popoverController.dismiss('take-picture');
    }
    else{
      this.modalController.dismiss('take-picture');
    }
  }

  addFile(){
    if( window.innerWidth < 701){
      this.popoverController.dismiss('add-document');
    }
    else{
      this.modalController.dismiss('add-document');
    }
  }

  anexarFoto(){
    if( window.innerWidth < 701){
      this.popoverController.dismiss('add-picture');
    }
    else{
      this.modalController.dismiss('add-picture');
    }
  }

  addDocGestaoDocumental(){
    if( window.innerWidth < 701){
      this.popoverController.dismiss('documentoGestaoDocumental');
    }
    else{
      this.modalController.dismiss('documentoGestaoDocumental');
    }
  }

  close(){
    if( window.innerWidth < 701){
      this.popoverController.dismiss();
    }
    else{
      this.modalController.dismiss();
    }
  }

  attachDocument(){
    

  }


  async getDoc(){
    const modal = await this.modalController.create({
      component: SearchPage,
      cssClass: 'group-messages modal-desktop search-modal search-modal-to-desktop',
      componentProps: {
        type: 'AccoesPresidenciais & ArquivoDespachoElect',
        select: true,
        showSearchInput: true,
      }
    });

    modal.onDidDismiss().then((res)=>{
      if(res) {
        const data = res.data;
        this.documents.push(data.selected);
        

      }
    });

    await modal.present();
  }

  async bookMeeting() {
    
    if( window.innerWidth < 701){
      this.popoverController.dismiss('meeting');
    }
    else{
      this.modalController.dismiss('meeting');
    }
  }

}

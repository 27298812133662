import { Injectable } from '@angular/core';
import { localstoreService } from './localstore.service'
import { AES, enc, SHA1 }  from  'crypto-js'
import { isArray } from 'ionic-angular/umd/util/util';
import { EventoApprovePipe } from 'src/app/pipes/evento-approve.pipe'
import { momentG } from 'src/plugin/momentG';
import { v4 as uuidv4 } from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class EventoaprovacaoStoreService {

 // main data
 private _list: {[key: string]: any[]} = {}
 private _concatList = []
 
 // local storage keyName
 private keyNameAll: string;

 private _counts : {[key: string]: number} = {}
 private _countsAll = 0 


 EventoApprovePipe = new EventoApprovePipe()

  callbacks: {
    [key: string]: {
      funx: Function
      id: string
    }
  } = {} 

 newList = []

  constructor() {

    this.keyNameAll = (SHA1("EventoaprovacaoStoreService"+"all")).toString()
    
    
    setTimeout(() => {
      let {list , counts} = localstoreService.get(this.keyNameAll, {})

      this._list = list || {}

      this.updateCount();
      this.runCallback()
    }, 10)

  }


  registerCallback({funx, id = uuidv4()}) {

    this.callbacks[id] = { funx, id}

    return {
      delete: ()=> {
        delete this.callbacks[id]
      }
    }
  }

  runCallback() {
    for (const [key, value] of Object.entries(this.callbacks)) {
      value.funx()
    }
  }


  get countAll() {
    return this._countsAll
  }

  get contactList() {
    return this._concatList
  }


  save(segment: string, value: any[]) {

    if(typeof segment == 'object') {
      throw("segment most be userId")
    }

    if(!this._list[segment]) {
      this._list[segment] = []
      this._counts[segment] = 0
    }
    

    value = value.filter(e => e.activityInstanceName != 'Comunicar Evento') 
    value = value.map( e => this.EventoApprovePipe.transform(e, e))

    this._list[segment] = value
    this._counts[segment] = value.length
    this.runCallback()
    
    this.updateCount();

    setTimeout(() => { 
      localstoreService.set(this.keyNameAll,{
        list: this._list,
        counts: this._counts
      })

    }, 10)

    if(window['all-process-gabinete']) {
      window['all-process-gabinete']()
    }
  }

  clear() {
    this._list = {}
    this._counts = {}

    setTimeout(() => { 
      localstoreService.set(this.keyNameAll,{
        list: this._list,
        counts: this._counts
      })
    }, 10)
  }

  get(segment) {
    return this._list[segment] || []
  }

  updateCount() {

    let allList = []

    for(let [name, value] of Object.entries(this._list)) {
      allList = allList.concat(value)
    }

    this._countsAll = allList.length
    this._concatList = allList


    this.newList = this._concatList.filter((e) =>{
      return this.lessthen24Hours(e.TaskStartDate || e.taskStartDate)
    })
  }


  lessthen24Hours(isoDateString:string) {

    if(!isoDateString) {
      return false
    }
    
    const creationDate = new Date(isoDateString)
    return momentG(new Date(), 'dd MMMM yyyy') == momentG(new Date(creationDate), 'dd MMMM yyyy')
  }



}

export const EventoAprovacaoStore = new EventoaprovacaoStoreService()

import { Injectable } from '@angular/core';
import { Result } from 'neverthrow';
import { HttpService } from 'src/app/services/http.service';
import { AddMemberToRoomInputDTO } from '../../../domain/use-case/member/member-add-use-case.service';
import { DataSourceReturn } from 'src/app/services/Repositorys/type';
import { SessionStore } from 'src/app/store/session.service';
import { SignalRService } from 'src/app/infra/socket/signalR/signal-r.service';
import { v4 as uuidv4 } from 'uuid'
import { CreateRoomInputDTO, RoomOutPutDTO } from '../../../domain/use-case/room/room-create-use-case.service';
import { IRoomRemoteRepository } from 'src/app/core/chat/repository/room/room-remote-repository';
import { RoomByIdOutputDTO } from 'src/app/module/chat/domain/use-case/room/room-get-by-id-use-case.service';
import { RoomUpdateInputDTO, RoomUpdateOutputDTO } from 'src/app/module/chat/domain/use-case/room/room-update-by-id-use-case.service';
import { RoomListOutPutDTO } from '../../../domain/use-case/room/room-get-list-use-case.service';
import { z } from 'zod';
import { HttpAdapter } from 'src/app/infra/http/adapter';

const RoomByIdInputDTOSchema =  z.string()
type RoomByIdInputDTO = z.infer<typeof RoomByIdInputDTOSchema>


@Injectable({
  providedIn: 'root'
})
export class RoomRemoteDataSourceService implements IRoomRemoteRepository {

  private baseUrl = 'https://gdapi-dev.dyndns.info/stage/api/v2/Chat'; // Your base URL

  constructor(
    private httpService: HttpService,
    private socket: SignalRService,
    private Http: HttpAdapter
  ) {}


  //@ValidateSchema(CreateRoomInputDTOSchema)
  //@APIReturn(RoomOutPutDTOSchema, 'post/Room')
  async createRoom(data: CreateRoomInputDTO): DataSourceReturn<RoomOutPutDTO> {
    return await this.httpService.post<RoomOutPutDTO>(`${this.baseUrl}/Room`, data);
  }


  //@APIReturn(RoomListOutPutDTOSchema, 'get/Room')
  async getRoomList(): Promise<DataSourceReturn<RoomListOutPutDTO>> {
    const result = await this.Http.get<RoomListOutPutDTO>(`${this.baseUrl}/Room?userId=${SessionStore.user.UserId}`);

    return result.map((e)=> e.data)
  }

  //@ValidateSchema(RoomByIdInputDTOSchema)
 //@APIReturn(RoomByIdOutputDTOSchema,'get/Room/${id}')
  async getRoom(id: RoomByIdInputDTO): DataSourceReturn<RoomByIdOutputDTO> {
    const result = await this.Http.get(`${this.baseUrl}/Room/${id}`);

    return result.map((e)=> e.data)
  }

  //@ValidateSchema(RoomUpdateInputDTOSchema)
  //@APIReturn(RoomByIdOutputDTOSchema,'update/Room/${id}')
  async updateRoom(data: RoomUpdateInputDTO): Promise<DataSourceReturn<RoomUpdateOutputDTO>> {
    const id = data.roomId
    delete data.roomId
    return await this.httpService.put<any>(`${this.baseUrl}/Room/${id}`, data);
  }

  async deleteRoom(id: string): Promise<Result<any ,any>> {
    return await this.httpService.delete<any>(`${this.baseUrl}/Room/${id}`, {});
  }


  async addMemberToRoomSocket(data: AddMemberToRoomInputDTO) {

    const result = await this.socket.sendData({
      method: 'AddRoomMember',
      data: {
        requestId: uuidv4(),
        roomId: data.id,
        members: data.members
      }
    })

    console.log({result})

    return result
  }
}

import { err } from "neverthrow";
import { ColoredLoggerService } from "../logger/colored/service";

export function captureAndReraiseAsync(taskName: string) {
  return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
      const originalMethod = descriptor.value;
      descriptor.value = async function(...args: any[]) {
          try {
            return await originalMethod.apply(this, args);
          } catch (error) {
            // Log the error along with taskName
            ColoredLoggerService.error('Capture error from', taskName, error)
            console.error(error)
            // Re-throw the same error
            return err(error) ;
          }
      };
      return descriptor;
  };
}

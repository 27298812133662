
import { EventToApproveList } from "src/app/models/entiry/agenda/eventToApproveList";
import { EEventCategory, EEventOwnerType, EEventStatus } from "../../data/dto/enums";
import { EventToApproveListOutputDTO } from "../../data/dto/eventToApproveListOutputDTO";


function getTextInsideParentheses(inputString): string {
  var startIndex = inputString.indexOf('(');
  var endIndex = inputString.indexOf(')');
  if (startIndex !== -1 && endIndex !== -1) {
    return inputString.substring(startIndex + 1, endIndex);
  } else {
    return null;
  }
}

export class EventListToApproveMapper {
  static toDomain(dtoList: EventToApproveListOutputDTO, calendarOwnerName: string, userId: string): EventToApproveList {


    return dtoList.data.map((dto) => {

      let category;
      if(dto.category == EEventCategory.Oficial) {
        category = 'Oficial'
      } else {
        category = 'Pessoal'
      }

      let color;
      let workflowDisplayName;

      if(dto.ownerType == EEventOwnerType.PR) {
        color = 'PR'
        workflowDisplayName = `Agenda ${category} ${color}`
      } else {
        color = 'MDGPR'
        workflowDisplayName = `Agenda ${category} ${color}`
      }
      //  else  {
      //   workflowDisplayName = `Agenda ${category}`
      // }

      let activityInstanceName;
      let taskStatus;

      if(dto.status == EEventStatus.Pending) {
        activityInstanceName = 'Aprovar evento'
        taskStatus = 'Pending' //////
      } else if(dto.status == EEventStatus.Revision) {
        activityInstanceName = 'Editar evento'
        taskStatus = 'Revision' //////
      } else if (dto.status == EEventStatus.Approved) {
        activityInstanceName = 'Evento Aprovado'
        taskStatus = 'Approved' //////
      } else if (dto.status == EEventStatus.Declined) {
        activityInstanceName = 'Declined'
        taskStatus = 'Declined' //////
      } else if (dto.status == EEventStatus.Communicated) {
        activityInstanceName = 'Communicated'
        taskStatus = 'Communicated' //////
      } else if (dto.status == EEventStatus.ToCommunicate) {
        activityInstanceName = 'ToCommunicate'
        taskStatus = 'ToCommunicate' //////
      }

      return {
        serialNumber: dto.id,
        taskStatus: taskStatus,
        taskStartDate: dto.startDate,
        taskReceiveDate: dto.startDate,
        deadline: null,
        workflowDisplayName,
        activityInstanceName,
        totalDocuments: 0,
        workflowInstanceDataFields: {
          Agenda: category,
          EndDate: dto.endDate,
          StartDate: dto.startDate,
          Subject: dto.subject,
          Location: dto.location,
          Status: "Active",
          IsAllDayEvent: dto.isAllDayEvent,
          InstanceId: "",
          originator: ""
        }
      }
    })
  }

  static toDTO() {}
}


// "serialNumber": "47315_184",
// "taskStatus": "Open",
// "taskStartDate": "2024-04-24T11:27:12.527",
// "taskReceiveDate": "2024-04-24T11:29:17.76",
// "deadline": null,
// "workflowDisplayName": "Agenda Oficial PR",
// "activityInstanceName": "Comunicar Evento",
// "totalDocuments": 0,
// "workflowInstanceDataFields": {
//     "Agenda": "Oficial",
//     "EndDate": "2024-04-25 11:30:00",
//     "StartDate": "2024-04-24 11:30:00",
//     "Subject": "Event 2 After MERGE",
//     "Location": "lUANDA",
//     "Status": "Active",
//     "IsAllDayEvent": false,
//     "InstanceId": "AAMkADVhOGY3ZDQzLTg4ZGEtNDYxMC1iMzc5LTJkMDgwNjMxOWFlZQBGAAAAAABEDW9nKs69TKQcVqQURj8YBwBR2HR2eO7pSpNdD9cc70l+AAAAAAFKAABR2HR2eO7pSpNdD9cc70l+AACK2Od9AAA=",
//     "originator": "dlima@gabinetedigital.local"
// }

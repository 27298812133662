import { HttpErrorResponse } from "@angular/common/http";
import { Result } from "neverthrow";
import { Observable } from "rxjs";
import { MessageEntity } from "src/app/core/chat/entity/message";
import { HttpListenToMessageLoadHistoryUseCaseInput } from "src/app/core/chat/usecase/message/http-listen-to-message-load-history-by-roomId-use-case";
import { HttpResult } from "src/app/infra/http/type";
import { UseCase } from "src/app/utils/use-case-interface";
import { IMessageGetAllByRoomIdOutPut } from 'src/app/core/chat/usecase/message/message-get-all-by-room-Id'
export abstract class ITemplateCreateAdapter implements UseCase<MessageEntity, any> {
  execute(input: MessageEntity): Promise<any> {
    throw new Error("Method not implemented.");
  }
}

export abstract class SocketOnReconnectAdapter {
  abstract execute(): Observable<boolean>
}

export abstract class HttpListenToMessageLoadHistoryAdapter {
  abstract execute(input: HttpListenToMessageLoadHistoryUseCaseInput): Observable<IMessageGetAllByRoomIdOutPut>
}

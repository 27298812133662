<ion-content class="container">
  <div class="arrow-right">
    <button class="btn-no-color" (click)="close()">
      <ion-icon *ngIf="ThemeService.currentTheme == 'default' "slot="end" class="arrow-right-icon" src='assets/images/icons-arrow-arrow-right.svg'></ion-icon>
      <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " slot="end" class="arrow-right-icon" src='assets/images/theme/gov/icons-calendar-arrow-right.svg'></ion-icon>
    </button>
  </div>
  <div class="buttons">
    <button  (click)="takePicture()" full class="btn-ok" shape="round" >Tirar Fotografia</button>
    <button (click)="addFile()" class="btn-ok" shape="round" >Anexar Documento</button>
    <button (click)="anexarFoto()" full class="btn-ok" shape="round" >Anexar Fotografia</button>
    <button (click)="addDocGestaoDocumental()" class="btn-ok" shape="round" >Gestão Documental</button>
    <div class="solid"></div>

    <!-- <button *ngIf="p.userPermission([p.permissionList.Agenda.access]) == true " (click)="bookMeeting()" class="btn-ok" shape="round" >Novo Evento</button> -->
    <button (click)="close()" full class="btn-cancel" shape="round" >Cancelar</button>
  </div>

</ion-content>

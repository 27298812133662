import { z } from "zod";
import { SharedCalendarListItemOutputDTO } from "../../dto/sharedCalendarOutputDTO";

type Changes = {
  insert: SharedCalendarListItemOutputDTO[];
  update: SharedCalendarListItemOutputDTO[];
  remove: SharedCalendarListItemOutputDTO[];
};

export function SharedCalendarListDetectChanges(
  localList: SharedCalendarListItemOutputDTO[],
  serverList: SharedCalendarListItemOutputDTO[]
): Changes {
  const changes: Changes = { insert: [], update: [], remove: [] };

  const localMap = new Map(localList.map(item => [item.wxUserId, item]));
  const serverMap = new Map(serverList.map(item => [item.wxUserId, item]));

  // Detect new or updated items
  for (const [id, serverItem] of serverMap) {
    const localItem = localMap.get(id);
    if (!localItem) {
      changes.insert.push(serverItem);
    } else if (localItem.wxFullName !== serverItem.wxFullName) {
      changes.update.push(serverItem);
    }
  }

  // Detect deleted items
  for (const [id, localItem] of localMap) {
    if (!serverMap.has(id)) {
      changes.remove.push(localItem);
    }
  }

  return changes;
}

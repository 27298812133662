import { z } from 'zod';
import { EAttendeeType, EEventCategory, EEventOwnerType, EEventStatus, EEventType } from './enums';

export const AttachmentInputDTOSchema = z.object({
  id: z.string().nullable(),
  docId: z.number().nullable(),
  sourceName: z.string().nullable(),
  description: z.string().nullable(),
  applicationId: z.number().int(),
}).strict();
const EAttendeeTypeDTO = z.nativeEnum(EAttendeeType);


const CommentSchema = z.object({
  message: z.string(),
  createdAt: z.string(),
});

const AttendeeSchema = z.object({
  id: z.string(),
  name: z.string(),
  attendeeType: z.nativeEnum(EAttendeeType), // ["Required", "Acknowledgment", "Optional"] = [1,2,3]
  emailAddress: z.string(),
  wxUserId: z.number(),
});

const OwnerSchema = z.object({
  wxUserId: z.number(),
  wxFullName: z.string(),
  wxeMail: z.string(),
  userPhoto: z.string(),
});

const OrganizerSchema = z.object({
  wxUserId: z.number(),
  wxFullName: z.string(),
  wxeMail: z.string(),
  userPhoto: z.string(),
});

const EventRecurrenceSchema = z.object({
  Type: z.number().optional(),
  Day: z.any().optional(),
  DayOfWeek: z.any(),
  Month: z.any(),
  LastOccurrence: z.any().optional(),
  frequency: z.number().optional(),
  until: z.string().optional()
}).nullable()


export const EventOutputDTOSchema = z.object({
  success: z.boolean(),
  message: z.string(),
  data: z.object({
    id: z.string(),
    owner: OwnerSchema,
    ownerType: z.nativeEnum(EEventOwnerType), // ["PR", "MD", "Other"] = [1,2,3],
    subject: z.string(),
    body: z.string(),
    location: z.string(),
    startDate: z.string(),
    endDate: z.string(),
    type: z.nativeEnum(EEventType),
    category: z.nativeEnum(EEventCategory), // ['Oficial', 'Pessoal'] = [1, 2]
    attendees: z.array(AttendeeSchema),
    isRecurring: z.boolean(),
    eventRecurrence: EventRecurrenceSchema,
    hasAttachments: z.boolean(),
    attachments: z.array(AttachmentInputDTOSchema),
    comments: z.array(CommentSchema),
    isPrivate: z.boolean(),
    isAllDayEvent: z.boolean(),
    organizer: OrganizerSchema,
    status: z.nativeEnum(EEventStatus), // ['Pending', 'Revision', 'Approved', 'Declined', 'Communicated', 'ToCommunicate'] = [1, 2, 3, 4, 5, 6]
  }),
})

export type EventOutputDTO = z.infer<typeof EventOutputDTOSchema>

import { Pipe, PipeTransform } from '@angular/core';
import { Publication } from 'src/app/models/publication';

@Pipe({
  name: 'publication'
})
export class PublicationPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

  itemList(element): Publication {
    return {
      "DateIndex": element.DateIndex,
      "DocumentId":element.DocumentId,
      "ProcessId":element.ProcessId,
      "Title":element.Title,
      "Message": element.Message,
      "DatePublication": element.DatePublication,
      /* image:itemImage, */
      "Files": element.Files,
      "OriginalFileName": element.OriginalFileName,
      "FileExtension": element.FileExtension,
      "OrganicEntityId": element.OrganicEntityId,
    }
  }

}

import { MessageTable } from "src/app/infra/database/dexie/instance/chat/schema/message";
import { DexieRepository } from "src/app/infra/repository/dexie/dexie-repository.service";
import { MessageEntity } from "../../entity/message";
import { Observable as  DexieObservable, PromiseExtended } from 'Dexie';
import { AttachmentTable } from "src/app/infra/database/dexie/instance/chat/schema/attachment";
import { RoomTable } from "src/app/infra/database/dexie/instance/chat/schema/room";
import { Observable } from "rxjs";

export abstract class IRoomLocalRepository extends DexieRepository<RoomTable, RoomTable> {
  abstract getItemsLive(): Observable<RoomTable[]>
  abstract getRoomByIdLive(id: any) : Observable<RoomTable>
}

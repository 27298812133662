import { Injectable } from '@angular/core';
import { IBoldLocalRepository } from 'src/app/core/chat/repository/bold/bold-local-repository';
import { TracingType, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';
import { zodSafeValidation } from 'src/app/utils/zodValidation';
import { z } from 'zod';


const BoldRemoveByRoomIdInputSchema = z.object({
  roomId: z.string()
})
export type BoldRemoveByRoomIdInput = z.infer<typeof BoldRemoveByRoomIdInputSchema>
@Injectable({
  providedIn: 'root'
})
export class BoldRemoveByRoomIdService {

  constructor(
    private boldLocalRepository: IBoldLocalRepository,
  ) { }

  @XTracerAsync({name:'BoldRemoveByRoomIdService', module:'chat',  bugPrint: true})
  async execute(input: BoldRemoveByRoomIdInput, tracing?: TracingType) {

    const validation = zodSafeValidation<any>(BoldRemoveByRoomIdInputSchema, input)

    if(validation.isOk()) {
      return await this.boldLocalRepository.delete(input.roomId)
    } else {
      tracing.hasError("invalid parameter")
    }

  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { PermissionService } from 'src/app/services/permission.service';
import { ProcessesService} from 'src/app/services/processes.service';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service';
import { ToastService } from 'src/app/services/toast.service';
import { TaskService } from 'src/app/services/task.service';
import { RouteService } from 'src/app/services/route.service';
import { PopupQuestionPage } from 'src/app/modals/popup-question/popup-question.page';

@Component({
  selector: 'app-event-details-documents-options',
  templateUrl: './event-details-documents-options.page.html',
  styleUrls: ['./event-details-documents-options.page.scss'],
})
export class EventDetailsDocumentsOptionsPage implements OnInit {

  Document: any
  content: any = "";
  InstanceID:any
  FolderID:any
  DraftIds:any
  DraftNames:any
  serialnumber: any

  constructor(
    public p: PermissionService,
    private modalController: ModalController,
    private navParams: NavParams,
    private processService: ProcessesService,
    private erroHandler: HttpErrorHandle,
    
    private processes: ProcessesService,
    private toastService: ToastService,
    public TaskService: TaskService,
    private RouteService: RouteService,
  ) {
    this.Document = this.navParams.get('Document')
    this.content = this.navParams.get('content')
    this.InstanceID = this.navParams.get('InstanceId')
    this.FolderID = this.navParams.get('FolderId')
    this.DraftIds = this.navParams.get('DraftIds')
    this.DraftNames = this.navParams.get('OriginalFileName')
    this.serialnumber = this.navParams.get('Serialnumber');
  }

  ngOnInit() {
    console.log(this.serialnumber)
  }

  close() {
    this.modalController.dismiss();
  }

  goBack() {
    this.modalController.dismiss();
  }

  async openBookMeetingModal() {
    this.modalController.dismiss({ component: 'openBookMeetingModal', taskAction: '' });
  }

  async openExpedientActionsModal(taskAction: any) {
    this.modalController.dismiss({ component: 'openExpedientActionsModal', taskAction });
  }

  async distartExpedientModal(body: any) { }

  save(document,content) {
    console.log(document)
    let objectDraft = {
      "status": true,
      "description": document.Assunto,
      "content": content,
      "path": document.path,
      "ownerId": document.ownerId
    }
    this.processService.SaveDraftByID(document.DocId, objectDraft).subscribe((res) => [
      this.erroHandler.httpsSucessMessagge('Draft Save'),
      this.modalController.dismiss()
    ],(error) => {
      this.erroHandler.httpStatusHandle(error)
    })

    this.modalController.dismiss();

  }

  saveDraft(document, content) {
    console.log(document)
    let objectDraft = {
      "status": false,
      "description": document.Assunto,
      "content": content,
      "path": document.path,
      "ownerId": document.ownerId
    }
    this.processService.SaveDraftByID(document.DocId, objectDraft).subscribe((res) => {
      this.erroHandler.httpsSucessMessagge('Draft Save');
      this.modalController.dismiss();
      window['tinyupdate']()
    },(error) => {
      this.erroHandler.httpStatusHandle(error)
    })
    
  }

  async Assinar() {
    let body = {
      "serialNumber": this.serialnumber,
      "action": "Assinado",
      "ActionTypeId": 99999842,
      "FolderId": this.FolderID,
      "dataFields": {
        "ReviewUserComment": '',
        "InstanceIDNew": this.InstanceID,
        "DraftIds": "",
      },
      "AttachmentList": {
        "ProcessInstanceID": this.InstanceID,
        "DraftIds": null,
        "Attachments": []
      },
    }


    try {
      await this.processes.CompleteTask(body).toPromise()
      this.erroHandler.httpsSucessMessagge('Assinado')
    } catch (error) {
      this.erroHandler.httpStatusHandle(error)
    }
    finally { }

  }

  async AssinarDraft() {
      const modal = await this.modalController.create({
        component: PopupQuestionPage,
        componentProps: {
          title: 'Deseja assinar este Diploma?',
         /*  message: 'Nota: Ao Efetuar esta operação, o tratamento deste diploma não poderá ser realizado a partir da caixa de correspondência' */
        },
        cssClass: "popup-question discart-expedient-modal",
        backdropDismiss: true
      });
  
      modal.onDidDismiss().then(async (res) => {
        const data = res.data
        if(data == "Yes") {

          console.log(' event detais Draft draft',this.DraftIds)
            let body = {
              "InstanceId": this.InstanceID,
              "FolderId": this.FolderID,
              "DraftIds": this.DraftIds,
              "OriginalFileName": this.DraftNames
            }
  
            const loader = this.toastService.loading()
  
  
            try {
              await this.processes.presidentialActionsSignature(body).toPromise()
  
              await this.Assinar();
              this.TaskService.loadDiplomas();
              this.goBackRoute();
            } catch (error) {
            this.erroHandler.httpStatusHandle(error)
            }
            finally {
              loader.remove()
            }
        }
  
      }, (error) => {
        console.log(error)
      });
  
      await modal.present();
 
  }

  goBackRoute() {
    this.RouteService.goBack();
  }

}

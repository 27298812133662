import { Observable, BehaviorSubject } from 'rxjs';
import { Result } from 'neverthrow';
import { HubConnection } from '@microsoft/signalr';
import { ISignalRInput } from './type';

export abstract class ISignalRService {
  abstract establishConnection(): Promise<Result<HubConnection, false>>;
  abstract sendData<T>(input: ISignalRInput): Promise<void>;
  abstract join(): void;
  abstract getData<T>(): Observable<{ method: string; data: T }>;
  abstract getConnectionState(): Observable<boolean>;
  abstract onReconnect(): Observable<boolean>;
  abstract newConnection(): void;
}
import { Component, OnInit } from '@angular/core';
import { MenuController, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { ProcessesService } from 'src/app/services/processes.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ExpedientTaskModalPage } from 'src/app/pages/gabinete-digital/expediente/expedient-task-modal/expedient-task-modal.page';
import { DocumentSetUpMeetingPage } from '../document-set-up-meeting/document-set-up-meeting.page';
import { SearchDocumentDetails, SearchFolderDetails } from 'src/app/models/search-document';
import { ExpedientTaskModalPageNavParamsTask } from 'src/app/models/ExpedientTaskModalPage';
import { PermissionService } from 'src/app/services/permission.service';
import { SearchedDocumentOptionsPage } from 'src/app/shared/popover/searched-document-options/searched-document-options.page';
import { Location } from '@angular/common';
import { ViewDocumentPage } from '../view-document/view-document.page';
import { ThemeService } from 'src/app/services/theme.service';


@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.page.html',
  styleUrls: ['./document-detail.page.scss'],
})
export class DocumentDetailPage implements OnInit {

  months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
  days = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];

  customDate:any;

  docId: string;
  applicationId:string;
  LoadedDocument:any = null;

  folder: SearchFolderDetails
  document:  SearchDocumentDetails

  task: ExpedientTaskModalPageNavParamsTask;
  DocumentTask: ExpedientTaskModalPageNavParamsTask;
  dicIndex = 0;

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private alertService: AlertService,
    private processes: ProcessesService,
    private menu: MenuController,
    private iab: InAppBrowser,
    public p: PermissionService,
    private popoverController: PopoverController,
    private location: Location,
    public ThemeService: ThemeService
  ) {
    this.docId = this.navParams.get('docId');
    this.applicationId = this.navParams.get('applicationId');
  }

  ngOnInit() {
    this.LoadDocumentDetails();
  }

  async LoadDocumentDetails() {
    this.processes.GetDocumentDetails(this.docId, this.applicationId).subscribe(res=>{

      this.LoadedDocument = res;

      this.LoadedDocument.Subject = this.LoadedDocument.Assunto

      let thedate = new Date(this.LoadedDocument.DateDispatch || this.LoadedDocument.DocDate);

      this.customDate = this.days[thedate.getDay()]+ ", "  + thedate.getDate() +" de "  + ( this.months[thedate.getMonth()]);

      let task: ExpedientTaskModalPageNavParamsTask
      let document: SearchDocumentDetails = this.LoadedDocument
      let folder: SearchFolderDetails = this.LoadedDocument

      if(this.LoadedDocument.ApplicationID == 361 || this.LoadedDocument.ApplicationId == 361) {

      } else if (document.ApplicationID == 8 || document.ApplicationId == 8) {

      } else {

      }


      task = {
        serialNumber: '',
        taskStartDate: '',
        isEvent: true,
        workflowInstanceDataFields: {
          FolderID: '',
          Subject: this.LoadedDocument.SourceName || this.LoadedDocument.Assunto,
          SourceSecFsID: this.LoadedDocument.ApplicationId || this.LoadedDocument.ApplicationID,
          SourceType: 'DOC',
          SourceID: this.LoadedDocument.DocId || this.LoadedDocument.SourceId || this.LoadedDocument['folderId'],
          DispatchNumber: ''
        }
      }

      this.DocumentTask = Object.assign(task, res)

    });

  }

  async viewDocument() {

    const modal = await this.modalController.create({
      component: ViewDocumentPage,
      componentProps: {
        trustedUrl: '',
        file: {
          title: this.LoadedDocument.Assunto,
          url: '',
          title_link: '',
        },
        Document: this.LoadedDocument.Documents,
        applicationId: this.DocumentTask.workflowInstanceDataFields.SourceSecFsID,
        docId: this.DocumentTask.workflowInstanceDataFields.SourceID,
        task: this.DocumentTask
      },
      cssClass: 'modal modal-desktop'
    });
    await modal.present();

  }

  docIndex(index: number) {
    this.dicIndex = index;
  }

  async viewDocumentModal() {

    const selectedDoc = this.LoadedDocument.Documents[ this.dicIndex]

    let task = {
      serialNumber: '',
      taskStartDate: '',
      isEvent: true,
      workflowInstanceDataFields: {
        FolderID: '',
        Subject: selectedDoc.SourceName || selectedDoc.Assunto,
        SourceSecFsID: selectedDoc.ApplicationId || selectedDoc.ApplicationID,
        SourceType: 'DOC',
        SourceID: selectedDoc.DocId || selectedDoc.SourceId,
        DispatchNumber: ''
      }
    }

    const modal = await this.modalController.create({
      component: ViewDocumentPage,
      componentProps: {
        trustedUrl: '',
        file: {
          title: task.workflowInstanceDataFields.Subject,
          url: '',
          title_link: '',
        },
        Document: this.LoadedDocument.Documents[ this.dicIndex],
        applicationId: task.workflowInstanceDataFields.SourceSecFsID,
        docId: task.workflowInstanceDataFields.SourceID ,
        folderId: '',
        task: task
      },
      cssClass: 'modal modal-desktop'
    });
    await modal.present();
  }

  openMenu() {
    this.menu.open();
    this.modalController.dismiss();
  }

  close() {
    this.modalController.dismiss();
  }

  notImplemented() {
    this.alertService.presentAlert('Funcionalidade em desenvolvimento');
  }

  // efetuar despacho
  async openExpedientActionsModal( taskAction: any) {

    let classs;
    if( window.innerWidth < 701) {
      classs = 'modal modal-desktop'
    } else  {
      classs = 'modal modal-desktop showAsideOptions'
    }
    const modal = await this.modalController.create({
      component: ExpedientTaskModalPage,
      componentProps: {
        taskAction: taskAction,
        task: this.DocumentTask,
        aplicationId: this.LoadedDocument.ApplicationId || this.LoadedDocument.ApplicationID
      },
      cssClass: classs,
    });

    modal.onDidDismiss().then( async(res)=>{}, (error) => {
      console.log(error)
    });

    await modal.present();

  }

  async openBookMeetingModal() {

    let classs;
    if( window.innerWidth < 701) {
      classs = 'book-meeting-modal modal modal-desktop'
    } else  {
      classs = 'modal modal-desktop showAsideOptions'
    }
    const modal = await this.modalController.create({
      component: DocumentSetUpMeetingPage,
      componentProps: {
        subject: this.LoadedDocument.Assunto,
        document: this.LoadedDocument,
      },
      cssClass: classs,
      backdropDismiss: false
    });

    modal.onDidDismiss().then(res => {
      //this.RouteService.goBack();
    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }

  async openOptions() {
    const popover = await this.popoverController.create({
      component: SearchedDocumentOptionsPage,
      cssClass: 'exp-options',
      componentProps: {
        task: this.task,
        LoadedDocument: this.LoadedDocument
      },
      translucent: true
    });


    popover.onDidDismiss().then((res:any) => {
      if(res.data.component == 'openBookMeetingModal') {
        this.openBookMeetingModal()
      } else if(res.data.component == 'openExpedientActionsModal') {
        this.openExpedientActionsModal(res.data.taskAction)
      }
    }, (error) => {
      console.log(error)
    });

    await popover.present()
  }

}

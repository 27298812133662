import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { ToastService } from 'src/app/services/toast.service';
import { ThemeService } from 'src/app/services/theme.service'
import { SetRoomOwnerPage } from 'src/app/ui/chat/modal/set-room-owner/set-room-owner.page';
import { SessionStore } from 'src/app/store/session.service';
import { ZodError } from 'zod';

import { ChatServiceService } from 'src/app/module/chat/domain/chat-service.service'
import { RoomInfoPage } from '../room-info/room-info.page';
import { RoomType } from 'src/app/core/chat/entity/group';
import { isHttpResponse } from 'src/app/infra/http/http.service';

@Component({
  selector: 'app-chat-popover',
  templateUrl: './chat-popover.page.html',
  styleUrls: ['./chat-popover.page.scss'],
})
export class ChatPopoverPage implements OnInit {
  roomId:string;
  room: any;
  isAdmin = false;
  isGroupCreated: boolean;
  showLoader = false
  EnumRoomType = RoomType
  roomType!: RoomType

  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController,
    private navParams: NavParams,
    private toastService: ToastService,
    public ThemeService: ThemeService,
    private ChatServiceService: ChatServiceService
  ) {
    this.roomId = this.navParams.get('roomId');
    this.isAdmin = this.navParams.get('isAdmin');
    this.roomType = this.navParams.get('roomType');

  }

  ngOnInit() {
    // this.chatService.refreshtoken();
  }

  close(action:any){
    if( window.innerWidth < 701){
      this.popoverController.dismiss(action);
    }
    else{
      this.modalController.dismiss(action)
    }
  }

   //Top menu options
   //Close

   async setRoomOwner() {
    let classs;
    if (window.innerWidth < 701) {
      classs = 'modal modal-desktop'
    } else {
      classs = 'centered-rounded-modal'
    }
    const modal = await this.modalController.create({
      component: SetRoomOwnerPage,
      cssClass: classs,
      backdropDismiss: true,
      componentProps: {
        roomId: this.roomId,
        isAdmin: this.isAdmin
      }
    });
    await modal.present();
      modal.onDidDismiss().then((res)=>{
        if(res.data == 'success'){
          this.leaveGroup();
          //this.ChatSystemService.hidingRoom(this.roomId);
        }
    });
   }


   setAdmin() {
    this.setRoomOwner();
   }

   async leaveGroup() {

    //this.setRoomOwner();
    this.showLoader = true
    const result = await this.ChatServiceService.leaveRoom({
      id: this.roomId,
      members: [SessionStore.user.UserId]
    })


    if(result.isOk()) {
      this.close('leave');
      // this.openGroupMessage.emit(this.roomId);
    } else if (isHttpResponse(result.error)) {
      if(result.error.status == 404) {
        this.close('leave');
      }
      // this.httpErrorHandle.httpStatusHandle(result.error)
    } else if (result.error instanceof ZodError) {
      this.toastService._badRequest("Pedimos desculpa mas não foi possível executar a acção. Por favor, contacte o apoio técnico.")
      console.log(result.error.errors)
    } else {
      this.toastService._badRequest("Pedimos desculpa mas não foi possível executar a acção. Por favor, contacte o apoio técnico.")
      console.log(result.error)
    }

    this.showLoader = false
  }

  //Delete
  async deleteGroup() {
    this.showLoader = true

    const result = await this.ChatServiceService.deleteRoomById(this.roomId)


    if(result.isOk()) {
      this.close('delete');
      // this.openGroupMessage.emit(this.roomId);
    } else if (isHttpResponse(result.error)) {
      if(result.error.status == 404) {
        this.close('close');
      }
    } else if (result.error instanceof ZodError) {
      this.toastService._badRequest("Pedimos desculpa mas não foi possível executar a acção. Por favor, contacte o apoio técnico.")
      console.log(result.error.errors)
    } else {
      this.toastService._badRequest("Pedimos desculpa mas não foi possível executar a acção. Por favor, contacte o apoio técnico.")
      console.log(result.error)
    }

    this.showLoader = false

  }

  async openChangeGroupName(){
    this.close('edit');
  }


  async addUser() {
    this.close('addUser');
  }


  async details() {
    const modal = await this.modalController.create({
      component: RoomInfoPage,
      cssClass: 'modal-aside',
      backdropDismiss: true,
      componentProps: {
        roomId: this.roomId
      }
    });
    await modal.present();
    modal.onDidDismiss().then((res)=>{
      if(res.data == 'success') {
        this.leaveGroup();
        //this.ChatSystemService.hidingRoom(this.roomId);
      }
    });
  }

}

interface attendees  {

  EmailAddress : string
  Name: string
  UserType: string
  attendeeType: string
  wxUserId: string
  Id: string
}

type Changes = {
  insert: attendees[];
  remove: attendees[];
};

export function AttendeesLIstChangeDetector(
  localList: attendees[],
  serverList: attendees[]
): Changes {
  const changes: Changes = { insert: [], remove: [] };

  const localMap = new Map(localList.map(item => [item.wxUserId, item]));
  const serverMap = new Map(serverList.map(item => [item.wxUserId, item]));

  // Detect new or updated items
  for (const [id, serverItem] of serverMap) {
    const localItem = localMap.get(id);
    if (!localItem) {
      changes.insert.push(serverItem);
    }
  }

  // Detect deleted items
  for (const [id, localItem] of localMap) {
    if (!serverMap.has(id)) {
      changes.remove.push(localItem);
    }
  }

  return changes;
}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

export type EventRecurrenceComponentReturn = 'EditAll' | 'EditOne' | 'Cancel'

@Component({
  selector: 'app-event-recurrence',
  templateUrl: './event-recurrence.page.html',
  styleUrls: ['./event-recurrence.page.scss'],
})
export class EventRecurrencePage implements OnInit {

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  close(data: EventRecurrenceComponentReturn) {
    this.modalController.dismiss(data)
  }
}

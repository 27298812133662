import { z } from "zod";
import { EntityTable } from 'Dexie';
import { RoomType } from "src/app/core/chat/entity/group";
import { MessageEntity, MessageEntitySchema } from "src/app/core/chat/entity/message";
import { MessageTableSchema } from "./message";

export const RoomTableSchema = z.object({
  id: z.string().uuid(),
  roomName: z.string(),
  createdBy: z.object({
    wxUserId: z.number(),
    wxFullName: z.string(),
    wxeMail: z.string().email(),
    userPhoto: z.string().nullable().optional()// api check
  }),
  createdAt: z.any(),
  expirationDate: z.any().nullable(),
  roomType: z.nativeEnum(RoomType),
  messages: MessageTableSchema.array().optional(),
  bold: z.number().optional()
})

export type RoomTable = z.infer<typeof RoomTableSchema>
export type DexieRoomsTable = EntityTable<RoomTable, 'id'>;
export const RoomTableColumn = 'id, createdBy, roomName, roomType, expirationDate, lastMessage'

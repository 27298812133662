<ion-header class="ion-no-border">
</ion-header>

<ion-content>
  <div class="header-content width-100">
    <div class="header-title d-flex width-100">
      <h3>Deseja arquivar este expediente?</h3>
    </div>
  </div>
  <div class="header-body width-100">
     <p>Nota: Ao Efetuar esta operação, o tratamento deste expediente não poderá ser realizado a partir da caixa de correspondência</p>
   </div>
</ion-content>
<ion-footer>
  <div class="buttons width-100">
    <button class="btn-ok-medium" shape="round" (click)="close()">Não</button>
    <button class="btn-delete-medium" shape="round" (click)="save()">Sim</button>
  </div>

</ion-footer>

import { Injectable } from '@angular/core';

/* import { Plugins, CameraResultType, Capacitor, FilesystemDirectory, CameraPhoto, CameraSource } from '@capacitor/core'; */
import { Photo } from '../models/photo';
import { Platform } from '@ionic/angular';
/* import { Camera, CameraOptions } from '@ionic-native/camera/ngx'; */

  /* const { Camera, Filesystem, Storage } = Plugins; */

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  public photos: Photo[] = [];
  private PHOTO_STORAGE: string = "photos";
  private platform: Platform;

  constructor(platform: Platform,
    /* private camera:Camera */
    ) {
    this.platform = platform;
   }

  async takePicture(){
    /* const options: CameraOptions = {
      quality: 100,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE
    }

    
    
    this.camera.getPicture(options).then((imageData) => {
     // imageData is either a base64 encoded string or a file URI
     // If it's base64 (DATA_URL):
     let base64Image = 'data:image/jpeg;base64,' + imageData;
     return base64Image;
    }, (err) => {
     // Handle error
    }); */

  }


 /*  async addNewToGallery() {
    // Take a photo using Capacitor
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Base64, 
      source: CameraSource.Camera, 
      quality: 90,
      width: 1080,
      height: 720,
    });
    
    this.photos.unshift({
      fileName: new Date().getTime() + '.png',
      webviewPath: capturedPhoto.base64String,
      fileFormat: capturedPhoto.format,
    });

    
    
    
    
    
  } */

}

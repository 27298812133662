import { Injectable } from '@angular/core';
import { SignalRService } from 'src/app/infra/socket/signalR/signal-r.service';
import { filter, map } from 'rxjs/operators';
import { z } from 'zod';
import { SocketMessage } from 'src/app/infra/socket/signalR/signalR';
import { v4 as uuidv4 } from 'uuid'
import { IRoomSocketRepository } from 'src/app/core/chat/repository/room/room-socket-repository';
import { CreateRoomInputDTO } from '../../../domain/use-case/room/room-create-use-case.service';

const listenToDeleteRoomInputSchema = z.object({
  roomId: z.string()
})


export const RoomSocketOutPutDTOSchema = z.object({
  id: z.string(),
  roomName: z.string(),
  createdBy: z.any().nullable(),
  createdAt: z.string(),
  expirationDate: z.string().nullable(),
  roomType: z.any()
});

export type RoomSocketOutPutDTO = z.infer<typeof RoomSocketOutPutDTOSchema>


export type ListenToDeleteRoomInput = z.infer<typeof listenToDeleteRoomInputSchema>

@Injectable({
  providedIn: 'root'
})
export class RoomSocketRepositoryService implements IRoomSocketRepository {

  constructor(
    private socket: SignalRService
  ) { }


  async CreateGroup(data: CreateRoomInputDTO) {
    const result =  await this.socket.sendData<RoomSocketOutPutDTO>({
      method: 'CreateGroup',
      data: {
        ...data,
        requestId: uuidv4()
      }  as any,
    })

    return result;
  }

  listenToCreateRoom() {
    return this.socket.getData().pipe(
      filter((data) => data?.method == 'UserAddGroup')
    )
  }

  listenToDeleteRoom() {
    return this.socket.getData<any>().pipe(
      filter((data): data is SocketMessage<ListenToDeleteRoomInput> => 
        data?.method === 'UserRemoveGroup'
      ),
      map((e) => e.data)
    );
  }
}

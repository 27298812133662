import { Pipe, PipeTransform } from '@angular/core';
import { Attachments, SearchList } from '../models/search-document';

@Pipe({
  name: 'searchDocument'
})
export class SearchDocumentPipe implements PipeTransform {

  transform(document): unknown {
    return null;
  }

  transformToAttachment(document: SearchList): Attachments[] {
    const attachments = []

    if(document.ApplicationType == 361) {
      attachments.push({
        ApplicationId: 361,
        Source: 1,
        SourceId: document['docID'] || document['docId'] || document['DocId'] || document.Id,
        SourceName: document.Assunto,
        SourceType: 'FOLDER'
      })
    } else if (document.ApplicationType == 8) {
      attachments.push({
        ApplicationId: 8,
        Source: 1,
        SourceId: document['docID'] || document['docId'] || document['DocId'] || document.Id,
        SourceName: document.Assunto,
        SourceType: 'WEBTRIX'
      })
    }

    return attachments;
  }

}

import { z } from 'zod';


export const AttendeeInputDTOSchema = z.array(z.object({
  name: z.string(),
  emailAddress: z.string(),
  attendeeType: z.number(),
  wxUserId: z.number(),
  userType: z.enum(['GD','External', 'Internal']),
  entity: z.string()
}))


export type AttendeeInputDTO = z.infer<typeof AttendeeInputDTOSchema>

import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavParams } from '@ionic/angular';
import { ExpedientTaskModalPageNavParamsTask } from 'src/app/models/ExpedientTaskModalPage';
import { ExpedientTaskModalPage } from 'src/app/pages/gabinete-digital/expediente/expedient-task-modal/expedient-task-modal.page';
import { ProcessesService } from 'src/app/services/processes.service';
import { EventDetailsDocumentsOptionsPage } from 'src/app/shared/popover/event-details-documents-options/event-details-documents-options.page';
import { DocumentSetUpMeetingPage } from '../document-set-up-meeting/document-set-up-meeting.page';
import { AlertController, ModalController } from '@ionic/angular';
import { SearchList } from 'src/app/models/search-document';

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.page.html',
  styleUrls: ['./view-document.page.scss'],
})
export class ViewDocumentPage implements OnInit {

  trustedUrl: any;
  file: any;
  applicationId: any;
  docId: any
  task: ExpedientTaskModalPageNavParamsTask;
  Document: any
  loader = true
  attachment: SearchList[] = [];

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private sanitazer: DomSanitizer,
    private processes: ProcessesService,
    private alertController: AlertController,
  ) {
    this.file = this.navParams.get('file');
    this.applicationId = this.navParams.get('applicationId');
    this.docId = this.navParams.get('docId');
    this.Document = this.navParams.get('Document')
    this.task = this.navParams.get('task')
    this.attachment = this.navParams.get('attachment')


    if(!this.file.title) {
      this.file.title = 'Sem Título'
    }

    console.log({
      file: this.file,
      applicationId: this.applicationId,
      docId: this.docId,
      Document: this.Document,
      task: this.task,
      attachment: this.attachment
    })

  }

  ngOnInit() {

    this.processes.GetViewer(this.docId, this.applicationId).subscribe(async(res)=> {

      const link: string = res.replace('//pdfjs/web/', '/pdfjs/web/')
      this.trustedUrl = this.sanitazer.bypassSecurityTrustResourceUrl(link);

      // const iframe = document.getElementById("iframe")
      // const handleLoad = () => {
      //   this.loader = false
      // };

      // iframe.addEventListener('load', handleLoad, true)

      if(res == "") {
        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          //header: 'Apagar evento!',
          message: 'Sem imagem',
          buttons: [
            {
              text: 'Sim',
              handler: () => {
                this.close();
              }
            }
          ]
        });
      }

    }, ()=>{
      this.close();
    });

  }



  // @XTracerAsync({name:'view-document/LoadViewer', bugPrint: true, daley: 4000})
  // async LoadViewer(tracing?: TracingType) {
  //   this.processes.GetViewer(this.docId, this.applicationId).subscribe(async(res)=> {

  //     const link: string = res.replace('//pdfjs/web/', '/pdfjs/web/')
  //     this.trustedUrl = this.sanitazer.bypassSecurityTrustResourceUrl(link);

  //     // const iframe = document.getElementById("iframe")
  //     // const handleLoad = () => {
  //     //   this.loader = false
  //     // };

  //     // iframe.addEventListener('load', handleLoad, true)

  //     if(res == "") {
  //       const alert = await this.alertController.create({
  //         cssClass: 'my-custom-class',
  //         //header: 'Apagar evento!',
  //         message: 'Sem imagem',
  //         buttons: [
  //           {
  //             text: 'Sim',
  //             handler: () => {
  //               this.close();
  //             }
  //           }
  //         ]
  //       });
  //     }

  //     tracing.setAttribute('outcome', 'success')

  //   }, (error)=>{
  //     if(isHttpError(error)) {
  //       tracing?.setAttribute('status.code', error.status.toString())
  //       if (error.status == 400) {
  //         tracing?.bugFlag()
  //         tracing?.setAttribute('outcome', 'failed')
  //       }
  //     }
  //     tracing.setAttribute('outcome', 'failed')
  //     this.close();
  //   });

  // }



  close() {
    this.modalController.dismiss();
  }

  async openOptions() {
    const modal = await this.modalController.create({
      component: EventDetailsDocumentsOptionsPage,
      cssClass: 'model aside-modal search-submodal',
      componentProps: {
        content: "", // check
        Document:this.Document // check
      }
    });
    await modal.present();

    modal.onDidDismiss().then((res)=>{

      if(res.data) {
        if(res.data.component == 'openBookMeetingModal') {
          this.openBookMeetingModal()
        } else if(res.data.component == 'openExpedientActionsModal') {
          this.openExpedientActionsModal(res.data.taskAction)
        } else {

        }
      }

    }, (error) => {
      console.log(error)
    })
  }

  async openBookMeetingModal() {
    //console.log('this.Document----------', this.Document)
    let classs;
    if( window.innerWidth < 701) {
      classs = 'book-meeting-modal modal modal-desktop'
    } else  {
      classs = 'modal modal-desktop showAsideOptions'
    }

    // check passing
    const modal = await this.modalController.create({
      component: DocumentSetUpMeetingPage,
      componentProps: {
        subject: this.task.workflowInstanceDataFields.Subject, // check
        document: this.Document, // document
      },
      cssClass: classs,
      backdropDismiss: false
    });

    modal.onDidDismiss().then(res=>{
      //this.RouteService.goBack();
    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }

  // efetuar despacho

  async openExpedientActionsModal( taskAction: any) {

    let classs;
    if( window.innerWidth < 701) {
      classs = 'modal modal-desktop'
    } else  {
      classs = 'modal modal-desktop showAsideOptions'
    }
    // check passing

    //console.log('this.Document----------openExpedientActionsModal', this.Document)
    const modal = await this.modalController.create({
      component: ExpedientTaskModalPage,
      componentProps: {
        taskAction: taskAction, // check
        task: this.task, // check
        document: this.Document, // nope
        aplicationId: this.Document.ApplicationId || this.Document.ApplicationID, // check
        applicationId: this.applicationId,
        docId: this.docId,
        createProcessFromFile: true,
      },
      cssClass: classs,
    });

    modal.onDidDismiss().then(
      async(res)=>{}
      , (error) => {
        console.log(error)
      }
    );

    await modal.present();

  }

}

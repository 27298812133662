<ion-header>
  <ion-toolbar>
    <ion-title>cropImage</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div style="height: 100%; width: 100%;">
    <image-cropper
    [imageBase64]="this.base64ToCroppe"
    [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1"
    format="jpeg"
    allowMoveImage="true"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
  </div>

<!--  <div>
  <button (click)="save()">Salvar</button>
  <button (click)="cancel()">Cancelar</button>
 </div> -->

</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar class="footer-toolbar">
    <ion-buttons slot="start">
      <button class="btn-ok" fill="clear" color="#fff" (click)="save()">
        <ion-label>Gravar</ion-label>
      </button>
    </ion-buttons>
    <ion-buttons slot="end">
      <button class="btn-cancel" fill="clear" color="#061b52" (click)="cancel()">
        <ion-label>Cancelar</ion-label>
      </button>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>

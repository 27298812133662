<ion-content>
  <div class="p-header">
    <h3>Duração</h3>
  </div>
  <div class="picker">

  </div>
  <div class="p-buttons">
    <ion-row>
      <ion-col>
        <button (click)="close()" full class="btn-cancel" shape="round" >Cancelar</button>
      </ion-col>
      <ion-col>
        <button class="btn-ok" shape="round" >Ok</button>
      </ion-col>
    </ion-row>
  </div>

</ion-content>

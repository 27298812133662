import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class checkFileTypeService {

  constructor(private router: Router) {

  }

  checkFileType(fileType) {
    // Convert to lowercase to handle case-insensitivity
    const lowerCaseType = fileType.toLowerCase();
 
    // Define image and video extensions/types
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp','tiff','tif',
    'image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp','image/tiff','image/tif', 'image/*']; // Add more if needed
    const videoExtensions = ['mp4', 'webm', 'mpg', 'mpeg', 'ogg',
    'video/mp4', 'video/webm', 'video/mpg', 'video/mpeg', 'video/ogg','video/quicktime','MOV','mov', 'video/*']; // Add more if needed

    // Check if it's an image
    if (imageExtensions.includes(lowerCaseType)) {
      return 'image';
    }

    // Check if it's a video
    if (videoExtensions.includes(lowerCaseType)) {
      return 'video';
    }

    // If it's neither image nor video
    return 'null';
  }
}

import { Injectable, Input } from '@angular/core';
import { IAttachmentRemoteRepository } from 'src/app/core/chat/repository/attachment/attachment-remote-repository';
import { HttpService } from 'src/app/services/http.service';
import { DataSourceReturn } from 'src/app/services/Repositorys/type';

@Injectable({
  providedIn: 'root'
})
export class AttachmentRemoteDataSourceService implements IAttachmentRemoteRepository {
  private baseUrl = 'https://gdapi-dev.dyndns.info/stage/api/v2/Chat'; // Your base URL

  constructor(
    private httpService: HttpService
  ) { }

  async getAttachment(id: string | number): DataSourceReturn<Blob>  {
    return await this.httpService.getFile(`${this.baseUrl}/attachment/${id}`, { responseType: 'blob' });
  }

}

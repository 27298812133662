import { Injectable } from '@angular/core';
import { APIReturn } from 'src/app/services/decorator/api-validate-schema.decorator';
import { HttpService } from 'src/app/services/http.service';
import { ContactCombinedOutputDTO, EventListDataOutputDTOSchema } from '../DTO/contactsCombined';

export interface UserContacts {
  wxUserId: number;
  wxFullName: string;
  wxeMail: string | null;
  userPhoto: string | null;
}

export interface UserListResult {
  total: number,
  result: UserContacts[]
}

export interface UserList {
  success: boolean;
  message: string;
  data: UserListResult;
}

@Injectable({
  providedIn: 'root'
})
export class ContactsDataSourceService {

  private baseUrl = 'https://gdapi-dev.dyndns.info/stage/api/v2'; // Your base URL

  constructor(private httpService: HttpService) {}


  async getUsers() {
    return await this.httpService.get<UserList>(`${this.baseUrl}/Users`);
  }


  @APIReturn(EventListDataOutputDTOSchema, '/Contacts/Combined')
  async getCombinedList() {
    return await this.httpService.get<ContactCombinedOutputDTO>(`${this.baseUrl}/Contacts/Combined`);
  }
}

import { Injectable } from '@angular/core';
import { RoomSocketRepositoryService } from 'src/app/module/chat/data/repository/room/room-socket-repository.service'
@Injectable({
  providedIn: 'root'
})
export class RoomService {

  constructor(
    private RoomSocketRepositoryService: RoomSocketRepositoryService
  ) {}
  
  init() {
    this.OnReceiveCreateRoom()
    this.OnDeleteCreateRoom()
  }


  OnReceiveCreateRoom() {
    this.RoomSocketRepositoryService.listenToCreateRoom().subscribe((data)=> {
      console.log('OnReceiveCreateRoom', data)
    })
  }

  OnDeleteCreateRoom() {
    this.RoomSocketRepositoryService.listenToDeleteRoom().subscribe((data)=> {
      console.log('OnDeleteCreateRoom7', data)
    })
  }
}

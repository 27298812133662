import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AnimationController, ModalController,Platform } from '@ionic/angular';
import { SHA1 }  from  'crypto-js'
import { localstoreService } from '../store/localstore.service';
/* import { Plugins } from '@capacitor/core';
const { Storage } = Plugins; */
@Injectable({
  providedIn: 'root'
  })
  export class StorageService {
    private keyName: string;

  constructor(private storage:Storage,
    private platform: Platform
  ) {}

  key(key:string): string {
    return (SHA1('service'+"StorageService"+key)).toString()
  }

  async keyExist(key:string) {
    return await localstoreService.keyExist(this.key(key))
  }

  // Store the value
  async store(key: string, value: any) {

    await localstoreService.set(this.key(key), value)

  }
  // Get the value
  async get(key: string): Promise<any> {

    return new Promise((resolve, reject)=>{
      const data =  localstoreService.get(this.key(key), false)
      if(data) resolve(data)
      else reject(data)
    })

  }

  async remove(key: string){
    
    await localstoreService.delete(this.key(key))
    
  }

/*
  // Get the value
  async get(storageKey: string) {
  const ret = await Storage.get({ key: storageKey });
  return JSON.parse(unescape(atob(ret.value)));
  }

  async removeStorageItem(storageKey: string) {
  await Storage.remove({ key: storageKey });
  }

  // Clear storage
  async clear() {
  await Storage.clear();
  } */
}

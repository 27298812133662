export class UserForm {
  username: string;
  password: string;
  domainName: string;
  BasicAuthKey: string;
}


export class LoginUserRespose {
  BasicAuthKey: string;
  UserId: number;
  Authorization: string;
  ChatData: {
    status: string,
    data: {
      userId: string,
      authToken: string
    }
  }
  Email: string
  FullName: string
  OwnerCalendars: {
    CalendarId: string
    CalendarName: "Oficial" | "Pessoal";
    CalendarRoleId: string;
    Id: number;
  }[]
  RoleDescription: string
  RoleID: number
  SharedCalendars: {
    CalendarId: string
    CalendarName: "Oficial" | "Pessoal";
    CalendarRoleId: string;
    Id: number;
    OwnerUserId: string;
    TypeShare: number;
  }[]
  UserName: string
  Profile: any;
  UserPermissions: any;
}


export class  calendarInterface {
  CalendarId: string
  CalendarName: "Oficial" | "Pessoal";
  CalendarRoleId: string;
  Id: number;
  OwnerUserId: any
}

export class UserSession {
  BasicAuthKey: string;
  UserId: number;
  Authorization: string;
  ChatData: {
    status: string,
    data: {
      userId: string,
      authToken: string
    }
  }
  Email: string
  FullName: string
  ManagerName: string
  OwnerCalendars: {
    CalendarId: string
    CalendarName: "Oficial" | "Pessoal";
    CalendarRoleId: string;
    Id: number;
    OwnerUserId: any
  }[]
  RoleDescription: string
  RoleID: number
  SharedCalendars: {
    CalendarId: string
    CalendarName: "Oficial" | "Pessoal";
    /**
     * @description User Role Id
     */
    CalendarRoleId: string;
    Id: number;
    OwnerUserId: string;
    TypeShare: number;
    /**
     * @description deprecated
     */
    CalendarToken: string;
  }[]
  UserName: string
  Password: string
  RochetChatUserId: string
  Profile: 'PR' | 'MDGPR' | 'Consultant' | 'SGGPR' | 'Unknown'  ;
  LoginPreference: 'None' | 'Password' | 'Pin' | null;
  PIN: string
  Inactivity: boolean
  UrlBeforeInactivity: string;
  UserPermissions: any;
  UserPhoto: string;
  RefreshToken: string
}
import { z } from 'zod';
import { EEventCategory, EEventOwnerType, EEventStatus, EEventType } from './enums';

const OwnerSchema = z.object({
  wxUserId: z.number(),
  wxFullName: z.string(),
  wxeMail: z.string(),
  userPhoto: z.string(),
});


export const EventListOutputDTOSchema = z.object({
  id: z.string(),
  owner: OwnerSchema,
  ownerType: z.nativeEnum(EEventOwnerType),// ['MD','PR', 'Other']  // Assuming "MD" is the only valid option based on provided data
  subject: z.string(),
  body: z.string().optional(),
  location: z.string().nullable(),
  startDate: z.string().datetime({ offset: true }),
  endDate: z.string().datetime({ offset: true }),
  type: z.nativeEnum(EEventType), // ['Meeting', 'Travel'] = [1,2 ]
  // category: z.enum(['Oficial', 'Pessoal']), // Assuming "Oficial" is the only valid option based on provided data
  category: z.nativeEnum(EEventCategory),
  isRecurring: z.boolean(),
  eventRecurrence: z.any().nullable(),
  hasAttachments: z.boolean(),
  isPrivate: z.boolean(),
  isAllDayEvent: z.boolean(),
  // status: z.enum(['Approved']), // Assuming "Approved" is the only valid option based on provided data
  status: z.nativeEnum(EEventStatus), // Assuming "Approved" is the only valid option based on provided data
  createdAt:  z.string().datetime({ offset: true }),
})

export const EventListDataOutputDTOSchema = z.object({
  success: z.boolean(),
  message: z.string(),
  data: z.array(EventListOutputDTOSchema),
}).nullable();

export type EventListOutputDTO = z.infer<typeof EventListDataOutputDTOSchema>;

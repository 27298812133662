// import { DateUtils } from './date';

import { environment } from "src/environments/environment";
import { openTelemetryLogging } from "../../monitoring/opentelemetry/logging";
import { SessionStore } from "src/app/store/session.service";
import { DeviceInfo } from "@capacitor/device";
import { Device } from '@capacitor/device';

export type MessageType = {
  message: string;
  context?: string;
  obj?: object;
};

function getCurrentTime() {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
}

let device: DeviceInfo;

Device.getInfo().then(e => {
  device = e
});

export class Logger {

  constructor() {}

  static log(message: string, obj = {}): void {
    console.log(
      `[${getCurrentTime()}] %cINFO : `, // Console Message
      'color: #00897B', // CSS Style
      Object.assign(obj, { createdAt: getCurrentTime(), message })
    );


    if(environment.apiURL != 'https://gdqas-api.oapr.gov.ao/api/') {
      openTelemetryLogging.send({
        type: 'graylog',
        spanContext: null,
        payload: {
          message: message,
          object: {
            ...obj,
            spanId: null,
            name,
            user:  SessionStore?.user?.FullName,
            device_name: device?.name || device?.model,
            commit_date: environment.version.lastCommitTime,
          }
        }
      })
    }
  }

  static  debug(message: string, obj = {}): void {
    console.info(
      `[${getCurrentTime()}] %cINFO : `, // Console Message
      'color: #039BE5', // CSS Style
      Object.assign(obj, {createdAt: getCurrentTime(), message })
    );

    if(environment.apiURL != 'https://gdqas-api.oapr.gov.ao/api/') {
      openTelemetryLogging.send({
        type: 'graylog',
        spanContext: null,
        payload: {
          message: message,
          object: {
            ...obj,
            spanId: null,
            name,
            user:  SessionStore?.user?.FullName,
            device_name: device?.name || device?.model,
            commit_date: environment.version.lastCommitTime,
          }
        }
      })
    }
  }

  static  info(message: string, obj = {}): void {
    console.info(
      `[${getCurrentTime()}] %cINFO : `, // Console Message
      'color: #039BE5', // CSS Style
      message+' '+
      '\n',
      obj,
      '\n',
    );

    if(environment.apiURL != 'https://gdqas-api.oapr.gov.ao/api/') {
      openTelemetryLogging.send({
        type: 'graylog',
        spanContext: null,
        payload: {
          message: message,
          object: {
            ...obj,
            spanId: null,
            name,
            user:  SessionStore?.user?.FullName,
            device_name: device?.name || device?.model,
            commit_date: environment.version.lastCommitTime,
          }
        }
      })
    }
  }

  static  warn(message: string, obj = {}): void {
    console.warn(
      `[${getCurrentTime()}] %cWARN : `, // Console Message
      'color: #FB8C00', // CSS Style
      message+' '+
      '\n',
      obj,
      '\n',
    );

    if(environment.apiURL != 'https://gdqas-api.oapr.gov.ao/api/') {
      openTelemetryLogging.send({
        type: 'graylog',
        spanContext: null,
        payload: {
          message: message,
          object: {
            ...obj,
            spanId: null,
            name,
            user:  SessionStore?.user?.FullName,
            device_name: device?.name || device?.model,
            commit_date: environment.version.lastCommitTime,
          }
        }
      })
    }
  }

  static  error(message?: string, obj = {}): void {

    console.error(
      `[${getCurrentTime()}] %cERROR : `, // Console Message
      'color: #E53935', // CSS Style
      message+' '+
      '\n',
      obj,
      '\n',
    );

    if(environment.apiURL != 'https://gdqas-api.oapr.gov.ao/api/') {
      openTelemetryLogging.send({
        type: 'graylog',
        spanContext: null,
        payload: {
          message: message,
          object: {
            ...obj,
            spanId: null,
            name,
            user:  SessionStore?.user?.FullName,
            device_name: device?.name || device?.model,
            commit_date: environment.version.lastCommitTime,
          }
        }
      })
    }
  }

  fatal(error?: any, message?: string, context?: string): void {}
}

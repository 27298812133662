import { z } from 'zod';

export const AttachInputDTOSchema = z.object({
  attachments: z.array(z.object({
    docId: z.any(),
    sourceName: z.any(),
    description: z.any().nullable(),
    applicationId: z.any(),
  }))
  
})

export type AttachInputDTO = z.infer<typeof AttachInputDTOSchema>

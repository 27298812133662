import { Injectable } from '@angular/core';
import { EventPerson } from '../models/eventperson.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { LoginUserRespose } from '../models/user.model';
import { SessionStore } from '../store/session.service';
import { ChangeProfileService } from './change-profile.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  authheader = {};
  loggeduser: LoginUserRespose;
  headers: HttpHeaders;

  constacts: EventPerson[] = []

  constructor(
    private http: HttpClient,
    user: AuthService,
    private changeProfileService: ChangeProfileService) { 

    this.setHeader()
    this.changeProfileService.registerCallback(() => {
      this.setHeader()
    })

    this.getContacts("").subscribe( result => {
      this.constacts = result
    })
  }

  setHeader() {
    this.loggeduser = SessionStore.user;
    this.headers = new HttpHeaders();;
    this.headers = this.headers.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
  }

  getContacts(namefilter:string): Observable<EventPerson[]>{
    /* const geturl = environment.apiURL + 'contacts/get' */;
    const geturl = environment.apiURL + 'userauthentication/list'
    let params = new HttpParams();

    /* params = params.set("namefilter", namefilter);
    params = params.set("domain", environment.domain); */

    params = params.set("namefilter", namefilter);
    params = params.set("domain", "");
 
    let options = { 
      headers: this.headers, 
      params: params 
    };

    return this.http.get<EventPerson[]>(`${geturl}`, options);
  }
}

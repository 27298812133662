
var month = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
]

var week = new Array('Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado');
var weekV = new Array('Do', 'Seg', 'Terç', 'Qua', 'Qui', 'Sex', 'Sáb');

class momentG {
    static run(date, formate , wgs){


      if(! date instanceof Date){
        date = new Date(date)
      }

        let toChange = {}

        //

        var  e = {
            d   : (date.getDate()).toString() ,
            M   : (date.getMonth()).toString() ,
            ss  : (date.getSeconds().toString()).padStart(2,"0"),
            mm : (date.getMinutes().toString()).padStart(2,"0") ,
            dd   : (date.getDate()).toString().padStart(2,"0") ,
            MM   : (date.getMonth()+1).toString().padStart(2,"0"),
            HH : date.getHours().toString().padStart(2,"0"),
            yy: date.getFullYear().toString().slice(2,4).padStart(2,"0") ,
            EEEE  : week[date.getDay()].padStart(2,"0") ,
            EEEEV  : weekV[date.getDay()].padStart(2,"0") ,
            MMMM : month[date.getMonth()],
            yyyy : date.getFullYear() ,
        }

        Object.keys(e).reverse().forEach((element, index) => {
            toChange[index] = e[element];
            var reg = new RegExp(element,'g')
            formate = formate.replace(reg, `___${index}___` )
        });

        for (var index in toChange) {
            var reg = new RegExp(`___${index}___`,'g')
            formate = formate.replace(reg, toChange[index] )
        }

        return formate
    }
}

module.exports = {
    momentG: momentG.run,
    formatDate: formatDate
};



var monthNames = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
];
var dayOfWeekNames = [
    "Sunday", "Monday", "Tuesday",
    "Wednesday", "Thursday", "Friday", "Saturday"
];

function formatDate(date, patternStr){
    if (!patternStr) {
        patternStr = 'M/d/yyyy';
    }
    var day = date.getDate(),
        month = date.getMonth(),
        year = date.getFullYear(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds(),
        miliseconds = date.getMilliseconds(),
        h = hour % 12,
        hh = twoDigitPad(h),
        HH = twoDigitPad(hour),
        mm = twoDigitPad(minute),
        ss = twoDigitPad(second),
        aaa = hour < 12 ? 'AM' : 'PM',
        EEEE = dayOfWeekNames[date.getDay()],
        EEE = EEEE.substr(0, 3),
        dd = twoDigitPad(day),
        M = month + 1,
        MM = twoDigitPad(M),
        MMMM = monthNames[month],
        MMM = MMMM.substr(0, 3),
        yyyy = year + "",
        yy = yyyy.substr(2, 2)
    ;
    // checks to see if month name will be used
    patternStr = patternStr
    .replace('hh', hh).replace('h', h)
    .replace('HH', HH).replace('H', hour)
    .replace('mm', mm).replace('m', minute)
    .replace('ss', ss).replace('s', second)
    .replace('S', miliseconds)
    .replace('dd', dd).replace('d', day)

    .replace('EEEE', EEEE).replace('EEE', EEE)
    .replace('yyyy', yyyy)
    .replace('yy', yy)
    .replace('aaa', aaa);
    if (patternStr.indexOf('MMM') > -1) {
        patternStr = patternStr
        .replace('MMMM', MMMM)
        .replace('MMM', MMM);
    }
    else {
        patternStr = patternStr
        .replace('MM', MM)
        .replace('M', M);
    }
    return patternStr;
}

function twoDigitPad(num) {
    return num < 10 ? "0" + num : num;
}

import { Injectable } from '@angular/core';
import { PublicationFolder } from '../models/publicationfolder';
import { Storage } from '@ionic/storage';
import { Publication } from '../models/publication';
import { PublicationsService } from '../services/publications.service';
import { PublicationPipe } from 'src/app/pipes/publication.pipe';

@Injectable({
  providedIn: 'root'
})
export class PublicationFolderService {

  publicationList: { [key: string]: Publication[] } = {};
  FolderDetails: { [key: string]: PublicationFolder } = {};

  restoreFolder: {} = {}

  keyName: string

  showLoader = true
  publicationPipe = new PublicationPipe()
  getpublication = [];

  gotoAction: string | false


  constructor(
    private storage: Storage,
    private publications: PublicationsService,
  ) { }

  clear() {
    this.publicationList = {};
    this.FolderDetails = {};

    this.restoreFolder = {}

    this.showLoader = true
    this.publicationPipe = new PublicationPipe()
    this.getpublication = [];

  }


  createPublicationList(folderId) {
    if (!this.publicationList[folderId]) {
      this.publicationList[folderId] = []
    }
    if (!this.FolderDetails[folderId]) {
      this.FolderDetails[folderId] = new PublicationFolder();
    }
  }

  getFromDB(folderId: any) {


    if (!this.restoreFolder[folderId]) {

      this.storage.get(folderId).then((viewPublications) => {
        this.publicationList[folderId] = viewPublications
      })

      this.storage.get(folderId + "name").then((viewPublications) => {
        this.FolderDetails[folderId] = viewPublications
      })
    }

    this.restoreFolder[folderId] = true
  }

  updateFolderDetails(folderId, res) {
    this.FolderDetails[folderId] = res
    this.storage.set(folderId + "name", res)
  }

  save(folderId) {
    this.storage.set(folderId + "name", this.FolderDetails)
    this.storage.set(folderId, this.publicationList[folderId]);
  }

  publicationIsPresent(publicationId, folderId) {
    return this.publicationList[folderId].find(e => e.DocumentId == publicationId)
  }
  publicationFind(publicationId, folderId) {
    return this.publicationList[folderId].find(e => e.DocumentId == publicationId)
  }
  publicationFindIndex(publicationId, folderId) {
    return this.publicationList[folderId].findIndex(e => e.DocumentId == publicationId)
  }

  PublicationAddOrUpdate(folderId, publicationId, Publication: Publication) {
    const findIndex = this.publicationFindIndex(publicationId, folderId)
    const found = this.publicationIsPresent(publicationId, folderId)
    if (!found) {
      this.publicationList[folderId].push(Publication)
      this.revertPublicationOrder(folderId);
    } else {
      this.publicationList[folderId][findIndex] = Publication
    }
  }

  deletePost(folderId: any, DocumentId: any) {

    if (this.publicationList[folderId]) {

      this.publicationList[folderId] = this.publicationList[folderId].filter(e => e.DocumentId != DocumentId)

      this.save(folderId)
    }
  }


  getPublicationDetail(folderId) {

    this.publications.GetPresidentialAction(folderId).subscribe(res => {
      this.FolderDetails[folderId] = res
      this.storage.set(folderId + "name", res)
    }, (error) => {
      this.showLoader = false;
      // this.httpErroHandle.httpStatusHandle(error)
    });
  }


  async getPublicationsIds(folderId) {


    this.showLoader = true;

    try {
      const publicationIds: number[] = await this.publications.GetPublicationsList(folderId).toPromise();

      this.createPublicationList(folderId)

      for (let localPublication of this.publicationList[folderId]) {
        const apiPublication = publicationIds.includes(localPublication.DocumentId)
        if (!apiPublication) {
          this.deletePost(folderId, localPublication.DocumentId)
        }
      }


      let loadLater = []
      for (let publicationId of publicationIds) {

        if (!this.publicationIsPresent(publicationId, folderId)) {
          await this.loadPublication(publicationId, folderId)

        } else {
          loadLater.push(publicationId)
        }
      }

      for (let publicationId of loadLater) {
        await this.loadPublication(publicationId, folderId)
      }


      this.showLoader = false;

      this.storage.set(folderId, this.publicationList[folderId]);
      this.getpublication = this.publicationList[folderId];
    } catch (error) {
      this.showLoader = false;
    }

  }


  async loadPublication(publicationId, folderId) {
    let Publication = await this.publications.GetPublicationWithArrayOfFilesById(publicationId).toPromise();
    let publicationDetails: Publication = this.publicationPipe.itemList(Publication)

    const findIndex = this.publicationFindIndex(publicationId, folderId)
    const found = this.publicationIsPresent(publicationId, folderId)
    if (!found) {
      this.publicationList[folderId].push(publicationDetails)
      this.revertPublicationOrder(folderId);
      console.log('found')
    } else {

      console.log('try')

      let a: any = this.publicationList[folderId][findIndex]
      let b: any = publicationDetails

      const fileNamesB = a.Files.map((e) => e.OriginalFileName).join('')
      const fileNamesL = b.Files.map((e) => e.OriginalFileName).join('')

      if (a.Message != b.Message || a.Title != b.Title || a.Files.length != b.Files.length || fileNamesB != fileNamesL) {

        this.publicationList[folderId][findIndex] = publicationDetails
      }

    }

  }


  async CreatePublication(folderId, publication: Publication) {
    const response = await this.publications.CreatePublication(folderId, publication).toPromise()
    let publicationDetails: Publication = this.publicationPipe.itemList(response)
    this.publicationList[folderId].push(publicationDetails)
    this.revertPublicationOrder(folderId)
    return publicationDetails
  }

  async setPublication(publicationId, folderId, publicationDetails: Publication) {


    const findIndex = this.publicationFindIndex(publicationId, folderId)
    const found = this.publicationIsPresent(publicationId, folderId)
    if (!found) {
      this.publicationList[folderId].push(publicationDetails)
      this.revertPublicationOrder(folderId)
    } else {
      this.publicationList[folderId][findIndex] = publicationDetails
    }

  }


  async getPublication(publicationId, folderId, publicationDetails: Publication) {


    const findIndex = this.publicationFindIndex(publicationId, folderId)
    const found = this.publicationIsPresent(publicationId, folderId)
    if (!found) {
      this.publicationList[folderId].push(publicationDetails)
      this.revertPublicationOrder(folderId);

    } else {
      return this.publicationList[folderId][findIndex]
    }

  }


  getLocalPublication(folderId, DocumentId) {

    if (this.publicationList[folderId]) {
      return this.publicationList[folderId].find(e => e.DocumentId == DocumentId)
    } else {

    }

  }


  PublicationHasImage(Publication: Publication) {
    return Publication?.Files?.[0]?.FileBase64 != '' && Publication?.Files?.[0]?.FileBase64 != "data:image/jpg;base64,null"
  }

  hasCapturedImage(Publication: Publication) {
    return Publication?.Files?.[0]?.FileBase64 != '' && Publication?.Files?.[0]?.FileBase64 != "data:image/jpg;base64,null"
  }

  revertPublicationOrder(folderId) {
    //Revert order of the list
    if (this.publicationList[folderId]) {
      this.publicationList[folderId] = this.publicationList[folderId].sort((a, b) => {
        const dateA = new Date(a.DatePublication).getTime();
        const dateB = new Date(b.DatePublication).getTime();
        return dateB - dateA; // Revertendo a ordem aqui
      })
    }
  }

}



import { Injectable } from '@angular/core';
import { z } from "zod";
import { ValidateSchema } from 'src/app/services/decorators/validate-schema.decorator';
import { IMemberRemoteRepository } from 'src/app/core/chat/repository/member/member-remote-repository';

// Define the schema for the entire response
const MemberSetAdminDTOSchema = z.object({
  roomId: z.string(),
  memberId: z.string()
});

export type MemberSetAdminDTO = z.infer<typeof MemberSetAdminDTOSchema>


@Injectable({
  providedIn: 'root'
})
export class MemberAdminUseCaseService {

  constructor(
    public repository: IMemberRemoteRepository
  ) { }

  @ValidateSchema(MemberSetAdminDTOSchema)
  execute(input: MemberSetAdminDTO) {

    return this.repository.setAmin(input)
  }
}

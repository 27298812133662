import { z } from "zod";

export const MemberEntitySchema = z.object({
  $roomIdUserId: z.string().optional(),
  id: z.string().optional(), // useless
  roomId: z.string(),
  wxUserId: z.number(),
  wxFullName: z.string(),
  wxeMail: z.string(),
  userPhoto: z.string().nullable(),
  joinAt: z.string(),
  status: z.string().optional(), // useless
  isAdmin: z.boolean()
})

export type IMember = z.infer<typeof MemberEntitySchema>


export class MemberEntity implements IMember {
  id: typeof MemberEntitySchema._type.id
  roomId: typeof MemberEntitySchema._type.roomId
  wxUserId: typeof MemberEntitySchema._type.wxUserId
  wxFullName: typeof MemberEntitySchema._type.wxFullName
  wxeMail: typeof MemberEntitySchema._type.wxeMail
  userPhoto: typeof MemberEntitySchema._type.userPhoto
  joinAt: typeof MemberEntitySchema._type.joinAt
  status: typeof MemberEntitySchema._type.status
  isAdmin: typeof MemberEntitySchema._type.isAdmin

  hasPhoto() {
    return typeof this.userPhoto == 'string'
  }
}

import { Injectable } from '@angular/core';
import { MessageDeleteLiveUseCaseService } from 'src/app/module/chat/domain/use-case/message/message-delete-by-id-live-use-case.service'
import { SessionStore } from 'src/app/store/session.service';
import { MessageReactionInput, MessageReactionUseCaseService } from 'src/app/module/chat/domain/use-case/message/message-reaction-by-id-use-case.service';
import { MessageUpdateInput, MessageUpdateUseCaseService } from 'src/app/module/chat/domain/use-case/message/message-update-by-id-use-case.service';
import { MemberAdminUseCaseService, MemberSetAdminDTO } from 'src/app/module/chat/domain/use-case/member/member-admin-use-case.service';
import { MessageCreateUseCaseService } from 'src/app/module/chat/domain/use-case/message/message-create-use-case.service';
import { SignalRService } from 'src/app/infra/socket/signalR/signal-r.service';
import { SocketMessageDeleteUseCaseService } from 'src/app/module/chat/domain/use-case/socket/socket-message-delete-use-case.service';
import { SocketMessageUpdateUseCaseService } from 'src/app/module/chat/domain/use-case/socket/socket-message-update-use-case.service';
import { SocketMessageCreateUseCaseService } from 'src/app/module/chat/domain/use-case/socket/socket-message-create-use-case.service';
import { DownloadMessageAttachmentByMessageId, DownloadMessageAttachmentUserCaseService } from 'src/app/module/chat/domain/use-case/message/message-download-attachment-user-case.service';
import { ListenMessageByRoomIdNewUseCase } from 'src/app/module/chat/domain/use-case/message/listen-message-by-roomId.service';
import { MemberListUpdateStatusUseCaseService } from 'src/app/module/chat/domain/use-case/socket/member-list-update-status-use-case.service';
import { ListenMessageDeleteByRoomIdService } from './use-case/message/listene-message-delete-by-roomId.service';
import { ListenMessageUpdateByRoomIdUseCase } from './use-case/message/listen-message-update-by-roomId.service';
import { GetRoomByIdUseCaseService } from './use-case/room/room-get-by-id-use-case.service';
import { DeleteRoomUseCaseService } from './use-case/room/room-delete-by-id-use-case.service';
import { CreateRoomInputDTO, CreateRoomUseCaseService } from './use-case/room/room-create-use-case.service';
import { RoomLeaveUseCase, UserRemoveListInputDTO } from './use-case/room/room-leave-by-id-use-case.service';
import { SyncAllRoomMessagesService } from './use-case/message/sync-all-room-messages.service';
import { ListenSendMessageUseCase } from './use-case/message/listen-send-message.service';
import { SendLocalMessagesUseCaseService } from './use-case/message/messages-send-offline-use-case.service'
import { RemoveMemberUseCaseService } from './use-case/member/-use-case.service'
import { AddMemberUseCaseService } from './use-case/member/member-add-use-case.service'
import { RoomUpdateInputDTO, UpdateRoomByIdUseCaseService } from './use-case/room/room-update-by-id-use-case.service'
import { SocketConnectUseCaseService } from './use-case/socket-connect-use-case.service'
import { MessageMarkAsReadUseCaseService } from './use-case/message/message-mark-as-read-use-case.service'
import { MessageMarkAllMessageAsReadByRoomIdInputSchema, MessageMarkAllMessageAsReadByRoomIdService } from './use-case/message/message-mark-all-message-as-read-by-room-id.service'
import { GetRoomListUseCaseService } from 'src/app/module/chat/domain/use-case/room/room-get-list-use-case.service';
import { filter } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid'
import { IMessage, MessageEntity } from '../../../core/chat/entity/message';
import { MessageAttachmentByMessageIdInput, MessageAttachmentByMessageIdUseCase } from './use-case/message/message-attachment-by-message-id.service';
import { AddMemberToRoomInputDTO } from '../domain/use-case/member/member-add-use-case.service';
import { RoomType } from "src/app/core/chat/entity/group";
import { HttpListenToMessageLoadHistoryAdapter } from './adapter'
import { HttpListenToMessageLoadHistoryUseCaseInput } from 'src/app/core/chat/usecase/message/http-listen-to-message-load-history-by-roomId-use-case';
import { MessageSocketRepositoryService } from 'src/app/module/chat/data/repository/message/message-live-signalr-data-source.service'
import { MessageMarkAsReadInput } from "src/app/module/chat/domain/use-case/message/message-mark-as-read-use-case.service";
import { BoldRemoveByRoomIdInput, BoldRemoveByRoomIdService } from 'src/app/module/chat/domain/use-case/bold/bold-remove-by-room-id.service';
import { MemberListHttpSyncUseCase } from 'src/app/module/chat/domain/use-case/member/member-list-http-sync-use-case.ts.service'
import { RoomBoldSyncUseCaseService } from 'src/app/module/chat/domain/use-case/room/room-bold-sync-use-case.service'
import { RoomSetLastMessageService } from 'src/app/module/chat/domain/use-case/room/room-set-last-message.service';
import { IUserPhotoGetByIdInput, UserPhotoGetByIdUseCase } from 'src/app/module/chat/domain/use-case/user-photo/user-photo-get-by-id-use-case.service'


export const getInstanceId = (): string => {
  const storageKey = 'instanceId'; // Key for localStorage
  let instanceId = localStorage.getItem(storageKey);

  // If not found in localStorage, generate and set a new UUID
  if (!instanceId) {
    instanceId = uuidv4();
    localStorage.setItem(storageKey, instanceId);
  }

  return instanceId;
};

export const InstanceId = getInstanceId();

@Injectable({
  providedIn: 'root'
})
export class ChatServiceService {

  constructor(
    private MessageDeleteLiveUseCaseService: MessageDeleteLiveUseCaseService,
    private MessageReactionUseCaseService: MessageReactionUseCaseService,
    private MessageUpdateUseCaseService: MessageUpdateUseCaseService,
    private SocketMessageDeleteUseCaseService: SocketMessageDeleteUseCaseService,
    private messageLiveSignalRDataSourceService: SignalRService,
    private SocketMessageUpdateUseCaseService: SocketMessageUpdateUseCaseService,
    private SocketMessageCreateUseCaseService: SocketMessageCreateUseCaseService,
    private MemberListUpdateStatusUseCaseService: MemberListUpdateStatusUseCaseService,
    private MemberAdminUseCaseService: MemberAdminUseCaseService,
    private MessageCreateUseCaseService: MessageCreateUseCaseService,
    private ListenMessageByRoomIdNewUseCase: ListenMessageByRoomIdNewUseCase,
    private ListenMessageDeleteService: ListenMessageDeleteByRoomIdService,
    private ListenMessageUpdateByRoomIdUseCase: ListenMessageUpdateByRoomIdUseCase,
    private ListenSendMessageUseCase: ListenSendMessageUseCase,
    private MessageAttachmentByMessageIdService: MessageAttachmentByMessageIdUseCase,
    private SyncAllRoomMessagesService: SyncAllRoomMessagesService,
    private DownloadMessageAttachmentUserCaseService: DownloadMessageAttachmentUserCaseService,
    private GetRoomListUseCaseService: GetRoomListUseCaseService,
    private GetRoomByIdUseCaseService: GetRoomByIdUseCaseService,
    private DeleteRoomUseCaseService: DeleteRoomUseCaseService,
    private CreateRoomUseCaseService: CreateRoomUseCaseService,
    private RoomLeaveUseCase: RoomLeaveUseCase,
    private AddMemberUseCaseService: AddMemberUseCaseService,
    private UpdateRoomByIdUseCaseService: UpdateRoomByIdUseCaseService,
    private RemoveMemberUseCaseService: RemoveMemberUseCaseService,
    private SendLocalMessagesUseCaseService: SendLocalMessagesUseCaseService,
    private MessageMarkAsReadUseCaseService: MessageMarkAsReadUseCaseService,
    private SocketConnectUseCaseService: SocketConnectUseCaseService,
    private MessageMarkAllMessageAsReadByRoomIdService: MessageMarkAllMessageAsReadByRoomIdService,
    private HttpListenToMessageLoadHistory: HttpListenToMessageLoadHistoryAdapter,
    private MessageSocketRepositoryService: MessageSocketRepositoryService,
    private BoldRemoveByRoomIdService: BoldRemoveByRoomIdService,
    private MemberListHttpSyncUseCase: MemberListHttpSyncUseCase, // dont remove
    private RoomBoldSyncUseCaseService: RoomBoldSyncUseCaseService, // dont remove
    private RoomSetLastMessageService: RoomSetLastMessageService, // dont remove
    private UserPhotoGetByIdUseCase: UserPhotoGetByIdUseCase
  ) {
    this.MessageSocketRepositoryService.listenToDeleteMessages()
    .pipe()
    .subscribe(async (message) => {
      if(message?.id) {
        this.SocketMessageDeleteUseCaseService.execute(message)
      }
    })

    this.MessageSocketRepositoryService.listenToUpdateMessages().pipe(
      filter((message) => {
        return !message?.requestId?.startsWith(InstanceId)
      })
    ).subscribe(async (message) => {
      if(message?.id) {
        this.SocketMessageUpdateUseCaseService.execute(message)
      }
    })

    this.MessageSocketRepositoryService.listenToMessages().pipe(
      filter((message) => {
        if(!message?.requestId?.startsWith(InstanceId) == false) {
          // console.log('exclude my message---')
        }
        return !message?.requestId?.startsWith(InstanceId)
      })
    ).subscribe(async (message) => {
      if(message?.id) {
        this.SocketMessageCreateUseCaseService.execute(message)
      }
    })

    this.messageLiveSignalRDataSourceService.getData().pipe(
      filter((message) => {
        if(message?.method == 'AvailableUsers') {
          // console.log('exclude my message---')
          return true
        }
      })
    ).subscribe(async (message) => {
      this.MemberListUpdateStatusUseCaseService.execute(message.data as any)
    })

  }

  async start() {
    this.chatSync()
    this.SocketConnectUseCaseService.execute();
  }


  async chatSync() {
    await this.getRoomList();
    await this.asyncAllRoomMessage();
  }

  removeMemberToRoom(data: UserRemoveListInputDTO) {
    return this.RemoveMemberUseCaseService.execute(data)
  }

  messageDelete(data: {roomId, messageId}) {

    const params = {
      ...data,
      senderId: SessionStore.user.UserId,
    }

    return this.MessageDeleteLiveUseCaseService.execute(params)
  }

  getUserPhoto(input: IUserPhotoGetByIdInput) {
    return this.UserPhotoGetByIdUseCase.execute(input)
  }

  reactToMessage(input: MessageReactionInput) {
    return this.MessageReactionUseCaseService.execute(input);
  }

  updateMessage(input: MessageUpdateInput) {
    return this.MessageUpdateUseCaseService.execute(input);
  }

  setAdmin(input: MemberSetAdminDTO) {
    return this.MemberAdminUseCaseService.execute(input)
  }

  sendMessage(input: IMessage, messageEnum: RoomType) {
    return this.MessageCreateUseCaseService.execute(input, messageEnum);
  }

  asyncAllRoomMessage() {
    return this.SyncAllRoomMessagesService.execute()
  }

  getMessageAttachmentByMessageId(input: MessageAttachmentByMessageIdInput) {
    return this.MessageAttachmentByMessageIdService.execute(input)
  }

  downloadMessageAttachmentByMessageId(input: DownloadMessageAttachmentByMessageId) {
    return this.DownloadMessageAttachmentUserCaseService.execute(input)
  }

  // getMessageAttachmentLocallyByMessageId(input: GetMessageAttachmentLocallyByMessageId) {
  //   return this.GetMessageAttachmentLocallyUseCaseService.execute(input)
  // }

  removeBoldFromRoom(input: BoldRemoveByRoomIdInput) {
    return this.BoldRemoveByRoomIdService.execute(input)
  }

  listenToMessageLoadHistory(input: HttpListenToMessageLoadHistoryUseCaseInput) {
    return this.HttpListenToMessageLoadHistory.execute(input)
  }

  getRoomList() {
    return this.GetRoomListUseCaseService.execute()
  }

  getRoomById(id: string) {
    return this.GetRoomByIdUseCaseService.execute(id)
  }


  deleteRoomById(id: string) {
    return this.DeleteRoomUseCaseService.execute(id)
  }

  createRoom(data: CreateRoomInputDTO) {
    return this.CreateRoomUseCaseService.execute(data)
  }

  leaveRoom(data: UserRemoveListInputDTO) {
    return this.RoomLeaveUseCase.execute(data)
  }

  addMemberToRoom(data: AddMemberToRoomInputDTO) {
    return this.AddMemberUseCaseService.execute(data)
  }

  updateRoomById(data: RoomUpdateInputDTO) {
    return this.UpdateRoomByIdUseCaseService.execute(data)
  }

  // messageMarkAsRead(roomId) {
  //   return this.MessageReadAtByIdUseCaseService.execute({roomId})
  // }

  sendLocalMessages() {
    return this.SendLocalMessagesUseCaseService.execute()
  }
  removeMember() {

  }

  sendReadAt(sendReadAt: MessageMarkAsReadInput) {
    return this.MessageMarkAsReadUseCaseService.execute(sendReadAt)
  }

  markAllMessagesAsRead(input: MessageMarkAllMessageAsReadByRoomIdInputSchema) {
    return this.MessageMarkAllMessageAsReadByRoomIdService.execute(input)
  }

  listenToIncomingMessage(roomId:string) {
    return this.ListenMessageByRoomIdNewUseCase.execute({roomId})
  }

  listenToDeleteMessage(roomId: string) {
    return this.ListenMessageDeleteService.execute({roomId})
  }

  listenToUpdateMessage(roomId: string) {
    return this.ListenMessageUpdateByRoomIdUseCase.execute({roomId})
  }


  listenToSendMessage(roomId: string) {
    return this.ListenSendMessageUseCase.execute({roomId})
  }

}


import { SearchList } from "src/app/models/search-document";
import { EventListOutputDTO } from "../../data/dto/eventListDTOOutput";

function getTextInsideParentheses(inputString): string {
  var startIndex = inputString.indexOf('(');
  var endIndex = inputString.indexOf(')');
  if (startIndex !== -1 && endIndex !== -1) {
    return inputString.substring(startIndex + 1, endIndex);
  } else {
    return null;
  }
}

export class EventSearchMapper {
  // @XTracer({name:'ListEventMapper/toDomain', log: false, bugPrint: false})
  static toDomain(dto: EventListOutputDTO, calendarOwnerName: string, userId: string): SearchList[] {

    return dto.data.map((e) => {

      return {
        Id: e.id as any,
        subject: e.subject,
        dateEntry: e.createdAt as any,
        Data: e.createdAt as any,
        entity: (e.owner.wxFullName),
        ApplicationType: 0 as any,
        Assunto: e.subject,
        appName: "string",
        ApplicationName: "",
        docId: e.id as any,
        applicationId: 0 as any
      }
    })
  }

  static toDTO() {}
}

import { Injectable } from '@angular/core';
import { ObjectService } from './object.service';

@Injectable({
  providedIn: 'root'
})
export class SortService {

  constructor(private ObjectService: ObjectService) { }

  sortArrayISODate(myArray: any) {

    if(!Array.isArray(myArray)) {
      myArray = []
    }
    if(myArray.length > 0){
      return myArray.sort(function (a, b) {
        return (a.CreateDate < b.CreateDate) ? -1 : ((a.CreateDate > b.CreateDate) ? 1 : 0);
      });
    }
  }

  sortArrayByDate(myArray: any) {
    if(!Array.isArray(myArray)) {
      myArray = []
    }
    if(myArray.length > 0){
      return myArray.sort(function (a, b) {
        return (new Date(a.workflowInstanceDataFields.StartDate) < new Date(b.workflowInstanceDataFields.StartDate)) ? -1 : ((new Date(a.workflowInstanceDataFields.StartDate) > new Date(b.workflowInstanceDataFields.StartDate)) ? 1 : 0);
      });
    }
  }

  sortDate(array = [], path: string) {
    if(!Array.isArray(array)) {
      array = []
    }

    return array.sort( (a,b)=> {

      return (new Date(this.ObjectService.deepFind(a, path))   < new Date(this.ObjectService.deepFind(b, path))) ? -1 : ((new Date(this.ObjectService.deepFind(a, path)) > new Date(this.ObjectService.deepFind(b, path))) ? 1 : 0);

      //return new Date(this.ObjectService.deepFind(b, path)).getTime() - new Date(this.ObjectService.deepFind(a, path)).getTime();

    })

  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { AddNotePage } from 'src/app/modals/add-note/add-note.page';
import { BookMeetingModalPage } from 'src/app/pages/gabinete-digital/expediente/book-meeting-modal/book-meeting-modal.page';
import { ProcessesService } from 'src/app/services/processes.service';
import { ToastService } from 'src/app/services/toast.service';
import { RouteService } from 'src/app/services/route.service';
import { PermissionService } from 'src/app/services/permission.service';
import { DiscartExpedientModalPage } from 'src/app/pages/gabinete-digital/discart-expedient-modal/discart-expedient-modal.page';
import { CreateProcessPage } from 'src/app/modals/create-process/create-process.page';
import { AttachmentList } from 'src/app/models/Excludetask';
import { DespachoService } from 'src/app/Rules/despacho.service';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service';
import { TaskService } from 'src/app/services/task.service'
import { PopupQuestionPage } from 'src/app/modals/popup-question/popup-question.page';

@Component({
  selector: 'app-deploma-options',
  templateUrl: './deploma-options.page.html',
  styleUrls: ['./deploma-options.page.scss'],
})
export class DiplomaOptionsPage implements OnInit {


  serialNumber: string;
  profile: string;
  task: any
  fulltask: any
  DraftIds = ""
  DraftNames = ""
  asDraft: boolean;


  constructor(
    public popoverController: PopoverController,
    private modalController: ModalController,
    private processes: ProcessesService,
    private navParams: NavParams,
    private toastService: ToastService,
    private RouteService: RouteService,
    public p: PermissionService,
    private despachoService: DespachoService,
    private httpErroHandle: HttpErrorHandle,
    public TaskService: TaskService) {
    this.serialNumber = this.navParams.get('serialNumber');
    this.task = this.navParams.get('task');
    this.fulltask = this.navParams.get('fulltask');

    this.DraftIds = this.navParams.get("DraftIds");
    this.DraftNames = this.navParams.get("DraftNames");
    this.asDraft = this.navParams.get('asDraft');


    console.log('this.task', this.task)

  }

  ngOnInit() {
    console.log(this.task.activityInstanceName)

  }

  async openAddNoteModal(actionName: string) {
    // this.popoverController.dismiss();
    let classs;
    if (window.innerWidth <= 800) {
      classs = 'modal modal-desktop'
    } else {
      classs = 'modal modal-desktop'
    }
    const modal = await this.modalController.create({
      component: AddNotePage,
      componentProps: {
        actionName: actionName
      },
      cssClass: classs,
      backdropDismiss: true
    });

    modal.onDidDismiss().then(async (res) => {
      if (res.data) {


        const DocumentToSave = res.data.documents.map((e) => {
          return {
            ApplicationId: e.ApplicationType,
            SourceId: e.Id,
          }
        });

        let docs = {
          ProcessInstanceID: "",
          Attachments: DocumentToSave,
        }

        if (actionName == 'Solicitar assinatura') {
          await this.askSignature(res.data.note, docs);
          this.goBack();
        } else if (actionName == 'Solicitar alteração') {
          await this.askToChange(res.data.note, docs);
          this.goBack();
        } else if (actionName == 'Assinar Diploma') {
          await this.sign(res.data.note, docs);
          this.goBack();
        } else if (actionName == 'Concluir diploma') {
          await this.finish(res.data.note, docs);
          this.goBack();
        } else if (actionName == 'Arquivo') {
          await this.arquivar(res.data.note, docs);
          this.goBack();
        } else if (actionName == 'AssinarNew') {
          await this.AssinarNew(res.data.note, docs)
        }
      }
    }, (error) => {
      console.log(error)
    });

    await modal.present();
  }

  async askToChange(note: string, documents: any) {
    let body = {
      "serialNumber": this.serialNumber,
      "action": "Retificar",
      "ActionTypeId": 99999841,
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList": documents,
    }

    const loader = this.toastService.loading()

    try {
      await this.processes.CompleteTask(body).toPromise()
      this.close();
      this.toastService._successMessage()
    } catch (error) {
      if (error.status == 0) {
        this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
      } else {

        this.toastService._badRequest()
      }
    } finally {
      loader.remove()
    }

  }


  async openBookMeetingModal(task: any) {
    this.popoverController.dismiss();
    let classs;
    if (window.innerWidth <= 800) {
      classs = 'book-meeting-modal modal modal-desktop'
    } else {
      classs = 'modal modal-desktop showAsideOptions'
    }
    const modal = await this.modalController.create({
      component: BookMeetingModalPage,
      componentProps: {
        task: this.task,
        fulltask: this.fulltask
      },
      cssClass: classs,
      backdropDismiss: false
    });
    await modal.present();
    modal.onDidDismiss().then(
      () => { },
      (error) => {
        console.log(error)
      }
    )
  }


  async askSignature(note: string, documents: any) {
    let body = {
      "serialNumber": this.serialNumber,
      "action": "Aprovar",
      "ActionTypeId": 99999840,
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList": documents,
    }

    const loader = this.toastService.loading()

    try {
      await this.processes.CompleteTask(body).toPromise()
      this.close();
      this.toastService._successMessage()
    } catch (error) {
      if (error.status == 0) {
        this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
      } else {

        this.toastService._badRequest()
      }
    } finally {
      loader.remove()
    }

  }


  async sign(note?: string, documents?: any) {
    let body = {
      "serialNumber": this.serialNumber,
      "action": "Assinado",
      "ActionTypeId": 99999842,
      "FolderId": this.task.FolderID,
      "dataFields": {
        "ReviewUserComment": "",
        "InstanceIDNew": this.task.InstanceID,
        "DraftIds": "",
      },
      "AttachmentList": {
        "ProcessInstanceID": this.task.InstanceID,
        /* "DraftIds": null, */
        "Attachments": []
      },
    }


    try {
      await this.processes.CompleteTask(body).toPromise()
      this.httpErroHandle.httpsSucessMessagge('Assinado')
    } catch (error) {
      if (error.status == 0) {
        this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
      } else {
        this.toastService._badRequest()
      }
    } finally { }
  }

  async AssinarNew(note?, doc?) {

    if (this.asDraft) {
      const modal = await this.modalController.create({
        component: PopupQuestionPage,
        componentProps: {
          title: 'Deseja assinar este Diploma?',
          /* message: 'Nota: Ao Efetuar esta operação, o tratamento deste diploma não poderá ser realizado a partir da caixa de correspondência' */

        },
        cssClass: "popup-question discart-expedient-modal",
        backdropDismiss: true
      });

      modal.onDidDismiss().then(async (res) => {
        const data = res.data
        if (data == "Yes") {

          console.log(' diploma options Draft draft',this.DraftIds)
          let body = {
            "InstanceId": this.task.InstanceID,
            "FolderId": this.task.FolderID,
            "DraftIds": this.task.DraftIds,
            "OriginalFileName": this.DraftNames
          }
          const loader = this.toastService.loading()

          try {
            await this.processes.presidentialActionsSignature(body).toPromise()
            await this.sign()
            this.TaskService.loadDiplomas()
            this.goBack();
          } catch (error) {
            this.httpErroHandle.httpStatusHandle(error)
          }
          finally {
            loader.remove()
          }
        }

      }, (error) => {
        console.log(error)
      });

      await modal.present();
    } else {
      this.httpErroHandle.validationMessagge("diplomaAsDraft");
    }

  }


  async finish(note: string, documents: any) {

    let body = {
      "serialNumber": this.serialNumber,
      "action": "Concluir",
      "ActionTypeId": 95,
      "dataFields": {
        "ReviewUserComment": note,
      },
      "AttachmentList": documents,
    }

    const loader = this.toastService.loading()

    try {
      await this.processes.CompleteTask(body).toPromise();
      this.toastService._successMessage('Processo concluído')
    } catch (error) {
      if (error.status == 0) {
        this.toastService._badRequest('Sem acesso à internet. Por favor verifique sua conexão')
      } else {

        this.toastService._badRequest()
      }
    }
    finally {
      loader.remove()
    }

  }

  async arquivar(note: string, documents: AttachmentList) {

    const loader = this.toastService.loading()

    try {
      await this.despachoService.arquivar(note, documents, this.serialNumber).toPromise()
      this.httpErroHandle.httpsSucessMessagge('Arquivar')
      this.close();
    } catch (error) {
      this.httpErroHandle.httpStatusHandle(error)
    }
    finally {
      loader.remove()
    }

  }

  async openExpedientActionsModal(taskAction: any, task: any) {

    let classs;
    if (window.innerWidth <= 800) {
      classs = 'modal modal-desktop'
    } else {
      classs = 'modal modal-desktop showAsideOptions'
    }


    const modal = await this.modalController.create({
      component: CreateProcessPage,
      componentProps: {
        taskAction: taskAction,
        task: task,
        profile: this.profile,
        fulltask: this.fulltask
      },
      cssClass: classs,
    });
    modal.onDidDismiss().then(async (res) => {


      if (res['data'] == 'openDiscart') {
        await this.distartExpedientModal();
      }

      this.goBack();
    }, (error) => {
      console.log(error)
    }).catch(e => {
      console.log(e)
    })

    await modal.present();
  }

  async distartExpedientModal() {

    const modal = await this.modalController.create({
      component: DiscartExpedientModalPage,
      componentProps: {
        serialNumber: this.fulltask.serialNumber,
        folderId: this.fulltask.workflowInstanceDataFields.FolderID,
        action: 'complete',
      },
      cssClass: 'discart-expedient-modal',
      backdropDismiss: false
    });

    modal.onDidDismiss().then(res => {
      if (res['data'] == 'close') {
        this.close();
        /*
        this.close();
        this.openMenu(); */
      }

    }, (error) => {
      console.log(error)
    }).catch(e => {
      console.log(e)
    })

    await modal.present();

  }

  goBack() {
    this.RouteService.goBack()
    this.TaskService.loadDiplomas()
  }


  close() {
    this.popoverController.dismiss();
  }

}

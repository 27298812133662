import { Injectable } from '@angular/core';
import { liveQuery, Observable } from 'Dexie';
import { DexieRepository } from 'src/app/infra/repository/dexie/dexie-repository.service';
import { from } from 'rxjs';
import { RoomTable, RoomTableSchema } from 'src/app/infra/database/dexie/instance/chat/schema/room';
import { chatDatabase } from 'src/app/infra/database/dexie/service';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';

@Injectable({
  providedIn: 'root'
})
export class RoomLocalRepository extends DexieRepository<RoomTable, RoomTable> implements IRoomLocalRepository  {

  constructor() {
    super(chatDatabase.room, RoomTableSchema)

    chatDatabase.room.hook('updating', (modifications, primKey, oldValue, transaction) => {

      // if((modifications as Partial<RoomTable>).messages?.[0].requestId == oldValue.messages?.[0].requestId) {
      //   (modifications as Partial<RoomTable>).messages[0].sentAt = oldValue.messages?.[0]?.sentAt;
      // } else if ((modifications as Partial<RoomTable>).messages?.[0].id == oldValue.messages?.[0].id) {
      //   (modifications as Partial<RoomTable>).messages[0].sentAt = oldValue.messages?.[0]?.sentAt
      // }

      return modifications
    });

  }

  getItemsLive() {
    return from (liveQuery(() => chatDatabase.room.toArray()));
  }

  getRoomByIdLive(id: any) {
    return from(liveQuery(() => chatDatabase.room.get(id)));
  }

}


import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, PickerController, PopoverController } from '@ionic/angular';
import { GroupDurationPage } from 'src/app/shared/popover/group-duration/group-duration.page';
import { GroupContactsPage } from '../group-messages/group-contacts/group-contacts.page';
import { ThemeService } from 'src/app/services/theme.service';
import { SessionStore } from 'src/app/store/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-new-group',
  templateUrl: './new-group.page.html',
  styleUrls: ['./new-group.page.scss'],
})
export class NewGroupPage implements OnInit {
  isGroupCreated: boolean;
  showLoader: boolean;
  displayDuration: any;
  showDuration: boolean;
  selectedDuration = ['', '', ''];
  thedate: any;
  groupName: string;
  documents: any;
  loggedUserChat: any;

  constructor(
    private pickerController: PickerController,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private navParams: NavParams,
    public ThemeService: ThemeService,
    // public ChatSystemService: ChatSystemService,
    private toastService: ToastService,
  ) {
    this.loggedUserChat = SessionStore.user.ChatData['data'];
    this.isGroupCreated = false;
    this.groupName = this.navParams.get('name');
    this.documents = this.navParams.get('documents');
  }

  ngOnInit() {
    // this.chatService.refreshtoken();
    // console.log(this.documents)
  }

  _ionChange(event) {
    this.showDuration = event.detail.checked;

    if (event.detail.checked) {
      this.thedate = new Date();
    }
    else {
      this.thedate = '';
    }
  }
  close() {
    this.modalController.dismiss();
  }

  async createGroup() {

    // let name = this.groupName.split(' ').join('-');
    // //Take out all special characters in string
    // name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    // let customFields = {}
    // let res: any;

    // if(!SessionStore.user?.ChatData?.data) {
    //   this.toastService._successMessage("Chat temporariamente indisponível")
    // }

    // try {

    //   if (this.thedate) {
    //     let customFields = {
    //       "countDownDate": this.thedate
    //     }
    //     res = await this.ChatSystemService.createPrivateRoom(name, SessionStore.user.UserName, customFields);
    //   }
    //   else {
    //     res = await this.ChatSystemService.createPrivateRoom(name, SessionStore.user.UserName, customFields);
    //   }

    //   try {
    //     this.isGroupCreated = true;
    //     this.addContacts(res.result);
    //     this.ChatSystemService.getRoom([res.result]);

    //     setTimeout(() => {
    //       this.ChatSystemService.subscribeToRoomUpdate(res.result.rid, res.result);
    //     }, 10)

    //   } catch (error) {
    //     await this.ChatSystemService.getUser();
    //     await this.ChatSystemService.getAllRooms();
    //     await this.ChatSystemService.subscribeToRoom();

    //     this.isGroupCreated = true;
    //     this.addContacts(res.result);
    //     this.ChatSystemService.getRoom([res.result]);

    //     setTimeout(() => {
    //       this.ChatSystemService.subscribeToRoomUpdate(res.result.rid, res.result);
    //     }, 10)

    //   }



    //   if (res?.result?.rid) {

    //     this.ChatSystemService.getAllRooms(() => {
    //       if (!this.ChatSystemService.getGroupRoom(res.result.rid)) {
    //         this.createGroupWithAttachmentsCath(res)
    //       } else {
    //         setTimeout(() => {

    //           this.createGroupWithAttachments(res)

    //         }, 500)
    //       }
    //     }, res.result.rid);



    //   } else {

    //     this.toastService._badRequest('Existe um grupo com este nome!');

    //   }

    // } catch(error) {
    //   this.toastService._successMessage("Chat temporariamente indisponível")
    // }

  }


  createGroupWithAttachmentsCath(res: any) {
    // if (!this.ChatSystemService.getGroupRoom(res.result.rid)) {
    //   setTimeout(() => {
    //     this.createGroupWithAttachmentsCath(res)
    //   }, 1500)
    // } else {
    //   this.createGroupWithAttachments(res)
    // }
  }

  createGroupWithAttachments(res: any) {
    // this.ChatSystemService.getGroupRoom(res.result.rid).hasLoadHistory = true;

    // if (this.documents) {
    //   this.documents.forEach(element => {
    //     this.ChatSystemService.getGroupRoom(res.result.rid).send({
    //       file: {
    //         "name": element.Assunto,
    //         "type": "application/webtrix",
    //         "ApplicationId": element.ApplicationId,
    //         "DocId": element.DocId,
    //         "Assunto": element.Assunto,
    //       },
    //       temporaryData: {
    //         data: {
    //           selected: {
    //             Id: element.DocId,
    //             ApplicationType: element.ApplicationId
    //           }
    //         }
    //       },
    //       attachments: [{
    //         "title": element.Assunto,
    //         "description": element.Assunto,
    //         "title_link_download": true,
    //         "type": "webtrix",
    //         "text": element.Assunto,
    //         "thumb_url": "https://static.ichimura.ed.jp/uploads/2017/10/pdf-icon.png",
    //       }],
    //     })
    //   });
    // }


    // this.ChatSystemService.getAllRooms();

    // setTimeout(() => {
    //   this.groupName = ""
    // }, 150);
  }

  async addContacts(room) {
    this.close();

    let name = this.groupName.split(' ').join('-');


    const modal = await this.modalController.create({
      component: GroupContactsPage,
      componentProps: {
        room: room,
      },
      cssClass: 'contacts',
      backdropDismiss: false
    });


    modal.onDidDismiss();
    await modal.present();
  }

  async setDuration(ev: any) {
    const popover = await this.popoverController.create({
      component: GroupDurationPage,
      cssClass: 'group-duration',
      event: ev,
      translucent: true
    });
    return await popover.present();
  }

  async showPicker() {
    const picker = await this.pickerController.create({
      cssClass: '',
      buttons: [
        {
          text: 'Cancelar', role: 'cancel', cssClass: 'btn-cancel'
        },
        {
          text: 'Ok',
          cssClass: 'btn-cancel',
          handler: (value: any) => {

            let now = new Date();
            this.thedate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + value.days.value, now.getHours() + value.hours.value, now.getMinutes() + value.minutes.value, now.getSeconds(), now.getMilliseconds());

            this.selectedDuration = [
              value.days.value,
              value.hours.value,
              value.minutes.value,
            ]

            if (value.days.value != null && value.hours.value != null && value.minutes.value != null) {
              if (value.days.value > 0) {
                if (value.days.value == 1) {
                  if (value.hours.value == 1) {
                    this.displayDuration = value.days.value + " day " +
                      value.hours.value + " hora " +
                      value.minutes.value + " minutos";
                  }
                  else {
                    this.displayDuration = value.days.value + " days " +
                      value.hours.value + " horas " +
                      value.minutes.value + " minutos";
                  }
                }
                else {
                  if (value.hours.value == 1) {
                    this.displayDuration = value.days.value + " days " +
                      value.hours.value + " hora " +
                      value.minutes.value + " minutos";
                  }
                  else {
                    this.displayDuration = value.days.value + " days " +
                      value.hours.value + " horas " +
                      value.minutes.value + " minutos";
                  }
                }
              }
              else {
                if (value.hours.value == 1) {
                  this.displayDuration = value.hours.value + " hora " +
                    value.minutes.value + " minutos";
                }
                else {
                  this.displayDuration = value.hours.value + " horas " +
                    value.minutes.value + " minutos";
                }
              }
            }
          },
        },
      ],
      columns: [
        {
          name: 'days',
          prefix: 'Dias',
          options: [
            { text: '0', value: 0 },
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
          ]
        },
        {
          name: 'hours',
          prefix: 'Horas',
          options: [
            { text: '0', value: 0 },
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
            { text: '5', value: 5 },
            { text: '6', value: 6 },
            { text: '7', value: 7 },
            { text: '8', value: 8 },
          ]
        },
        {
          name: 'minutes',
          prefix: 'Minutos',
          selectedIndex: 0,
          options: [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '15', value: 15 },
            { text: '20', value: 20 },
            { text: '25', value: 25 },
            { text: '30', value: 30 },
            { text: '35', value: 35 },
            { text: '45', value: 45 },
            { text: '50', value: 50 },
            { text: '55', value: 55 },
          ]
        }
      ]
    });
    await picker.present();
    picker.onDidDismiss().then(async data => {
      let day = await picker.getColumn('days');
      let hour = await picker.getColumn('hours');
      let minutes = await picker.getColumn('minutes');
    });

  }

}

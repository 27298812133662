<ion-content>
  <div class="content-container d-flex flex-column">
    <div class="title-content width-100 ">
      <div class="div-title padding">
        <ion-label class="title">{{publicationTitle}}</ion-label> <br>
        <i style="margin-top: -3px; font-size: 15px;"> Campos marcados com * são obrigatórios</i>

      </div>
    </div>

    <div class="overflow-y-auto padding">
      <div *ngIf="publicationType!='1'" class="ion-item-container"
        [class.input-error]="Form?.get('Subject')?.invalid && validateFrom ">
        <ion-input autocomplete="on" autocorrect="on" spellcheck="true" [(ngModel)]="PublicationFromMvService.form.Title" name="title"
          ngDefaultControl placeholder="Título*"></ion-input>
      </div>

      <div *ngIf="publicationType!='1'" class="container-div">
        <div class="ion-item-class-2">
          <div class="ion-icon-class">
            <ion-icon slot="start" src="assets/images/icons-description.svg"></ion-icon>
          </div>
          <div class="ion-textarea-class" [class.input-error]="Form?.get('Message')?.invalid && validateFrom ">
            <ion-textarea autocomplete="on" autocorrect="on" spellcheck="true" [(ngModel)]="PublicationFromMvService.form.Message"
              name="description" ngDefaultControl rows="12" cols="20" placeholder="Corpo de texto*"></ion-textarea>
          </div>
        </div>
      </div>

      <div *ngIf="PublicationFromMvService.form.Files.length > 0" class="ion-item-container-no-border mb-20">
        <ion-label class="attached-title pb-10">Fotografia Anexada</ion-label>

        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div *ngFor="let seleted of PublicationFromMvService.form.Files.slice(0, displayLimit), let i = index"  lot="start" class="mr-10">

              <div class="text-center cursor-pointer"  (click)="deleteFromSeletedContent(i)" style="font-weight: 700;color: #c63527; text-align-last: right;">
                X
              </div>

              <div *ngIf="checkFileType.checkFileType(seleted.FileExtension)  == 'video' && seleted.chucksManager">

                <mat-progress-bar

                  mode="determinate"
                  [style.width]="seleted.chucksManager.uploadPercentage"
                ></mat-progress-bar>

              </div>

              <ion-img *ngIf="checkFileType.checkFileType(seleted.FileExtension) == 'image'" [(ngModel)]="capturedImage"
                name="image" ngDefaultControl [src]="seleted.url"
                (click)="imageSize(capturedImage)"  style="height: 69px; width: 69px;"></ion-img>

              <video *ngIf="checkFileType.checkFileType(seleted.FileExtension)  == 'video'" width="70" height="70"
                controls="controls" preload="metadata" webkit-playsinline="webkit-playsinline">
                <source type="video/mp4" [src]="seleted.url">
              </video>


            </div>
            <!-- Display the blurred image and count if there are more images -->
            <ion-thumbnail *ngIf="PublicationFromMvService.form.Files.length > displayLimit" lot="start">
              <ion-img [src]="'data:image/jpg;base64,' + PublicationFromMvService.form.Files[displayLimit - 1].Base64"
                style="filter: blur(5px);"></ion-img>

                <p>mais {{ PublicationFromMvService.form.Files.length - displayLimit }}</p>
            </ion-thumbnail>
            <ion-label class="pl-10">
              <!-- <p>{{capturedImageTitle}}</p> -->
              <p hidden>size</p>
            </ion-label>
          </div>

        </div>
      </div>

      <div *ngIf="!intent" class="ion-item-container-no-border pt-10">
        <ion-label (click)="takePicture()">
          <div class="attach-icon">
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' "
              src="assets/images/icons-add-photo.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' "
              src="assets/images/theme/gov/icons-add-photo.svg"></ion-icon>

          </div>
          <div class="attach-document">
            <ion-label>Tirar Fotografia</ion-label>
          </div>
        </ion-label>
      </div>

      <div *ngIf="!intent" class="ion-item-container-no-border pt-10">
        <ion-label (click)="startVideoRecording()">
          <div class="attach-icon">
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' "
              src="assets/images/icons-add-photo.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' "
              src="assets/images/theme/gov/icons-add-photo.svg"></ion-icon>

          </div>
          <div class="attach-document">
            <ion-label>Fazer Vídeo</ion-label>
          </div>
        </ion-label>
      </div>

      <div *ngIf="!intent" class="ion-item-container-no-border">
        <ion-label (click)="loadVideo()" class="cursor-pointer">
          <div class="attach-icon">
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' "
              src="assets/images/icons-add-photos.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' "
              src="assets/images/theme/gov/icons-add-photos.svg"></ion-icon>
          </div>
          <div class="attach-document cursor-pointer">
            <ion-label>Galeria</ion-label>
          </div>
        </ion-label>
      </div>
    </div>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="footer-toolbar">
    <ion-buttons slot="start">
      <button class="btn-ok" fill="clear" color="#fff" (click)="save()">
        <ion-label>Gravar</ion-label>
      </button>
    </ion-buttons>
    <ion-buttons slot="end">
      <button class="btn-cancel" fill="clear" color="#061b52" (click)="cancel()">
        <ion-label>Cancelar</ion-label>
      </button>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ObjectQueryService {

  data : any[] = []
  save: Function = () =>{}
  
  constructor() {}

  Update(select:Function, update:Function, data: any[] = this.data ) {
    
    let changes = 0

    data.forEach((e)=> {
      if(select(e)) {
        changes++
        update(e)
      }  
      
    })

    if(changes != 0) {
      this.save()
    }
  }
  
  select(select:Function, data: any[] = this.data ) {
    return data.filter((e)=>{
      return select(e)
    })
  }
  
  Delete(select:Function, data: any[] = this.data) {

    let changes = 0

    data.forEach((e, index)=>{
      if(select(e)) {
        if (index > -1) {
          changes++
          data.splice(index, 1);
        }
      }
    })

    if(changes != 0) this.save()
  }

  Insert(inert:any, data: any[] = this.data ) {
    data.push(inert)
  }

  print() {
    
  }
  
}

import { z } from "zod";
import { EntityTable } from 'Dexie';
import { zodDataUrlSchema } from "src/app/utils/zod";
import { MessageAttachmentFileType, MessageAttachmentSource } from "src/app/core/chat/entity/message";

export const AttachmentTableSchema = z.object({
  $id: z.number().optional(), // local id
  $messageId: z.string(),
  attachmentId: z.string().optional(),
  file: z.instanceof(Blob).optional(),
  base64: zodDataUrlSchema.nullable().optional(),
  //
  fileType: z.nativeEnum(MessageAttachmentFileType).optional(),
  source: z.nativeEnum(MessageAttachmentSource).optional(),
  fileName: z.string().optional(),
  applicationId: z.number().optional(),
  docId: z.number().optional(),
  mimeType: z.string().nullable().optional(),
  id: z.string().uuid().optional(),
  description: z.string().optional()
})

export type AttachmentTable = z.infer<typeof AttachmentTableSchema>
export type DexieAttachmentsTableSchema = EntityTable<AttachmentTable, '$id'>;
export const AttachmentTableColumn = '++$id, id, $messageId, messageId, file'

import { z } from 'zod';

export const EventListOutputDTOSchema = z.object({
  wxUserId: z.number(),
  fullName: z.string(),
  email: z.string(),
  role: z.any(),
  roleId: z.number(),
  entity: z.string(),
  userType: z.string(),
})

export const EventListDataOutputDTOSchema = z.object({
  success: z.boolean(),
  message: z.string(),
  data: z.array(EventListOutputDTOSchema),
}).nullable();

export type ContactCombinedOutputDTO = z.infer<typeof EventListDataOutputDTOSchema>;

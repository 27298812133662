import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
// import { z } from "zod";

@Component({
  selector: 'app-popup-question',
  templateUrl: './popup-question.page.html',
  styleUrls: ['./popup-question.page.scss'],
})
export class PopupQuestionPage implements OnInit {

  title = 'Deseja arquivar este expediente?'
  message = 'Nota: Ao Efetuar esta operação, o tratamento deste expediente não poderá ser realizado a partir da caixa de correspondência'

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
  ) {
    const parameters: any = this.navParams.data

    // const parametersValidation = PopUpQuestionParametersSchema.safeParse(parameters)

    // if(parametersValidation.success) {
      this.title = parameters.title
      this.message = parameters.message
    // } else {
    //   throw('parameters error')
    // }

  }

  ngOnInit() {}

  close() {
    this.modalController.dismiss('No');
  }

  save() {
    this.modalController.dismiss('Yes');
  }

}



// const PopUpQuestionParametersSchema = z.object({
//   title: z.string(),
//   message: z.string(),
// });
// export type PopUpQuestionParameters = z.infer<typeof PopUpQuestionParametersSchema>;

<ion-content class="options-container">

  <!-- <div class="buttons">
    <button (click)="openAddNoteModal('Solicitar assinatura')" class="btn-cancel" shape="round" >Solicitar assinatura</button>
    <button (click)="openAddNoteModal('Solicitar alteração')" class="btn-cancel" shape="round" >Solicitar alteração</button>
    <button (click)="openBookMeetingModal(task)" class="btn-cancel" shape="round" >Marcar Reunião</button>
    <div class="solid"></div>
  </div> -->

  <div class="buttons">
    <div *ngIf="task" class="aside-right flex-column height-100 width-100">
      <div class="buttons width-100" *ngIf="task.activityInstanceName == 'Revisar Diploma'">
        <button (click)="openAddNoteModal('Solicitar assinatura')" class="btn-cancel" shape="round" >Solicitar assinatura do Presidente</button>
        <button (click)="openAddNoteModal('Solicitar alteração')" class="btn-cancel" shape="round" >Solicitar alteração</button>
        <button (click)="openExpedientActionsModal('0',fulltask)" class="btn-cancel" shape="round" >Efetuar despacho</button>
        <button (click)="openBookMeetingModal(task)" class="btn-cancel" shape="round" >Marcar Reunião</button>


        <div class="solid"></div>
      </div>
      <!-- <div class="buttons width-100" *ngIf="task.activityInstanceName == 'Diploma Assinado'">
        <button (click)="openAddNoteModal('Concluir diploma')" class="btn-cancel" shape="round" >Concluir</button>
      </div> -->
      <div class="buttons width-100" *ngIf="task.activityInstanceName == 'Assinar Diploma'">
        <button *ngIf="p.userRole('PR')" (click)="AssinarNew()" class="btn-cancel" shape="round" >Assinar</button>
      </div>
      <div class="buttons width-100">
        <button (click)="openAddNoteModal('Arquivo')" class="btn-cancel" shape="round" >Arquivar</button>
      </div>
      <div class="buttons width-100" *ngIf="task.activityInstanceName != 'Assinar Diploma' && task.activityInstanceName != 'Revisar Diploma'">
        <button (click)="openExpedientActionsModal('0',task)" class="btn-cancel" shape="round" >Efetuar despacho</button>
      </div>
    </div>
  </div>

</ion-content>

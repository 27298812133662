import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { Observable } from 'rxjs';
import { MemberListLocalRepository } from 'src/app/module/chat/data/repository/member/member-list-local-repository.service'
import { RoomLocalRepository } from 'src/app/module/chat/data/repository/room/room-local-repository.service'
import { RoomTable } from 'src/app/infra/database/dexie/instance/chat/schema/room';
import { map, tap } from 'rxjs/operators';
import { ChatServiceService } from 'src/app/module/chat/domain/chat-service.service'
import { MemberEntity } from 'src/app/core/chat/entity/member';
@Component({
  selector: 'app-room-info',
  templateUrl: './room-info.page.html',
  styleUrls: ['./room-info.page.scss'],
})
export class RoomInfoPage implements OnInit {

  roomId:string;
  roomMembers$:  Observable<MemberEntity[]>
  roomData$: Observable<RoomTable | undefined>
  ObjectURL : {[key: string]: string} = {};

  constructor(
    private navParams: NavParams,
    private MemberListLocalRepository: MemberListLocalRepository,
    private RoomLocalRepository: RoomLocalRepository,
    private ChatServiceService: ChatServiceService
  ) {
    this.roomId = this.navParams.get('roomId');
  }

  ngOnInit() {

    // this.roomMessage$ = this.messageRepositoryService.getItemsLive(this.roomId)
    this.roomMembers$ = this.MemberListLocalRepository.getRoomMemberByIdLive(this.roomId).pipe(
      map((memberList) => memberList.map(e =>  Object.assign(new MemberEntity(), e))),
      tap((members) => {
        for(let member of members) {

          if(!this.ObjectURL[member.wxUserId] && member.hasPhoto()) {
            this.ChatServiceService.getUserPhoto({wxUserId: member.wxUserId, attachmentId: member.userPhoto}).then((result)=> {

              if(result.isOk()) {
                console.log('get photo', result.value)
                this.ObjectURL[member.wxUserId] = 'data:image/jpeg;base64,'+result.value
              }
            })
          }
        }
      }),

    )

    this.roomData$ = this.RoomLocalRepository.getRoomByIdLive(this.roomId)
  }

}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrganicEntityService } from 'src/app/services/organic-entity.service';
import { OrganicEntity } from 'src/app/models/organic-entity.model';
import { ThemeService } from 'src/app/services/theme.service'


@Component({
  selector: 'app-organic-entity',
  templateUrl: './organic-entity.page.html',
  styleUrls: ['./organic-entity.page.scss'],
})
export class OrganicEntityPage implements OnInit {

  organicEntities: OrganicEntity[];
  showOrganicEntities: OrganicEntity[];
  findEntity: string;

  
  constructor(
    private modalController:ModalController,
    private OrganicEntityService: OrganicEntityService,
    public ThemeService: ThemeService
  ) {

    }

  ngOnInit() {
    this.getOrganicEntity();
  }


  getOrganicEntity(){
    this.OrganicEntityService.getOrganicEntity().subscribe(res=>{
      this.organicEntities = res;
      this.showOrganicEntities = res
    });
  }

  filterContact(){

    const findEntity = this.findEntity.toLowerCase();
    
    const entities = this.organicEntities.filter((Entity) => {

      if (Entity.Description.toLowerCase().indexOf(findEntity) == 0){
       return true;
      }

    });

    this.showOrganicEntities = entities;
  }

  selectOrganicEntidy(selectedOraganicEntit){
    this.modalController.dismiss(selectedOraganicEntit);
  }

  close(){
    this.modalController.dismiss();
  }
}

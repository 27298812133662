import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpAdapter } from './adapter';
import { HttpService } from './http.service';

@NgModule({
  imports: [],
  declarations: [],
  schemas: [],
  providers: [
    {
      provide: HttpAdapter,
      useClass: HttpService, // or MockDataService
    }
  ],
  entryComponents: []
})
export class HttpModule {}

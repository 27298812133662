import { Injectable } from '@angular/core';
import { IGetUserPhotoByAttachmentId, IGetUserPhotoByAttachmentIdInputSchema, IUserPhotoRemoteRepository } from 'src/app/core/chat/repository/user-photo/user-photo-remote-repository';
import { HttpAdapter } from 'src/app/infra/http/adapter';
import { SafeValidateSchema } from 'src/app/services/decorators/validate-schema.decorator';
import { environment } from 'src/environments/environment';
import { z } from 'zod';

@Injectable({
  providedIn: 'root'
})
export class UserPhotoRemoteRepositoryService implements IUserPhotoRemoteRepository{

  constructor(
    private http: HttpAdapter
  ) { }

  @SafeValidateSchema(IGetUserPhotoByAttachmentIdInputSchema, 'GET/UserAuthentication/GetPhoto?UserPhoto=')
  getUserPhotoByAttachmentId(input: IGetUserPhotoByAttachmentId) {
    const geturl = environment.apiURL + `UserAuthentication/GetPhoto?UserPhoto=${input.attachmentId}`;

    return this.http.get<string>(geturl)
  }

}

import { Injectable } from '@angular/core';
import { IMessageSocketRepository } from 'src/app/core/chat/repository/message/message-socket-repository';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { filter, map, tap } from 'rxjs/operators';
import { MessageEntity } from 'src/app/core/chat/entity/message';
import { HttpAdapter } from 'src/app/infra/http/adapter';
import { IMessageGetAllByRoomIdOutPut } from 'src/app/core/chat/usecase/message/message-get-all-by-room-Id';
import { RoomEntity } from 'src/app/core/chat/entity/group';
import { RoomTable } from 'src/app/infra/database/dexie/instance/chat/schema/room';
import { IMessageLocalRepository } from 'src/app/core/chat/repository/message/message-local-repository';

@Injectable({
  providedIn: 'root'
})
export class RoomSetLastMessageService {

  constructor(
    private roomLocalRepository: IRoomLocalRepository,
    private messageSocketRepository: IMessageSocketRepository,
    private messageLocalRepository: IMessageLocalRepository,
    private http: HttpAdapter,
  ) {
    this.listenToIncomingMessage()
    this.listenToOnSendDataToSocket()
    this.loadHistory()
    this.listenToUpdateMessage()
  }

  private listenToUpdateMessage() {
    let roomList: RoomTable[] = []
    this.roomLocalRepository.getItemsLive().pipe(
      tap((_roomList) => {

        roomList = _roomList

      })
    ).subscribe();

    this.messageSocketRepository.listenToUpdateMessages().subscribe(async(_message) => {
      const message = {..._message}

      for(const room of roomList) {
        if(room.messages?.[0]?.id == message.id) {
          console.log('listenToUpdateMessage', message.roomId)
          const result = await this.roomLocalRepository.update(message.roomId, {
            messages: [message]
          })
        }
      }
    })

    this.messageSocketRepository.listenToDeleteMessages().subscribe(async(_message) => {
      const message = {..._message}

      for(const room of roomList) {
        if(room.messages?.[0]?.id == message.id) {
          console.log({...room.messages?.[0],isDeleted: true})
          const result = await this.roomLocalRepository.update(message.roomId, {
            messages: [{...room.messages?.[0],isDeleted: true}]
          })
        }
      }
    })
  }

  private listenToIncomingMessage() {
    return this.messageSocketRepository.listenToMessages().pipe(
      map(message => Object.assign(new MessageEntity(), message))
    ).subscribe(async (message) => {
      if(message?.roomId) {
        console.log('listenToIncomingMessage', message.roomId)
        const result = await this.roomLocalRepository.update(message.roomId, {
          messages: [message]
        })

        if(result.isErr()) {
          console.log('failed to update last message')
        } else {
          console.log('set last message')
        }
      }


    });

  }

  private listenToOnSendDataToSocket() {
    this.messageLocalRepository.onCreateObservable().subscribe(async (message) => {
      if(message?.roomId) {

        setTimeout(async() => {
          if(message.origin != 'history') {
            const result = await this.roomLocalRepository.update(message.roomId, {
              messages: [message]
            })
          }
        }, 100)
      }

    })
  }

  private loadHistory() {
    const regex = new RegExp("Room\\/([0-9a-fA-F]{8})-([0-9a-fA-F]{4})-([0-9a-fA-F]{4})-([0-9a-fA-F]{4})-([0-9a-fA-F]{12})\\/Messages");

    return this.http.listen().pipe(
      filter((response: any)=> {
        return response?.isOk() && regex.test(response.value.url) && response.value.url.endsWith('/Messages')
      }),
      map((response: any) => response.value.data as IMessageGetAllByRoomIdOutPut)
    ).subscribe(async (data)=> {
      const loadHistoryFirstMessage = data.data[0]
      if(loadHistoryFirstMessage) {

        const roomId = loadHistoryFirstMessage.roomId

        const room = await this.roomLocalRepository.findOne({id: roomId})

        if(room.isOk()) {
          const roomEntity = new RoomEntity(room.value)
          if(!roomEntity.hasLastMessage()) {
            await this.roomLocalRepository.update(loadHistoryFirstMessage.roomId, {
              messages: [loadHistoryFirstMessage]
            })
          } else if (roomEntity.hasLastMessage()) {
            const localLastMessageDate = new Date(room.value.messages[0].sentAt).getTime()
            const loadHistoryLastMessageDate = new Date(loadHistoryFirstMessage.sentAt).getTime()

            if(loadHistoryFirstMessage.id == room.value.messages?.[0]?.id) {
              // do nothing
            } else if(loadHistoryLastMessageDate>localLastMessageDate) {
              await this.roomLocalRepository.update(loadHistoryFirstMessage.roomId, {
                messages: [loadHistoryFirstMessage]
              })
            } else if(loadHistoryLastMessageDate == localLastMessageDate) {
              // do nothing
            } else if(room.value.messages[0].isDeleted != loadHistoryFirstMessage.isDeleted) {
              await this.roomLocalRepository.update(loadHistoryFirstMessage.roomId, {
                messages: [loadHistoryFirstMessage]
              })
            }

          }

        }

      }
    })
  }

}

import { Injectable } from '@angular/core';
import { z } from 'zod';
import { MessageLocalDataSourceService } from 'src/app/module/chat/data/repository/message/message-local-data-source.service'
import { MessageSocketRepositoryService } from 'src/app/module/chat/data/repository/message/message-live-signalr-data-source.service'
import { SessionStore } from 'src/app/store/session.service';

const MessageMarkAllMessageAsReadByRoomIdInputSchema = z.object({
  roomId: z.string(),
})

export type MessageMarkAllMessageAsReadByRoomIdInputSchema = z.infer<typeof MessageMarkAllMessageAsReadByRoomIdInputSchema>


/**
 * @description avoid using it, avoid duplicate query. do it on the UI layer as the component load the message.
 */
@Injectable({
  providedIn: 'root'
})
export class MessageMarkAllMessageAsReadByRoomIdService {

  constructor(
    private MessageLocalDataSourceService: MessageLocalDataSourceService,
    private MessageSocketRepositoryService: MessageSocketRepositoryService
  ) { }


  async execute(input: MessageMarkAllMessageAsReadByRoomIdInputSchema) {
    const messages = await this.MessageLocalDataSourceService.find({roomId:input.roomId})

    if(messages.isOk()) {
      for(const message of messages.value) {
        this.MessageSocketRepositoryService.sendReadAt({
          memberId: SessionStore.user.UserId,
          messageId: message.id,
          roomId: input.roomId,
          requestId: 'uuid'
        });
      }
    }

  }
}

import { z } from "zod"

export enum MessageAttachmentSource {
  Webtrix = 1,
  Device,
}

export enum MessageAttachmentFileType {
  Doc = 1,
  Image ,
  Audio ,
  Video
}

export const MessageOutPutDataDTOSchema = z.object({
  id: z.string(),
  roomId: z.string(),
  sender: z.object({
    wxUserId: z.number(),
    wxFullName: z.string(),
    wxeMail: z.string(),
    userPhoto: z.string().nullable().optional()
  }),
  message: z.string().nullable().optional(),
  messageType: z.number(),
  sentAt: z.string(),
  canEdit: z.boolean(),
  oneShot: z.boolean(),
  requireUnlock: z.boolean(),
  requestId: z.string().optional().nullable(),
  reactions: z.object({
    id: z.string(),
    reactedAt: z.string(),
    reaction: z.string(),
    sender: z.object({}),
  }).array(),
  info: z.array(z.object({
    memberId: z.number(),
    readAt: z.string().nullable(),
    deliverAt: z.string().nullable()
  })),
  attachments: z.array(z.object({
    fileType: z.nativeEnum(MessageAttachmentFileType),
    source: z.nativeEnum(MessageAttachmentSource),
    file: z.string().optional(),
    fileName: z.string().optional(),
    applicationId: z.number().optional(),
    docId: z.number().optional(),
    id: z.string().optional()
  }))
});

export type MessageOutPutDataDTO = z.infer<typeof MessageOutPutDataDTOSchema>

<ion-content class="options-container">
  <div class="arrow-right header-fix">
    <button class="btn-no-color" (click)="close('cancel')">
      <ion-icon *ngIf="ThemeService.currentTheme == 'default' " slot="end" class="arrow-right-icon"
        src='assets/images/icons-arrow-arrow-right.svg'></ion-icon>
      <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " slot="end" class="arrow-right-icon"
        src='assets/images/theme/gov/icons-calendar-arrow-right.svg'></ion-icon>
    </button>
  </div>
  <div class="buttons">
    <!-- <button *ngIf="isAdmin" (click)="addUser()" class="btn-cancel" shape="round">Adicionar</button>
    <button (click)="leaveGroup()" class="btn-cancel" shape="round">Sair do Grupo</button>
    <button *ngIf="isAdmin" (click)="openChangeGroupName()" class="btn-cancel btn-cancel mt-10" shape="round" style="min-width: 192px;">Alterar
      nome do grupo</button>
    <div class="solid"></div>
    <button (click)="close('cancel')" full class="btn-cancel mobile-only" shape="round">Cancelar</button>
    <button *ngIf="isAdmin" (click)="deleteGroup()" class="btn-delete" shape="round">Apagar grupo</button>
 -->

    <ion-progress-bar type="indeterminate" *ngIf="showLoader"></ion-progress-bar>
    <br *ngIf="showLoader">
    <button (click)="details()" class="btn-cancel" shape="round">Detalhes</button>
    <button *ngIf="isAdmin && roomType == EnumRoomType.Group" (click)="addUser()" class="btn-cancel" shape="round">Adicionar</button>
    <button *ngIf="roomType == EnumRoomType.Group" (click)="leaveGroup()" class="btn-cancel" shape="round">Sair do Grupo</button>
    <button *ngIf="isAdmin && roomType == EnumRoomType.Group" (click)="openChangeGroupName()" class="btn-cancel btn-cancel mt-10" shape="round" style="min-width: 192px;">Alterar
      nome do grupo</button>
      <button *ngIf="isAdmin && roomType == EnumRoomType.Group"  (click)="setRoomOwner()" class="btn-cancel" shape="round">Adicionar admin</button>
    <div class="solid"></div>
    <button (click)="close('cancel')" full class="btn-cancel mobile-only" shape="round">Cancelar</button>
    <button *ngIf="isAdmin && roomType == EnumRoomType.Group" (click)="deleteGroup()" class="btn-delete" shape="round">Apagar grupo</button>
  </div>
</ion-content>

import { Pipe, PipeTransform } from '@angular/core';
import { SearchDocumentDetails, SearchFolderDetails, SearchList } from '../models/search-document';

@Pipe({
  name: 'event'
})
export class EventPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

  AttactSearchResult(seachDocument: SearchList, eventId, {Source = '1'}): addAttachment {

    return {
      SourceTitle: seachDocument.Assunto,
      ParentId: eventId,
      Source: Source,
      SourceId: seachDocument.Id,
      ApplicationId: seachDocument.ApplicationType.toString(),
      Id: '',
      Link: '',
      SerialNumber: ''
    }

  }

  AttactSearchResultDetails(searchResult: SearchFolderDetails | SearchDocumentDetails, eventId, {Source = '1'}) {
    return {}
  }

}

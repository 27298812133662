import { Injectable } from '@angular/core';
import { localstoreService } from './localstore.service'
import { AES, enc, SHA1 }  from  'crypto-js'
import { momentG } from 'src/plugin/momentG';
import { v4 as uuidv4 } from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class PedidosStoreService {

  private _listParecer = []
  private _listDeferimento = []
  // local storage keyName
  private keyNameparecer: string;
  private keyNamedeferiemnto: string; 
  private _countparecer = 0
  private _countDeferiemnto = 0


  listParecerCount: Event[] = []
  listDeferimentoCount: Event[] = []

  callbacks: {
    [key: string]: {
      funx: Function
      id: string
    }
  } = {} 
 
  constructor() {
 
    this.keyNameparecer = (SHA1("PedidosStoreService"+"parecer")).toString()
    this.keyNamedeferiemnto = (SHA1("PedidosStoreService"+"deferimneto")).toString()
    
    setTimeout(()=>{ 
      let restoreParecer = localstoreService.get(this.keyNameparecer, {})
      let restoreDeferimento = localstoreService.get(this.keyNamedeferiemnto, {})
      
      this._listParecer = restoreDeferimento.listDeferimento || []
      this._listDeferimento = restoreParecer.listParecer || []
      this._countparecer =  parseInt(restoreParecer.count) || 0
      this._countDeferiemnto = parseInt(restoreDeferimento.count) || 0

      this.runCallback()
      
    }, 10)
 
  }

  registerCallback({funx, id = uuidv4()}) {

    this.callbacks[id] = { funx, id}

    return {
      delete: ()=> {
        delete this.callbacks[id]
      }
    }
  }


  runCallback() {
    for (const [key, value] of Object.entries(this.callbacks)) {
      value.funx()
    }
  }

 
  get listparecer(): any[] {
    return this._listParecer || []
  }
 
  get listdeferimento(): any[] {
   return this._listDeferimento || []
  }

  get countparecer() {
    return this._countparecer || 0
  }
  set countparecer(value: number) {
    this._countparecer = value
    this.saveParecer()
  }

  get countdeferimento() {
    return this._countDeferiemnto || 0
  }
  set countdeferimento(value: number ) {
    this._countDeferiemnto = value
    this.saveDeferimento()
  }
 
  resetparecer(eventsList: any) {
    this._listParecer = eventsList
 
    this.countparecer = this._listParecer.length
    this.saveDeferimento()

    this.runCallback()
    if(window['all-process-gabinete']) {
      window['all-process-gabinete']()
    }
  }
 
  resetdeferimento(list: any) {
    this._listDeferimento = list
 
   this.countdeferimento =this._listDeferimento.length
   this.saveParecer()
   this.runCallback()
    if(window['all-process-gabinete']) {
      window['all-process-gabinete']()
    }
  }

  private saveParecer() {
    setTimeout(()=>{ 
      localstoreService.set(this.keyNameparecer,{
        listParecer: this._listParecer,
        count: this._countparecer,
      })
    }, 10)

    this.updateNewCount()
    
  }
 
  private saveDeferimento() {
    setTimeout(()=>{ 
      localstoreService.set(this.keyNamedeferiemnto,{
        listDeferimento: this._listParecer,
        count: this._countDeferiemnto,
      })
    }, 10)

    this.updateNewCount()
    
  }


  
  updateNewCount() {
    this.listParecerCount = this._listParecer.filter((e) =>{
      return this.lessthen24Hours(e.TaskReceiveDate)
    })
    this.listDeferimentoCount = this._listDeferimento.filter((e) =>{
      return this.lessthen24Hours(e.TaskReceiveDate)
    })
  }


  lessthen24Hours(isoDateString:string) {

    if(!isoDateString) {
      return false
    }
    
    const creationDate = new Date(isoDateString)
    return momentG(new Date(), 'dd MMMM yyyy') == momentG(new Date(creationDate), 'dd MMMM yyyy')
  }


}

export const PedidosStore = new PedidosStoreService()

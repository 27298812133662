import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpServiceService } from 'src/app/services/http/http-service.service';
import { Observable} from 'rxjs';
import { CreateEvent, EditEvent, EventDetailsDTO, EventsDTO, IuploadFileLK, refreshTokenDTO } from "./interface";
import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { DetectCalendars, makeHeaderForCalendar } from '../../utils/utils';
import { z } from "zod";
import { ok, err } from 'neverthrow';
import { SessionStore } from 'src/app/store/session.service';
/* import { ok, err } from 'neverthrow'; */


@Injectable({
  providedIn: 'root'
})
export class MiddlewareServiceService {

  constructor(
    private HttpServiceService: HttpServiceService,
    private http: HttpClient,
  ) {
    window["MiddlewareServiceService"] = this
  }

  refreshToken(refreshToken: string) {
    const data = {
      refreshToken: refreshToken
    }

    return this.HttpServiceService.put<refreshTokenDTO>(environment.apiURL + "UserAuthentication/RefreshToken", data, {})
    // .pipe(
    //   map((response: HttpResponse<refreshToken>) => {
    //     return response.body
    //   })
    // );
  }

  //  ================================ Calendar =================================================

  GetEvents(startDate: string, endDate: string, calendarId): Observable<EventsDTO[]> {

    let geturl = environment.apiURL + 'calendar/GetEvents';
    geturl = geturl.replace('/V4/', '/V5/')

    let params = new HttpParams();

    params = params.set("StartDate", startDate);
    params = params.set("EndDate", endDate);

    const calendar = DetectCalendars(calendarId)
    const header =  makeHeaderForCalendar(calendar)

    let options = {
      headers: header,
      params: params
    };

    // return this.HttpServiceService.get<Event[]>(`${geturl}`, options);

    return {} as any
  }

  GetEventDetail(eventId: string, calendarId: string) {
    let geturl = environment.apiURL + 'calendar/GetEvent';
    let params = new HttpParams();

    params = params.set("EventId", eventId);

    const calendar = DetectCalendars(calendarId)
    const header = makeHeaderForCalendar(calendar)

    let options = {
      headers: header,
      params: params
    }
    return this.HttpServiceService.get<EventDetailsDTO>(`${geturl}`, options);
  }

  createEvent(event: CreateEvent, calendarName: string, CalendarId) {
    const puturl = environment.apiURL + 'Calendar/PostEvent';
    let params = new HttpParams();

    if(!event.TimeZone) {
      const now = new Date();
      event.TimeZone = event.TimeZone = now.toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1];
    }

    if(!event.Organizer) {
      event.Organizer = {
        "Id": SessionStore.user.UserId,
        "EmailAddress": SessionStore.user.Email,
        "Name": SessionStore.user.UserName,
        "IsRequired": true,
        "UserType": "GD"
      }
    }

    params = params.set("CalendarName", calendarName);
    params = params.set("notifyUsers", true)

    let options: any;

    const calendar = DetectCalendars(CalendarId)
    const header = makeHeaderForCalendar(calendar)

    options = {
      headers: header,
      params: params
    };

    return this.HttpServiceService.post<string>(`${puturl}`, event, options)
  }


  editEvent(event: EditEvent, conflictResolutionMode: number, sendInvitationsOrCancellationsMode: number, CalendarId? ) {

    let options: any;

    const calendar = DetectCalendars(CalendarId)
    const header = makeHeaderForCalendar(calendar)
    let params = new HttpParams();

    params = params.set("conflictResolutionMode", conflictResolutionMode.toString());
    params = params.set("sendInvitationsOrCancellationsMode", sendInvitationsOrCancellationsMode.toString());
    params.set('CalendarId', event.CalendarId)
    params.set('CalendarName', event.CalendarName)

    options = {
      headers: header,
      params: params
    };

    const putUrl = environment.apiURL + 'calendar/PutEvent';
    return this.HttpServiceService.put<string>(`${putUrl}`, event, options);

  }

  //  ================================ Acções =================================================


  // ========== LKFRT

  uploadFileLK(formData: FormData) {

    const headers = new HttpHeaders();
    headers.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);

    //const geturl = environment.apiURL + 'Tasks/DelegateTask';
    const geturl = environment.apiURL + 'ObjectServer/UploadFiles';

    let options = {
      headers: headers
    };

    return this.http.post<IuploadFileLK>(`${geturl}`, formData, options)
  }

  // ===========================================================================

  CMAPIPing() {
    const headers = new HttpHeaders();
    headers.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);

    //const geturl = environment.apiURL + 'Tasks/DelegateTask';
    const geturl = environment.apiPCURL + 'FileContent';

    let options = {
      headers: headers
    };


    const formData = new FormData();

    formData.append("blobFile", "blobFile");
    formData.append("length", "length");
    formData.append("index", "index.toString(");


    return this.http.post<IuploadFileLK>(`${geturl}`, formData, options)
  }


  GetViewer(DocId: string, FsId: string) {

    const geturl = environment.apiURL + 'ecm/document/viewfile';
    let params = new HttpParams();

    params = params.set("docId", DocId);
    params = params.set("applicationId", FsId);

    let options = {
      params: params
    };
    return this.http.get<string>(`${geturl}`, options);
  }

  CMAPIFileContent({length, path, index, base64}) {

    // const geturl = 'http://localhost:3001/FileHub';
    const geturl = environment.apiPCURL + 'FileContent/UploadFile';

    const data = {
      index,
      length,
      base64,
      path,
    }

    return this.http.post<IuploadFileLK>(`${geturl}`, data)
  }

  CMAPIRequestUpload() {
    const geturl = environment.apiPCURL + 'FileContent/RequestUpload';
    return this.http.get<string>(`${geturl}`)
  }

  CMAPIUploadStatus() {
    const geturl = environment.apiPCURL + 'FileContent/UploadStatus';
    return this.http.get<string>(`${geturl}`)
  }


  tryToReachTheServer() {
    let opts = {
      headers: {},
    }

    return this.http.post(environment.apiURL + "UserAuthentication/Login", '', opts)
  }

}

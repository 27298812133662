import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loadingList: {
    name: string
  }[] = []

  private loader = {}

  constructor() { }

  get loading() {
    return this.loadingList.length != 0
  }


  initialize(name) {
    this.loader[name] = []
    return {
      push() { 
        this.loader[name].push()
      },
      pop() {
        this.loader[name].pop()
      },
      isLoader() {
        this.loader[name].length
      }
    }
  }
  
  push({name = ''}) {
    this.loadingList.push({
      name: name
    })
  }

  pop({}) {
    this.loadingList.pop()
  }

}

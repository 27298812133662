import { Injectable } from '@angular/core';
import { SessionStore } from 'src/app/store/session.service';
import { TracingType, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';
import { z } from "zod";
import { IRoomRemoteRepository } from 'src/app/core/chat/repository/room/room-remote-repository';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { IRoomSocketRepository } from 'src/app/core/chat/repository/room/room-socket-repository';

export const CreateRoomInputDTOSchema = z.object({
  roomName: z.string(),
  createdBy: z.number(),
  roomType: z.number(),
  expirationDate: z.string().nullable().optional(),
  members: z.array(z.number())
});
export type CreateRoomInputDTO = z.infer<typeof CreateRoomInputDTOSchema>


export const RoomOutPutDTOSchema = z.object({
  success: z.boolean(),
  message: z.string(),
  data: z.object({
    id: z.string(),
    roomName: z.string(),
    createdBy: z.any().nullable(),
    createdAt: z.string(),
    expirationDate: z.string().nullable(),
    roomType: z.any()
  })
});
export type RoomOutPutDTO = z.infer<typeof RoomOutPutDTOSchema>

@Injectable({
  providedIn: 'root'
})
export class CreateRoomUseCaseService {

  constructor(
    private roomRemoteDataSourceService: IRoomRemoteRepository,
    private roomLocalDataSourceService: IRoomLocalRepository,
    private RoomSocketRepositoryService: IRoomSocketRepository
  ) { }

  @XTracerAsync({name:'room-create-use-case.service', module:'chat',  bugPrint: true, waitNThrow: 5000})
  async execute(data: CreateRoomInputDTO, tracing?: TracingType) {

    const result = await this.RoomSocketRepositoryService.CreateGroup(data)

    console.log('history', result)
    // const result = await this.roomRemoteDataSourceService.createRoom(data)

    if(result.isOk()) {

      return result
    } else {
      tracing.hasError("socket close");
      console.log(result.error)
    }

    return result
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
// import { readFile } from 'fs';



const CACHE_FOLDER = 'CACHED-IMG'

@Component({
  selector: 'app-custom-image-cache',
  templateUrl: './custom-image-cache.page.html',
  styleUrls: ['./custom-image-cache.page.scss'],
})
export class CustomImageCachePage implements OnInit {

  _src ="";
  @Input () spinner = false

  ngOnInit() {
  }
  constructor() { }

  @Input()
  set src(imageUrl: string){
    

    const imageName = imageUrl.split('/').pop()
    const fileType = imageName.split('.').pop()

    Filesystem.readFile({
      directory:  Directory.Cache,
      path: `${CACHE_FOLDER}/${imageName}`}).then(readFile =>{
          
          // set to SRC
          this._src = `data:image/${fileType};base64ToFile, ${readFile.data}`
      }).catch(async e =>{
        await this.storedImage(imageUrl, imageName)
      })
      Filesystem.readFile({
        directory: Directory.Cache,
        path: `${CACHE_FOLDER}/${imageName}`
      }).then(readFile =>{
        this._src = `data:image/${fileType};base64ToFile, ${readFile.data}`
      })

    }
    async storedImage(url, path){
      const response = await fetch(`http://api-cors-proxy-devdactic.herokuapp.com/${url}`)
      const blob = await response.blob()

      const base64Data = await this.convertBlobToBase64(blob) as string;
      const savedFile = await Filesystem.writeFile({
        path: `${CACHE_FOLDER}/${path}`,
        data: base64Data,
        directory: Directory.Cache
      })
      return savedFile
    }

    convertBlobToBase64(blob: Blob){
      return new Promise((resolve, reject) =>{
        const reader = new FileReader;
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.readAsDataURL(blob)
      })
    }
  } 







<ion-content class="container-wrapper" >
  <div *ngIf="task" class="main-content  d-flex height-100 overflow-hidden">
    <div class="width-100 d-flex flex-column content" *ngIf="task">
        <app-task-details
          *ngIf="task"
          [task]=task
          [intervenientes]=intervenientes
          [cc]=cc
          [customDate]=customDate
          [mergedArray]="fulltask.Documents"
          [fulltask] = "fulltask"
          (openOptions)= openOptions()
          (goBack)= goBack()
          class="d-flex height-100 flex-column"
        ></app-task-details>
    </div>

    <div *ngIf="task && showOptions" class="aside-right" (click)="showOptions=!showOptions">
      <div class="aside-right-container flex-column height-100 overflow-y-auto">
        <div class="buttons">
          <div *ngIf="p.userPermission([p.permissionList.Gabinete.md_tasks])" class="option-desc"> <div>Enviar para o PR</div> </div>
          <button *ngIf="p.userPermission([p.permissionList.Gabinete.md_tasks])" (click)="openAddNoteModal('Aprovar')" class="btn-cancel" shape="round" >Solicitar consideração superior</button>
          <div *ngIf="p.userPermission([p.permissionList.Gabinete.md_tasks])" class="option-desc"> <div>Solicitar revisão</div> </div>
          <button *ngIf="p.userPermission([p.permissionList.Gabinete.md_tasks])" (click)="openAddNoteModal('Revisão')" class="btn-cancel" shape="round" >Submeter para revisão</button>
          <div *ngIf="p.userPermission([p.permissionList.Gabinete.md_tasks])" class="option-desc"> <div>Outras opções</div> </div>
          <button (click)="openExpedientActionsModal('0',fulltask)" class="btn-cancel" shape="round" >Efetuar Despacho</button>
          <button (click)="openExpedientActionsModal('1',fulltask)" class="btn-cancel" shape="round" >Solicitar Parecer</button>
          <button *ngIf="!p.userPermission([p.permissionList.Gabinete.pr_tasks])" (click)="openExpedientActionsModal('2',fulltask)" class="btn-cancel" shape="round" >Pedido de Deferimento</button>
          <button *ngIf="p.userPermission([p.permissionList.Agenda.access])" (click)="openBookMeetingModal(task)" class="btn-cancel" shape="round" >Marcar Reunião</button>
          <button *ngIf="p.userPermission([p.permissionList.Gabinete.md_tasks])" (click)="attachDocument()" class="btn-cancel" shape="round" >Anexar Documentos</button>
          <button (click)="distartExpedientModal('descartar')" class="btn-cancel" shape="round" >Arquivar</button>
          <button (click)="sendExpedienteToPending()" *ngIf="task.Status != 'Pending'" full class="btn-cancel" shape="round"  >Enviar para pendentes</button>
          <button *ngIf="p.userPermission([p.permissionList.Chat.access])" (click)="openNewGroupPage()" class="btn-cancel" shape="round" >Iniciar Conversa</button>
          <div class="solid"></div>
        </div>
      </div>

    </div>

  </div>

  <div *ngIf="!task" class="main-content d-flex height-100 overflow-hidden">
    <ion-item lines="none"
                class="item-skeleton width-100 d-flex ion-no-border ion-no-margin ion-no-padding">

                  <div class="item-content flex-grow-1 cursor-pointer">
                    <p class="item-content-date my-5"><ion-skeleton-text animated style="width: 90%"></ion-skeleton-text></p>
                    <p class="item-content-title my-10"><ion-skeleton-text animated style="width: 50%"></ion-skeleton-text></p>
                    <p class="item-content-detail my-5"><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
                  </div>
    </ion-item>
    <ion-item lines="none"
    class="item-skeleton width-100 d-flex ion-no-border ion-no-margin ion-no-padding">

      <div class="item-content flex-grow-1 cursor-pointer">
        <p class="item-content-date my-5"><ion-skeleton-text animated style="width: 90%"></ion-skeleton-text></p>
        <p class="item-content-title my-10"><ion-skeleton-text animated style="width: 50%"></ion-skeleton-text></p>
        <p class="item-content-detail my-5"><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
      </div>
    </ion-item>
    <ion-item lines="none"
    class="item-skeleton width-100 d-flex ion-no-border ion-no-margin ion-no-padding">

      <div class="item-content flex-grow-1 cursor-pointer">
        <p class="item-content-date my-5"><ion-skeleton-text animated style="width: 90%"></ion-skeleton-text></p>
        <p class="item-content-title my-10"><ion-skeleton-text animated style="width: 50%"></ion-skeleton-text></p>
        <p class="item-content-detail my-5"><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
      </div>
    </ion-item>
    <ion-item lines="none"
    class="item-skeleton width-100 d-flex ion-no-border ion-no-margin ion-no-padding">

      <div class="item-content flex-grow-1 cursor-pointer">
        <p class="item-content-date my-5"><ion-skeleton-text animated style="width: 90%"></ion-skeleton-text></p>
        <p class="item-content-title my-10"><ion-skeleton-text animated style="width: 50%"></ion-skeleton-text></p>
        <p class="item-content-detail my-5"><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
      </div>
    </ion-item>
  </div>

  <div>

  </div>
</ion-content>


import { Injectable } from '@angular/core';
import { localstoreService } from './localstore.service'
import { AES, enc, SHA1 }  from  'crypto-js'

@Injectable({
  providedIn: 'root'
})
export class TotalDocumentService {

  private _count = 0
  // local storage keyName
  private keyName: string; 

  constructor() {
    this.keyName = (SHA1("TotalDocumentService")).toString()

    setTimeout(()=> {
      let restore = localstoreService.get(this.keyName, {})
      this._count = restore.count || 0
    }, 10)

  }

  get count() {
    return this._count
  }

  resetCount(value) {
    this._count = value
  }

  saveCount() {
    setTimeout(()=> {
      localstoreService.set(this.keyName, {
        count: this._count
      })
    }, 10)
  }

}


export let TotalDocumentStore = new TotalDocumentService()

<ion-header class="ion-no-border" translucent>
  <div class="main-header d-flex align-items-center">
    <div class= "left cursor-pointer" (click)="close()">
      <button class="btn-no-color" (click)="close()">
        <fa-icon icon="chevron-left" class="header-top-btn font-awesome-1 font-35-rem"></fa-icon>
      </button>
    </div>
    <div  class="middle-container">
      <div class="middle">
        <ion-label class="title">{{name}}<span class="font-15">, {{_updatedAt | date}}</span>
        </ion-label>
      </div>
    </div>
  </div>
</ion-header>
<ion-content fullscreen>
  <div *ngIf="view" class="media d-flex align-items-center justify-center">
    <div class="media-content w-100 d-flex align-items-center justify-center">

      <ion-slides style="width: 100%; height: 100%;" [options]="sliderOpts">
        <ion-slide>
          <div class="swiper-zoom-container" >
            <div style="overflow: scroll; width: 100%; height:100%;"  class="d-flex justify-center">
              <img src="{{image}}">
            </div>
          </div>

          <div *ngIf="!view" class="swiper-zoom-container" >
            <img src="{{image}}">
          </div>
        </ion-slide>
      </ion-slides>

    </div>
  </div>
  <ion-slides *ngIf="!view" style="width: 100%; height: 100%;" [options]="sliderOpts">
    <ion-slide>
      <div  class="swiper-zoom-container" >
        <img src="{{image}}">
      </div>
    </ion-slide>
  </ion-slides>
       
      
</ion-content>
import { Injectable } from '@angular/core';
import { Either, error, ok} from "src/app/services/Either"
import * as Sentry from '@sentry/capacitor';

@Injectable({
  providedIn: 'root'
})
export class FileValidatorService {

  forbiddenExtensions = [
    ".exe",
    ".bat",
    ".sh",
    ".jar",
    ".js",
    ".vbs",
    ".ps1",
    ".ini",
    ".config",
    ".zip",
    ".rar",
    ".tar.gz",
    ".7z",
    ".cab",
    ".sql",
    ".bak",
    ".htaccess",
    ".htpasswd",
    ".scr",
    ".pif",
    ".com",
    ".msi",
    ".dll",
    ".sys",
    ".ini",
    ".docm",
    ".xlsm",
    ".pptm",
    ".rtf",
    ".so",
    ".dylib",
    ".dat",
    ".log",
    ".conf",
    ".php",
    ".py",
    ".rb",
  ];

  constructor() { }

  private base64ToBlob(base64) {
    const binaryData = atob(base64);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      view[i] = binaryData.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: 'video/mp4' }); // Adjust the type as per your video format
  }

  ValidateImage(base64Image: string): Promise<Either<true, Event>>  {
    const imageDiv: HTMLImageElement = document.createElement("img")

    return new Promise((resolve, reject) => {
      imageDiv.onload = () => {
        resolve(ok(true))
      }

      imageDiv.onerror = (e: Event) => {
        resolve(error(e))
        Sentry.captureMessage('FileValidatorService invalid image content');
      }

      imageDiv.src = base64Image
    })
  }

  ValidateVideoFromBase64Data(base64Data: string) : Promise<Either<true, false>> {
    const blob = this.base64ToBlob(base64Data);

    return new Promise((resolve, reject) => {

      const videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(blob);

      // Add event listeners to handle video load and error
      videoElement.addEventListener('loadeddata',  () => {
        resolve(ok(true))
        // You can also perform additional validation checks here if needed.
      });

      videoElement.addEventListener('error', () => {
        resolve(ok(true))
        Sentry.captureMessage('FileValidatorService invalid video content');
      });
    })

  }

  ValidateVideoFromBlob(blob: Blob) : Promise<Either<true, false>> {

    return new Promise((resolve, reject) => {

      const videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(blob);

      // Add event listeners to handle video load and error
      videoElement.addEventListener('loadeddata',  () => {
        resolve(ok(true))
        // You can also perform additional validation checks here if needed.
      });

      videoElement.addEventListener('error', () => {
        resolve(ok(true))
        Sentry.captureMessage('FileValidatorService invalid video content');
      });
    })

  }


  validateAudioFromBlob(blob: Blob): Promise<Either<true, false>> {

    return new Promise((resolve, reject) => {

      const audioElement = new Audio();


      // Add event listeners to handle audio load and error
      audioElement.addEventListener('canplaythrough',  () => {
        console.log('Valid audio');
        resolve(ok(true))
        // You can also perform additional validation checks here if needed.
      });

      audioElement.addEventListener('error', () => {
        console.log('Invalid audio');
        resolve(error(false))
      });


      audioElement.src = URL.createObjectURL(blob);
    })

  }

  hasDuplicateExtension(filename): Either<true, false> {
    // Use a regular expression to match multiple consecutive dots
    const duplicateExtensionRegex = /\.\.+/;
    const a = duplicateExtensionRegex.test(filename);
    if(a) {
      return ok(true)
    } else {
      Sentry.captureMessage('FileValidatorService invalid filename '+ filename);
      return error(false)
    }
  }


  fileNameValidation(fileName): Either<true, false> {
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

    const found = this.forbiddenExtensions.includes(`.${fileExtension.toLowerCase()}`);

    if(found) {
      return error(false)
    } else {
      return ok(true)
    }
  }

}

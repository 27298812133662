import { Injectable } from '@angular/core';
import { localstoreService } from './localstore.service'
import { AES, enc, SHA1 }  from  'crypto-js'
import { momentG } from 'src/plugin/momentG';
import { v4 as uuidv4 } from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class DespachosprStoreService {

// main data
private _list = []
// local storage keyName
private keyName: string; 
 newList = [];

callbacks: {
  [key: string]: {
    funx: Function
    id: string
  }
} = {} 

constructor() {

  this.keyName = (SHA1("DespachosprStoreService")).toString()
  
  
  setTimeout(()=>{ 
    let restore = localstoreService.get(this.keyName, {})
    this._list = restore.list || []
    this.runCallback()
  }, 10)

}

registerCallback({funx, id = uuidv4()}) {

  this.callbacks[id] = { funx, id}

  return {
    delete: ()=> {
      delete this.callbacks[id]
    }
  }
}

runCallback() {
  for (const [key, value] of Object.entries(this.callbacks)) {
    value.funx()
  }
}


get list() {
  return this._list || []
}

get count() { return this._list.length  || 0 }
set count(value: number) {
  this.save()
}

reset(eventsList: any) {
  this._list = eventsList

  this.count = this._list.length
  this.save()

  if(window['all-process-gabinete']) {
    window['all-process-gabinete']()
  }
}

private save() {
  setTimeout(()=>{ 
    localstoreService.set(this.keyName,{
      list: this._list
    })
  }, 10)

  this.runCallback()
  this.updateNewCount()
}

updateNewCount() {
  this.newList = this._list.filter((e) =>{
    return this.lessthen24Hours(e.TaskReceiveDate)
  })
}


lessthen24Hours(isoDateString:string) {

  if(!isoDateString) {
    return false
  }
  
  const creationDate = new Date(isoDateString)
  return momentG(new Date(), 'dd MMMM yyyy') == momentG(new Date(creationDate), 'dd MMMM yyyy')
}

}

export const DespachosprStore = new DespachosprStoreService()

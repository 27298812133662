import { MessageEntity, IMessage } from "../../../../core/chat/entity/message";
import { MessageOutPutDataDTO } from "src/app/core/chat/repository/dto/messageOutputDTO";
import { MessageInputDTO } from "../use-case/message/message-create-use-case.service";

export class MessageMapper {
  static toDomain(DTO: MessageOutPutDataDTO) : MessageEntity {
    return DTO as MessageEntity
  }

  static fromDomain(entity:IMessage, requestId): MessageInputDTO {
    return {
      receiverId: entity.receiverId,
      canEdit: entity.canEdit,
      message: entity.message,
      messageType: entity.messageType,
      oneShot: entity.oneShot,
      requireUnlock: entity.requireUnlock,
      roomId: entity.roomId,
      senderId: entity.sender.wxUserId,
      requestId: entity.requestId || requestId,
      attachment: entity.attachments.map((e)=>({
        fileType:e.fileType,
        source: e.source,
        file: e.file,
        fileName: e.fileName,
        applicationId: e.applicationId || 0,
        docId: Number(e.docId) || 0,
        mimeType: e.mimeType,
        description: e.description
      }))[0] || {}

    }
  }
}

import { EventToApproveDetails } from "src/app/models/entiry/agenda/eventToApproveDetails"
import { EEventCategory, EEventOwnerType, EEventStatus, EAttendeeType } from "../../data/dto/enums";
import { EventOutputDTO } from "../../data/dto/eventDTOOutput";

function getTextInsideParentheses(inputString) {
  var startIndex = inputString.indexOf('(');
  var endIndex = inputString.indexOf(')');
  if (startIndex !== -1 && endIndex !== -1) {
      return inputString.substring(startIndex + 1, endIndex);
  } else {
      return null;
  }
}


export class EventToApproveDetailsMapper {

  constructor() {}
  static toDomain(_dto: EventOutputDTO): EventToApproveDetails {

    let dto = _dto.data

    let category;
    if(dto.category == EEventCategory.Oficial) {
      category = 'Oficial'
    } else {
      category = 'Pessoal'
    }

    let color;
    if(dto.ownerType == EEventOwnerType.PR) {
      color = 'PR'
    } else {
      color = 'MDGPR'
    }

    // if(dto.ownerType != 'PR') {
    let ownerType;
    if(dto.ownerType == EEventOwnerType.PR) {
      ownerType = 'PR'
    } else if(dto.ownerType == EEventOwnerType.MD) {
      ownerType = 'MD'
    } else if(dto.ownerType == EEventOwnerType.Others) {
      ownerType = 'Other'
    }

    let activityInstanceName;
    let taskStatus;

    if(dto.status == EEventStatus.Pending) {
      activityInstanceName = 'Aprovar evento'
      taskStatus = 'Pending' //////
    } else if(dto.status == EEventStatus.Revision) {
      activityInstanceName = 'Editar evento'
      taskStatus = 'Revision' //////
    } else if (dto.status == EEventStatus.Approved) {
      activityInstanceName = 'Evento Aprovado'
      taskStatus = 'Approved' //////
    } else if (dto.status == EEventStatus.Declined) {
      activityInstanceName = 'Declined'
      taskStatus = 'Declined' //////
    } else if (dto.status == EEventStatus.Communicated) {
      activityInstanceName = 'Communicated'
      taskStatus = 'Communicated' //////
    } else if (dto.status == EEventStatus.ToCommunicate) {
      activityInstanceName = 'ToCommunicate'
      taskStatus = 'ToCommunicate' //////
    }

    function FEAttendeeType(num) {
      if(num == EAttendeeType.Required) {
        return 'Required'
      } else if (num == EAttendeeType.Acknowledgment) {
        return 'Acknowledgment'
      } else if (num == EAttendeeType.Optional) {
        return 'Optional'
      }
    }



    let status;
    if(dto.status == EEventStatus.Pending) {
      status = 'Pending'
    } else if(dto.status == EEventStatus.Revision) {
      status = 'Revision'
    }

    return {
      "owner": dto.owner,
      "serialNumber": dto.id,
      "taskStatus": taskStatus,
      "originator": {
        "email": dto.organizer.wxeMail,
        "manager": "",
        "displayName": "",
        // "fqn": "K2:gabinetedigital\\dlima",
        // "username": "gabinetedigital\\dlima"
      },
      // "isDelegated": false,
      // "isExtended": false,
      // "hasTakenAcknowledge": false,
      // "actions": [
      //   "Reenviar",
      //   "Descartar"
      // ],
      "activityInstanceName": activityInstanceName,
      // "workflowInstanceFolio": "MTk4",
      // "taskStartDate": "2024-04-10T14:30:39.58",
      // "deadline": null,
      // "workflowID": 24744,
      // "workflowInstanceID": 47026,
      //"workflowName": "Agenda\\Agenda Oficial MDGPR",
      "workflowDisplayName": `Agenda ${category} ${color}`,
      //"formURL": "http://bpmdev.gabinetedigital.local/Runtime/Runtime/Form/Exchange+Calendar+Meeting+Edition/?SerialNo=47026_169&ProcessInstanceID=&InstanceId=",
      "workflowInstanceDataFields": {
        "Body": dto.body,
        "Location": dto.location,
        "Subject": dto.subject,
        "StartDate": dto.startDate,
        "EndDate": dto.endDate,
        // "Participants": "evilarinho@gabinetedigital.local",
        // "CC": "",
        // "ReviewUserComment": "{\r\n  \"option\": \"save\",\r\n  \"note\": \"\"\r\n}",
        // "Role": 100000011,
        // "MDName": "Eduardo Vilarinho",
        // "MDEmail": "evilarinho@gabinetedigital.local",
        // "OriginatorComments": "",
        "Status": status,
        "TimeZone": getTextInsideParentheses(new Date(dto.startDate)+ ''),
        "Agenda": category ,
        "EventType": dto.type,
        //"EventID": "",
        //"HasAttachments": true,
        "ParticipantsList": [
          ...dto.attendees.map( e => ({
            Id: e.id,
            Name: e.name,
            EmailAddress: e.emailAddress,
            IsRequired: FEAttendeeType(e.attendeeType) == 'Required',
            UserType: "GD",
            wxUserId: e.wxUserId,
            attendeeType: FEAttendeeType(e.attendeeType)
          }))
        ],
        //"EventOrganizer": "{\"$type\":\"GabineteDigital.k2RESTidentifier_EventPerson, GabineteDigital, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null\",\"EmailAddress\":\"agenda.mdgpr@gabinetedigital.local\",\"Name\":\"Agenda do Ministro e Director do Gabinete do PR\",\"IsRequired\":true}",
        "CreateEvent": "",
        "IsAllDayEvent": false,
        //"SerializedItem": "",
        //"MDwxUserID": 198,
        //"DeserializedItem": "",
        //"Message": "Foi adicionado para a sua agenda um evento com o assunto: Event to approve MD, para a vossa aprovação",
        //"InstanceId": "AGD_dlima_2024-04-10_14:30:39.453",
        //"For Each Result": "",
        //"For Each Index": 0,
        //"Header": "",
        //"RecurringString": "",
        //"LastOccurrence": "",
        //"OccurrenceType": "-1",
        //"SerialNumber": "",
        //"For Each Result 1": "false",
        //"For Each Index 1": 0,
        //"UserEmail": "",
        //"LastActivInstanceID": "78",
        "IsRecurring": false,
        //"CalendarId": "AQMkAGVhZWZkOGM0LWNiZjMtNGE4Ny05NTY4LWZjMTEANTE2MWU4YmUALgAAAwkDRuPtBKJItLDcu6efhYABACLK19NpGvtHh8oQYZPTW2sAAAIBSgAAAA==",
        //"wxUserID": 312
      },
      "totalDocuments": null,
      "Documents": null,
      "PrivateMessage": null,
      Attachments: dto.attachments.map( e => ({
        id: e.id,
        DocId: e.docId,
        Description: e.sourceName,
        Stakeholders: '',
        CreateDate: '',
        ApplicationId: e.applicationId,
      })),
      eventRecurrence: {
        frequency: dto?.eventRecurrence?.frequency,
        until: dto?.eventRecurrence?.until
      }
    }
  }

  static toDTO(data: any): any {
    return {}
  }

}

import { Injectable } from '@angular/core';
import { from } from "rxjs";
import { DexieRepository } from "src/app/infra/repository/dexie/dexie-repository.service";
import { Dexie, EntityTable, liveQuery, Observable } from 'Dexie';
import { BoldTable, BoldTableSchema } from "src/app/infra/database/dexie/instance/chat/schema/bold";
import { chatDatabase } from "src/app/infra/database/dexie/service";
import { IBoldLocalRepository } from "src/app/core/chat/repository/bold/bold-local-repository";

@Injectable({
  providedIn: 'root'
})
export class  BoldLocalRepository extends DexieRepository<BoldTable, BoldTable> implements IBoldLocalRepository  {
  constructor() {
    super(chatDatabase.bold, BoldTableSchema)
  }

  open() {
    return  chatDatabase.open()
  }

  listen() {
    return from(liveQuery(() => chatDatabase.bold.toArray()))
  }
}

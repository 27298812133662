import { Injectable } from '@angular/core';
import { AES, enc, SHA1 }  from  'crypto-js'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalstoreService {

  private prefix = environment.version.lastCommitNumber + environment.id+ Number(environment.storageProduction)+"-";
  private previewPrefix = 'v17-';

  callbacks:  {[key: string]:  {
    path: string,
    funx: Function,
    id: string
  }} = {}

  constructor() {

    const key =  SHA1('version').toString()
    this.set(key, this.prefix)
  }

  getKey(keyName:string) {
    return this.prefix + keyName
  }
  
  keyExist(keyName) {
    keyName = this.getKey(keyName)
    return !localStorage.getItem(keyName) === null
  }

  get( keyName:string, safe) {

    keyName = this.getKey(keyName)

    const ciphertext = localStorage.getItem(keyName)

    const hashKey = SHA1(keyName).toString()

    if(ciphertext) {
      const bytes   = AES.decrypt(ciphertext, hashKey)
      var decryptedData = bytes.toString(enc.Utf8);
      try {
        return JSON.parse(decryptedData)
      } catch {
        return decryptedData;
      }

    } else {
      return safe
    }
  }

  set(keyName:string, value) {

    keyName = this.getKey(keyName)

    if(typeof(value) != 'string') {
      value = JSON.stringify(value)
    }

    const hashKey = SHA1(keyName).toString()

    const data = value
    const encoded = AES.encrypt( data, hashKey).toString();
    localStorage.setItem(keyName, encoded)
  }

  delete(keyName:string) {

    keyName = this.getKey(keyName)
    localStorage.removeItem(keyName)
  }

  private async change(changeType: 'set' | 'delete') {
    const currentPath = window.location.pathname

    for (const [key, value] of Object.entries(this.callbacks)) {

      if(currentPath.startsWith(value.path)) {}
      const dontRepeat = await value.funx({event:{type: changeType}})
      if(dontRepeat) {
        delete this.callbacks[key]
      }
    }

  }

  listener() {}

}

export const localstoreService = new LocalstoreService()
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventoApprove'
})
export class EventoApprovePipe implements PipeTransform {

  transform(fullTask, obj): any {
    let date = new Date(fullTask.taskStartDate);
    let month = date.getMonth() + 1;
          let taskDate = date.getFullYear() + "-" + month +"-"+date.getDate()+" "+date.getHours()+":"+date.getMinutes()+ ":"+date.getSeconds();

    return Object.assign({
        "SerialNumber": fullTask.serialNumber,
        "Folio": fullTask.workflowInstanceDataFields.Subject,
        "Event": {
          StartDate: '',
          EndDate: '',
          Agenda: '',
          Location: ''
        },
        "CreateDate": taskDate,
        "TaskStartDate": fullTask.taskStartDate,
        "DocumentURL": fullTask.workflowInstanceDataFields.ViewerRequest,
        "Remetente": fullTask.workflowInstanceDataFields.Remetente,
        "DocumentsQty": fullTask.totalDocuments,
        "DocId": fullTask.workflowInstanceDataFields.DispatchDocId,
        "FolderID": fullTask.workflowInstanceDataFields.FolderID,
        "WorkflowName": fullTask.workflowDisplayName,
        "activityInstanceName": fullTask.activityInstanceName,
        "Status": fullTask.workflowInstanceDataFields.Status,
        "Deadline": fullTask.deadline,
        "TaskStatus": fullTask.taskStatus,
        "TaskReceiveDate": fullTask.taskReceiveDate
      },
      obj
    )
  }

}

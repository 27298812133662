export enum EEventFilterStatus {
  All = -1,
  Pending = 1,
  Revision,
  Approved,
  Declined,
  Communicated,
  ToCommunicate,
  AllToCommunicate, // approvado e to communicate
  PendingEvents,
}


// Define your TypeScript enum
export enum EEventStatus {
  Pending = 1,
  Revision,
  Approved,
  Declined,
  Communicated,
  ToCommunicate,
}


export enum EEventCategory
{
  Oficial = 1,
  Pessoal
}

export enum EEventOwnerType {
  PR = 1,
  MD,
  Others
}


export enum EEventType
{
  Meeting  = 1,
  Travel,
  Conference,
}


export  enum EAttendeeType {
  Required = 1,
  Acknowledgment,
  Optional
}

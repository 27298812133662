import { err, ok, Result } from "neverthrow";
import { defer, from } from "rxjs";
import { catchError, timeout } from "rxjs/operators";

/**
 * Retrieves a `FileReader` instance, accounting for potential Zone.js modifications.
 * @returns {FileReader} The original `FileReader` instance or a modified one if applicable.
 */
function getFileReader(): FileReader {
  const fileReader = new FileReader();
  const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
  return zoneOriginalInstance || fileReader;
}

/**
 * Converts a `File` object to a data url encoded string.
 * @param {File} file - The file to be converted.
 * @returns {Promise<Result<string, any>>} A promise that resolves with a `Result` object containing either the Base64 encoded string or an error.
 */
export function JSFileToDataUrl(file: File): Promise<Result<string, any>> {
  return new Promise((resolve, reject) => {
    var reader = getFileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(ok(reader.result as string));
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      resolve(err(error));
    };
  });
}



/**
 * Creates a Data URL from a base64-encoded string and MIME type.
 * 
 * @param base64String - The base64-encoded data as a string. This should not include the `data:[mime-type];base64,` prefix.
 * @param mimeType - The MIME type of the data (e.g., `image/png`, `application/pdf`).
 * 
 * @returns A Data URL formatted string.
 * 
 * @example
 * ```typescript
 * const base64String = 'iVBORw0KGgoAAAANSUhEUgAAAAU...'; // Your base64 string
 * const mimeType = 'image/png'; // Your MIME type
 * 
 * const dataURL = createDataURL(base64String, mimeType);
 * console.log(dataURL);
 * ```
 */
export function createDataURL(base64String: string, mimeType: string): string {
  // Make sure the base64 string doesn't have the data URL scheme or extra padding
  const cleanedBase64String = base64String.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');
  return `data:${mimeType};base64,${cleanedBase64String}`;
}



export function createBlobFromBase64(base64String: string, mimeType: string): Blob {
  // Make sure the base64 string doesn't have the data URL scheme or extra padding
  const cleanedBase64String = base64String.replace(/^data:[a-z]+\/[a-z]+;base64,/, '');

  // Decode the base64 string
  const binaryString = atob(cleanedBase64String);

  // Convert binary string to Uint8Array
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Create and return the Blob object
  return new Blob([bytes], { type: mimeType });
}









/**
 * Converts a `Blob` to a Data URL.
 * @param {Blob} blob - The `Blob` to be converted.
 * @returns {Promise<string>} A promise that resolves with the Data URL representation of the `Blob`.
 * @example const result = 'data:audio/aac;base64,ZGF0YTphdWRpby9hYWM7YmFzZTY0…RnNRQmxmL0FGQUl'
 */
export function convertBlobToDataURL(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = getFileReader();

    reader.onloadend = () => {
      // Resolve the promise with the Data URL
      resolve(reader.result as string);
    };

    reader.onerror = () => {
      // Reject the promise on error
      reject(new Error('Failed to convert Blob to Data URL'));
    };

    // Read the Blob as a Data URL
    reader.readAsDataURL(blob);
  });
}

// export function convertBlobToDataURLWithTimeoutNoThrow(blob: Blob): Promise<Result<string, any>> {
//   const convert = () => new Promise<Result<string, any>>((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onloadend = () => resolve(ok(reader.result as string));
//     reader.onerror = () => resolve(err(new Error('Failed to convert Blob to Data URL')));
//     reader.readAsDataURL(blob);
//   });

//   return defer(() => from(convert()))
//     .pipe(
//       timeout(15000),
//       catchError(error => {
//         console.error('Warning: Operation took too long, but continuing:', error);
//         return from(convert()); // Continue with the original operation
//       })
//     )
//     .toPromise();
// }

// export function convertBlobToDataURLWithTimeout(blob: Blob): Promise<Result<string, any>> {
//   return from(new Promise<Result<string, any>>((resolve, reject) => {
//     const reader = getFileReader();

//     reader.onloadend = () => resolve(ok(reader.result as string));
//     reader.onerror = () => resolve(err(new Error('Failed to convert Blob to Data URL')));
//     reader.readAsDataURL(blob);
//   }))
//   .pipe(
//     timeout(15000),
//     catchError(error => {
//       console.error('Error: Operation took too long or failed:', error);
//       return Promise.resolve(err('timeout'));
//     })
//   )
//   .toPromise();
// }


/**
 * Converts a `Blob` to a Data URL.
 * @param {Blob} blob - The `Blob` to be converted.
 * @returns {Promise<string>} A promise that resolves with the Data URL representation of the `Blob`.
 * @example result.value = 'data:audio/aac;base64,ZGF0YTphdWRpby9hYWM7YmFzZTY0…RnNRQmxmL0FGQUl'
 */
export function createBlobUrl(blob: Blob): Result<string, any> {
  try {
    return ok(URL.createObjectURL(blob));
  } catch (error) {
    return err('error '+ error);
  }
  
}
<ion-content class="container-wrapper main-content-l height-100 white  ">
  <ion-refresher  name="refresher" slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-progress-bar type="indeterminate" *ngIf="showLoader"></ion-progress-bar>
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>

  <div class="main-content d-flex height-100" [className]="isModal ? '_main-content d-flex height-100 ma-0 px-20 pt-30 pb-20 background-white' : 'main-content d-flex height-100'">
    <div class="content d-flex flex-column width-100">
      <div class="main-header  d-flex">
        <div class="title-content  d-flex justify-between width-100 mb-10">
          <div class="left d-flex">
            <button class="btn-no-color d-flex align-center" (click)="goBack()">
              <ion-icon *ngIf="ThemeService.currentTheme == 'default' " src="assets/images/theme/blue/icons-calendar-arrow-left.svg"></ion-icon>
              <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " slot="end" src='assets/images/theme/gov/icons-calendar-arrow-left.svg'></ion-icon>
              <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " slot="end" src='assets/images/theme/{{ThemeService.currentTheme}}/icons-calendar-arrow-left.svg'></ion-icon>
            </button>
          </div>
          <div class="middle d-flex align-center">
            <p class="title" style="font-size: 21.1px"><span>{{loadedEvent.Subject}}</span></p>
            <!-- Event details page -->
          </div>
          <div class="menu-options d-flex">
            <button class="btn-no-color" (click)="editEvent()" *ngIf="p.userPermission([p.permissionList.Agenda.creatEvent])">
              <ion-icon *ngIf="ThemeService.currentTheme == 'default' " class="edit" slot="end" src="assets/images/icons-edit.svg" ></ion-icon>
              <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " class="edit" slot="end" src="assets/images/theme/gov/icons-edit.svg" ></ion-icon>
            </button>
            <button class="btn-no-color d-flex" (click)="deleteYesOrNo()">
              <ion-icon class="delete"  name="trash-sharp"></ion-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="overflow-y-auto">
        <div class="upper-content ml-45">

          <div class="content-location">
            <span class="date">{{loadedEvent.Location}}</span>
            <div *ngIf="loadedEvent.Organizer" class="font-15-rem">

              <div *ngIf="eventsService.isMyEvent(loadedEvent) == false || sesseionStora.user.Profile =='PR'">
                <span class="label" *ngIf="loadedEvent.CalendarName == 'Oficial' " style="background-color: #99e47b;">{{loadedEvent.CalendarName}}</span>
                <span class="label" *ngIf="loadedEvent.CalendarName == 'Pessoal' " style="background-color: #958bfc;">{{loadedEvent.CalendarName}}</span>
              </div>

              <div *ngIf="eventsService.isMyEvent(loadedEvent) == true && sesseionStora.user.Profile !='PR'">
                <span class="label" *ngIf="loadedEvent.CalendarName == 'Oficial' "  style="background-color: #ffb703;">{{loadedEvent.CalendarName}}</span>
                <span class="label" *ngIf="loadedEvent.CalendarName == 'Pessoal' " style="background-color: #f05d5e;">{{loadedEvent.CalendarName}}</span>
              </div>
            </div>

          </div>

          <div class="content-details">
            <ion-label>
              <p>{{customDate}}</p>
              <p>{{TimeZoneString}}</p>
              <p *ngIf="toDateString(loadedEvent.StartDate) == toDateString(loadedEvent.EndDate)">das {{loadedEvent.StartDate | date: 'HH:mm'}} às {{loadedEvent.EndDate | date: 'HH:mm'}}</p>
              <p *ngIf="toDateString(loadedEvent.StartDate) != toDateString(loadedEvent.EndDate)">{{loadedEvent.StartDate | date: 'd/M/yy' }} - {{ loadedEvent.EndDate | date: 'dd/M/yy'}} </p>
              <p *ngIf="loadedEvent.EventRecurrence">
                <span *ngIf="stringToNumber(loadedEvent.EventRecurrence.frequency) == 1">Diário</span>
                <span *ngIf="stringToNumber(loadedEvent.EventRecurrence.frequency) == 2">Semanal</span>
                <span *ngIf="stringToNumber(loadedEvent.EventRecurrence.frequency) == 3">Mensal</span>
                <span *ngIf="stringToNumber(loadedEvent.EventRecurrence.frequency) == 4">Anual</span>
                <span *ngIf="!loadedEvent.IsRecurring">(Não se repete)</span>
              </p>
            </ion-label>
          </div>
        </div>
        <div class="line"></div>
        <div class="middle-content">
          <div *ngIf="loadedEvent.Attendees">
            <h5 class="font-17-rem">Intervenientes({{ loadedEvent.Attendees.length }})</h5>
            <ion-item class="ion-no-margin ion-no-padding">
              <ion-label>
                <div *ngFor="let attendee of loadedEvent.Attendees">
                  <p>{{attendee.Name}}</p>
                </div>
              </ion-label>
            </ion-item>
          </div>
          <div *ngIf="loadedEvent.Body.Text">
            <h5 class="font-17-rem">Detalhes</h5>
            <ion-item lines="none" class="ion-no-margin ion-no-padding">
              <pre class="width-100 text">{{ loadedEvent.Body.Text }} </pre>
            </ion-item>
            <div class="line"></div>
          </div>
        </div>

        <div *ngIf="loadedEvent.Attachments" class="bottom-content width-100">
          <h5 class="font-17-rem">Documentos Anexados({{ loadedEvent.Attachments.length }})</h5>
          <ion-list class="width-100">
            <ion-item *ngFor="let attach of loadedEvent.Attachments; let i = index" class="width-100" class="ion-no-margin ion-no-padding">
              <ion-label class="width-100 d-flex "  (click)="docIndex(i);LoadDocumentDetails()">
                <p class="flex-grow-1" >
                  <span class="attach-title-item d-block">{{attach.SourceName || attach.subject || attach.sourceName  || 'Sem título'}}</span>

                  <span class="span-left d-block">{{attach.Stakeholders}}</span>
                </p>

                <div class="d-flex pr-10">
                  <span class="span-right">{{ attach.CreateDate | date: 'dd-MM-yyyy HH:mm' }}</span>
                </div>

                <!-- <div (click)="docIndex(i);LoadDocumentDetails()" class="cursor-pointer" style="width: 35px; height: 41px;" autoHide="false">
                  <ion-icon *ngIf="ThemeService.currentTheme == 'default' " src="assets/images/icons-menu.svg"  ></ion-icon>
                  <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " src="assets/images/theme/gov/icons-menu.svg"  ></ion-icon>
                </div> -->

              </ion-label>
            </ion-item>
          </ion-list>
        </div>

      </div>

    </div>
    <div class="aside-right flex-column height-100">
      <div class="buttons">
        <button (click)="editEvent()" full class="btn-ok" shape="round" >Editar</button>
        <div class="solid"></div>
        <button (click)="deleteYesOrNo()" full class="btn-delete" shape="round" >Eliminar</button>
      </div>
    </div>

  </div>
</ion-content>

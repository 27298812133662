import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DeplomaOptionsPageRoutingModule } from './deploma-options-routing.module';

import { DiplomaOptionsPage } from './deploma-options.page';
import { CreateProcessPage } from 'src/app/modals/create-process/create-process.page';
import { CreateProcessPageModule } from 'src/app/modals/create-process/create-process.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DeplomaOptionsPageRoutingModule,
    CreateProcessPageModule
  ],
  declarations: [
    DiplomaOptionsPage,
  ],
  exports: [DiplomaOptionsPage],
  entryComponents: [
    DiplomaOptionsPage,
    CreateProcessPage
  ]
})
export class DeplomaOptionsPageModule {}

import { Injectable } from '@angular/core';
// import { ChatSystemService } from '../chat/chat-system.service';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  countDownTime: any;
  room: any;

  constructor(
    //private chatService: ChatService,
    //public ChatSystemService: ChatSystemService,
    ) { }

  showDateDuration(start:any) {
    let end;
    end = new Date();
    start = new Date(start);

    const totalSeconds     = Math.floor((end - (start))/1000);
    const totalMinutes     = Math.floor(totalSeconds/60);
    const totalHours       = Math.floor(totalMinutes/60);
    const totalDays        = Math.floor(totalHours/24);

    const hours   = totalHours - ( totalDays * 24 );
    const minutes = totalMinutes - ( totalDays * 24 * 60 ) - ( hours * 60 );
    const seconds = totalSeconds - ( totalDays * 24 * 60 * 60 ) - ( hours * 60 * 60 ) - ( minutes * 60 );

    if(totalDays == 0) {
      if(start.getDate() == new Date().getDate()) {
        let time = start.getHours() + ":" + this.addZero(start.getUTCMinutes());
        return time;
      }
      else {
        return 'Ontem';
      }
    }
    else {
      let date = this.addZero(start.getDate()) + "/" + this.addZero(start.getMonth()+1) + "/" + start.getFullYear();
      return date;
    }
  }

  countDownDate(date:any, roomId:string){

    let difference = new Date(date).getTime() - new Date().getTime();
    let c_day = Math.floor(difference/(1000*60*60*24));
    let c_hours = Math.floor((difference % (1000*60*60*24)) / (1000*60*60));
    let c_minutes = Math.floor((difference % (1000*60*60)) / (1000*60));
    let c_seconds = Math.floor((difference % (1000*60)) / 1000);

    this.countDownTime = this.addZero(c_day) + " : " + this.addZero(c_hours) + " : " + this.addZero(c_minutes) + " : " + this.addZero(c_seconds) ;

    if(difference < 0){

      this.countDownTime = "Expired";
      let body = { "roomId":roomId, }
      // this.chatService.getRoomInfo(roomId).subscribe(room=>{
      //   this.room = room['room'];

      //   if(this.room.t === 'p'){
      //     this.chatService.deleteGroup(body).subscribe(res=>{

      //     });
      //   }
      //   else{
      //     this.chatService.deleteChannel(body).subscribe(res=>{

      //     });
      //   }
      // });
    }

    return this.countDownTime;
  }

  countDownDateTimer(date:any, roomId:string){
    // let timer = setInterval(() =>{
    //   let difference = new Date(date).getTime() - new Date().getTime();
    //   let c_day = Math.floor(difference/(1000*60*60*24));
    //   let c_hours = Math.floor((difference % (1000*60*60*24)) / (1000*60*60));
    //   let c_minutes = Math.floor((difference % (1000*60*60)) / (1000*60));
    //   let c_seconds = Math.floor((difference % (1000*60)) / 1000);

    //   this.countDownTime = this.addZero(c_day) + " : " + this.addZero(c_hours) + " : " + this.addZero(c_minutes) + " : " + this.addZero(c_seconds) ;

    //   if(difference < 0) {
    //     clearInterval(timer);
    //     this.countDownTime = "Expired";
    //     let body = { "roomId":roomId, }
    //     this.chatService.getRoomInfo(roomId).subscribe(room=>{
    //       this.room = room['room'];

    //       if(this.room.t === 'p'){
    //         this.chatService.deleteGroup(body).subscribe(res=>{
    //           this.ChatSystemService.deleteRoom(roomId)
    //         });
    //       }
    //       else{

    //         this.chatService.deleteChannel(body).subscribe(res=>{
    //           this.ChatSystemService.deleteRoom(roomId)
    //         });
    //       }
    //     });
    //   }
    // })
    // return this.countDownTime;
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

}

import { HubConnection } from "@microsoft/signalr";
import { Result } from "neverthrow";
import { Observable } from "rxjs";
import { ListenToDeleteRoomInput, RoomSocketOutPutDTO } from "src/app/module/chat/data/repository/room/room-socket-repository.service";
import { CreateRoomInputDTO } from "src/app/module/chat/domain/use-case/room/room-create-use-case.service";

export abstract class IRoomSocketRepository {


  abstract CreateGroup(data: CreateRoomInputDTO): Promise<Result<RoomSocketOutPutDTO,any>>

  abstract listenToCreateRoom(): Observable<any>
  abstract listenToDeleteRoom(): Observable<ListenToDeleteRoomInput>


}

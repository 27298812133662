import { RoleIdService } from "src/app/services/role-id.service";
import { ContactCombinedOutputDTO } from "../DTO/contactsCombined";
import { UserList } from "src/app/models/entiry/agenda/contact";


const roles = new RoleIdService()

export class ListEventMapper {
  // @XTracer({name:'ListEventMapper/toDomain', log: false, bugPrint: false})
  static toDomain(dto: ContactCombinedOutputDTO): UserList {

    return dto.data.map((e) => {

      return {
        Id: e.wxUserId,
        EmailAddress: e.email,
        Name: e.fullName,
        UserType: e.userType,
        Entity: e.entity,
        IsPR: e.roleId == roles.PRES,
        RoleId: e.roleId,
        IsRequired: false
      }
    })
  }

  static toDTO() {}
}

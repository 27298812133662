import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { IMessageSocketRepository } from 'src/app/core/chat/repository/message/message-socket-repository';
import { MessageEntity } from 'src/app/core/chat/entity/message';
import { IBoldLocalRepository } from 'src/app/core/chat/repository/bold/bold-local-repository';
import { IMessageLocalRepository } from 'src/app/core/chat/repository/message/message-local-repository';
import { InstanceId } from '../../chat-service.service';
import { HttpAdapter } from 'src/app/infra/http/adapter';
import { SessionStore } from 'src/app/store/session.service';
import { TracingType, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';


@Injectable({
  providedIn: 'root'
})
export class RoomBoldSyncUseCaseService {

  constructor(
    private MessageSocketRepositoryService: IMessageSocketRepository,
    private boldLocalRepository: IBoldLocalRepository,
    private http: HttpAdapter,
    private messageLocalRepository: IMessageLocalRepository,
    private roomLocalDataSourceService: IRoomLocalRepository,
  ) {
    this.listenToIncomingMessage();
    // this.loadHistory()
    this.onInsertToDB()
    this.listenToUpdateMessages();
  }

  private listenToIncomingMessage() {
    return this.MessageSocketRepositoryService.listenToMessages().pipe(
      filter((message) => !message?.requestId?.startsWith(InstanceId)),
      map(message => Object.assign(new MessageEntity(), message)),
      filter((message) => !message.meSender())
    ).subscribe(async (message) => {

      const result = await this.boldLocalRepository.findOne({roomId: message.roomId})

      if(result.isOk() && !result.value) {
        const result = await this.boldLocalRepository.insert({roomId: message.roomId, bold: 1})
      } else if(result.isOk() && result.value.bold == 0) {
        const result = await this.boldLocalRepository.update(message.roomId, {bold: 1})
      }

    });
  }

  private listenToUpdateMessages() {
    return this.MessageSocketRepositoryService.listenToUpdateMessages().pipe(
      filter((message) => !message?.requestId?.startsWith(InstanceId)),
      map(message => Object.assign(new MessageEntity(), message))
    ).subscribe(async (message) => {

      const haveSeen  = MessageEntity.haveSeen(message.info)

      if(haveSeen) {

        const result = await this.boldLocalRepository.findOne({roomId: message.roomId})

        if(result.isOk() && result.value?.bold == 1) {
          const lastMessage = await this.roomLocalDataSourceService.findOne({id: message.roomId})
          if(lastMessage.isOk()) {
            if(lastMessage.value.messages[0].id == message.id) {
              const result = await this.boldLocalRepository.update(message.roomId, {bold: 0})
            }
          }

        }
      }

    });
  }


  @XTracerAsync({name:'RoomBoldSyncUseCaseService/onInsertToDB', module:'chat',  bugPrint: true})
  private onInsertToDB(tracing?: TracingType) {

    let delay = 2000
    this.messageLocalRepository.onCreateObservable().pipe(
      filter(e => e?.origin == 'history'),
      filter(e => e.sender.wxUserId != SessionStore.user.UserId),
    ).subscribe(async (newMessage)=> {

      setTimeout(async ()=> {
        const haveSeen  = MessageEntity.haveSeen(newMessage.info)
        if(!haveSeen) {
          await this.boldLocalRepository.open()
          const result = await this.boldLocalRepository.findOne({roomId: newMessage.roomId})

          if(result.isOk() && !result.value?.bold) {
            const result = await this.boldLocalRepository.insert({roomId: newMessage.roomId, bold: 1})
          } else if(result.isOk() && result.value.bold == 0) {
            const result = await this.boldLocalRepository.update(newMessage.roomId, {bold: 1})
          } else {
            // tracing.hasError("failed to set bold",{})
          }
        }

      }, delay);
      delay = 0
    })
  }
}

import { Injectable } from '@angular/core';
import { captureAndReraiseAsync } from 'src/app/services/decorators/captureAndReraiseAsync';
import { z } from "zod";
import { DataSourceReturn } from 'src/app/services/Repositorys/type';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { IRoomRemoteRepository } from 'src/app/core/chat/repository/room/room-remote-repository';

export const RoomUpdateInputDTOSchema = z.object({
  roomName: z.string(),
  roomId: z.string(),
  roomType: z.number(),
});
export type RoomUpdateInputDTO = z.infer<typeof RoomUpdateInputDTOSchema>



const UserSchema = z.object({
  wxUserId: z.number(),
  wxFullName: z.string(),
  wxeMail: z.string(),
  userPhoto: z.string().nullable(),
});
const MemberSchema = z.object({
  id: z.string(),
  user: UserSchema,
  joinAt: z.string(),
});

export const RoomUpdateOutputDTOSchema = z.object({
  success: z.boolean(),
  message: z.string(),
  data: z.object({
    id: z.string(),
    roomName: z.string(),
    createdBy: UserSchema,
    createdAt: z.string(),
    expirationDate: z.string().nullable(),
    roomType: z.number(),
    members: z.array(MemberSchema),
  }),
});
export type RoomUpdateOutputDTO = z.infer<typeof RoomUpdateOutputDTOSchema>


@Injectable({
  providedIn: 'root'
})
export class UpdateRoomByIdUseCaseService {

  constructor(
    private roomRemoteDataSourceService: IRoomRemoteRepository,
    private roomLocalDataSourceService: IRoomLocalRepository,
  ) { }


  @captureAndReraiseAsync('RoomRepositoryService/updateRoomBy')
  async execute(data: RoomUpdateInputDTO): Promise<DataSourceReturn<RoomUpdateOutputDTO>> {

    const result = await this.roomRemoteDataSourceService.updateRoom(data)

    if(result.isOk()) {
      const localList = await this.roomLocalDataSourceService.findAll()
      // const { roomsToDelete, roomsToInsert, roomsToUpdate } = roomListDetermineChanges([result.value.data], localList)

      // for( const roomData of  roomsToUpdate) {
      //   if(!roomData.chatRoom.createdBy?.wxUserId) {
      //     delete roomData.chatRoom.createdBy;
      //   }

      //   this.roomLocalDataSourceService.updateRoom(roomData.chatRoom)
      // }

    }

    return result
  }
}

import { z } from 'zod';
import { EEventCategory, EEventOwnerType, EEventType } from './enums';

const attendeeSchema = z.object({
  name: z.string(),
  emailAddress: z.string(),
  attendeeType: z.number(),
  wxUserId: z.number(),
  userType: z.enum(['GD','External', 'Internal']),
  entity: z.string()
});

const attachmentSchema = z.object({
  docId: z.number(),
  sourceName: z.string(),
  description: z.string(),
  applicationId: z.number()
});

const recurrenceSchema = z.object({
  frequency: z.number(),
  until: z.string().nullable().optional()
});

export const EventInputDTOSchema = z.object({
  userId: z.number(),
  ownerType: z.nativeEnum(EEventOwnerType),
  subject: z.string(),
  body: z.string(),
  location: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  type: z.nativeEnum(EEventType),
  category: z.nativeEnum(EEventCategory),
  attendees: z.array(attendeeSchema),
  attachments: z.array(attachmentSchema),
  recurrence: recurrenceSchema,
  organizerId: z.number(),
  isAllDayEvent: z.boolean()
});

export type EventInputDTO = z.infer<typeof EventInputDTOSchema>

// calendar.actions.ts
import { createAction, props } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import { EventList, EventListStore } from 'src/app/models/agenda/AgendaEventList';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const loadEvents = createAction('[Calendar] Load Events');
export const loadEventsSuccess = createAction(
  '[Calendar] Load Events Success',
  props<{ events: EventListStore[] }>()
);

export const resetList = createAction(
  '[Calendar] Reset List',
  props<{ eventSource: EventListStore[] }>()
);

export const pushEvent = createAction(
  '[Calendar] Push Event',
  props<{ eventsList: EventList[], profile: 'pr' | 'md', userId: string }>()
);

export const removeRangeForCalendar = createAction(
  '[Calendar] Remove Range For Calendar',
  props<{ startDate: Date, endDate: Date, userId: string }>()
);

export const getRangeForCalendar = createAction(
  '[Calendar] Remove Range For Calendar',
  props<{ startDate: Date, endDate: Date, userId: string }>()
);


export const deleteAllEvents = createAction('[Calendar] Delete All Events');



// =========================================================================


export interface CalendarState {
  eventSource: EventListStore[];
}

export const initialState: CalendarState = {
  eventSource: []
};

export const calendarReducer = createReducer(
  initialState,
  on(loadEventsSuccess, (state, { events }) => ({
    ...state,
    eventSource: events
  })),
  on(resetList, (state, { eventSource }) => ({
    ...state,
    eventSource
  })),
  on(pushEvent, (state, { eventsList, profile, userId }) => {
    let news = eventsList.map(element => ({
      startTime: new Date(element.StartDate),
      endTime: new Date(element.EndDate),
      allDay: false,
      event: element,
      calendarName: element.CalendarName,
      profile: profile,
      id: element.EventId,
      CalendarId: userId
    }));

    let instance = state.eventSource.concat(news as any);
    const ids = instance.map(o => o.id);
    const filtered = instance.filter(({ id }, index) => !ids.includes(id, index + 1));

    return {
      ...state,
      eventSource: filtered
    };
  }),
  on(removeRangeForCalendar, (state, { startDate, endDate, userId }) => ({
    ...state,
    eventSource: state.eventSource.filter(e =>
      !(new Date(e.endTime).getTime() >= new Date(startDate).getTime() &&
      new Date(endDate).getTime() >= new Date(e.startTime).getTime() && e.CalendarId == userId)
    )
  })),
  on(deleteAllEvents, state => ({
    ...state,
    eventSource: []
  }))
);

// =========================================================================
export const selectCalendarState = createFeatureSelector<CalendarState>('calendar');

export const selectEventSource = createSelector(
  selectCalendarState,
  (state: CalendarState) => state.eventSource
);


// Create selector to get range of events
export const selectEventsInRange = (startDate: Date, endDate: Date, userId: string) => createSelector(
  selectEventSource,
  (events) => events.filter(event =>
    new Date(event.startTime).getTime() >= new Date(startDate).getTime() &&
    new Date(event.endTime).getTime() <= new Date(endDate).getTime() &&
    event.CalendarId === userId
  )
);

import { v4 as uuidv4 } from 'uuid'

export class Subscribe {

    function: Function
    callbacks: {} = {}
    execute: boolean
    deleteOnExecute: boolean

    constructor({execute = true, deleteOnExecute = false}) {
        this.execute = execute
        this.deleteOnExecute = deleteOnExecute
    }

    executor() {
        if(!this.execute) {
            this.execute = true
            for (let id in this.callbacks) {
                this.callbacks[id].e()
                if(this.deleteOnExecute) {
                    delete this.callbacks[id]
                }
            }
        } else {
            throw('already ben called')
        }
    }

    subscribe(e) {
        const id = uuidv4();
        
        let shadow

        if(!this.execute) {
            this.callbacks[id] = {e}
            shadow = e
        } else {
            e()
            shadow = () => {}
        }

        this.callbacks[id] = {e}
        return {
            unSubscribe: () => {
                this.callbacks[id] = shadow
            }
        }
    }

}
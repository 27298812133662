<ion-header class="ion-no-border">
  <ion-toolbar class="d-flex">
    <div class="d-flex align-items-center px-20 pt-20 font-25">
      <div class="left cursor-pointer" (click)="close()">
        <fa-icon icon="chevron-left" class="menu-icon font-awesome-1"></fa-icon>
      </div>

      <div class="middle ">
        {{file.title}}
      </div>

      <div *ngIf=" task || Document" class="right cursor-pointer" (click)="openOptions()">
        <fa-icon icon="ellipsis-v" class="menu-icon font-awesome-1"></fa-icon>
      </div>

    </div>
  </ion-toolbar>
</ion-header>

<ion-content class="pt-10 height-100">
  <div class="height-100 container-img">
    <iframe class="iframe" [src]="trustedUrl" height="100%" width="100%" title="Iframe Example"></iframe>
  </div>
</ion-content>

<ion-header class="ion-no-border px-20">
  <ion-toolbar class="px-10">
    <ion-title class="pa-0">Pesquisar contactos</ion-title>
  </ion-toolbar>
  <ion-searchbar search-icon="undefined" class="attendee-search-bar" (ionChange)="onChange($event)" placeholder="Insira email para adicionar destinatário"></ion-searchbar><!-- [(ngModel)]="searchCountryString" -->
  <ion-progress-bar type="indeterminate" *ngIf="showLoader"></ion-progress-bar>
</ion-header>

<ion-content class="height-100 overflow-y-auto">

  <ion-item-group class="d-flex flex-column height-100 ">
    <ion-list lines="none"  class="flex-grow-1 overflow-y-auto height-100" >
      <ion-item-sliding class="px-20">
        <div *ngFor="let attendee of contacts;">
          <ion-item (click)="selectContact(attendee)" class="cursor-pointer" lines="none"  *ngIf="filterSearchList(attendee)" >
            <div class="pr-10">

              <ion-icon *ngIf="ThemeService.currentTheme == 'default' " class="font-35-rem" src="assets/images/icons-default-profile.svg"></ion-icon>
              <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " class="font-35-rem" src="assets/images/theme/gov/icons-profile.svg"></ion-icon>
              <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " class="font-35-rem" src="assets/images/theme/{{ThemeService.currentTheme}}/icons-profile.svg"></ion-icon>

            </div>
            <ion-label>
              <h3>{{ attendee.Name }}</h3>
              <p class="lowercase">{{ attendee.EmailAddress }}</p>
            </ion-label>
            <div>
              <ion-icon *ngIf="ThemeService.currentTheme == 'default' "   class="font-35-rem" src="assets/images/icons-add-25.svg" ></ion-icon>
              <ion-icon *ngIf="ThemeService.currentTheme == 'gov' "    class="font-35-rem" src="assets/images/theme/gov/icons-add-25.svg" ></ion-icon>
              <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' "    class="font-35-rem" src="assets/images/theme/{{ThemeService.currentTheme}}/icons-add-25.svg" ></ion-icon>
            </div>
          </ion-item>
        </div>

      </ion-item-sliding>
    </ion-list>
    <hr/>

    <ion-list lines="none" *ngIf="taskParticipants?.length >= 1 && adding == 'intervenient' " class="flex-grow-1 overflow-y-auto height-100 pb-0" >
      <div  class="px-20 font-15 pt-20" style="border-top:1px solid #ebebeb;font-weight: 500;" *ngIf="taskParticipants.length >= 1 || taskParticipantsCc.length >= 1"  > Destinatário</div>
      <ion-item-sliding class="px-20">
        <ion-item lines="none" *ngFor="let attendee of taskParticipants;" class="d-flex">
          <div class="pr-10">
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' " class="font-35-rem" src="assets/images/icons-default-profile.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " class="font-35-rem" src="assets/images/theme/gov/icons-profile.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " class="font-35-rem" src="assets/images/theme/{{ThemeService.currentTheme}}/icons-profile.svg"></ion-icon>
          </div>
          <ion-label>
            <h3>{{ attendee.Name }}</h3>
            <p class="lowercase">{{ attendee.EmailAddress }}</p>
          </ion-label>
          <div class="cursor-pointer" (click)="remove(attendee)">
            <ion-icon class="font-35-rem" src="assets/images/icons-delete-25.svg"></ion-icon>
          </div>
        </ion-item>
      </ion-item-sliding>
    </ion-list>
    <ion-list lines="none" *ngIf="taskParticipantsCc?.length >= 1 && adding == 'CC' " class="flex-grow-1 overflow-y-auto height-100 pb-0" >
      <div  class="px-20 font-15 pt-20" style="border-top:1px solid #ebebeb;font-weight: 500;" *ngIf="taskParticipants.length >= 1 || taskParticipantsCc.length >= 1"  > Destinatário</div>

      <ion-item-sliding  class="px-20">
        <ion-item lines="none" *ngFor="let attendee of taskParticipantsCc;" class="d-flex">
          <div class="pr-10">

            <ion-icon *ngIf="ThemeService.currentTheme == 'default' " class="font-35-rem" src="assets/images/icons-default-profile.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " class="font-35-rem" src="assets/images/theme/gov/icons-profile.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " class="font-35-rem" src="assets/images/theme/{{ThemeService.currentTheme}}/icons-profile.svg"></ion-icon>

          </div>
          <ion-label>
            <h3>{{ attendee.Name }}</h3>
            <p class="lowercase">{{ attendee.EmailAddress }}</p>
          </ion-label>
          <div class="cursor-pointer" (click)="remove(attendee)">
            <ion-icon class="font-35-rem" src="assets/images/icons-delete-25.svg"></ion-icon>
          </div>
        </ion-item>
      </ion-item-sliding>
    </ion-list>
  </ion-item-group>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="d-flex">
    <ion-buttons class="flex-grow-1" slot="start">
      <button class="btn-ok" fill="clear" color="#fff" (click)="save()">
        <ion-label>Gravar</ion-label>
      </button>
    </ion-buttons>
    <ion-buttons class="flex-grow-1" slot="end">
      <button class="btn-cancel" fill="clear" color="#061b52" (click)="close()">
        <ion-label>Cancelar</ion-label>
      </button>
    </ion-buttons>
    
  </ion-toolbar>
</ion-footer>

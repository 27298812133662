import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class ChangeProfileService {


  callbacks: {
    funx: Function
    id: string
  }[] = []


  callbacksLogin: {
    funx: Function
    id: string
  }[] = []

  constructor() { }


  registerCallback(funx: Function, object: any = {} ) {

    const id = uuidv4()
    this.callbacks.push({funx, id})

    return id;
  }


  registerLoginCallback(funx: Function, object: any = {} ) {

    const id = uuidv4()
    this.callbacksLogin.push({funx, id})

    return id;
  }

  deleteCallback(id) {
    this.callbacks.forEach((e, index)=> {
      if(e.id == id) {
        if (index > -1) {
          this.callbacks.splice(index, 1)
        }
      }
    })
  }

  run() {
    this.callbacks.forEach((e, index)=> {
      e.funx()
    })
  }

  runLogin() {
    this.callbacksLogin.forEach((e, index)=> {
      e.funx()
    })
  }
  
}

import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { localstoreService } from '../store/localstore.service';
import { AES, enc, SHA1 }  from  'crypto-js'
@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  themes = [
    'gov',
    'default',
    'doneIt'
  ]

  private defaultTheme: any = 'gov'
  currentTheme: 'gov' | 'default' | 'doneIt' = this.defaultTheme
  keyName: string

  constructor() { 

    this.keyName = (SHA1("ThemeService")).toString()
    let restore = localstoreService.get(this.keyName, {
      theme: this.defaultTheme
    })

    this.setTheme(restore.theme)

  }

  setTheme(theme: 'gov' | 'default'  | 'doneIt') {

    if(this.themes.includes(theme)) {
      document.body.classList.remove("gov");
      document.body.classList.remove("default");
      document.body.classList.remove("doneIt");
      
      document.body.classList.add(theme);
      this.currentTheme = theme;
  
      localstoreService.set(this.keyName, {
        theme: theme
      })
    }


  }

  allows(args) {
    if(!Array.isArray(args)) {
      args = [args]
    }

    return args.includes(this.currentTheme)
  }

  saveTheme() {

  }

}
import { HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import { BackgroundService } from './background.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggingInterceptorService {

  constructor(
    private backgroundservice: BackgroundService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    // extend server response observable with logging
    return next.handle(req)
      .pipe(
        tap({
          // Succeeds when there is a response; ignore other events
          next: (event) => {

            const ok = event instanceof HttpResponse ? 'succeeded' : ''
            
            if(ok == 'succeeded' || (typeof event['status'] == 'number' && event['status'] !=0)) {
              // set to online
              this.backgroundservice.online();
            }
            
          },
          // Operation failed; error is an HttpErrorResponse
          error: (error) => {
            if (error.status === 0 && error.error instanceof ProgressEvent &&
               !req.url.includes('UserAuthentication/Login') && 
               req.url.includes(environment.apiURL)
            ) {
              // A client-side or network error occurred. Handle it accordingly.
              this.backgroundservice.offline();
            }
          }
        }),
        // Log when response observable either completes or errors
        finalize(() => {})
      );
  }
}

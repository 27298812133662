import { Injectable } from "@angular/core";
import { v4 as uuidv4 } from 'uuid';
import { WebSocketGraylogService } from "../socket/socket";
import { Span } from "@opentelemetry/sdk-trace-web";
import { environment } from "src/environments/environment";


export class OpenTelemetryLogging {

  socket = new WebSocketGraylogService()

  constructor() {

    if(environment.apiURL != 'https://gdqas-api.oapr.gov.ao/api/') {
      // this.socket.start()
    }

  }

  send(data: Object & { type: string; payload: any, spanContext:any }): void {
    // this.socket.send({
    //   type: data.type,
    //   payload: data.payload,
    //   requestId: uuidv4(),
    //   spanContext: data.spanContext
    // });
  }

}

export const openTelemetryLogging = new OpenTelemetryLogging()

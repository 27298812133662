import { Injectable } from '@angular/core';
import { FileLoaderService } from '../file/file-loader.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { LoadingController, Platform } from '@ionic/angular';
import {Photo} from '@capacitor/camera';
import { FileType } from 'src/app/models/fileType';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileToBase64Service } from 'src/app/services/file/file-to-base64.service';

const IMAGE_DIR = 'stored-images';

interface LocalFile {
  name: string;
  path: string;
  data: string;
}

@Injectable({
  providedIn: 'root'
})
export class FileService {

  images: LocalFile[] = [];
  capturedImage:any;
  capturedImageTitle:any;
  showLoader: boolean;

  files: Set<File>;
  photos: any[] = [];
  idroom: any;

  downloadProgess = 0;
  downloadFilename: any;
  convertBlobToBase64Worker;

  constructor(
    private fileLoaderService: FileLoaderService,
    private iab: InAppBrowser,
    private loadingCtrl: LoadingController,
    private platform: Platform,
    private fileToBase64Service: FileToBase64Service
  ) {}


  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });



  async saveImage(photo: Photo) {
    const base64Data = await this.readAsBase64(photo);

    const fileName = new Date().getTime() + '.jpeg';
    const savedFile = await Filesystem.writeFile({
      path: `${IMAGE_DIR}/${fileName}`,
      data: base64Data,
      directory: Directory.Data
    });

  }

  //new method 3
  async loadFiles() {

    const loading = await this.loadingCtrl.create({
      message: 'Loading data...',
    });
    await loading.present();

    return new Promise((resolve, reject)=>{
      Filesystem.readdir({
        path: IMAGE_DIR,
        directory: Directory.Data,
      }).then(result => {

        let lastphoto = result.files[result.files.length - 1]
        resolve(lastphoto)
      },
        async (err) => {

          reject('ERROR FILE DOSENT EXIST')
          // Folder does not yet exists!
          await Filesystem.mkdir({
            path: IMAGE_DIR,
            directory: Directory.Data,
            recursive: true
          });
        }
      ).then(_ => {
        loading.dismiss();
      });
    })
  }


  //new method 2
  private async readAsBase64(photo: Photo) {
    if (this.platform.is('hybrid')) {
      const file = await Filesystem.readFile({
        path: photo.path
      });

      return file.data;
    }
    else {
      // Fetch the photo, read as a blob, then convert to base64 format
      const response = await fetch(photo.webPath);
      const blob = await response.blob();

      return await this.convertBlobToBase64(blob) as string;
    }
  }


  async readFile(filePath) {
    return await Filesystem.readFile({
      path: filePath,
      directory: Directory.Data,
    });
  }

  //new method 4
  async loadFileData(fileName: string) {

    // for (let f of fileNames) {
    const filePath = `${IMAGE_DIR}/${fileName}`;

    const readFile = await this.readFile(filePath)

    const image ={
      name: fileName,
      path: filePath,
      data: `data:image/jpeg;base64,${readFile.data}`,
    };



    const capturedImage = image.data

    const capturedImageTitle = new Date().getTime() + '.jpeg';


    return { capturedImage, capturedImageTitle}

  }

  getFileFromDevice(types: typeof FileType[]): Promise<File>  {
    const input = this.fileLoaderService.createInput({
      accept: types
    })

    return new Promise((resolve, reject)=>{
      input.onchange = async () => {
        const file = this.fileLoaderService.getFirstFile(input)

        resolve(file);
      };
    })


  }

  getFileFromDeviceBase64(types: typeof FileType[]) {
    const input = this.fileLoaderService.createInput({
      accept: types
    })

    return new Promise((resolve, reject)=>{
      input.onchange = async () => {
        const file = this.fileLoaderService.getFirstFile(input)

        resolve(await this.getBase64(file));
      };
    })
  }


  getBase64(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result)
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    })

 }


  viewDocumentByUrl(url) {
    const browser = this.iab.create(url,"_parent");
    browser.show();
  }


  blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }


  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }


  base64StringToBlob(base64Data) {
    return new Promise((resolve, reject) => {

      fetch(base64Data)
      .then(res => resolve(res.blob()))
      .then(console.log)

    })
  }


  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  base64toBlobIos(base64Data, contentType) {
    contentType = contentType || '';
    const byteCharacters = atob(base64Data);
    const byteArray = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }

    return new Blob([byteArray], { type: contentType });
  }


}

import { z } from "zod";
import { EntityTable } from 'Dexie';

export const TypingTableSchema = z.object({
  id: z.string().optional(),
  userId: z.number().optional(),
  userName: z.string(),
  roomId: z.string(),
})

export type TypingTable = z.infer<typeof TypingTableSchema>
export type DexieTypingsTable = EntityTable<TypingTable, 'id'>;
export const TypingTableColumn = 'id, userId, userName, roomId, entryDate'

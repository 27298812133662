import { Injectable } from '@angular/core';
import { localstoreService } from './localstore.service'
import { SHA1 }  from  'crypto-js'
import { ExpedienteTask } from '../models/dailyworktask.model';
import { momentG } from 'src/plugin/momentG';
import { v4 as uuidv4 } from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class ExpedientegdStoreService {

  // main data
  private _list: ExpedienteTask[] = []
  // local storage keyName
  private keyName: string; 
  private _count = 0

  newList = []

  callbacks: {
    [key: string]: {
      funx: Function
      id: string
    }
  } = {} 

  constructor() {

    this.keyName = (SHA1("EventoaprovacaoStoreService")).toString()
    
    setTimeout(()=> { 
      let restore = localstoreService.get(this.keyName, {})
      this._list = restore.list || []
      this._count = parseInt(restore.count) || 0

      this.runCallback()
    }, 10)

  }

  registerCallback({funx, id = uuidv4()}) {

    this.callbacks[id] = { funx, id}

    return {
      delete: ()=> {
        delete this.callbacks[id]
      }
    }
  }

  runCallback() {
    for (const [key, value] of Object.entries(this.callbacks)) {
      value.funx()
    }
  }


  get list() {
    return this._list
  }
  get count() {
    return this._list.length  || 0
  }

  reset(eventsList: any) {
    this._list = eventsList
    this.save()

    if(window['all-process-gabinete']) {
      window['all-process-gabinete']()
    }

    this.runCallback()
  }

  private save() {
    setTimeout(() => { 
      localstoreService.set(this.keyName,{
        list: this._list,
        count: this._count
      })
    }, 10)

    this.updateNewList()
    
  }

  updateNewList() {
    this.newList = this._list.filter((e) =>{
      return this.lessthen24Hours(e.TaskReceiveDate)
    })
  }


  lessthen24Hours(isoDateString:string) {

    if(!isoDateString) {
      return false
    }
    
    const creationDate = new Date(isoDateString)
    return momentG(new Date(), 'dd MMMM yyyy') == momentG(new Date(creationDate), 'dd MMMM yyyy')
  }

}

export const ExpedienteGdStore = new ExpedientegdStoreService()
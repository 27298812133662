import { EntityTable } from 'Dexie';
import { z } from 'zod';

export const UserPhotoTableSchema = z.object({
  wxUserId: z.number(),
  file: z.string(),
  attachmentId: z.string().nullable()
})

export type UserPhotoTable = z.infer<typeof UserPhotoTableSchema>
export type DexieUserPhotoTable = EntityTable<UserPhotoTable, 'wxUserId'>;
export const UserPhotoTableColumn = 'wxUserId'

import { SessionStore } from "src/app/store/session.service";
import { BaseEntity } from "src/app/utils/entity";
import { z } from "zod"
import { MessageEntitySchema } from "./message";

export enum RoomType {
  Group = 1,
  Direct = 2
}

const UserSchema = z.object({
  wxUserId: z.number(),
  wxFullName: z.string(),
  wxeMail: z.string(),
  userPhoto: z.string().nullable(),
});

const MemberSchema = z.object({
  id: z.string(),
  user: UserSchema,
  joinAt: z.string(),
  isAdmin: z.boolean()
});

export const RoomEntitySchema = z.object({
  id: z.string(),
  roomName: z.string(),
  createdBy: z.object({
    wxUserId: z.number(),
    wxFullName: z.string(),
    wxeMail: z.string().email(),
    userPhoto: z.string().nullable().optional()// api check
  }),
  createdAt: z.any(),
  expirationDate: z.any().nullable(),
  roomType: z.nativeEnum(RoomType),
  members: z.array(MemberSchema).optional(),
  messages: MessageEntitySchema.array().optional()
})

export type IRoom = z.infer<typeof RoomEntitySchema>

export class RoomEntity  extends BaseEntity<RoomEntity>(RoomEntitySchema) implements IRoom{

  id: typeof RoomEntitySchema._input.id
  roomName: typeof RoomEntitySchema._input.roomName
  createdBy: typeof RoomEntitySchema._input.createdBy
  createdAt: typeof RoomEntitySchema._input.createdAt
  expirationDate: typeof RoomEntitySchema._input.expirationDate
  roomType: typeof RoomEntitySchema._input.roomType
  members: typeof RoomEntitySchema._input.members
  messages: typeof RoomEntitySchema._input.messages

  constructor(data: IRoom) {
    super();
    Object.assign(this, data)
    if(data.roomType == RoomType.Direct) {
      this.setName()
    }
  }

  setName() {
    const userChatName = this.members?.find((e) => e.user.wxUserId != SessionStore.user.UserId)
    if(userChatName) {
      this.roomName = userChatName.user.wxFullName
    }
  }

  hasLastMessage() {
    return this.messages?.length >= 1
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { customTask } from '../models/dailyworktask.model';

@Pipe({
  name: 'customTask'
})
export class CustomTaskPipe implements PipeTransform {

  transform(fullTask): customTask {

    let date = new Date(fullTask.taskStartDate);
    let month = date.getMonth() + 1;
    let taskDate = date.getFullYear() + "-" + month +"-"+date.getDate()+" "+date.getHours()+":"+date.getMinutes()+ ":"+date.getSeconds();

    return {
      "SerialNumber": fullTask.serialNumber,
      "Subject": fullTask.workflowInstanceDataFields.Subject,
      "Folio": fullTask.workflowInstanceDataFields.Subject,
      "Senders": fullTask.workflowInstanceDataFields.Sender || "SEM ENTIDADE",
      "CreateDate": taskDate,
      "TaskStartDate": fullTask.taskStartDate,
      "DocumentURL": fullTask.workflowInstanceDataFields.ViewerRequest,
      "Remetente": fullTask.workflowInstanceDataFields.Remetente,
      "DocumentsQty": fullTask.totalDocuments,
      "DocId": fullTask.workflowInstanceDataFields.DispatchDocId,
      "FolderID": fullTask.workflowInstanceDataFields.FolderID,
      "WorkflowName": fullTask.workflowDisplayName,
      "activityInstanceName": fullTask.activityInstanceName,
      "Status": fullTask.workflowInstanceDataFields.Status,
      "Deadline": fullTask.deadline,
      "TaskStatus": fullTask.taskStatus,
      "TaskReceiveDate": fullTask.taskReceiveDate,
      "Note": fullTask.workflowInstanceDataFields.TaskMessage,
      "InstanceID": fullTask.workflowInstanceDataFields?.InstanceID,
      "DeadlineType":  fullTask.workflowInstanceDataFields?.InstanceID
    }
  }

}
import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Publication } from 'src/app/models/publication';
import { PublicationsService } from 'src/app/services/publications.service';
import { ToastService } from 'src/app/services/toast.service';
import { NewPublicationPage } from '../../new-publication/new-publication.page';
import { ThemeService } from 'src/app/services/theme.service'
import { ViewMediaPage } from 'src/app/modals/view-media/view-media.page';
import { RouteService } from 'src/app/services/route.service';
import { PermissionService } from 'src/app/services/permission.service';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service';
import { PublicationFolderService } from 'src/app/store/publication-folder.service';
import { checkFileTypeService } from 'src/app/services/checkFileType.service';
import { StopvideoService } from "src/app/services/stopvideo.service"
import { PublicationHolderService } from 'src/app/services/publication/publication-holder.service'


@Component({
  selector: 'app-publication-detail',
  templateUrl: './publication-detail.page.html',
  styleUrls: ['./publication-detail.page.scss'],
})
export class PublicationDetailPage implements OnInit {
  @ViewChild('videoPlayer', { static: true }) videoPlayer: ElementRef;
  @ViewChild('ScrollContainer', { static: true }) ScrollContainer: ElementRef;

  showLoader: boolean;
  DocumentId: string;
  folderId: string;
  publication: Publication;
  isModal = false
  videoControls = false;

  pagination = {
    clickable: true,
    dynamicBullets: true
  }

  isComponentIsAlive = true

  constructor(
    private modalController: ModalController,
    private publications:PublicationsService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private RouteService: RouteService,
    public ThemeService: ThemeService,
    public p:PermissionService,
    private httpErrorHandle: HttpErrorHandle,
    public publicationFolderService: PublicationFolderService,
    public checkFileType: checkFileTypeService,
    public StopvideoService: StopvideoService,
    public PublicationHolderService: PublicationHolderService
    ) {
    }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      // console.log(params["params"])
      if(params["params"]) {
        this.folderId = params["params"].folderId;
        this.DocumentId = params["params"].publicationId;
        this.isModal = params["params"].isModal;
        //

        console.log('params', params)
        if(this.DocumentId) {
          this.getPublicationDetail();
        }
      }
    });

    this.publication = {
      DateIndex: null,
      DocumentId: null,
      ProcessId:'',
      Title:'',
      Message: '',
      /* image: null, */
      DatePublication: null,
      Files: [],
      OriginalFileName: '',
      FileExtension: '',
    };

    window['publicationEdit'] = () => {
      if(this.isComponentIsAlive == true) {
        this.getPublicationDetail()
      }
    }

  }

  ngOnDestroy() {
    this.isComponentIsAlive = false
  }

  doRefresh(event) {
    this.getPublicationDetail();

    setTimeout(() => {
      try {
        event?.target?.complete();
      } catch(error) {}
      }, 2000);
  }

  getPublicationDetail() {
    const folderId = this.folderId

    this.showLoader = true;

    this.publications.GetPublicationWithArrayOfFilesById(this.DocumentId).subscribe(res => {

      /* this.publication = res; */
      this.publication = {
        DateIndex: res.DateIndex,
        DocumentId: res.DocumentId,
        ProcessId:res.ProcessId,
        Title:res.Title,
        Message: res.Message,
        DatePublication: res.DatePublication,
        Files: res.Files,
        OriginalFileName: res.OriginalFileName,
        FileExtension: res.FileExtension,
      }

      console.log('this.publication', this.publication)
      this.showLoader = false;
    }, (error) => {
      this.showLoader = false;

      if(error.status == 404) {
        this.publicationFolderService.deletePost(this.publication.ProcessId, this.publication.DocumentId)
      }

      this.goBack();
      this.httpErrorHandle.httpStatusHandle(error)
    });

  }

  close() {
    this.modalController.dismiss();
  }

  goBack() {
    if(this.isModal) {
      this.close()
    } else {
      this.RouteService.goBack();
    }

  }

  async deletePost() {

    const loader = this.toastService.loading()

    try {
      await this.publications.DeletePublication(this.folderId, this.DocumentId).toPromise();
      this.httpErrorHandle.httpsSucessMessagge('Eliminar publicação')

      if(window['app-view-publications-page-doRefresh']) {
        window['app-view-publications-page-doRefresh']()
      }

      this.publicationFolderService.deletePost(this.folderId, this.DocumentId)
      this.goBack();
    } catch (error) {
      if(error.status == 404) {
        this.publicationFolderService.deletePost(this.folderId, this.DocumentId)
        this.goBack();
      }
      this.httpErrorHandle.httpStatusHandle(error)
    }  finally {
      loader.remove()
    }


  }

  async editPost(publicationType:any) {
    if(this.PublicationHolderService.PublicationFormMV.length == 0) {
      const modal = await this.modalController.create({
        component: NewPublicationPage,
        componentProps:{
          publicationType: publicationType,
          publication: this.publication,
          folderId: this.folderId
        },
        cssClass: 'new-publication modal modal-desktop',
        backdropDismiss: false
      });

      modal.onDidDismiss().then((data) => {

      });

      await modal.present();
    } else {
      this.toastService._successMessage('já existe uma publicação a ser processado');
    }


  }

  async openPreview(item) {
    const modal = await this.modalController.create({
      component:  ViewMediaPage,
      cssClass: 'modal modal-desktop',
      componentProps: {
        image: item.FileBase64,
        username: item.Title,
        _updatedAt: item.DatePublication
      }
    });
    modal.present();
  }

  play() {
    const video = this.videoPlayer.nativeElement;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  }

}

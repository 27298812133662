import { RoomType } from "src/app/core/chat/entity/group";
import { RoomTable } from "src/app/infra/database/dexie/instance/chat/schema/room";
import { RoomListItemOutPutDTO } from "src/app/module/chat/domain/use-case/room/room-get-list-use-case.service";

export function roomListDetermineChanges(serverRooms: RoomListItemOutPutDTO[], localRooms: RoomTable[]) {
  const serverRoomMap = new Map(serverRooms.map(room => [room.chatRoom.id, room]));
  const localRoomMap = new Map(localRooms.map(room => [room.id, room]));

  const roomsToInsert = serverRooms.filter(room => !localRoomMap.has(room.chatRoom.id));
  const roomsToUpdate = serverRooms.filter(room => {
    const localRoom = localRoomMap.get(room.chatRoom.id);

    return localRoom && (
      room.chatRoom.roomName !== localRoom.roomName && room.chatRoom.roomType == RoomType.Group  ||
      room.chatRoom.createdBy.wxUserId !== localRoom.createdBy.wxUserId ||
      room.chatRoom.createdAt !== localRoom.createdAt ||
      room.chatRoom.expirationDate !== localRoom.expirationDate  // ||
      // room.chatRoom.messages?.[0]?.id !== localRoom.messages?.[0]?.id
      // room.chatRoom.roomType !== localRoom.roomType
    );
  });
  const roomsToDelete = localRooms.filter(room => !serverRoomMap.has(room.id));

  return { roomsToInsert, roomsToUpdate, roomsToDelete };
}

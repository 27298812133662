<ion-header  class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <div class="main-header">
      <div class="title-content width-100">
        <div class="back-icon">
          <button class="btn-no-color cursor-pointer" (click)="close()">
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' " slot="end" src='assets/images/icons-arrow-arrow-left.svg'></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " src="assets/images/theme/doneIt/icons-calendar-arrow-left.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " slot="end" src='assets/images/theme/gov/icons-calendar-arrow-left.svg'></ion-icon>
          </button>
        </div>
        <div class="div-title">
          <ion-label class="title">Contactos</ion-label>
        </div>
        <app-btn-seguinte (click)="updateGroup()"></app-btn-seguinte>
      </div>
    </div>
  </ion-toolbar>
  <ion-toolbar class="toolbar-search">
    <div class="search">
      <ion-searchbar search-icon="undefined" debounce="500" (ionChange)="onChange($event)" placeholder="Pesquisar por contacto" ></ion-searchbar>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher  name="refresher" slot="fixed" (ionRefresh)="doRefresh($event)">
      <ion-progress-bar type="indeterminate" *ngIf="showLoader"></ion-progress-bar>
      <ion-refresher-content>
      </ion-refresher-content>
    </ion-refresher>

  <div class="main-content">
    <div *ngIf="members">
      <ion-label class="members-label">Contactos selecção:</ion-label>
      <div class="members-list" *ngFor="let user of members">
        <ion-item-sliding [class.inactive]="sessionStore.user.UserName == user.username">
          <ion-item lines="none" class="members-checkbox ion-no-border ion-no-padding">
            <ion-checkbox checked disabled color="primary"></ion-checkbox>
            <p>{{user.name}}</p>
            <ion-icon class="{{user.status}}" name="ellipse"></ion-icon>
          </ion-item>
          <ion-item-options (click)="deleteMember(user)" class="members-options" side="end">
            <ion-item-option color="danger">Apagar</ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </div>
    </div>

    
    <div *ngFor="let userContainer of userContainer | keyvalue;" >

      <div class="item-divider">
        <ion-label>{{ userContainer.key }}</ion-label>
      </div>

      <div *ngFor="let user of userContainer.value" class="d-flex px-20 align-center">
        <ion-checkbox [(ngModel)]="user.isChecked"  color="primary" (click)="selectedContact(user)"></ion-checkbox>
        <ion-label class="flex-grow-1 px-10">{{user.name}}</ion-label>
        <div class="icon"><ion-icon class="{{user.status}}" name="ellipse"></ion-icon></div>
      </div>
    </div>

  </div>
</ion-content>

import { Injectable } from '@angular/core';
import { MessageLocalDataSourceService } from '../../../data/repository/message/message-local-data-source.service';
import { MessageEntitySchema } from 'src/app/core/chat/entity/message';
import { z } from 'zod';

const SocketMessageDeleteOutputSchema = MessageEntitySchema.pick({
  id: true,
  attachments: true,
  canEdit: true,
  editedAt: true,
  info: true,
  isDeleted: true,
  message: true,
  messageType: true,
  oneShot: true,
  reactions: true,
  receiverId: true,
  requireUnlock: true,
  roomId: true,
  sender: true,
  sending: true,
  sentAt: true,
})


export type ISocketMessageDeleteOutput = z.infer<typeof SocketMessageDeleteOutputSchema>

@Injectable({
  providedIn: 'root'
})
export class SocketMessageDeleteUseCaseService {

  constructor(
    private messageLocalDataSourceService: MessageLocalDataSourceService
  ) { }


  async execute(input: ISocketMessageDeleteOutput) {
    const result = await this.messageLocalDataSourceService.update(input.id, { isDeleted: true})

    if(result.isOk()) {
      console.log('deleled', result.value)
    } else {
      console.log(result.error)
    }
  }
}

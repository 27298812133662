
function removeDuplicate(data){


    return [...new Set(data.map(JSON.stringify))].map(JSON.parse)
    
}

module.exports = {
    removeDuplicate: removeDuplicate
};
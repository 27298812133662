import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, PickerController } from '@ionic/angular';
import { ThemeService } from 'src/app/services/theme.service'

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.page.html',
  styleUrls: ['./edit-group.page.scss'],
})
export class EditGroupPage implements OnInit {
  showLoader: boolean;
  displayDuration: any;
  showDuration: boolean;
  selectedDuration = ['','',''];
  groupName:string;
  room:any;
  roomId:string;

  constructor(
    private modalController: ModalController,
    private pickerController: PickerController,
    private navParams: NavParams,
    public ThemeService: ThemeService
  ) {
    this.roomId = this.navParams.get('roomId');
   }

  ngOnInit() {
    // this.chatService.refreshtoken();
    this.getRoomInfo();
  }

  getRoomInfo(){

  }

  close(){
    this.modalController.dismiss();
  }

  changeGroupName(){
    if(this.groupName.trim().length > 1){
      let name = this.groupName.split(' ').join('-');
      let body = {
        "roomId": this.room._id,
        "name": name,
      }
    }
    else{

    }
  }
  _ionChange(event){
    this.showDuration = event.detail.checked;
  }
  async showPicker(){
    const picker = await this.pickerController.create({
      cssClass: '',
      buttons: [
          {
            text: 'Cancelar', role: 'cancel', cssClass: 'btn-cancel'
          },
          {
            text: 'Ok',
            cssClass: 'btn-cancel',
            handler:(value:any)=>{

              this.selectedDuration = [
                value.days.value,
                value.hours.value,
                value.minutes.value,
              ]

              if(value.days.value != null && value.hours.value != null && value.minutes.value != null){
                if(value.days.value > 0){
                  if(value.days.value == 1){
                    if(value.hours.value == 1){
                      this.displayDuration = value.days.value  + " day " +
                      value.hours.value + " hora " +
                      value.minutes.value + " minutos";
                    }
                    else{
                      this.displayDuration = value.days.value  + " days " +
                      value.hours.value + " horas " +
                      value.minutes.value + " minutos";
                    }
                  }
                  else{
                    if(value.hours.value == 1){
                      this.displayDuration = value.days.value  + " days " +
                      value.hours.value + " hora " +
                      value.minutes.value + " minutos";
                    }
                    else{
                      this.displayDuration = value.days.value  + " days " +
                      value.hours.value + " horas " +
                      value.minutes.value + " minutos";
                    }
                  }
                }
                else{
                  if(value.hours.value == 1){
                    this.displayDuration = value.hours.value + " hora " +
                    value.minutes.value + " minutos";
                  }
                  else{
                    this.displayDuration = value.hours.value + " horas " +
                    value.minutes.value + " minutos";
                  }
                }
              }
            },
        },
      ],
      columns: [
        {
          name: 'days',
          prefix: 'Dias',
          options: [
            { text: '0', value: 0 },
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
          ]
        },
        {
          name: 'hours',
          prefix: 'Horas',
          options: [
            { text: '0', value: 0 },
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
            { text: '5', value: 5 },
            { text: '6', value: 6 },
            { text: '7', value: 7 },
            { text: '8', value: 8 },
          ]
        },
        {
          name: 'minutes',
          prefix: 'Minutos',
          selectedIndex: 0,
          options: [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '15', value: 15 },
            { text: '20', value: 20 },
            { text: '25', value: 25 },
            { text: '30', value: 30 },
            { text: '35', value: 35 },
            { text: '45', value: 45 },
            { text: '50', value: 50 },
            { text: '55', value: 55 },
          ]
        }
      ]
    });
    await picker.present();
    picker.onDidDismiss().then(async data =>{
      let day = await picker.getColumn('days');
      let hour = await picker.getColumn('hours');
      let minutes = await picker.getColumn('minutes');
    });

  }

}


<ion-content>
  <div class="pb-30 text-center pt-25">Este é um evento recorrente</div>
  <div>
    <ion-buttons slot="start" class="pb-15">
      <button class="btn-ok" style="width: 240px !important" fill="clear" color="#fff" (click)="close('DeleteAll')" >
        <ion-label>Eliminar todos eventos da serie</ion-label>
      </button>
    </ion-buttons>
    <ion-buttons  slot="end" class="pb-15">
      <button class="btn-cancel" fill="clear" color="#061b52" style="width: 240px !important" (click)="close('DeleteOne')">
        <ion-label>Eliminar apenas este evento</ion-label>
      </button>
    </ion-buttons>
    <ion-buttons  slot="end">
      <button class="btn-cancel" fill="clear" color="#061b52" style="width: 240px !important" (click)="close('Cancel')">
        <ion-label>Cancelar</ion-label>
      </button>
    </ion-buttons>

  </div>
</ion-content>

import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { DomSanitizer} from '@angular/platform-browser';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-view-media',
  templateUrl: './view-media.page.html',
  styleUrls: ['./view-media.page.scss'],
})
export class ViewMediaPage implements OnInit {

  image: any;
  type: any;
  name: string
  _updatedAt: string
  view: boolean

  sliderOpts = {
    zoom: true
  };

  base64Sanitize:any = "";

  constructor(
    private modalController: ModalController,
    private navParams:NavParams,
    public sanitizer: DomSanitizer,
    private platform: Platform,
    
  ) {
    this.image = this.navParams.get('image')
    this.type = this.navParams.get('type')
    this.name = this.navParams.get('username')
    this._updatedAt = this.navParams.get('_updatedAt')
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
  }

  ngOnInit() {

      this.base64Sanitize = this.sanitizer.bypassSecurityTrustResourceUrl(this.image);

      if (this.platform.is('desktop')) {
        this.view = true;
      } else {
        this.view = false;
      }
    
  }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  close(){
    this.modalController.dismiss()
  }
}

import { EntityTable } from 'Dexie';
import { z } from 'zod';

export const BoldTableSchema = z.object({
  roomId: z.string(),
  bold: z.number()
})

export type BoldTable = z.infer<typeof BoldTableSchema>;
export type DexieBoldTable = EntityTable<BoldTable, 'roomId'>;
export const BoldTableColumn = 'roomId, bold';

import { Injectable } from '@angular/core';
import { AlertController, AnimationController } from '@ionic/angular';
// import { ChatSystemService } from './chat/chat-system.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    public alertController: AlertController,
    private animationController: AnimationController,
    private toastService: ToastService,
    // public ChatSystemService: ChatSystemService,
    ) { }

  async presentAlert(message:string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Mensagem do sistema',
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentErrorMessage(message:string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Mensagem de erro',
      message: message,
    });

    await alert.present();

    setTimeout(()=>{
      alert.dismiss();
    }, 2500);
  }

  async confirmDeleteMessage(msgId:any, room:any) {

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Apagar a mensagem?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //
          }
        }, {
          text: 'Apagar',
          handler: () => {
            //const loader = this.toastService.loading();

            // this.ChatSystemService.deleteMessage(msgId).then(() => {
            //   room.deleteMessage(msgId)
            // }).catch((error) => console.error(error))
            //this.ChatSystemService.subscribeToRoomUpdate(room._id, room);
            //loader.remove();
          }
        }
      ]
    });

    await alert.present();
  }

}

import { Injectable } from '@angular/core';
import { MessageSocketRepositoryService } from 'src/app/module/chat/data/repository/message/message-live-signalr-data-source.service';
import { XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';

@Injectable({
  providedIn: 'root'
})
export class SocketConnectUseCaseService {

  constructor(
    private MessageSocketRepositoryService: MessageSocketRepositoryService
  ) { }

  @XTracerAsync({name:'SocketConnectUseCaseService', module:'chat',  bugPrint: true})
  async execute() {
    return await this.MessageSocketRepositoryService.connect()
  }


}

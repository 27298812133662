<!-- HEADER-->
<ion-header class="ion-no-border header-main  d-md-flex">
  <div *ngIf="select" class="btn-close d-none d-lg-flex cursor-pointer font-35-rem" (click)="close()">
    <ion-icon *ngIf="ThemeService.currentTheme == 'default' " class="icon"
      src="assets/images/icons-search-close.svg"></ion-icon>
    <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " class="icon"
      src="assets/images/theme/gov/icons-search-close.svg"></ion-icon>
  </div>

  <div class="d-md-flex flex-md-row-reverse flex-grow-1">
    <ion-progress-bar type="indeterminate" class="loader" *ngIf="showLoader"></ion-progress-bar>


    <div class="top-box d-flex ion-justify-content-between pb-10">
      <!-- search -->

      <!-- <div *ngIf="!showAdvanceSearch" class="icon-z icon-most-searched-word-open cursor-pointer" (click)="showHideAdvanceSearch(true)">
        <ion-icon  *ngIf="ThemeService.currentTheme == 'default' && type!='Agenda'" src="assets/images/theme/blue/icons-most-searched-words-open.svg" class="icon" slot="end"></ion-icon>
        <ion-icon *ngIf="ThemeService.currentTheme != 'default'&& type!='Agenda' " src="assets/images/icons-most-searched-words-open.svg" class="icon" slot="end"></ion-icon>
      </div>
      <div *ngIf="showAdvanceSearch " class="icon-z icon-most-searched-word-open align-md-baseline pl-10 cursor-pointer" (click)="showHideAdvanceSearch(false)" >
        <ion-icon *ngIf="ThemeService.currentTheme == 'default' && type!='Agenda' "src="assets/images/advance-search-show-modal.svg"  class="icon" slot="end"></ion-icon>
        <ion-icon *ngIf="ThemeService.currentTheme == 'gov' && type!='Agenda'" src="assets/images/theme/gov/advance-search-show-modal.svg"  class="icon" slot="end"></ion-icon>
      </div> -->

      <div class="profile">
        <ion-icon class="icon" src="assets/images/icons-profile.svg"></ion-icon>
      </div>

      <div class="btn-modal-dismiss" *ngIf="showSearchInput">
        <button (click)="close()" style="
          margin: 0px;
          padding: 0px;
          background: transparent;
          color: white;
        ">
          <ion-icon *ngIf="ThemeService.currentTheme == 'default' " class="icon font-40-rem"
            src="assets/images/icons-search-close.svg"></ion-icon>
          <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " class="icon font-40-rem"
            src="assets/images/theme/gov/icons-search-close.svg"></ion-icon>
        </button>
      </div>
    </div>

    <!-- Search drop down -->
    <div class="advance-search pa-0 pt-10 pr-10 flex-md-grow-1">
      <ion-form>
        <div class="searchParent">

          <div class="d-flex search-input-container ion-justify-content-between searchChild">
            <!-- <div class="icon">
              <button class="btn-no-color" (click)="basicSearch()">
                <ion-icon  class="icon-z" slot="end" src="assets/images/theme/gov/search.svg"></ion-icon>
              </button>
            </div> -->
            <div class="input-text d-flex ion-align-items-center pm-10 pl-10">
              <ion-input (keyup.enter)="basicSearch()" [(ngModel)]='searchSubject'
                class="search-input {{Cy.p.search.input.subject}}" type="search" placeholder="Assunto"></ion-input>
            </div>
            <!-- <div (click)="clearSearchInput()"  class="d-flex align-center icon">

              <ion-icon *ngIf="ThemeService.currentTheme == 'default' " class="font-25" name="restaurant-outline" src="assets/images/icons-search-close.svg"></ion-icon>
              <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " class="font-25" name="restaurant-outline" src="assets/images/theme/gov/icons-search-close.svg"></ion-icon>

            </div> -->

          </div>
          <div (click)="basicSearch()" class="d-flex align-center searchChild pl-10 {{Cy.p.search.b.search}}">

            <ion-icon class="icon-z" slot="end" src="assets/images/theme/gov/icons-search.svg"></ion-icon>

          </div>

          <div *ngIf="!showAdvanceSearch" class="icon-z icon-most-searched-word-open pl-10 cursor-pointer"
            (click)="showHideAdvanceSearch(true)">
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' && type!='Agenda'"
              src="assets/images/theme/gov/filter_icon_grey.svg" class="icon" slot="end"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme != 'default'&& type!='Agenda' "
              src="assets/images/theme/gov/filter_icon_grey.svg" class="icon" slot="end"></ion-icon>
          </div>
          <div *ngIf="showAdvanceSearch "
            class="icon-z icon-most-searched-word-open align-md-baseline pl-10 cursor-pointer"
            (click)="showHideAdvanceSearch(false)">
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' && type!='Agenda' "
              src="assets/images/theme/gov/filter_icon.svg" class="icon" slot="end"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' && type!='Agenda'"
              src="assets/images/theme/gov/filter_icon.svg" class="icon" slot="end"></ion-icon>
          </div>

        </div>
      </ion-form>
      <!--  -->
      <li *ngIf="showAdvanceSearch" class="d-flex date-container ion-justify-content-between">
        <div class="icon-mer icon">
          <ion-icon name="restaurant-outline" src="assets/images/icons-advance-search-document.svg"></ion-icon>
        </div>
        <div class="input-container d-flex flex-grow-1 justify-center align-center materia-top">

          <mat-form-field appearance="none" class="date-hour-picker" (click)="clickDate()" style="
            margin-top: -11px;
            margin-left: 10px;
          ">
            <input matInput [ngxMatDatetimePicker]="picker1" placeholder="{{datePickerPlaceholder}}"
              [(ngModel)]="searchDocumentDate">
            <mat-datepicker-toggle id="new-inicio" matSuffix [for]="picker1"
              (click)="clickDate()"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker1 (click)="clickDate()" [showSpinners]="showSpinners"
              [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
              [touchUi]="touchUi" [hideTime]="true">
            </ngx-mat-datetime-picker>
          </mat-form-field>

          <div class="icon cursor-pointer" (click)="clearInputDocumentDate()">
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' " name="restaurant-outline"
              src="assets/images/icons-search-close.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " name="restaurant-outline"
              src="assets/images/theme/gov/icons-search-close.svg"></ion-icon>
          </div>

        </div>
      </li>
      <li *ngIf="showAdvanceSearch" class="d-flex date-container ion-justify-content-between">
        <div *ngIf="checkDateEnd" class="icon-mer icon">
          <ion-icon name="restaurant-outline" src="assets/images/icons-advance-search-document.svg"></ion-icon>
        </div>
        <div *ngIf="checkDateEnd" class="input-container d-flex flex-grow-1 justify-center align-center materia-top">

          <mat-form-field appearance="none" class="date-hour-picker" (click)="clickDate()" style="
            margin-top: -11px;
            margin-left: 10px;
          ">
            <input matInput [ngxMatDatetimePicker]="picker2" placeholder="Data Fim*"
              [(ngModel)]="searchDocumentDateEnd">
            <mat-datepicker-toggle id="new-inicio" matSuffix [for]="picker2"
              (click)="clickDate()"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker2 (click)="clickDate()" [showSpinners]="showSpinners"
              [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
              [touchUi]="touchUi" [hideTime]="true">
            </ngx-mat-datetime-picker>
          </mat-form-field>

          <div class="icon cursor-pointer" (click)="clearInputDocumentDate()">
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' " name="restaurant-outline"
              src="assets/images/icons-search-close.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " name="restaurant-outline"
              src="assets/images/theme/gov/icons-search-close.svg"></ion-icon>
          </div>

        </div>
      </li>
      <!-- Sender -->
      <li *ngIf="showAdvanceSearch" class="d-flex date-container ion-justify-content-between">
        <div *ngIf="checkRoutPublication" class="icon-mer icon">
          <ion-icon name="restaurant-outline" src="assets/images/icons-advance-search-sender.svg"></ion-icon>
        </div>
        <div *ngIf="checkRoutPublication" class="input-container d-flex ion-align-items-center">
          <ion-input [(ngModel)]='searchSenderdescription' class="search-input cursor-pointer"
            (click)="openAdvanceSearchSelection()" type="search" placeholder="Remetentes"></ion-input>
          <div class="icon cursor-pointer" (click)="clearInputRemetente()">
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' " name="restaurant-outline"
              src="assets/images/icons-search-close.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " name="restaurant-outline"
              src="assets/images/theme/gov/icons-search-close.svg"></ion-icon>
          </div>
        </div>
      </li>
      <!-- Organic entity -->
      <li *ngIf="showAdvanceSearch" class="d-flex date-container ion-justify-content-between">
        <div *ngIf="checkRoutPublication" class="icon-mer icon">
          <ion-icon name="restaurant-outline" src="assets/images/icons-arrow-arrow-down-25-copy.svg"></ion-icon>
        </div>
        <div *ngIf="checkRoutPublication" class="input-container d-flex ion-align-items-center">
          <ion-input [(ngModel)]='searchOrganicEntiryDescription' class="search-input custom-checkbox" type="search"
            placeholder="Entidade Orgânica" (click)="openOrganicEntitySelection()"></ion-input>
          <div class="icon cursor-pointer" (click)="clearInputOrganicEntity()">
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' " name="restaurant-outline"
              src="assets/images/icons-search-close.svg"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " name="restaurant-outline"
              src="assets/images/theme/gov/icons-search-close.svg"></ion-icon>
          </div>
        </div>
      </li>
    </div>

    <!-- search result categories-->
    <div class="options flex-md-grow-1 " *ngIf="showDocuments" style="border-bottom: 1px solid #f3f3f3;width: unset">
      <div class="container ">
        <div class="d-flex  overflow-x-auto">
          <!-- SLIDE -->

          <div class="d-flex py-10" style="width:0px" [ngClass]="{'active-category': TodosCategory}"
            class="button cursor-pointer" (click)="noFilter()">

            <span>Todos</span>
            <ion-label class="label">{{ searchDocuments.length }}</ion-label>
          </div>

          <div *ngFor="let category of searchCategories; index as i;" class="d-flex py-10" style="width:0px"
            [ngClass]="{'active-category': category.Active}" class="button cursor-pointer"
            (click)="activeCategoty(i); filterDocList(category.Name)">

            <span>{{ category.Name }}</span>
            <ion-label class="label">{{ category.Qtd }}</ion-label>

          </div>

        </div>

        <div class="pl-10">
          <button class="icon-z icon-most-searched-word-open cursor-pointer" ion-button icon-only>
            <ion-icon *ngIf="ThemeService.currentTheme == 'default' "
              src="assets/images/theme/blue/icons-most-searched-words-open.svg" class="icon" slot="end"></ion-icon>
            <ion-icon *ngIf="ThemeService.currentTheme != 'default' "
              src="assets/images/icons-most-searched-words-open.svg" class="icon" slot="end"></ion-icon>
          </button>
        </div>


      </div>
    </div>

  </div>


</ion-header>

<ion-content class="main-content">

  <div class="height-100">
    <div class="d-flex seach-wrapper height-100 flex-row justify-space-between">

      <div class="d-flex height-100 overflow-y-auto flex-1" *ngIf="!showDocuments || windowWidth >= 1024"
        style="border-right: 1px solid #ddd7d7;">

        <!-- most searched word-->
        <div class="most-searched-words d-md-flex flex-md-column">
          <p class="title ion-text-center">{{searchResult}}</p>
          <div class="most-searched-word-container height-100 overflow-y-auto">
          </div>
        </div>
      </div>

      <div class=" height-100 overflow-y-auto flex-1" *ngIf="showDocuments">
        <div>

          <!-- search result document-->
          <div class="search-result">
            <div class="header">
              <div class="d-flex ion-justify-content-between ion-align-items-center">
                <div class="d-flex sort-by ion-justify-content-center">
                  <p>
                    <span class="order">Order por:</span>
                    <span *ngIf="ordinance == 'recent'" class="order-labal">Mais Recentes</span>
                    <span *ngIf="ordinance == 'old'" class="order-labal">Mais Antigos</span>
                  </p>
                </div>
                <div>
                  <div *ngIf="ordinance == 'old'" (click)="reorderList('recent')">
                    <ion-icon *ngIf="ThemeService.currentTheme == 'default' "
                      src="assets/images/icons-arrow-arrow-up.svg"></ion-icon>
                    <ion-icon *ngIf="ThemeService.currentTheme == 'gov' "
                      src="assets/images/theme/gov/icons-arrow-arrow-up.svg"></ion-icon>
                  </div>

                  <div *ngIf="ordinance == 'recent'" (click)="reorderList('old')">
                    <ion-icon *ngIf="ThemeService.currentTheme == 'default' "
                      src="assets/images/icons-arrow-arrow-down-25.svg"></ion-icon>
                    <ion-icon *ngIf="ThemeService.currentTheme == 'gov' "
                      src="assets/images/theme/gov/icons-arrow-arrow-down.svg"></ion-icon>
                  </div>
                </div>
              </div>
            </div>
            <ul>
              <li *ngFor="let searchDocument of showSearchDocuments"
                (click)="viewDetail( searchDocument); selectItem(searchDocument)" class="d-flex cursor-pointer">
                <div class="icon">
                  <ion-icon
                    *ngIf="itemIcons() == 'AccoesPresidenciais & ArquivoDespachoElect'  && ThemeService.currentTheme == 'default' "
                    src="assets/images/icons-search-document.svg"></ion-icon>
                  <ion-icon
                    *ngIf="itemIcons() == 'AccoesPresidenciais & ArquivoDespachoElect'  && ThemeService.currentTheme == 'gov'"
                    src="assets/images/theme/gov/icons-search-document.svg"></ion-icon>
                  <ion-icon *ngIf="itemIcons() == 'Agenda' " src="assets/images/icons-default-agenda.svg"></ion-icon>
                  <ion-icon *ngIf="itemIcons() == 'AccoesPresidenciais' && searchDocument.DocTypeDesc == 'Publicações'"
                    src="assets/images/icons-viagem.svg"></ion-icon>

                  <ion-icon
                    *ngIf="ThemeService.currentTheme == 'gov' && itemIcons() == 'AccoesPresidenciais' && searchDocument.DocTypeDesc == 'Acções' "
                    slot="end" src='assets/images/theme/gov/icons-nav-actions.svg'></ion-icon>
                  <ion-icon
                    *ngIf="ThemeService.currentTheme == 'doneIt' && itemIcons() == 'AccoesPresidenciais' && searchDocument.DocTypeDesc == 'Acções' "
                    slot="end" src='assets/images/theme/{{ThemeService.currentTheme}}/icons-nav-actions.svg'></ion-icon>
                  <ion-icon
                    *ngIf="ThemeService.currentTheme == 'default' && itemIcons() == 'AccoesPresidenciais' && searchDocument.DocTypeDesc == 'Acções' "
                    slot="end" src='assets/images/icons-nav-actions.svg'></ion-icon>

                </div>
                <div class="content ion-align-items-center">

                  <div class="d-flex ion-justify-content-between ">
                    <span class="result-name">{{ searchDocument.subject || searchDocument.Assunto}}</span>
                    <span class="app-name"
                      *ngIf="itemIcons() == 'AccoesPresidenciais & ArquivoDespachoElect' || itemIcons() == 'Correspondencia' ">

                      <span class="app-name" *ngIf="searchDocument.applicationId  == '8' || searchDocument.applicationId  == 8 || searchDocument.ApplicationType == 8">  Correspondencia </span>
                      <span class="app-name" *ngIf="searchDocument.applicationId == '386' || searchDocument.applicationId == 386 || searchDocument.ApplicationType == 386"> AccoesPresidenciais </span>
                      <span class="app-name" *ngIf="searchDocument.applicationId == '361' || searchDocument.applicationId == 361 || searchDocument.ApplicationType == 361 "> ArquivoDespachoElect </span>

                    </span>
                  </div>
                  <div class="d-flex ion-justify-content-between ">
                    <span class="organic-entity">{{ searchDocument.entity || searchDocument.EntidadeOrganicaNome  }}</span>
                    <span class="documente-date">{{ formateIsoDate(searchDocument.dateEntry || searchDocument.Data) }}</span>
                  </div>

                  <div *ngIf="select" (click)="view(searchDocument)" style="color: gray;">
                    <button mat-icon-button  aria-label="Example icon-button with a menu">
                      <ion-icon  src="assets/icon/iconmonstr-eye-9.svg" class=" font-25-em "></ion-icon>
                    </button>
                  </div>
                </div>
              </li>

            </ul>
          </div>

          <!-- <a href="#" class="previous">&laquo; Previous</a>
          <a href="#" class="next">Next &raquo;</a> -->
          <div class="nextAndPreviewDiv" *ngIf="type != 'Agenda' && select == true  ">
            <a href="#" class="previous round" (click)="previeweButton($event)">&#8249;</a>
            <div>{{pageNumber + "/" + totalPage}}</div>
            <a href="#" class="next round" (click)="nextButton($event)" >&#8250;</a>
          </div>

        </div>

      </div>

    </div>
  </div>

</ion-content>

import { Injectable } from '@angular/core';
import { EventPerson } from '../models/eventperson.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { LoginUserRespose } from '../models/user.model';
import { OrganicEntity } from 'src/app/models/organic-entity.model';
import { SessionStore } from '../store/session.service';
import { ChangeProfileService } from './change-profile.service';

@Injectable({
  providedIn: 'root'
})
export class OrganicEntityService {

  authheader = {};
  loggeduser: LoginUserRespose;
  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    user: AuthService,
    private changeProfileService: ChangeProfileService) { 

      this.setHeader()
      this.changeProfileService.registerCallback(() => {
        this.setHeader()
      })
  }

  setHeader() {
    this.loggeduser = SessionStore.user;
    this.headers = new HttpHeaders();;
    this.headers = this.headers.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
  }

  getOrganicEntity(): Observable<OrganicEntity[]>{

    const geturl = environment.apiURL + 'ecm/organic';
 
    let options = { 
      headers: this.headers, 
    };

    return this.http.get<OrganicEntity[]>(`${geturl}`, options);
  }
}

import { Injectable } from '@angular/core';
import { roomListDetermineChanges } from '../../../data/async/list/rooms/roomListChangeDetector';
import { captureAndReraiseAsync } from 'src/app/services/decorators/captureAndReraiseAsync';
import { CronJobService } from 'src/app/utils/task-scheduler'
import { z } from "zod";
import { IRoomRemoteRepository } from 'src/app/core/chat/repository/room/room-remote-repository';
import { IRoomLocalRepository } from 'src/app/core/chat/repository/room/room-local-repository';
import { MessageEntitySchema } from 'src/app/core/chat/entity/message';
import { GetRoomListMapper } from 'src/app/core/chat/mapper/getRoomListMapper';


const CreatedBySchema = z.object({
  wxUserId: z.number(),
  wxFullName: z.string(),
  wxeMail: z.string().email(),
  userPhoto: z.string().nullable()// api check
});

const RoomListItemOutPutDTOSchema = z.object({
  chatRoom: z.object({
    id: z.string(),
    roomName: z.string(),
    createdBy: CreatedBySchema,
    createdAt: z.string(),
    expirationDate: z.string().nullable(), // api check
    roomType: z.number(),
    messages: MessageEntitySchema.array(),
    user1: CreatedBySchema.nullable(),
    user2: CreatedBySchema.nullable()
  }),
  joinAt: z.string()
})


// Define the schema for the entire response
export const RoomListOutPutDTOSchema = z.object({
  success: z.boolean(),
  message: z.string(),
  data: z.array(RoomListItemOutPutDTOSchema),
});

export type RoomListItemOutPutDTO = z.infer<typeof RoomListItemOutPutDTOSchema>

export type RoomListOutPutDTO = z.infer<typeof RoomListOutPutDTOSchema>


@Injectable({
  providedIn: 'root'
})
export class GetRoomListUseCaseService {

  constructor(
    private roomRemoteDataSourceService: IRoomRemoteRepository,
    private roomLocalDataSourceService: IRoomLocalRepository,
    private CronJobService: CronJobService
  ) { }

  @captureAndReraiseAsync('RoomRepositoryService/list')
  async execute() {
    const result = await this.roomRemoteDataSourceService.getRoomList()

    const localList = await this.roomLocalDataSourceService.findAll()

    if(localList.isOk()) {
      if(result.isOk()) {

        const { roomsToDelete, roomsToInsert, roomsToUpdate } = roomListDetermineChanges(result.value.data, localList.value)

        if(roomsToInsert) {
          const roomsToInsertEntity = GetRoomListMapper.toDomain(roomsToInsert)
          for( const room of  roomsToInsertEntity) {
            this.roomLocalDataSourceService.insert(room)
            if(room.expirationDate) {
              this.CronJobService.createCronJob('remove expired room', new Date(room.expirationDate), this.execute)
            }
          }
        }

        const roomsToUpdateEntity = GetRoomListMapper.toDomain(roomsToUpdate)
        this.roomLocalDataSourceService.updateMany(roomsToUpdateEntity)

        for( const room of  roomsToDelete) {
          this.roomLocalDataSourceService.delete(room.id)
        }

      }
    }
    return result
  }

}

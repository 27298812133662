import { Injectable } from '@angular/core';
import { captureAndReraiseAsync } from 'src/app/services/decorators/captureAndReraiseAsync';
import { RoomRemoteDataSourceService } from '../../../data/repository/room/room-remote-repository.service';
import { MemberListRemoteRepository } from '../../../data/repository/member/member-list-remote-repository.service';
import { UserRemoveListInputDTO } from '../room/room-leave-by-id-use-case.service';

@Injectable({
  providedIn: 'root'
})
export class RemoveMemberUseCaseService {

  constructor(
    private memberRemoteDataSourceService: MemberListRemoteRepository,
  ) { }


  @captureAndReraiseAsync('RoomRepositoryService/removeMemberToRoom')
  async execute(data: UserRemoveListInputDTO) {
    const result = await this.memberRemoteDataSourceService.removeMemberFromRoom(data)

    return result
  }
}

import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, PopoverController, Platform } from '@ionic/angular';
import { EventBody } from 'src/app/models/eventbody.model';
import { EventsService } from 'src/app/services/events.service';
import { Event } from '../../../models/event.model';
import { EditEventPage } from '../edit-event/edit-event.page';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';
import { EliminateEventPage } from 'src/app/modals/eliminate-event/eliminate-event.page';
import { ExpedientTaskModalPageNavParamsTask } from 'src/app/models/ExpedientTaskModalPage';
import { DocumentSetUpMeetingPage } from 'src/app/modals/document-set-up-meeting/document-set-up-meeting.page';
import { ExpedientTaskModalPage } from '../../gabinete-digital/expediente/expedient-task-modal/expedient-task-modal.page';
import { ViewDocumentPage } from 'src/app/modals/view-document/view-document.page';
import { ThemeService } from 'src/app/services/theme.service'
import { RouteService } from 'src/app/services/route.service';
import { SessionStore } from 'src/app/store/session.service';
import { HttpErrorHandle } from 'src/app/services/http-error-handle.service'
import { AgendaDataRepositoryService } from 'src/app/module/agenda/data/repository/agenda-data-repository.service';
import { TracingType, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';
import { isHttpError } from 'src/app/services/http.service';
import { PermissionService } from 'src/app/services/permission.service';
import { DeleteEventRecurrencePage, EventDeleteRecurrenceComponentReturn } from 'src/app/modals/delete-event-recurrence/delete-event-recurrence.page';

@Component({
  selector: 'app-view-event',
  templateUrl: './view-event.page.html',
  styleUrls: ['./view-event.page.scss'],
})

export class ViewEventPage implements OnInit {

  loadedEvent: Event;
  isEventEdited: boolean;
  eventBody: EventBody;
  loadedAttachments: any;
  pageId: string;
  showLoader: boolean;

  minDate: Date;

  profile: string;
  eventId: string;
  CalendarId: string;
  caller: string;
  customDate: any;
  today: any;

  months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
  days = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];

  dicIndex = 0;
  isModal = false
  header = true
  task: ExpedientTaskModalPageNavParamsTask;
  LoadedDocument: any = null;
  sesseionStora = SessionStore

  TimeZoneString = ''

  constructor(
    private modalController: ModalController,
    public eventsService: EventsService,
    public alertController: AlertController,
    public popoverController: PopoverController,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    public platform: Platform,
    public ThemeService: ThemeService,
    private RouteService: RouteService,
    private httpErrorHandle: HttpErrorHandle,
    private agendaDataRepository: AgendaDataRepositoryService,
    public p: PermissionService,
  ) {


    this.isEventEdited = false;
    this.loadedEvent = new Event();
    this.eventBody = { BodyType: "1", Text: "" };
    this.loadedEvent.Body = this.eventBody;

    this.activatedRoute.paramMap.subscribe(params => {
      this.eventId = params['params'].eventId;

      const urlParams = new URLSearchParams(window.location.search);
      this.CalendarId = urlParams.get('CalendarId');

      this.eventId = this.eventId.replace(' ', '')

      if (params["params"].caller) {
        this.caller = (params["params"].caller);
      }

      if (params["params"].isModal) {
        this.isModal = params["params"].isModal
      }

      if (params["params"].header) {
        this.header = params["params"].header
      }
    });

  }

  ngOnInit() {
    this.loadEvent();


    window.onresize = (event) => {
      // if not mobile remove all component
      if (window.innerWidth >= 1024) {
        this.modalController.dismiss(this.isEventEdited);
      }
    };

  }


  close() {

    this.modalController.dismiss(this.isEventEdited);
  }

  goBack() {

    if (this.isModal) {
      this.close()
    } else {
      this.activatedRoute.paramMap.subscribe(params => {
        if (params["params"].caller == 'expediente') {
          window.history.back();
        }
        else {
          // this.router.navigate(['/home', params["params"].caller]);
          this.RouteService.goBack();

        }
      });
    }

  }

  doRefresh(ev) {
    this.loadEvent();
    ev.target.complete();
  }

  setTimeZone() {
    this.TimeZoneString = this.loadedEvent.TimeZone
  }

  @XTracerAsync({name:'mobile/loadEventDetails', bugPrint: true})
  async loadEvent(tracing?: TracingType) {
    const loader = this.toastService.loading();

    let res = await this.agendaDataRepository.getEventById(this.eventId, tracing)

    if (res.isOk()) {
      tracing.setAttribute('outcome', 'success')
      console.log('Loaded Event', res.value)
      loader.remove()
      /* let changeDate = this.dateService.fixDate(res.value as any) as any */
      this.loadedEvent = res.value as any;
      this.setTimeZone()
    } else {

      tracing.setAttribute('eventId', this.eventId)
      if(!isHttpError(res.error)) {
        this.toastService._badRequest('Pedimos desculpa mas não foi possível executar a acção. Por favor, contacte o apoio técnico. #13')
        console.log(res.error)
      } else if (isHttpError(res.error)) {
        if(res.error.status == 404) {
          this.toastService._badRequest('Este evento já não existe')
        } else {
          this.httpErrorHandle.httpStatusHandle(res.error)
        }
      }


      tracing.setAttribute('outcome', 'failed')

      // this.toastService.badRequest('Este evento já não existe na sua agenda')
      this.RouteService.goBack();
    }

    loader.remove()

  }

  async deleteYesOrNo() {
    if (this.loadedEvent.IsRecurring) {

      const modal = await this.modalController.create({
        component: DeleteEventRecurrencePage,
        componentProps: {},
        cssClass: 'event-recurrence-modal',

      });

      modal.onDidDismiss().then((res) => {

        const data: EventDeleteRecurrenceComponentReturn = res.data
        if(data =='DeleteAll') {
          this.deleteEvent(true);

        } else if (data == 'DeleteOne') {
          this.deleteEvent(false);
        } else if(data == 'Cancel') {
          this.close()
        } else {
          this.close()
        }
      });

      await modal.present();

    } else {
      this.alertController.create({
        header: 'Eliminar evento?',
        message: '',
        buttons: [
          {
            text: 'Sim',
            handler: (data) => {
              this.deleteEvent(false);
            }
          },
          {
            text: 'Não',
            handler: () => {
              // sconsole.log('Let me think');
            }
          }
        ]
      }).then(res => {
        res.present();
      });
    }

  }

  async deleteEvent(deleteAll) {

    const loader = this.toastService.loading()


    console.log(this.loadedEvent.EventId)
    const result = await this.agendaDataRepository.deleteEvent(this.loadedEvent.EventId,deleteAll)//.subscribe(async () => {
    if(result.isOk()) {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Evento removido',
        buttons: ['OK']
      });

      setTimeout(() => {
        alert.dismiss();
      }, 1500);
      this.goBack();
      this.httpErrorHandle.httpsSucessMessagge('delete event')
    } else {

      console.log('delete event error: ', result.error)
      this.httpErrorHandle.httpStatusHandle(result.error)
    }


    loader.remove();
  }


  async OpenDeleteEventModal() {

    const modal = await this.modalController.create({
      component: EliminateEventPage,
      componentProps: {
        eventId: this.loadedEvent.EventId,
        caller: this.caller,
      },
      cssClass: 'discart-expedient-modal',

    });

    modal.onDidDismiss().then((res) => {


      if (res) {
        setTimeout(() => {
          /* this.loadEvent(); */
          this.loadEvent()
        }, 250);
        this.isEventEdited = true;
      }
    });

    await modal.present();
  }


  toDateString(e) {
    return new Date(e).toDateString()
  }

  async editEventDetail() {

    const modal = await this.modalController.create({
      component: EditEventPage,
      componentProps: {
        eventId: this.loadedEvent.EventId,
        caller: this.caller,
        closeModal: true
      },
      cssClass: 'modal modal-desktop',

    });
    await modal.present();
    modal.onDidDismiss().then((res) => {



      if (res.data?.action == 'cancel') {

        console.log('res', res)
      } else {
        this.loadEvent()
        this.isEventEdited = true;

        // if (res.data.Attendees?.length >= 1) {
        //   this.loadedEvent.HasAttachments = true
        //   // this.getAttachments()
        // }
        console.log('res', res)
      }

    });

  }

  async editEvent() {


    let classs;
    if (window.innerWidth <= 800) {
      classs = 'modal modal-desktop'
    } else {
      classs = 'modal modal-desktop showAsideOptions'
    }

    const modal = await this.modalController.create({
      component: EditEventPage,
      componentProps: {
        event: this.loadedEvent,
        caller: this.caller,
      },
      cssClass: classs,
    });

    modal.onDidDismiss().then((res) => {


      if (res.data?.action == 'cancel') {

        console.log('res', res)
      } else {
        this.loadEvent()
        this.isEventEdited = true;

        // if (res.data.Attendees?.length >= 1) {
        //   this.loadedEvent.HasAttachments = true
        //   // this.getAttachments()
        // }
        // console.log('res', res)
      }

    });

    await modal.present();
  }

  docIndex(index: number) {
    this.dicIndex = index
  }


  async LoadDocumentDetails() {

    const docId = this.loadedEvent.Attachments[this.dicIndex].SourceId
    const applicationId: any = this.loadedEvent.Attachments[this.dicIndex].ApplicationId
    const selectedDoc = this.loadedEvent.Attachments[this.dicIndex]



    this.task = {
      serialNumber: '',
      taskStartDate: '',
      isEvent: true,
      workflowInstanceDataFields: {
        FolderID: '',
        Subject: selectedDoc.SourceName,
        SourceSecFsID: selectedDoc.ApplicationId || selectedDoc['ApplicationID'],
        SourceType: 'DOC',
        SourceID: selectedDoc.SourceId,
        DispatchNumber: ''
      }
    }


    const modal = await this.modalController.create({
      component: ViewDocumentPage,
      componentProps: {
        trustedUrl: '',
        file: {
          title: this.task.workflowInstanceDataFields.Subject,
          url: '',
          title_link: '',
        },
        Document: this.loadedEvent.Attachments[this.dicIndex],
        applicationId: this.task.workflowInstanceDataFields.SourceSecFsID,
        docId: selectedDoc.SourceId,
        folderId: '',
        task: this.task
      },
      cssClass: 'modal modal-desktop'
    });
    await modal.present();
  }

  async openBookMeetingModal() {

    let classs;
    if (window.innerWidth < 701) {
      classs = 'book-meeting-modal modal modal-desktop'
    } else {
      classs = 'modal modal-desktop showAsideOptions'
    }
    const modal = await this.modalController.create({
      component: DocumentSetUpMeetingPage,
      componentProps: {
        subject: this.task.workflowInstanceDataFields.Subject,
        document: this.loadedEvent.Attachments[this.dicIndex],
      },
      cssClass: classs,
      backdropDismiss: false
    });

    modal.onDidDismiss().then(res => {
      //this.RouteService.goBack();
    });
    await modal.present();
  }

  // efetuar despacho
  async openExpedientActionsModal(taskAction: any) {

    let classs;
    if (window.innerWidth < 701) {
      classs = 'modal modal-desktop'
    } else {
      classs = 'modal modal-desktop showAsideOptions'
    }
    const modal = await this.modalController.create({
      component: ExpedientTaskModalPage,
      componentProps: {
        taskAction: taskAction,
        task: this.task,
        seachDocuments: this.loadedEvent.Attachments[this.dicIndex],
        aplicationId: this.loadedEvent.Attachments[this.dicIndex].ApplicationId || this.loadedEvent.Attachments[this.dicIndex]['ApplicationID']
      },
      cssClass: classs,
    });
    await modal.present();
    modal.onDidDismiss().then(async (res) => { });

  }



  getFromDb() {

  }

  stringToNumber(number: string) {
    return parseInt(number)
  }
}

<ion-content class="container width-100 ">
  <div *ngIf="content == ''" class="buttons header-fix">
    <button (click)="openExpedientActionsModal('0')" class="btn-cancel" shape="round" >Efetuar Despacho</button>
    <div class="solid"></div>
    <button (click)="openExpedientActionsModal('1')"  class="btn-cancel" shape="round" >Solicitar Parecer</button>
    <button *ngIf="!p.userRole(['PR']) && !p.userPermission([p.permissionList.Gabinete.pr_tasks])" (click)="openExpedientActionsModal('2')" class="btn-cancel" shape="round" >Pedido de Deferimento</button>
    <button (click)="openBookMeetingModal()" class="btn-cancel" shape="round" >Marcar Reunião</button>
    <button class="btn-cancel" shape="round"  (click)="close()">Cancelar</button>
  </div>
  <div *ngIf="content != ''" class="buttons header-fix">
    <!-- <button (click)="save(Document, content)" class="btn-cancel" shape="round" >Salvar</button> -->
    <button *ngIf="p.userRole('PR')" (click)="AssinarDraft()" class="btn-cancel" shape="round" >Assinar</button>
    <button class="btn-cancel" shape="round"  (click)="close()">Cancelar</button>
  </div>
</ion-content>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { Platform } from '@ionic/angular';
import { SignalRConnection, SocketMessage } from './signalR';
import { Plugins } from '@capacitor/core';
import { switchMap } from 'rxjs/operators';
import { Result } from 'neverthrow';
import { HubConnection } from '@microsoft/signalr';
import { ISignalRInput } from '../type';

const { App } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private connection!: SignalRConnection;
  private connectingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private sendDataSubject: BehaviorSubject<{method: string, data: any}> = new BehaviorSubject<{method: string, data: any}>(null);

  private deadConnectionBackGround: Subject<any>;

  constructor(private platform: Platform) {

    this.deadConnectionBackGround = new Subject()
    this.deadConnectionBackGround.pipe(
      switchMap(() => timer(150000)), // 2 minutes 30 seconds
    ).subscribe(() => {
      console.log('trigger new connections')
      this.newConnection()
    })

    try {
      if (!this.platform.is('desktop')) {
        App.addListener('appStateChange', ({ isActive }) => {
          if (isActive) {
            // The app is in the foreground.
            // console.log('App is in the foreground');
            this.deadConnectionBackGround.next()

          }
        });
      } else {
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible') {
            this.deadConnectionBackGround.next()
          }
      });
      }
    } catch(error) {}
  }

  async establishConnection(): Promise<Result<HubConnection, false>>  {

    // const connection = new SignalRConnection({url:'https://41e3-41-63-166-54.ngrok-free.app/api/v2/chathub'})
    const connection = new SignalRConnection({url:'https://gdapi-dev.dyndns.info/stage/api/v2/chathub'})
    const attempConnection = await connection.establishConnection()

    if(attempConnection.isOk()) {
      console.log('connect')
      this.connection?.closeConnection()
      this.connection = connection

      this.connection.getData().subscribe((data) => {
        this.sendDataSubject.next(data)
        this.deadConnectionBackGround.next()
      })

      this.connection.getConnectionState().subscribe((data) => {
        this.connectingSubject.next(data)
      })

      return attempConnection
    } else {

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(this.establishConnection())
        }, 2000)
      })
    }
  }

  sendData<T>(input: ISignalRInput) {
    return this.connection.sendData<T>(input)
  }

  join() {
    return this.connection.join()
  }

  getData<T>() {
    return this.sendDataSubject.asObservable() as BehaviorSubject<{method: string, data: T}>
  }

  public getConnectionState(): Observable<boolean> {
    return this.connectingSubject.asObservable();
  }

  newConnection() {
    this.establishConnection()
  }

}

<!-- <ion-header>
  <ion-toolbar>
    <ion-title>swiper</ion-title>
  </ion-toolbar>
</ion-header> -->

<div>
  <swiper-container id="C{{componentId}}" #swipers  (click)="click()" [navigation]="navigation" autoHeight="true">
    <swiper-slide *ngFor="let files of publicationList.Files let k = index" style="width: 100% !important;">
      <div >
        <img  *ngIf="checkFileType.checkFileType(files.FileExtension ) == 'image'" class="post-img"
          [src]="'data:image/jpg;base64,' + files.FileBase64" loading="lazy">

        <video #videoElements [appVisibility]="onVisibilityChange" *ngIf="checkFileType.checkFileType(files.FileExtension ) == 'video'" class="post-video"  controls="controls" preload="none"
        playsinline webkit-playsinline="webkit-playsinline" (play)="stopvideoService.registerVideoWithEvent($event)"q>
          <source [src]="files.FileBase64" type="video/mp4">
        </video>

       <!--  <button class="play-button" *ngIf="!videoPlaying && checkFileType.checkFileType(files.FileExtension ) == 'video'">
          ▶
        </button> -->

      </div>
    </swiper-slide>

  </swiper-container>


</div>

<ion-footer>
  <div *ngIf="pagination && publicationList?.Files?.length > 1" class="dots-container">
    <span *ngFor="let files of publicationList.Files; let k = index"
      [class.dotsSwiper]="true"
      [class.active-dot]="swiperIndex === k"
      (click)="goToSlide(k)"
      >
    </span>
  </div>
</ion-footer>

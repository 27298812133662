import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoginUserRespose } from '../models/user.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DocumentSetUpMeeting } from '../models/CallMeeting';
import { Excludetask } from '../models/Excludetask';
import { ExpedienteFullTask } from '../models/Expediente';
import { GetTasksListType } from '../models/GetTasksListType';
import { fullTaskList } from '../models/dailyworktask.model';
import { ChangeProfileService } from './change-profile.service';
import { SessionStore } from '../store/session.service';
@Injectable({
  providedIn: 'root'
})
export class ProcessesService {

  authheader = {};
  loggeduser: LoginUserRespose;
  headers: HttpHeaders;
  headers2: HttpHeaders;



  constructor(
    private http: HttpClient,
    private changeProfileService: ChangeProfileService
  ) {

    this.loggeduser = SessionStore.user;

    this.setHeader()
    this.changeProfileService.registerCallback(() => {
      this.loggeduser = SessionStore.user;
      this.setHeader()
    })



  }


  setHeader() {


    this.headers = new HttpHeaders();;

    this.headers = this.headers.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);

    this.headers2 = new HttpHeaders();;

    this.headers2 = this.headers2.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
  }

  uploadFile(formData: any) {

    //const geturl = environment.apiURL + 'Tasks/DelegateTask';
    const geturl = environment.apiURL + 'lakefs/UploadFiles';

    let options = {
      headers: this.headers
    };

    return this.http.post(`${geturl}`, formData, options);
  }

  GetTasksList(processname: typeof GetTasksListType, onlycount: boolean): Observable<fullTaskList[]> {
    const geturl = environment.apiURL + 'tasks/List';
    let params = new HttpParams();

    params = params.set("ProcessName", processname);
    params = params.set("OnlyCount", onlycount.toString());
    params = params.set("OnlyCount", onlycount.toString());
    params = params.set("pageNum", "1");
    params = params.set("pageSize", "500");

    let options = {
      headers: this.headers,
      params: params
    };

    return this.http.get<fullTaskList[]>(`${geturl}`, options);
  }

  // eventsToApprove(userid, caller) {
  //   const geturl = environment.apiURL + 'tasks/events-to-approve';
  //   let params = new HttpParams();

  //   params = params.set("pageNum", 1);
  //   params = params.set("pageSize", 500);
  //   params = params.set("userid", userid);

  //   let options = {
  //     headers: this.headers,
  //     params: params
  //   };

  //   return this.http.get<fullTaskList[]>(`${geturl}`, options);
  // }

  GetTaskListExpediente(onlycount1): Observable<ExpedienteFullTask[]> {
    const processname = "Expediente"
    const onlycount = false

    const geturl = environment.apiURL + 'tasks/List';
    let params = new HttpParams();

    params = params.set("ProcessName", processname);
    params = params.set("OnlyCount", onlycount.toString());

    let options = {
      headers: this.headers,
      params: params
    };

    return this.http.get<ExpedienteFullTask[]>(`${geturl}`, options);
  }

  GetTask(serialnumber: string): Observable<any> {

    const geturl = environment.apiURL + 'Tasks/FindTask';
    let params = new HttpParams();

    params = params.set("serialNumber", serialnumber);

    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  GetDraftByID(id: string): Observable<any> {

    const geturl = environment.apiPCURL + `Documents/${id}`;
    let params = new HttpParams();

    /* params = params.set("serialNumber", serialnumber); */

    let options = {
      headers: this.headers2,
      /* params: params */
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  SaveDraftByID(id: string, object): Observable<any> {
    const geturl = environment.apiPCURL + `Documents/${id}`;
    let params = new HttpParams();

    /* params = params.set("serialNumber", serialnumber); */

    let options = {
      headers: this.headers2,
      /* params: params */
    };
    return this.http.put<any>(`${geturl}`, object, options);
  }

  SetTaskToPending(serialNumber: string): Observable<any> {
    const geturl = environment.apiURL + 'Tasks/SetTaskPending';
    let params = new HttpParams();

    params = params.set("serialNumber", serialNumber);

    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.post<any>(`${geturl}`, '', options);
  }

  GetPendingTasks(onlyCount: boolean) {
    const geturl = environment.apiURL + 'Tasks/ListPending';
    let params = new HttpParams();

    params = params.set("onlyCount", onlyCount.toString());

    let options = {
      headers: this.headers,
      params: params
    };

    return this.http.get<any>(`${geturl}`, options);
  }

  DelegateTask(body: any) {
    const geturl = environment.apiURL + 'Tasks/DelegateTask';

    let options = {
      headers: this.headers,
    };
    return this.http.post<any>(`${geturl}`, body, options);
  }

  GetTaskParticipants(folderId): Observable<any> {
    const geturl = environment.apiURL + 'Processes/GetUsersInDispash';
    let params = new HttpParams();

    params = params.set("folderId", folderId);

    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  FindTaskDocId(serialnumber: string): Observable<any> {
    const geturl = environment.apiURL + 'Tasks/FindExpedienteDocId';
    let params = new HttpParams();

    params = params.set("serialNumber", serialnumber);

    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  GetMDOficialTasks(): Observable<any> {
    const geturl = environment.apiURL + 'tasks/GetMDOficialTasks';
    let options = {
      headers: this.headers,
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  GetMDPersonalTasks(): Observable<any> {
    const geturl = environment.apiURL + 'tasks/GetMDPersonalTasks';
    let options = {
      headers: this.headers,
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  GetToApprovedEvents(categoryname: string, count: string): Observable<any> {
    const geturl = environment.apiURL + 'Tasks/ListByCategory';
    let params = new HttpParams();

    params = params.set("categoryname", categoryname);
    params = params.set("onlyCount", count);

    let options = {
      headers: this.headers,
      params: params,
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  PostTaskAction(body: any) {
    const geturl = environment.apiURL + 'Tasks/Complete';

    let options = {
      headers: this.headers,
    };
    return this.http.post<any>(`${geturl}`, body, options)
  }

  presidentialActionsSignature(body) {
    const geturl = environment.apiURL + 'presidentialActions/signature';

    let options = {
      headers: this.headers,
    };
    return this.http.post<any>(`${geturl}`, body, options)
  }

  CompleteTask(body: Excludetask) {

    // double check
    const geturl = environment.apiURL + 'Tasks/CompleteTask';

    let options = {
      headers: this.headers,
    };
    return this.http.post<any>(`${geturl}`, body, options)
  }

  CompleteParecerPrTask(body: any) {
    const geturl = environment.apiURL + 'Tasks/CompleteTaskParecerPr';

    let options = {
      headers: this.headers,
    };
    return this.http.post<any>(`${geturl}`, body, options)
  }

  UpdateTaskStatus(FolderId: any): Observable<any> {
    const geturl = environment.apiURL + 'Tasks/UpdateTaskStatus';
    let params = new HttpParams();

    params = params.set("FolderId", FolderId);

    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.post<any>(`${geturl}`, '', options);
  }

  GetDocumentUrl(DocId: string, FsId: string): Observable<any> {
    const geturl = environment.apiURL + 'ecm/document/viewrequestshort';
    let params = new HttpParams();

    params = params.set("DocId", DocId);
    params = params.set("applicationid", FsId);

    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  postDespatcho(body: any) {
    const geturl = environment.apiURL + 'Processes/CreateDispatch';
    let options = {
      headers: this.headers,
    };
    return this.http.post<any>(`${geturl}`, body, options)
  }

  postDespatchoPr(body: any) {
    const geturl = environment.apiURL + 'Processes/CreateDispatchPR';
    let options = {
      headers: this.headers,
    };
    return this.http.post<any>(`${geturl}`, body, options)
  }

  postParecer(body: any) {

    try {
      if (!body.AttachmentList) {
        body.AttachmentList = {}
      }
      if (!body?.dataFields) {
        body.dataFields = {}
      }
      if (!body.dataFields?.DraftIds) {
        body.dataFields.DraftIds = ""
      }
      if (!body.AttachmentList?.DraftIds) {
        body.AttachmentList.DraftIds = ""
      }
    } catch (error) { }


    const geturl = environment.apiURL + 'Processes/CreateParecer';
    let options = {
      headers: this.headers,
    };
    return this.http.post<any>(`${geturl}`, body, options)
  }

  postParecerPr(body: any) {
    try {
      if (!body.AttachmentList) {
        body.AttachmentList = {}
      }
      if (!body?.dataFields) {
        body.dataFields = {}
      }
      if (!body.dataFields?.DraftIds) {
        body.dataFields.DraftIds = ""
      }
      if (!body.AttachmentList?.DraftIds) {
        body.AttachmentList.DraftIds = ""
      }
    } catch (error) { }


    const geturl = environment.apiURL + 'Processes/CreateParecerPR';
    let options = {
      headers: this.headers,
    };
    return this.http.post<any>(`${geturl}`, body, options)
  }

  postDeferimento(body: any) {
    const geturl = environment.apiURL + 'Processes/CreateDeferimento';
    let options = {
      headers: this.headers,
    };
    return this.http.post<any>(`${geturl}`, body, options)
  }

  GetActionsList() {
    const geturl = environment.apiURL + 'presidentialActions';
    let options = {
      headers: this.headers,
    };


    return this.http.get<any>(`${geturl}`, options);
  }

  GetSubjectType() {
    const geturl = environment.apiURL + 'ecm/SubjectType';
    let options = {
      headers: this.headers,
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  GetDocumentDetails(DocId: string, FsId: string) {

    const geturl = environment.apiURL + 'search/documents';
    let params = new HttpParams();

    params = params.set("docId", DocId);
    params = params.set("applicationId", FsId);

    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  documentSetUpMeeting(body: DocumentSetUpMeeting) {

    let url = environment.apiURL + 'Processes/CallMeeting';
    url = url.replace('/V4/', '/V5/')

    let options: any = {
      headers: this.headers,
    }
    return this.http.post<any>(`${url}`, body, options);
  }

  DocumentDetail(DocId: string, FsId: string) {

    const geturl = environment.apiURL + 'ecm/GetDocument';
    let params = new HttpParams();

    params = params.set("docId", DocId);
    params = params.set("applicationId", FsId);
    params = params.set("externalAppId ", 101);
    params = params.set("SourceLocation ", 17);

    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  getFileBase64(DocId: string | number) {
    let url = environment.apiURL + 'ecm/document/file';

    let params = new HttpParams();

    params = params.set("docid", DocId);

    let options: any = {
      headers: this.headers,
      params
    }

    return this.http.get<any>(`${url}`, options);
  }



  GetViewer(DocId: string, FsId: string) {

    const geturl = environment.apiURL + 'ecm/document/viewfile';
    let params = new HttpParams();

    params = params.set("docId", DocId);
    params = params.set("applicationId", FsId);

    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.get<any>(`${geturl}`, options);
  }

  uploadFiles(file) {
    const geturl = environment.apiURL + 'Tasks/AttachDocImage';

    let options = {
      headers: this.headers,
    };
    return this.http.post<any>(`${geturl}`, file, options);
  }

  createEventToAprove(calendarType, agenda, body) {

    const geturl = environment.apiURL + 'Processes/CreateEvent/' + agenda;
    let params = new HttpParams();
    params = params.set('calendarName', calendarType);
    let options = {
      headers: this.headers,
      params: params
    };
    return this.http.post<any>(`${geturl}`, body, options);
  }

  generateInstaceFormatDate(): string {
    const data = new Date();

    const ano = data.getFullYear().toString().padStart(4, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const dia = data.getDate().toString().padStart(2, '0');

    const horas = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');
    const segundos = data.getSeconds().toString().padStart(2, '0');
    const milissegundos = data.getMilliseconds().toString().padStart(3, '0');

    const dataFormatada = `${ano}-${mes}-${dia}_${horas}:${minutos}:${segundos}.${milissegundos}`;

    return dataFormatada;
  }

}

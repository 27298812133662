import { Injectable } from '@angular/core';
import { ExpedienteTask } from '../models/dailyworktask.model';
import { ExpedienteTaskPipe } from '../pipes/expediente-task.pipe';
import { ProcessesService } from '../services/processes.service';
import { ProcessDocumentService } from './process-document.service';
import { LoaderService } from 'src/app/store/loader.service'

@Injectable({
  providedIn: 'root'
})
export class ExpedienteService {

  expedienteTaskPipe = new ExpedienteTaskPipe();
  loader = this.LoaderService.initialize("ExpedienteTaskPipe")

  constructor(
    private processes: ProcessesService,
    private processDocumentService: ProcessDocumentService,
    public LoaderService: LoaderService,
  ) { }


  discard({SerialNumber, Note =''}) {
    let otherbody = {
      "serialNumber": SerialNumber,
      "action": "Passivo",
      "ActionTypeId": 99999877,
      "dataFields": {
        "Note": Note,
      }
    }

    return this.processes.CompleteTask(otherbody)
  }

  viewDocument({DocId, ApplicationId}) {
    this.processDocumentService.viewDocument({DocId, ApplicationId})
  }

  CompleteTask({body}) {
    return this.processes.CompleteTask(body)
  }


  UpdateTaskStatus({FolderId}) {
    return this.processes.UpdateTaskStatus(FolderId)
  }


  async getList(): Promise<ExpedienteTask[]> {
    let expedientes
    this.loader.push()
    
    try {
      expedientes = await this.processes.GetTaskListExpediente(false).toPromise();
    } catch (error) {

      this.loader.pop()
      return []
    }
    
    this.loader.pop()

    expedientes = expedientes.filter(data => data.workflowInstanceDataFields.Status == "Active")
    expedientes = expedientes.filter((item) => {
      return item.activityInstanceName != 'Retificar Expediente'
    });
    expedientes = expedientes.map((element) => this.expedienteTaskPipe.transform(element));

    return expedientes;
  }
}

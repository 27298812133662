import { Filesystem } from '@capacitor/filesystem';
import { SendIntent } from "send-intent";
import { Router } from '@angular/router';
import { CallbackScheduler } from './callbackScheduler';
import { AlertController, Platform } from '@ionic/angular';

export class SendIntentService {

  Router!: Router

  callbackScheduler = new CallbackScheduler()
  alertController = new  AlertController()

  constructor(
  ) {
    
    SendIntent.checkSendIntentReceived().then((result: any) => {
      // logger
      if (result) {
        console.log('SendIntent received');
        console.log(JSON.stringify(result));
      }
      // event handler
      if (result.url) {
        this.onReceive(result)
        
      }
    }).catch(err => console.error(err));
  }

  private initialize() {
    this.callbackScheduler.start()
  }

  setRouteService(Router: Router) {
    this.Router = Router
    this.initialize()
  }


  private onReceive = this.callbackScheduler.function<any>((result)=> {
    this.Router.navigateByUrl("/home/publications").then(() => {
      if(Platform.prototype.is('ios')) {
        this.alertIos();
      }
      
    });

    window["sharedContent"] = result;
  })

  private alertIos() {
    this.alertController .create({
      header: 'Selecione uma acção para criar a publicação',
      message: '',
      buttons: [
        {
          text: 'Ok',
          handler: () => {
          }
        }
      ]
    }).then(res => {
      res.present();
    });
  }

}

export const sendIntent = new SendIntentService()

import { Injectable } from '@angular/core';
import { SignalRService } from 'src/app/infra/socket/signalR/signal-r.service';
import { SessionStore } from 'src/app/store/session.service';
import { filter, map } from 'rxjs/operators';
import { SocketMessage } from 'src/app/infra/socket/signalR/signalR';
import { ITypingRemoteRepository } from 'src/app/core/chat/repository/typing/typing-remote-repository';
import { z } from "zod"
import { InstanceId } from '../../../domain/chat-service.service';
import { v4 as uuidv4 } from 'uuid'

export const UserTypingDTOSchema = z.object({
  requestId: z.string(),
  roomId: z.string(),
  userId: z.number(),
  userName: z.string()
})
export type UserTypingDTO = z.infer<typeof UserTypingDTOSchema>



@Injectable({
  providedIn: 'root'
})
export class UserTypingRemoteRepositoryService implements ITypingRemoteRepository {

  constructor(
    private socket: SignalRService
  ) { }

  sendTyping(roomId: string) {
    return this.socket.sendData({
      method: 'Typing',
      data: {
        roomId,
        UserName:SessionStore.user.FullName,
        userId:SessionStore.user.UserId,
        requestId: InstanceId +'@'+ uuidv4(),
      },
    })
  }


  listenToTyping() {
    return this.socket.getData().pipe(
      filter((e) : e is SocketMessage<UserTypingDTO>=> e?.method == 'TypingMessage'
      ),
      map((e)=> e.data)
    )
  }

}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Event } from '../models/event.model';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { LoginUserRespose } from '../models/user.model';
import { EventSearch } from "src/app/models/event-search";
import { TopSearch } from 'src/app/models/top-search';
import { SessionStore } from '../store/session.service';
import { ChangeProfileService } from './change-profile.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  // state
  authheader = {};
  loggeduser: LoginUserRespose;
  headers: HttpHeaders;

  categories= Array;

  // setup
  constructor(
    private http: HttpClient, 
    user: AuthService,
    private changeProfileService: ChangeProfileService) {
    
    this.setHeader();
    
    this.changeProfileService.registerCallback(() => {
      this.setHeader()
    })
  }


  setHeader() {
    this.loggeduser = SessionStore.user;
    this.headers = new HttpHeaders();;
    this.headers = this.headers.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
  }


  basicSearch(subject:string, date:string = null, dateend:string = null, sender:number = null, organicEntity:number = null, docTypeId:string = null, applicationType:string): Observable<EventSearch>{
    // Endpoint
    const geturl = environment.apiURL + 'search';
    // store params
    let params = new HttpParams();
    // set https params
    

    params = params.set("assunto", subject);
    params = params.set("StartDate", date);
    params = params.set("EndDate", dateend);
    params = params.set("remetente", sender);
    params = params.set("entidadeOrganica", organicEntity);
    params = params.set("docTypeId", docTypeId);
    params = params.set("applicationType", applicationType);


    const options = { 
      headers: this.headers, 
      params: params 
    };

    return this.http.get<EventSearch>(`${geturl}`, options);
  }


  basicSearchPublication(subject:string, date:string = null, dateEnd:string = null, sender:number = null, organicEntity:number = null, docTypeId:string = null, applicationType:string): Observable<EventSearch>{
    // Endpoint
    const geturl = environment.apiURL + 'search';
    // store params
    let params = new HttpParams();
    // set https params
    

    params = params.set("assunto", subject);
    params = params.set("StartDate", date);
    params = params.set("EndDate", dateEnd);
    params = params.set("remetente", sender);
    params = params.set("entidadeOrganica", organicEntity);
    params = params.set("docTypeId", docTypeId);
    params = params.set("applicationType", applicationType);
    params = params.set("listOnlyFolders", true)


    const options = { 
      headers: this.headers, 
      params: params 
    };

    return this.http.get<EventSearch>(`${geturl}`, options);
  }

  mostSeachWord(size:string):Observable<any>{
    // Endpoint

    let UserId = (this.loggeduser.UserId).toString();

    const geturl = environment.apiURL + 'Processes/GetHitsByUserId';
    // store params
    let params = new HttpParams();
    // set https params

    params = params.set("userId", UserId);
    params = params.set("size", size);

    const options = {
      headers: this.headers, 
      params: params 
    };

    return this.http.get<any>(`${geturl}`, options);
  }

  searchForDoc(subject:string, date:string, sender:number, organicEntity:number, docTypeId:string, applicationType:string): Observable<EventSearch> {
    // Endpoint
    const geturl = environment.apiURL + 'search/attachment?';
    // store params
    let params = new HttpParams();
    // set https params
    

    params = params.set("assunto", subject);
    params = params.set("data", date);
    params = params.set("remetente", sender);
    params = params.set("entidadeOrganica", organicEntity);
    params = params.set("docTypeId", docTypeId);
    params = params.set("applicationType", applicationType);


    const options = { 
      headers: this.headers, 
      params: params 
    };

    return this.http.get<EventSearch>(`${geturl}`, options);
  }

}

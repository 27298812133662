import { Injectable } from '@angular/core';
import { NavigationEnd, Router, NavigationStart, NavigationExtras } from '@angular/router';
import { LocalstoreService } from '../store/localstore.service';
import { Location } from '@angular/common'

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  history: any = [];
  liveHistory = [];

  constructor(
    private router: Router,
    private LocalstoreService: LocalstoreService,
    private Location: Location
  ) {

    this.history = this.LocalstoreService.get('history', [])

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {

        const lastRoute = this.history.slice(-1)

        if (event.url != lastRoute) {
          this.history.push(event.url)
          this.liveHistory.push(event.url)
          this.reduceHistory()
        }

      }
    });

    window['RouteService'] = this

  }

  /**
   * @param url [string] incase no history to go back   */
  goBack(url = null) {
    if (this.history.length >= 2) {


      this.history.pop();
      const url = this.history.pop();

      this.goTo(url)

    } else if (url) {
      this.goTo(url)
    }

    this.reduceHistory()
  }

  goTo(url) {
    let navigationExtras: NavigationExtras = {
      queryParams: this.queryParams(url)
    }

    const urlObject = new URL('http://localhost:8100' + url);
    this.router.navigate([urlObject.pathname], navigationExtras);
  }


  reduceHistory() {
    if (this.history.length >= 15) {
      this.history = this.history.slice(5, this.history.length)
    }
    this.LocalstoreService.set('history', this.history)
  }


  queryParams(url) {

    const urlObject = new URL('http://localhost:8100' + url);

    const paramsString = urlObject.search;
    let searchParams: any = new URLSearchParams(paramsString);
    let params = {}

    for (let [key, value] of searchParams) {
      params[key] = value
    }


    return params

  }


  getLastRoute() {
    return this.history[this.history.length - 1]
  }

  getLastRouteA() {
    return this.history[this.history.length - 2]
  }

}

import { err, ok } from 'neverthrow';
import { ZodError, ZodSchema, z } from 'zod';
export function zodSafeValidation<T>(schema: ZodSchema, data: unknown) {
  const validation = (schema as ZodSchema<T>).safeParse(data)

  if(validation.success) {
    return ok(validation.data)
  } else {
    return err((validation.error))
  }
}

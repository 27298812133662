import { Injectable } from '@angular/core';
import { localstoreService } from './localstore.service'
import { SHA1 }  from  'crypto-js'
import { UserSession } from '../models/user.model';


@Injectable({
  providedIn: 'root'
})
class SessionService {

  // main data
  private _user = new UserSession()
  // local storage keyName
  private keyName: string;

  forceToLoginWithForceToLogInWithPassword = false

  permission = {
    Agenda: {
      access: false
    },
    Gabinete: {
      access: false,
      pr_tasks: false,
      md_tasks: false,
      aprove_event: false
    },
    Actions: {
      access : false,
      create : false,
      delete : false,
      edit : false,
      createPost : false,
      deletePost : false,
      editPost : false
    },
    Chat: {
      access: false
    }
  }

  hasPassLogin = false

  constructor() {

    this.keyName = (SHA1("SessionService")).toString()
    let restore = this.getDataFromLocalStorage()
    this._user = restore.user || new UserSession()

    if(this._user.LoginPreference == 'Pin') {
      this._user.Inactivity = false
    }

    window['clearS']  = () => {
      delete this._user.Authorization
    }

  }

  getDataFromLocalStorage() {
    return localstoreService.get(this.keyName, {})
  }

  get user(): UserSession {
    return this._user  || new UserSession()
  }

  get exist() {
    let restore = localstoreService.get(this.keyName, {})
    let user: UserSession = restore.user
    if(user) {
      if(user.Profile) {
        return true
      }
    }

    return false
  }

  setLoginPreference(loginPreference: 'None' | 'Password' | 'Pin' | null) {
    this._user.LoginPreference = loginPreference
    this.save()
  }

  setPin(pin: string) {
    this._user.PIN = SHA1(pin).toString()
    this.save()
  }

  validatePin(pin: string) {
    return this._user.PIN == SHA1(pin).toString()
  }

  needToValidateUser() {
    return this._user.Inactivity
  }


  isUserActive() {
    return this._user.Inactivity
  }

  setInativity(value: boolean) {
    this._user.Inactivity = value
    this._user.UrlBeforeInactivity = ''
    this.save()
  }

  setUrlBeforeInactivity(pathname: string) {
    this._user.UrlBeforeInactivity = pathname
    this.save()
  }

  get hasPin() {

    if(!this._user.PIN) {
      return false
    }
    return this._user.PIN.length >= 2

  }

  reset(user) {
    this._user = user

    this.setInativity(true)
    this.save()
  }

  delete() {
    localstoreService.delete(this.keyName)
    this.reset(new UserSession())
  }

  save() {

    localstoreService.set(this.keyName, {
      user: this._user
    })

  }


  get getInitials() {
    let names = this._user.FullName.split(' ') || ' ',
    initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  }


  get getManagerInitials() {
    let names = this._user.ManagerName.split(' ') || ' ',
    initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  }


  clearPermission() {
    this.permission = {
      Agenda: {
        access: false
      },
      Gabinete: {
        access: false,
        pr_tasks: false,
        md_tasks: false,
        aprove_event: false
      },
      Actions: {
        access : false,
        create : false,
        delete : false,
        edit : false,
        createPost : false,
        deletePost : false,
        editPost : false
      },
      Chat: {
        access: false
      }
    }
  }

  setPermission() {}

}


export const SessionStore = new SessionService()

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-container',
  templateUrl: './empty-container.page.html',
  styleUrls: ['./empty-container.page.scss'],
})
export class EmptyContainerPage implements OnInit {
  @Input() texto:string;
  
  constructor() { }

  ngOnInit() {
  }

}

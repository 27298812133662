import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(array: any[], text:string, column:string): any {

    console.log(array, text, column);
    
    if(text === ''){
      return array;
    }

    text = text.toLowerCase();

    if(!Array.isArray(array)) {
      array = []
    }

    return array.filter(item =>{
      return item[column].toLowerCase().includes(text);
    });
  }

}

import { z } from "zod";
import { EntityTable } from 'Dexie';

export const MemberTableSchema = z.object({
  $roomIdUserId: z.string().optional(),
  id: z.string().optional(), // useless
  roomId: z.string(),
  wxUserId: z.number(),
  wxFullName: z.string(),
  wxeMail: z.string(),
  userPhoto: z.string().nullable(),
  joinAt: z.string(),
  status: z.string().optional(), // useless
  isAdmin: z.boolean()
})

export type MemberTable = z.infer<typeof MemberTableSchema>
export type DexieMembersTableSchema = EntityTable<MemberTable, '$roomIdUserId'>;
export const MemberTableColumn = '$roomIdUserId, userId, id, user, joinAt, roomId, status, wxUserId, isAdmin'

import { Injectable } from '@angular/core';
import { Event  } from '../models/event.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { ChangeProfileService } from './change-profile.service';
import { OfflineManagerService } from 'src/app/services/offline-manager.service';
import { SessionStore } from '../store/session.service';
import { calendarInterface } from '../models/user.model';
import { Subscribe } from './subcribe';


@Injectable({
  providedIn: 'root'
})
export class EventsService {

  authheader = {};
  headers: HttpHeaders;

  headersPrOficial: HttpHeaders;
  headersPrPessoal: HttpHeaders;

  headersMdOficial: HttpHeaders;
  headersMdPessoal: HttpHeaders;

  headerOwnOficial: HttpHeaders;
  headerOwnPessoal: HttpHeaders;

  headerSharedOficial: HttpHeaders;
  headerSharedPessoal: HttpHeaders;

  headersSharedOficial: HttpHeaders;
  headersSharedPessoal: HttpHeaders;

  calendarIds = []
  usersCalendarIds = []

  hasSharedCalendar = false;
  hasSharedOficial: boolean = false;
  hasSharedPessoal: boolean = false;

  hasOwnOficial: boolean = false;
  hasOwnPessoal: boolean = false;
  hasOwnCalendar = false

  calendarNames = {}

  calendarNamesAry = []
  calendarNamesAryNoPr = []
  calendarNamesAryNoPrNMD = []
  calendarNamesAryOnlyMD = []
  calendarNamesAryReverse = []
  calendarNamesAryPR = []

  calendarNamesType = {}
  calendarRole = []
  calendrFullName = []

  myCalendarNames = {}
  hasAnyCalendar = false

  HasMdGPR = false

  onLoadCalendars: Function[] =[]
  loadCalendars = false


  onCalendarFinishLoad = new Subscribe({execute : false, deleteOnExecute: true})

  constructor(
    private http: HttpClient,
    public user: AuthService,
    private changeProfileService: ChangeProfileService,
    private offlinemanager: OfflineManagerService) {

    this.setHeader()
    this.changeProfileService.registerCallback(() => {
      this.setHeader()
    })

  }

  async setHeader () {

    this.headers = new HttpHeaders();;
    this.headersMdOficial = new HttpHeaders();;
    this.headersMdPessoal = new HttpHeaders();;

    this.headersPrOficial = new HttpHeaders();;
    this.headersPrPessoal = new HttpHeaders();;

    this.headersSharedOficial = new HttpHeaders();;
    this.headersSharedPessoal = new HttpHeaders();;


    this.headerOwnOficial= new HttpHeaders();;
    this.headerOwnPessoal= new HttpHeaders();;

    this.headerSharedOficial= new HttpHeaders();;
    this.headerSharedPessoal= new HttpHeaders();;

    this.headers = this.headers.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);

    this.usersCalendarIds = [];
    this.calendarNames = {}
    this.calendarRole = []
    this.calendrFullName = [];
    this.calendarIds = []

    this.calendarNamesAry = []
    this.calendarNamesType = {}
    this.calendarNamesAryNoPr = []
    this.calendarNamesAryPR = []


    this.calendarNamesAryNoPrNMD = []
    this.calendarNamesAryOnlyMD = []

    this.hasSharedCalendar = false
    this.hasSharedOficial = false
    this.hasSharedPessoal = false

    this.hasOwnCalendar = false
    this.hasOwnOficial = false
    this.hasOwnPessoal = false
    this.HasMdGPR = false


    if (SessionStore.user) {
      if (SessionStore.user.Profile == 'MDGPR') {

        if(SessionStore?.user?.OwnerCalendars) {
          for (let  calendar of SessionStore?.user?.OwnerCalendars) {
            this.hasAnyCalendar = true

            if (calendar.CalendarName == 'Oficial') {

              this.hasOwnOficial = true


              this.headersMdOficial = this.headersMdOficial.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
              this.headersMdOficial = this.headersMdOficial.set('CalendarId', calendar.CalendarId);
              this.headersMdOficial = this.headersMdOficial.set('CalendarRoleId', calendar.CalendarRoleId);
            }
            else if (calendar.CalendarName == 'Pessoal') {

              this.hasOwnPessoal = true

              this.headersMdPessoal = this.headersMdPessoal.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
              this.headersMdPessoal = this.headersMdPessoal.set('CalendarId', calendar.CalendarId);
              this.headersMdPessoal = this.headersMdPessoal.set('CalendarRoleId', calendar.CalendarRoleId);

            }
          }
        }

        if(SessionStore?.user?.SharedCalendars) {
          for (let  sharedCalendar of SessionStore.user.SharedCalendars) {
            this.hasAnyCalendar = true

            if (sharedCalendar.CalendarName == 'Oficial') {

              this.hasSharedOficial = true

              this.headersSharedOficial = this.headersSharedOficial.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
              this.headersSharedOficial = this.headersSharedOficial.set('CalendarId', sharedCalendar.CalendarId);
              this.headersSharedOficial = this.headersSharedOficial.set('CalendarRoleId', sharedCalendar.CalendarRoleId);
            }
            else if (sharedCalendar.CalendarName == 'Pessoal') {

              this.hasSharedPessoal = true

              this.headersSharedPessoal = this.headersSharedPessoal.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
              this.headersSharedPessoal = this.headersSharedPessoal.set('CalendarId', sharedCalendar.CalendarId);
              this.headersSharedPessoal = this.headersSharedPessoal.set('CalendarRoleId', sharedCalendar.CalendarRoleId);
            }
          }
        }

      }

      else if (SessionStore.user.Profile == 'PR') {

        for (let  calendar of SessionStore?.user?.OwnerCalendars) {
          this.hasAnyCalendar = true
          if (calendar.CalendarName == 'Oficial') {

            this.hasOwnOficial = true

            this.headersPrOficial = this.headersPrOficial.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
            this.headersPrOficial = this.headersPrOficial.set('CalendarId', calendar.CalendarId);
            this.headersPrOficial = this.headersPrOficial.set('CalendarRoleId', calendar.CalendarRoleId);
          }
          else if (calendar.CalendarName == 'Pessoal') {

            this.hasOwnPessoal = true

            this.headersPrPessoal = this.headersPrPessoal.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
            this.headersPrPessoal = this.headersPrPessoal.set('CalendarId', calendar.CalendarId);
            this.headersPrPessoal = this.headersPrPessoal.set('CalendarRoleId', calendar.CalendarRoleId);

          }
        }

      }

      for (let  calendar of SessionStore?.user?.OwnerCalendars) {
        this.hasAnyCalendar = true
        if(!this.usersCalendarIds.includes(calendar.OwnerUserId)) {
          this.usersCalendarIds.push(calendar.OwnerUserId)
        }

        if(!this.calendarIds.includes(calendar.CalendarId)) {
          this.calendarIds.push(calendar.CalendarId)
        }

        this.hasOwnCalendar = true

        if (calendar.CalendarName == 'Oficial') {

          this.hasOwnOficial = true

          this.headerOwnOficial = this.headerOwnOficial.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
          this.headerOwnOficial = this.headerOwnOficial.set('CalendarId', calendar.CalendarId);
          this.headerOwnOficial = this.headerOwnOficial.set('CalendarRoleId', calendar.CalendarRoleId);
          this.headerOwnOficial = this.headerOwnOficial.set('CalendarName', calendar.CalendarName);
        }
        else if (calendar.CalendarName == 'Pessoal') {

          this.hasOwnPessoal = true

          this.headerOwnPessoal = this.headerOwnPessoal.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
          this.headerOwnPessoal = this.headerOwnPessoal.set('CalendarId', calendar.CalendarId);
          this.headerOwnPessoal = this.headerOwnPessoal.set('CalendarRoleId', calendar.CalendarRoleId);
          this.headerOwnPessoal = this.headerOwnPessoal.set('CalendarName', calendar.CalendarName);
        }
      }

      for (let  sharedCalendar of SessionStore?.user?.SharedCalendars) {
        this.hasAnyCalendar = true

        if(!this.usersCalendarIds.includes(sharedCalendar.OwnerUserId)) {
          this.usersCalendarIds.push(sharedCalendar.OwnerUserId)
        }

        if(!this.calendarIds.includes(sharedCalendar.CalendarId)) {
          this.calendarIds.push(sharedCalendar.CalendarId)
        }

        this.hasSharedCalendar = true

        if (sharedCalendar.CalendarName == 'Oficial') {
          this.hasSharedOficial = true

          this.headerSharedOficial = this.headerSharedOficial.set('Authorization','Bearer ' + SessionStore.user.Authorization);
          this.headerSharedOficial = this.headerSharedOficial.set('CalendarId', sharedCalendar.CalendarId);
          this.headerSharedOficial = this.headerSharedOficial.set('CalendarRoleId', sharedCalendar.CalendarRoleId);
          this.headerSharedOficial = this.headerSharedOficial.set('CalendarName', sharedCalendar.CalendarName);
        }
        else if (sharedCalendar.CalendarName == 'Pessoal') {

          this.hasSharedPessoal = true

          this.headerSharedPessoal = this.headerSharedPessoal.set('Authorization','Bearer ' + SessionStore.user.Authorization);
          this.headerSharedPessoal = this.headerSharedPessoal.set('CalendarId', sharedCalendar.CalendarId);
          this.headerSharedPessoal = this.headerSharedPessoal.set('CalendarRoleId', sharedCalendar.CalendarRoleId);
          this.headerSharedPessoal = this.headerSharedPessoal.set('CalendarName', sharedCalendar.CalendarName);
        }
      }


      for (let  OwnerCalendar of SessionStore?.user?.OwnerCalendars) {
        this.hasAnyCalendar = true

        this.calendarNames[OwnerCalendar.CalendarId] = 'Meu calendario'

        if(!this.calendarNamesAry.includes('Meu calendario')) {
          this.calendarNamesAry.push('Meu calendario')
          this.calendarNamesAryNoPr.push('Meu calendario')
          this.calendarNamesType['Meu calendario'] = {}
        }

        this.calendarNamesType['Meu calendario'][OwnerCalendar.CalendarName] = true
        this.calendarNamesType['Meu calendario'][OwnerCalendar.CalendarName+'Id'] = OwnerCalendar.OwnerUserId || SessionStore.user.UserId
        this.calendarNamesType['Meu calendario']['RoleId'] = OwnerCalendar.CalendarRoleId
        this.calendarNamesType['Meu calendario']['OwnerId'] = OwnerCalendar.OwnerUserId || SessionStore.user.UserId
        this.calendarNamesType['Meu calendario']['FullName'] = SessionStore.user.FullName
      }
      for (let  sharedCalendar of SessionStore?.user?.SharedCalendars) {
        this.hasAnyCalendar = true
        if(sharedCalendar?.OwnerUserId) {
          const e = await this.GetCalendarName(sharedCalendar.OwnerUserId).toPromise()

          this.calendarNames[sharedCalendar.CalendarId] = e.FullName
          this.myCalendarNames[sharedCalendar.CalendarId] = e.FullName

          if(!this.calendarNamesAry.find(x => x.Role == e.Role)) {


            let objectShared = {
              "Fullname": e.FullName,
              "Role": e.Role,
              "OwnerUserId": sharedCalendar.OwnerUserId,
              "RoleId": sharedCalendar.CalendarRoleId
            }

            this.calendarNamesAry.push(objectShared)

            if(e.Role == 'Presidente da República') {
              this.calendarNamesAryPR.push(objectShared)
            } else {
              this.calendarNamesAryNoPr.push(objectShared)
            }

            this.calendarNamesType[e.FullName] = {}
          }

          this.calendarNamesType[e.FullName][sharedCalendar.CalendarName] = true
          this.calendarNamesType[e.FullName][sharedCalendar.CalendarName+'Id'] = sharedCalendar.OwnerUserId
          this.calendarNamesType[e.FullName]['RoleId'] = sharedCalendar.CalendarRoleId
          this.calendarNamesType[e.FullName]['OwnerId'] = sharedCalendar.OwnerUserId
          this.calendarNamesType[e.FullName]['Shared'] = sharedCalendar.OwnerUserId
        }

      }

      this.calendarNamesAryNoPrNMD = this.calendarNamesAryNoPr.filter((e)=> {
        return e.Role != 'Ministro e Director do Gabinete do PR' && e.Role != 'Presidente da República'
      })

      this.calendarNamesAryOnlyMD = this.calendarNamesAryNoPr.filter((e)=> {
        return e.Role == 'Ministro e Director do Gabinete do PR'
      })


      this.HasMdGPR =  this.calendarNamesAryNoPr.find( (e)=> {
        if(e.Role == 'Ministro e Director do Gabinete do PR') {
          return true
        }
        return false
      })


      if(SessionStore.user?.OwnerCalendars.length == 0 && SessionStore.user.SharedCalendars.length == 0) {
        this.hasAnyCalendar = false
      } else {
        this.hasAnyCalendar = true
      }


      this.calendarNamesAryReverse = this.calendarNamesAry.reverse();

      this.onCalendarFinishLoad.executor();

      (() => {
        const keys = {}

        for (let e of SessionStore.user?.OwnerCalendars) {
          if(!keys[e.CalendarId]) {
            keys[e.CalendarId] = e.CalendarId
          } else {
            throw(`Calendar ${e.CalendarId} is repeated for user `+ SessionStore.user.FullName);
          }
        }

        for (let e of SessionStore.user.SharedCalendars) {
          if(!keys[e.CalendarId]) {
            keys[e.CalendarId] = e.CalendarId
          } else {
            throw(`Calendar ${e.CalendarId} is repeated for user `+ SessionStore.user.FullName);
          }
        }
      })();


    }


    this.onLoadCalendars.forEach(e=> e());
    this.loadCalendars = true
  }


  registerOnLoadCalendars(e: Function) {
    if( this.loadCalendars) {
      e()
    } else {
      this.onLoadCalendars.push(e)
    }

  }


  DetectCalendars(CalendarId) {
    const calendars = SessionStore.user.OwnerCalendars.concat(SessionStore.user.SharedCalendars)
    return calendars.find((e) => e.CalendarId == CalendarId)
  }

  makeHeader(calendar: calendarInterface) {
    let header = new HttpHeaders();;
    header = header.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
    header = header.set('CalendarId', calendar.CalendarId);
    header = header.set('CalendarRoleId', calendar.CalendarRoleId);
    header = header.set('CalendarName', calendar.CalendarName);

    return header
  }


  isMyCalendar(CalendarId) {
    return this.myCalendarNames[CalendarId] || ""
  }

  getCalendarOwnNameByCalendarId(CalendarId) {
    return this.calendarNames[CalendarId] || ""
  }


  isMyEvent(event: any) {

    const data: any = event
    return data.owner.wxUserId == SessionStore.user.UserId

  }


  detectCalendarNameByCalendarId(calendarId) {

    for ( const property in this.calendarNamesType) {
      if(this.calendarNamesType[property]?.['Oficial']) {
        if(this.calendarNamesType[property]['OficialId'] == calendarId) {
          return property
        }
      }
      if (this.calendarNamesType[property]?.['Pessoal']) {
        if(this.calendarNamesType[property]['PessoalId'] == calendarId) {
          return property
        }
      }
    }

  }


  // getAllPrOficialEvents(startdate: string, enddate: string): Observable<EventList[]> {
  //   let geturl = environment.apiURL + 'calendar/pr';
  //   geturl = geturl.replace('/V4/', '/V5/')

  //   let params = new HttpParams();

  //   params = params.set("Start", startdate);
  //   params = params.set("End", enddate);

  //   let options = {
  //     headers: this.headersPrOficial,
  //     params: params
  //   };
  //   return this.http.get<EventList[]>(`${geturl}`, options);
  // }

  // getAllPrPessoalEvents(startdate: string, enddate: string): Observable<EventList[]> {
  //   let geturl = environment.apiURL + 'calendar/pr';
  //   geturl = geturl.replace('/V4/', '/V5/')

  //   let params = new HttpParams();

  //   params = params.set("Start", startdate);
  //   params = params.set("End", enddate);

  //   let options = {
  //     headers: this.headersPrPessoal,
  //     params: params
  //   };
  //   return this.http.get<EventList[]>(`${geturl}`, options);
  // }

  // async getAllPrEvents(startdate: string, enddate: string): Promise<EventList[]> {

  //   let prO = [], prP = [];

  //   prO = await this.getAllPrOficialEvents(startdate, enddate).toPromise();
  //   prP = await this.getAllPrPessoalEvents(startdate, enddate).toPromise();
  //   const resFinal = prO.concat(prP);
  //   return new Promise(resolve => {
  //     return resolve(resFinal)
  //   })
  // }

  // getAllMdOficialEvents(startdate: string, enddate: string): Observable<EventList[]> {
  //   let geturl = environment.apiURL + 'calendar/md';

  //   let params = new HttpParams();

  //   params = params.set("Start", startdate);
  //   params = params.set("End", enddate);

  //   let options = {
  //     headers: this.headersMdOficial,
  //     params: params
  //   };
  //   return this.http.get<EventList[]>(`${geturl}`, options);
  // }

  // getAllMdPessoalEvents(startdate: string, enddate: string): any {
  //   let geturl = environment.apiURL + 'calendar/md';

  //   let params = new HttpParams();

  //   params = params.set("Start", startdate);
  //   params = params.set("End", enddate);

  //   let options = {
  //     headers: this.headersMdPessoal,
  //     params: params
  //   };
  //   return this.http.get<any>(`${geturl}`, options)
  // }


  GetCalendarName(userid:string) {

    let geturl = environment.apiURL + 'userauthentication/single';

    let params = new HttpParams();

    params = params.set("id", userid);

    let options = {
      headers: this.headers,
      params: params
    }

    return this.http.get<any>(`${geturl}`, options)
  }

  // async getAllMdEvents(startdate: string, enddate: string) {

  //   let prO = [], prP = [];

  //   for (let calendar of SessionStore.user.OwnerCalendars) {
  //     if (calendar.CalendarName == 'Oficial') {
  //       prO = await this.getAllMdOficialEvents(startdate, enddate).toPromise();
  //       if(!Array.isArray(prO)) {
  //         prO = []
  //       }
  //     }
  //     if(calendar.CalendarName == 'Pessoal') {
  //       prP = await this.getAllMdPessoalEvents(startdate, enddate).toPromise();
  //       if(!Array.isArray(prP)) {
  //         prP = []
  //       }
  //     }
  //   }

  //   const resFinal = prO.concat(prP);
  //   return new Promise(resolve => {
  //     return resolve(resFinal)
  //   });
  // }




  // async getAllOwnEvents(startdate: string, enddate: string) {

  //   let ownO = [], ownP = [];

  //   if(this.hasOwnOficial) {
  //     ownO = await this.getAllOwnOficialEvents(startdate, enddate).toPromise();
  //     if(!Array.isArray(ownO)) {
  //       ownO = []
  //     }
  //   }

  //   if(this.hasOwnPessoal) {
  //     ownP = await this.getAllOwnPessoalEvents(startdate, enddate).toPromise();
  //     if(!Array.isArray(ownP)) {
  //       ownP = []
  //     }
  //   }

  //   const resFinal = ownO.concat(ownP);
  //   return new Promise(resolve => {
  //     return resolve(resFinal)
  //   });
  // }

  // getAllOwnOficialEvents(startdate: string, enddate: string): Observable<EventList[]> {
  //   let geturl = environment.apiURL + 'calendar/GetEvents';

  //   let params = new HttpParams();

  //   params = params.set("StartDate", startdate);
  //   params = params.set("EndDate", enddate);


  //   let options = {
  //     headers: this.headerOwnOficial,
  //     params: params
  //   };
  //   return this.http.get<EventList[]>(`${geturl}`, options);
  // }

  // getAllOwnPessoalEvents(startdate: string, enddate: string): any {
  //   let geturl = environment.apiURL + 'calendar/GetEvents';

  //   let params = new HttpParams();

  //   params = params.set("StartDate", startdate);
  //   params = params.set("EndDate", enddate);

  //   let options = {
  //     headers: this.headerOwnPessoal,
  //     params: params
  //   };
  //   return this.http.get<any>(`${geturl}`, options)
  // }


  // private deferenceBetweenDays(start: any, end: any) {
  //   const diffTime = Math.abs(end - start);
  //   return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // }

  // async getEventsByCalendarId( startdate: string, enddate: string, calendarId: any) {
  //   const calendars = SessionStore.user.OwnerCalendars.concat(SessionStore.user.SharedCalendars)
  //   const agendasCalendars = calendars.filter( e => e.CalendarId == calendarId)

  //   let result = []

  //   for(let agendasCalendar of agendasCalendars) {
  //     var header = new HttpHeaders();;
  //     header = header.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
  //     header = header.set('CalendarId', agendasCalendar.CalendarId);
  //     header = header.set('CalendarRoleId', agendasCalendar.CalendarRoleId);
  //     header = header.set('CalendarName', agendasCalendar.CalendarName);


  //     let geturl = environment.apiURL + 'calendar/GetEvents';

  //     let params = new HttpParams();

  //     params = params.set("StartDate", startdate);
  //     params = params.set("EndDate", enddate);

  //     let options = {
  //       headers: header,
  //       params: params
  //     };

  //     let calendar = await this.http.get<EventList[]>(`${geturl}`, options).toPromise()

  //     calendar = calendar.map(e => {
  //       if(e.IsAllDayEvent && this.deferenceBetweenDays(new Date(e.StartDate), new Date(e.EndDate)) >= 1) {

  //         const date = new Date(e.EndDate);

  //         date.setDate(date.getDate() -1);

  //         const _date = String(date.getDate()).padStart(2,'0');
  //         const month = String(date.getMonth() + 1).padStart(2,'0');
  //         const fullYear = date.getFullYear();

  //         console.log({_date, month})
  //         const formattedDate = `${fullYear}-${month}-${_date} 23:59`;
  //         e.EndDate = formattedDate
  //       }

  //       return e
  //     })
  //     result = result.concat(calendar)
  //   }

  //   return result
  // }

  // async genericGetAllSharedEvents(startdate: string, enddate: string) {

  //   let result = []

  //   for (let  sharedCalendar of SessionStore.user.SharedCalendars) {

  //     var header = new HttpHeaders();;
  //     header = header.set('Authorization', 'Bearer ' + SessionStore.user.Authorization);
  //     header = header.set('CalendarId', sharedCalendar.CalendarId);
  //     header = header.set('CalendarRoleId', sharedCalendar.CalendarRoleId);
  //     header = header.set('CalendarName', sharedCalendar.CalendarName);

  //     let geturl = environment.apiURL + 'calendar/GetEvents';

  //     let params = new HttpParams();

  //     params = params.set("StartDate", startdate);
  //     params = params.set("EndDate", enddate);

  //     let options = {
  //       headers: header,
  //       params: params
  //     };

  //     const calendar = await this.http.get<Event[]>(`${geturl}`, options).toPromise()

  //     result = result.concat(calendar)

  //   }

  //   return new Promise(resolve => {
  //     return resolve(result)
  //   });
  // }

  // genericGetAllSharedOficialEvents(startdate: string, enddate: string): Observable<Event[]> {
  //   let geturl = environment.apiURL + 'calendar/GetEvents';
  //   geturl = geturl.replace('/V4/', '/V5/')

  //   let params = new HttpParams();

  //   params = params.set("StartDate", startdate);
  //   params = params.set("EndDate", enddate);



  //   let options = {
  //     headers: this.headerSharedOficial,
  //     params: params
  //   };

  //   return this.http.get<Event[]>(`${geturl}`, options);
  // }

  // genericGetAllSharedPessoalEvents(startdate: string, enddate: string): Observable<Event[]> {
  //   let geturl = environment.apiURL + 'calendar/GetEvents';
  //   geturl = geturl.replace('/V4/', '/V5/')

  //   let params = new HttpParams();

  //   params = params.set("StartDate", startdate);
  //   params = params.set("EndDate", enddate);

  //   let options = {
  //     headers: this.headerSharedPessoal,
  //     params: params
  //   };
  //   return this.http.get<Event[]>(`${geturl}`, options);
  // }

  // async getAllSharedEvents(startdate: string, enddate: string) {

  //   let prO = [], prP = [];

  //   for(let calendar of SessionStore.user.SharedCalendars) {
  //     if (calendar.CalendarName == 'Oficial') {
  //       prO = await this.getAllSharedOficialEvents(startdate, enddate).toPromise();
  //     }
  //     if(calendar.CalendarName == 'Pessoal') {
  //       prP = await this.getAllSharedPessoalEvents(startdate, enddate).toPromise();
  //     }

  //   }

  //   const resFinal = prO.concat(prP);

  //   return new Promise(resolve => {
  //     return resolve(resFinal)
  //   });
  // }

  // getAllSharedOficialEvents(startdate: string, enddate: string): Observable<Event[]> {
  //   let geturl = environment.apiURL + 'calendar/pr';
  //   geturl = geturl.replace('/V4/', '/V5/')

  //   let params = new HttpParams();

  //   params = params.set("Start", startdate);
  //   params = params.set("End", enddate);

  //   let options = {
  //     headers: this.headersSharedOficial,
  //     params: params
  //   };


  //   return this.http.get<Event[]>(`${geturl}`, options);
  // }

  // getAllSharedPessoalEvents(startdate: string, enddate: string): Observable<Event[]> {
  //   let geturl = environment.apiURL + 'calendar/pr';
  //   geturl = geturl.replace('/V4/', '/V5/')

  //   let params = new HttpParams();

  //   params = params.set("Start", startdate);
  //   params = params.set("End", enddate);

  //   let options = {
  //     headers: this.headersSharedPessoal,
  //     params: params
  //   };
  //   return this.http.get<Event[]>(`${geturl}`, options);
  // }


  getRecurrenceTypes(): any {
    const geturl = environment.apiURL + 'Calendar/RecurrenceTypes';
    let options = {
      headers: this.headers,
    };
    return this.http.get<any>(`${geturl}`, options);
  }


  // getEvents(calendarname: string, startdate: string, enddate: string): Observable<Event[]> {
  //   const geturl = environment.apiURL + 'calendar/GetEvents';
  //   let params = new HttpParams();

  //   params = params.set("CalendarName", calendarname);
  //   params = params.set("StartDate", startdate);
  //   params = params.set("EndDate", enddate);

  //   let options = {
  //     headers: this.headers,
  //     params: params
  //   };
  //   return this.http.get<Event[]>(`${geturl}`, options);
  // }

  getEvent(eventid: string): Observable<Event> {
    let geturl = environment.apiURL + 'calendar/GetEvent';
    let params = new HttpParams();

    params = params.set("EventId", eventid);

    let options = {
      headers: this.headers,
      params: params
    };

    return this.http.get<Event>(`${geturl}`, options);
  }

  genericGetEvent(eventid: string, calendarId: string) {
    let geturl = environment.apiURL + 'calendar/GetEvent';
    let params = new HttpParams();

    params = params.set("EventId", eventid);

    const calendar = this.DetectCalendars(calendarId)
    const header = this.makeHeader(calendar)

    if(header) {
      let options = {
        headers: header,
        params: params
      }

      return this.http.get<Event>(`${geturl}`, options);
    } else {

    }

    throw('error')
  }

  // putEvent(event: Event, conflictResolutionMode: number, sendInvitationsOrCancellationsMode: number, sharedagenda: string): Observable<Event> {
  //   const puturl = environment.apiURL + 'calendar/' + ((sharedagenda != '') ? sharedagenda : 'PutEvent');

  //   let params = new HttpParams();

  //   params = params.set("conflictResolutionMode", conflictResolutionMode.toString());
  //   params = params.set("sendInvitationsOrCancellationsMode", sendInvitationsOrCancellationsMode.toString());

  //   let options = {
  //     headers: this.headers,
  //     params: params
  //   };

  //   return this.http.put<Event>(`${puturl}`, event, options)
  // }

  // editEvent(event: Event, conflictResolutionMode: number, sendInvitationsOrCancellationsMode: number, CalendarId ): Observable<Event> {
  //   let arrayReq = [];
  //   arrayReq.push(event);
  //   const puturl = environment.apiURL + 'calendar/PutEvent';
  //   let params = new HttpParams();

  //   // event.Organizer = {
  //   //   "Id": SessionStore.user.UserId,
  //   //   "EmailAddress": SessionStore.user.Email,
  //   //   "Name": SessionStore.user.UserName,
  //   //   "IsRequired": true,
  //   //   "UserType": "GD"
  //   // }
  //   // params = params.set("notifyUsers", true)

  //   params = params.set("conflictResolutionMode", conflictResolutionMode.toString());
  //   params = params.set("sendInvitationsOrCancellationsMode", sendInvitationsOrCancellationsMode.toString());
  //   params.set('CalendarId', event.CalendarId)
  //   params.set('CalendarName', event.CalendarName)
  //   this.headers['CalendarId'] = event.CalendarId
  //   this.headers['CalendarName'] = event.CalendarName
  //   //params = params.set("CalendarName", event.CalendarName);

  //   if (event.CalendarName == 'Oficial') {
  //     if (SessionStore.user.Profile == 'MDGPR') {
  //       this.headers = this.headersMdOficial;
  //     }
  //     else if (SessionStore.user.Profile == 'PR') {
  //       this.headers = this.headersPrOficial;
  //     } else {


  //       if(CalendarId) {

  //         const headers = [
  //           this.headerSharedOficial,
  //           this.headerSharedPessoal,
  //           this.headerOwnPessoal,
  //           this.headerOwnOficial
  //         ]

  //         const calendar = this.DetectCalendars(CalendarId)
  //         const header = this.makeHeader(calendar)

  //         this.headers = header
  //       } else {
  //         const headers = [
  //           this.headerSharedOficial,
  //           this.headerSharedPessoal,
  //           this.headerOwnPessoal,
  //           this.headerOwnOficial
  //         ]

  //         const header = headers.find((header)=> {
  //           return header?.get('CalendarId')?.includes(CalendarId)
  //         })

  //         this.headers = header
  //       }

  //     }
  //   }
  //   else {
  //     if (SessionStore.user.Profile == 'MDGPR') {
  //       this.headers = this.headersMdPessoal;
  //     }
  //     else if (SessionStore.user.Profile == 'PR') {
  //       this.headers = this.headersPrPessoal;
  //     }
  //     else {



  //       if(CalendarId) {

  //         const headers = [
  //           this.headerSharedOficial,
  //           this.headerSharedPessoal,
  //           this.headerOwnPessoal,
  //           this.headerOwnOficial
  //         ]

  //         const calendar = this.DetectCalendars(CalendarId)
  //         const header = this.makeHeader(calendar)

  //         this.headers = header
  //       } else {
  //         const headers = [
  //           this.headerSharedOficial,
  //           this.headerSharedPessoal,
  //           this.headerOwnPessoal,
  //           this.headerOwnOficial
  //         ]

  //         const header = headers.find((header)=> {
  //           return header?.get('CalendarId')?.includes(CalendarId)
  //         })

  //         this.headers = header
  //       }
  //     }
  //   }

  //   let options = {
  //     headers: this.headers,
  //     params: params
  //   };

  //   return this.http.put<Event>(`${puturl}`, event, options).pipe(
  //     catchError(err => {

  //       this.offlinemanager.storeRequestData('eventEdit', arrayReq);
  //       throw new Error(err);
  //     })
  //   )
  // }


  // changeAgenda(body: any) {
  //   const puturl = environment.apiURL + 'Calendar/MoveEvent';
  //   let options = {
  //     headers: this.headers,
  //   };
  //   return this.http.post<any>(`${puturl}`, body, options);
  // }

  postEventMd(event: Event, calendarName: string) {
    const puturl = environment.apiURL + 'calendar/md';
    let params = new HttpParams();

    const now = new Date();
    event.TimeZone = now.toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1]

    params = params.set("CalendarName", calendarName);

    let options: any;
    switch (calendarName) {
      case 'Oficial':

        options = {
          headers: this.headersMdOficial,
          params: params
        };
        break;

      case 'Pessoal':

        options = {
          headers: this.headersMdPessoal,
          params: params
        };
        break;
    }

    return this.http.post<string>(`${puturl}`, event, options)
  }

  postEventPr(event: Event, calendarName: string) {
    const puturl = environment.apiURL + 'calendar/pr';
    let params = new HttpParams();

    const now = new Date();
    event.TimeZone = now.toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1]

    params = params.set("CalendarName", calendarName);
    params = params.set("notifyUsers", true)

    let options: any;
    switch (calendarName) {
      case 'Oficial':

        options = {
          headers: this.headersPrOficial,
          params: params
        };
        break;

      case 'Pessoal':

        options = {
          headers: this.headersPrPessoal,
          params: params
        };
        break;
    }

    return this.http.post<string>(`${puturl}`, event, options)
  }



  postEventGeneric(event: Event, calendarName: string, CalendarId) {
    const puturl = environment.apiURL + 'Calendar/PostEvent';
    let params = new HttpParams();

    const now = new Date();

    event.TimeZone = now.toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1];
    event.Organizer = {
      "Id": SessionStore.user.UserId,
      "EmailAddress": SessionStore.user.Email,
      "Name": SessionStore.user.UserName,
      "IsRequired": true,
      "UserType": "GD"
    }

    params = params.set("CalendarName", calendarName);
    params = params.set("notifyUsers", true)

    let options: any;

    const calendar = this.DetectCalendars(CalendarId)
    const header = this.makeHeader(calendar)

    options = {
      headers: header,
      params: params
    };

    return this.http.post<string>(`${puturl}`, event, options)
  }



  deleteEvent(eventid: string, eventDeleteType: number, calendarName: string) {
    let arrayReq = [];
    let Object = {
      eventid: eventid,
      eventDeleteType: eventDeleteType,
      calendarName: calendarName
    }
    arrayReq.push(Object)
    const puturl = environment.apiURL + 'calendar/DeleteEvent';
    let params = new HttpParams();

    params = params.set("EventId", eventid);
    // 0 for occurence and 1 for serie (delete all events)
    params = params.set("eventDeleteType", eventDeleteType.toString());

    let options;

    switch (SessionStore.user.Profile) {
      case 'MDGPR':
        if (calendarName == 'Pessoal') {
          options = {
            headers: this.headersMdPessoal,
            params: params
          };
        }
        else if (calendarName == 'Oficial') {
          options = {
            headers: this.headersMdOficial,
            params: params
          };
        }
        break;
      case 'PR':
        if (calendarName == 'Pessoal') {
          options = {
            headers: this.headersPrPessoal,
            params: params
          };
        }
        else if (calendarName == 'Oficial') {
          options = {
            headers: this.headersPrOficial,
            params: params
          };
        }
        break;
    }

    return this.http.delete(`${puturl}`, options)
  }


  // genericDeleteEvent(eventid: string, eventDeleteType: number, calendarName: string, calendarId: string) {
  //   let arrayReq = [];
  //   let Object = {
  //     eventid: eventid,
  //     eventDeleteType: eventDeleteType,
  //     calendarName: calendarName
  //   }
  //   arrayReq.push(Object)
  //   const puturl = environment.apiURL + 'calendar/DeleteEvent';
  //   let params = new HttpParams();

  //   params = params.set("EventId", eventid);
  //   // 0 for occurence and 1 for serie (delete all events)
  //   params = params.set("eventDeleteType", eventDeleteType.toString());

  //   let options;

  //   if(SessionStore.user.Profile == 'MDGPR') {
  //     if (calendarName == 'Pessoal') {
  //       options = {
  //         headers: this.headersMdPessoal,
  //         params: params
  //       };
  //     }
  //     else if (calendarName == 'Oficial') {
  //       options = {
  //         headers: this.headersMdOficial,
  //         params: params
  //       };
  //     }
  //   }
  //   else if (SessionStore.user.Profile == 'PR') {
  //     if (calendarName == 'Pessoal') {
  //       options = {
  //         headers: this.headersPrPessoal,
  //         params: params
  //       };
  //     }
  //     else if (calendarName == 'Oficial') {
  //       options = {
  //         headers: this.headersPrOficial,
  //         params: params
  //       };
  //     }
  //   }
  //   else {
  //     const headers = [
  //       this.headerSharedOficial,
  //       this.headerSharedPessoal,
  //       this.headerOwnPessoal,
  //       this.headerOwnOficial
  //     ]

  //     const header = headers.find((header)=> {
  //       return header?.get('CalendarId')?.includes(calendarId)
  //     })

  //     if (header) {


  //       options = {
  //         headers: header,
  //         params: params
  //       };

  //       return this.http.delete(`${puturl}`, options).pipe(
  //         catchError(err => {

  //           this.offlinemanager.storeRequestData('eventDelete', arrayReq);
  //           throw new Error(err);
  //         })
  //       )
  //     } else {
  //       const calendar = this.DetectCalendars(calendarId)
  //       const header = this.makeHeader(calendar)

  //       options = {
  //         headers: header,
  //         params: params
  //       };

  //       return this.http.delete(`${puturl}`, options).pipe(
  //         catchError(err => {

  //           this.offlinemanager.storeRequestData('eventDelete', arrayReq);
  //           throw new Error(err);
  //         })
  //       )
  //     }

  //   }


  // }


  // postExpedientEvent(docId: any, body: any, sharedagenda: string, serialNumber: any, applicationID: any) {
  //   const geturl = environment.apiURL + 'calendar/' + ((sharedagenda != '') ? sharedagenda : 'CreateEventExpediente') + '/event';
  //   let params = new HttpParams();
  //   let options;

  //   params = params.set("DocId", docId);
  //   params = params.set("SerialNumber", serialNumber);
  //   params = params.set("applicationID", applicationID);
  //   params = params.set("notifyUsers", true)

  //   switch (SessionStore.user.Profile) {
  //     case 'MDGPR':
  //       if (body.CalendarName == 'Pessoal') {
  //         options = {
  //           headers: this.headersMdPessoal,
  //           params: params
  //         };
  //       }
  //       else if (body.CalendarName == 'Oficial') {
  //         options = {
  //           headers: this.headersMdOficial,
  //           params: params
  //         };
  //       }
  //       break;
  //     case 'PR':
  //       if (body.CalendarName == 'Pessoal') {
  //         options = {
  //           headers: this.headersPrPessoal,
  //           params: params
  //         };
  //       }
  //       else if (body.CalendarName == 'Oficial') {
  //         options = {
  //           headers: this.headersPrOficial,
  //           params: params
  //         };
  //       }
  //       break;
  //   }
  //   return this.http.post<any>(`${geturl}`, body, options)
  // }

  genericPostExpedientEvent(docId: any, body: any, sharedagenda: string, serialNumber: any, applicationID: any, CalendarId) {
    const geturl = environment.apiURL + 'calendar/expediente';
    let params = new HttpParams();
    let options;

    params = params.set("DocId", docId);
    params = params.set("SerialNumber", serialNumber);
    params = params.set("applicationID", applicationID);

    const calendar = this.DetectCalendars(CalendarId)
    const header = this.makeHeader(calendar)

    options = {
      headers: header,
      params: params
    }
    return this.http.post<any>(`${geturl}`, body, options)
  }

  // createTaskEvent(folderId: any, body: any, sharedagenda: string, serialNumber: any, applicationID: any) {
  //   const geturl = environment.apiURL + 'calendar/' + ((sharedagenda != '') ? sharedagenda : 'CreateEventExpediente') + '/dispatch';
  //   let params = new HttpParams();
  //   let options;

  //   params = params.set("FolderId", folderId);
  //   params = params.set("SerialNumber", serialNumber);
  //   params = params.set("applicationID", applicationID);

  //   switch (SessionStore.user.Profile) {
  //     case 'MDGPR':
  //       if (body.CalendarName == 'Pessoal') {
  //         options = {
  //           headers: this.headersMdPessoal,
  //           params: params
  //         };
  //       }
  //       else if (body.CalendarName == 'Oficial') {
  //         options = {
  //           headers: this.headersMdOficial,
  //           params: params
  //         };
  //       }
  //       break;
  //     case 'PR':
  //       if (body.CalendarName == 'Pessoal') {
  //         options = {
  //           headers: this.headersPrPessoal,
  //           params: params
  //         };
  //       }
  //       else if (body.CalendarName == 'Oficial') {
  //         options = {
  //           headers: this.headersPrOficial,
  //           params: params
  //         };
  //       }
  //       break;
  //   }

  //   return this.http.post<any>(`${geturl}`, body, options)
  // }



  genericCreateTaskEvent(folderId: any, body: any, sharedagenda: string, serialNumber: any, applicationID: any, CalendarId) {
    const geturl = environment.apiURL + 'calendar/dispatch';
    let params = new HttpParams();
    let options;

    params = params.set("FolderId", folderId);
    params = params.set("SerialNumber", serialNumber);
    params = params.set("applicationID", applicationID);
    params = params.set("notifyUsers", true)

    const calendar = this.DetectCalendars(CalendarId)
    let header;
    console.log('calendar')

    try {

      header = this.makeHeader(calendar)


    } catch (error) {}


    console.log('header')

    options = {
      headers: header,
      params: params
    }

    console.log('post')
    return this.http.post<any>(`${geturl}`, body, options)
  }



  // postEventToApproveEdit(body: EventToApproveEdit) {
  //   const geturl = environment.apiURL + 'Tasks/EditEventTask';

  //   let options = {
  //     headers: this.headers,
  //   };

  //   return this.http.post<any>(`${geturl}`, body, options)
  // }
}

const token =  {
  eventId: '1',
  calendarId: '1',
  calendarName: '1',
  ownerAuth: 'basic: ou345873845982374598237498572938475987234957239845798345'
}


// decryptept token 873845982739845723987459823ouhfouidskfdjasklhdjfkusdhfliasdhfkljashdfkjhaskfhaskjdhfkasjdfhkjasdf

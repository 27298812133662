import { EventList } from "src/app/models/entiry/agenda/eventList"
import { XTracer } from "src/app/services/monitoring/opentelemetry/tracer";
import { EEventCategory, EEventOwnerType, EEventType, EEventStatus } from "../../data/dto/enums";
import { EventListOutputDTO } from "../../data/dto/eventListDTOOutput";

function getTextInsideParentheses(inputString): string {
  var startIndex = inputString.indexOf('(');
  var endIndex = inputString.indexOf(')');
  if (startIndex !== -1 && endIndex !== -1) {
    return inputString.substring(startIndex + 1, endIndex);
  } else {
    return null;
  }
}

export class ListEventMapper {
  // @XTracer({name:'ListEventMapper/toDomain', log: false, bugPrint: false})
  static toDomain(dto: EventListOutputDTO, calendarOwnerName: string, userId: string): EventList {

    return dto.data.map((e) => {

      let category;
      if(e.category == EEventCategory.Oficial) {
        category = 'Oficial'
      } else {
        category = 'Pessoal'
      }

      let ownerType;
      if(e.ownerType == EEventOwnerType.PR) {
        ownerType = 'PR'
      } else if(e.ownerType == EEventOwnerType.MD) {
        ownerType = 'MD'
      } else if(e.ownerType == EEventOwnerType.Others) {
        ownerType = 'Other'
      }


      let type;
      if(e.type == EEventType.Meeting) {
        type = 'Meeting'
      } else if (e.type == EEventType.Travel) {
        type = 'Travel'
      }

      let status;
      if(e.status == EEventStatus.Pending) {
        status = 'Pending'
      } else if(e.status == EEventStatus.Revision) {
        status = 'Revision'
      } else if(e.status == EEventStatus.Approved) {
        status = 'Approved'
      } else if(e.status == EEventStatus.Declined) {
        status = 'Declined'
      }

      return {
        "HasAttachments": e.hasAttachments,
        "IsAllDayEvent": e.isAllDayEvent,
        "EventId": e.id,
        "Subject": e.subject,
        "Location": e.location,
        "CalendarId": (e.owner.wxUserId) as any,
        "CalendarName": category,
        "StartDate": new Date(e.startDate) + '',
        "EndDate": new Date(e.endDate)+ '',
        "Schedule": (e.owner.wxFullName) as any,
        "RequiredAttendees": null as any,
        "OptionalAttendees": null as any,
        "HumanDate": "2 semanas atrás" as any,
        "TimeZone": getTextInsideParentheses(new Date(e.startDate)+ ''),
        "IsPrivate": false as any,
        "createdAt": e.createdAt
      }
    })
  }

  static toDTO() {}
}

import { z } from 'zod';


/**
 * Zod schema for validating data URLs.
 * This schema ensures that the input string matches the format of a data URL.
 *
 * @example
 * const result = dataUrlSchema.safeParse('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDCAAAAC0BEMAAW9R3AAAAAElFTkSuQmCC');
 * if (result.success) {
 *   console.log('Valid data URL:', result.data);
 * } else {
 *   console.error('Validation error:', result.error.errors);
 * }
 */
export const zodDataUrlSchema = z.string().refine(value => value.startsWith('data:'), {
  message: 'Invalid data URL',
});


//===============================================================================
/**
 * A schema for validating Base64 encoded strings.
 *
 * This schema checks if the string is valid Base64 and ensures that the first 20 characters
 * do not contain a comma.
 *
 * @example
 * ```typescript
 * const validString = 'SGVsbG8sIHdvcmxkIQ=='; // Base64 for "Hello, world!"
 * const invalidString = 'SGVsbG8sIHdvcmxkIQ,,=='; // Invalid due to commas
 *
 * console.log(base64Schema.safeParse(validString).success); // true
 * console.log(base64Schema.safeParse(invalidString).success); // false
 * ```
 */
export const base64Schema = z.string().refine(value => {
  return !value.slice(0, 20).includes(',');
}, {
  message: 'Invalid Base64 string or comma found in the first 20 characters'
});

/**
 * Validates a given string against the Base64 schema.
 *
 * @param input - The string to validate.
 * @returns `true` if the input is valid according to the schema, `false` otherwise.
 *
 * @example
 * ```typescript
 * console.log(validateInput('SGVsbG8sIHdvcmxkIQ==')); // true
 * console.log(validateInput('SGVsbG8sIHdvcmxkIQ,,==')); // false
 * ```
 */

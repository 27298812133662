import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public task: any;


  data = {}
  constructor() { }

  set(name: string, value: any) {
    this.data[name] = value
  }

  get (name: string): any {
    return this.data[name]
  }

  delate(name: string) {
    delete this.data[name]
  }


  clear() {
    this.data = {}
  }
}

import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HTTP_INTERCEPTORS,
    HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SessionStore } from '../store/session.service';
import { environment } from "src/environments/environment";
import { PermissionService } from '../services/permission.service';
import { NetworkServiceService, ConnectionStatus } from 'src/app/services/network-service.service';
// import { RochetChatConnectorService } from 'src/app/services/chat/rochet-chat-connector.service';

@Injectable()
export class ChatTokenInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    headers: HttpHeaders;
    options: any;
    private refreshChatTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

    private excludedDomains = ['Login',environment.apiURL, 'http://localhost:8019'];// Add other domains as needed

    constructor(private http: HttpClient, private router: Router, private p: PermissionService, private NetworkServiceService: NetworkServiceService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (this.shouldExcludeDomain(request)) {
            return next.handle(request);
        }

        if (SessionStore.user.Authorization) {
            request = this.addToken(request, SessionStore.user.Authorization);
          }

        return next.handle(request).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    return this.handle401Error(request, next);
                } else {
                    return throwError(error);
                }
            })
        );
    }

    private shouldExcludeDomain(request: HttpRequest<any>): boolean {
        const url = request.url.toLowerCase();
        return this.excludedDomains.some((domain) => url.includes(domain.toLowerCase()));
      }

    private handle401Error(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshChatTokenSubject.next(null);

            return this.refreshToken().pipe(
                switchMap((token: any) => {
                    this.isRefreshing = false;

                    let data = {
                        status: token['status'],
                        data: {
                            userId: token['data'].userId,
                            authToken: token['data'].authToken
                        }
                    }
                    SessionStore.user.ChatData = data
                    SessionStore.save()
                   /*  this.setheader() */



                    this.refreshChatTokenSubject.next(token.Authorization);
                    return next.handle(this.addToken(request, token.Authorization));
                })
            );
        } else {
            return this.refreshChatTokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((jwt) => {
                    return next.handle(this.addToken(request, jwt));
                })
            );
        }
    }

    private addToken(request: HttpRequest<any>, token: string) {
      let headers = new HttpHeaders();

      try {

        headers = headers.set('X-User-Id', SessionStore?.user?.ChatData?.data?.userId);
        headers = headers.set('X-Auth-Token', SessionStore?.user?.ChatData?.data.authToken);

        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            ...headers.keys().reduce((acc, key) => ({ ...acc, [key]: headers.get(key) }), {}),
          },
        })
      } catch (error) {


        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        })
      }
    }

    /*  private addToken(request: HttpRequest<any>, token: string) {
       return request.clone({
         setHeaders: {
           Authorization: `Bearer ${token}`,
         },
       });
     } */

    private refreshToken(): Observable<any> {
        return this.http
            .get<any>(environment.apiURL + 'UserAuthentication/RegenereChatToken', {
                /* refreshToken: SessionStore.user.RefreshToken, */
            })
            .pipe(
                catchError((error) => {
                    // Handle token refresh failure
                    console.log('ChatToken refresh failed:', error);
                    return throwError(error);
                })
            );
    }



    setheader() {
        try {

            if (this.p.userPermission(this.p.permissionList.Chat.access) && SessionStore.user.ChatData) {
                this.headers = new HttpHeaders();;

                if (this.p.userPermission(this.p.permissionList.Chat.access)) {
                    //
                    this.headers = this.headers.set('X-User-Id', SessionStore.user.ChatData.data.userId);
                    this.headers = this.headers.set('X-Auth-Token', SessionStore.user.ChatData.data.authToken);
                    this.options = {
                        headers: this.headers,
                    };

                }
            }
        } catch (error) {

        }
    }
}



export const chatTokenInterceptor = {
    provide: HTTP_INTERCEPTORS,
    useClass: ChatTokenInterceptor,
    multi: true
};

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as _ from 'lodash';
import { AuthService } from 'src/app/services/auth.service';
import { GroupMessagesPage } from '../group-messages.page';
import { ThemeService } from 'src/app/services/theme.service'
import { SessionStore } from 'src/app/store/session.service';
// import { ChatSystemService } from 'src/app/services/chat/chat-system.service';

@Component({
  selector: 'app-group-contacts',
  templateUrl: './group-contacts.page.html',
  styleUrls: ['./group-contacts.page.scss'],
})
export class GroupContactsPage implements OnInit {
  showLoader: boolean;
  users = [];

  contact: string[] = [" Ana M.", "Andre F.", "Bruno G.", "Catarina T", "Tiago"];

  headers: HttpHeaders;
  options:any;
  listContacts: any[];
  contacts: any;
  textSearch:string;
  room:any;
  members:any;
  dm:any;
  isGroupCreated:boolean;
  groupName:string;
  selectedUserList:any;
  sessionStore = SessionStore


  objectUserSingleStone = []
  userContainer = {}

  constructor(
    private modalController: ModalController,
    public ThemeService: ThemeService,
    // public ChatSystemService: ChatSystemService,
  )
  {

    // this.textSearch="";
    // this.dm=null;
    // this.room=null;
    // this.isGroupCreated = this.navParams.get('isCreated');
    // this.groupName = this.navParams.get('name');
    // this.room = this.navParams.get('room');
    // this.members = this.navParams.get('members');
  }

  ngOnInit() {
    // this.chatService.refreshtoken();
    this.loadUsers();
    // this.getMembers();


  }

  loadUsers(){
    // this.options = {
    //   headers: this.headers,
    // };
    // this.chatService.getAllUsers().subscribe((res:any)=>{
    //   if(this.members) {
    //     this.contacts =  res.users.filter(f => !this.members.some(item => item._id === f._id));
    //   }
    //   else{
    //     this.contacts = res.users.filter(data => data.username != this.sessionStore.user.UserName);
    //   }
    //   this.users = this.contacts.sort((a,b) => {
    //     if(a.name < b.name){
    //       return -1;
    //     }
    //     if(a.name > b.name){
    //       return 1;
    //     }
    //     return 0;
    //   });




    //   for( const user of  this.users) {

    //     const foundUser = this.objectUserSingleStone.find( e => e.name == user.name)

    //     if(!foundUser) {
    //       this.objectUserSingleStone.push(user)
    //     } else {
    //       'not found'
    //     }

    //   }


    //   const  userContainer = {}
    //   for(const user of this.objectUserSingleStone) {
    //     const firstLetter = user.name.charAt(0)

    //     if(!userContainer[firstLetter]) {
    //       userContainer[firstLetter] = [user]
    //     } else {
    //       userContainer[firstLetter].push(user)
    //     }

    //   }

    //   this.userContainer = userContainer


    //   this.showLoader = false;
    // });
  }

  getMembers(){

  }

  separateLetter(record, recordIndex, records){
    if(recordIndex == 0){
      return record.name[0];
    }

    let first_prev = records[recordIndex - 1].name[0];
    let first_current = record.name[0];

    if(first_prev != first_current){
      return first_current;
    }
    return null;
  }

  deleteMember(data:any){
    let body = {
      "roomId": this.room._id,
      "userId": data._id,
    }

    if(this.room.t == "p"){

    }
    else if(this.room.t == "c"){

    }
  }


  doRefresh(ev){
    this.loadUsers();
    //this.getMembers();
    ev.target.complete();
  }

  async close(){
    this.modalController.dismiss();
  }

  onChange(event) {
    this.textSearch = event.detail.value;

    const users = this.contacts.filter( e => e.name.toLowerCase().includes(this.textSearch.toLowerCase())).sort((a,b) => {
      if(a.name < b.name) {
        return -1;
      }
      if(a.name > b.name) {
        return 1;
      }
      return 0;
    });

    const selectedUsers = this.users.filter( e => e?.isChecked == true)

    users.forEach( (user, index) => {
      if(user[index]) {
        console.log({user, index})
        const isCheck = selectedUsers.find( e => e._id == user._id)?.isChecked
        if(isCheck) {
          user[index].isChecked = isCheck
        }

        // if(user[index].isChecked) {
        //   console.log('user[index].isChecked', user[index].isChecked)
        // }
      }

    })




    let a = this.objectUserSingleStone.filter( e => e.name.toLowerCase().includes(this.textSearch.toLowerCase()))

    let b = {}
    for(const user of a) {
      const firstLetter = user.name.charAt(0)

      if(!b[firstLetter]) {
        b[firstLetter] = [user]
      } else {
        b[firstLetter].push(user)
      }

    }


    this.userContainer = b


  }
  clicked(){


  }
  selectedContact(user:any) {
     /*   this.groupName = this.room.name; */
     if(user.isChecked != true ) {
      user.isChecked = false
    } else {
      user.isChecked = true
    }

    const userIndex = this.objectUserSingleStone.findIndex((e) => e._id == user._id)
    this.objectUserSingleStone[userIndex].isChecked = user.isChecked


  }
  addContacts(room:any){

    this.selectedUserList = this.users.filter(function(contact) {
      return contact.isChecked == true;
    });

    this.selectedUserList.forEach(user=>{
      let body ={
        "roomId":room._id,
        "userId":user._id,

      }

    });
  }

  loading = false
  updateGroup(){
    if(this.loading) {
      return
    }

    this.loading = true
    console.log('this.room', this.room)


  }

  async openGroupMessages(roomId:any){


    const modal = await this.modalController.create({
      component: GroupMessagesPage,
      componentProps: {
        roomId: roomId,
      },
      cssClass: 'group-messages',
      backdropDismiss: false
    });


    modal.onDidDismiss().then(() => {
      this.close();
    });
    await modal.present();

  }

}

import { err, ok } from 'neverthrow';
import { Schema, ZodError } from 'zod';
import { ColoredLoggerService } from '../logger/colored/service';
import { TracingType } from '../monitoring/opentelemetry/tracer';

export function ValidateSchema(schema: Schema) {
  return (
    target: unknown,
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ) => {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: unknown[]) {
      try {
        const model = schema.parse(args[0]);
        args[0] = model;
        return originalMethod.apply(this, args);
      } catch (e) {
        if (e instanceof ZodError) {
          // If validation fails, throw an error with the details
          //
          ColoredLoggerService.error(e.errors, 'unexpected data structure '+ schema._def.description)

        }
        return err(e)
      }
    };
  };
}

/**
 *
 * @param schema Zod Schema
 * @param context
 */
export function SafeValidateSchema(schema: Schema, context: string) {
  return (
    target: unknown,
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ) => {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: unknown[]) {

      const tracing: TracingType = args[args.length - 1] as any;

      try {
        const model = schema.parse(args[0]);
        args[0] = model;
        return originalMethod.apply(this, args);
      } catch (e) {
        if (e instanceof ZodError) {
          // If validation fails, throw an error with the details
          //
          // If validation fails, throw an error with the details
          console.error('unexpected data structure', context)
          // Capture the Zod validation error with additional context
          console.error('Validation failed:', e.errors);

          tracing?.setAttribute?.('APIReturn.error', 'true')

          let i = 0;
          for(const schema of e.errors) {
            tracing?.setAttribute?.('map.error.schema-'+i, JSON.stringify(schema))
          }
          ColoredLoggerService.error(e.errors, 'socket unexpected data structure '+ context, schema._def.description)
          console.error(args[0])

        }
        return originalMethod.apply(this, args);
      }
    };
  };
}

// export function ParamsValidationSchema(schema:Schema, { either = false, context = "" }) {
//   return (
//     target: unknown,
//     propertyKey: string,
//     descriptor: PropertyDescriptor,
//   ) => {
//     const originalMethod = descriptor.value;
//     descriptor.value = function (...args: unknown[]) {

//       const tracing: TracingType = args[args.length - 1] as any;

//       context = context ? context : tracing?.name;

//       try {
//         tracing.addEvent("Parameter Validation start")
//         const model = schema.parse(args[0]);
//         tracing.addEvent("Parameter Validation end")
//         args[0] = model;
//         return originalMethod.apply(this, args);
//       } catch (e) {
//         if (e instanceof ZodError) {
//           // If validation fails, throw an error with the details
//           // Capture the Zod validation error with additional context

//           tracing?.setAttribute?.('parameter.error', 'true')

//           let i = 0;
//           for(const schema of e.errors) {
//             tracing?.setAttribute?.('map.error.schema-'+i, JSON.stringify(schema))
//           }
//           ColoredLoggerService.error(e.errors, 'Unexpected data structure '+ context,'..')
//           console.error(args[0])

//           tracing.addEvent("Validation parameter error")
//           tracing.hasError("Invalid Parameter")
//           if(either) {
//             return err(e)
//           }

//         }
//         return originalMethod.apply(this, args);
//       }
//     };
//   };
// }


export function ParamsValidation(schema:Schema, args, tracing: TracingType) {
  try {
    tracing.addEvent("Parameter Validation start")
    const model = schema.parse(args);
    tracing.addEvent("Parameter Validation end")
    args = model;
    return ok(true);
  } catch (e) {
    if (e instanceof ZodError) {
      // If validation fails, throw an error with the details
      // Capture the Zod validation error with additional context
      let i = 0;
      for(const schema of e.errors) {
        tracing?.setAttribute?.('map.error.schema-'+i, JSON.stringify(schema))
      }

      tracing.log("zod error "+ tracing?.name, {
        zodError: e
      })
      ColoredLoggerService.error(e.errors, 'Unexpected data structure '+ tracing?.name,'..')
      console.error(args)

      tracing.addEvent("Validation parameter error")
      tracing.hasError("Invalid Parameter")

    }
    return err(false)
  }
}

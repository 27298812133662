<ion-content>
  <div class="main-content d-flex height-100">

    <div class="content d-flex flex-column width-100 height-100 ">
      <div class="header-content header-default-padding">
        <div class="header-title">
          <label>Convocar Reunião</label> <br>
          <i style="margin-top: -3px; font-size: 15px;" > Campos marcados com * são obrigatórios</i>
        </div>
      </div>
      <div class="overflow-y-auto content-default-padding">

        <div class="ion-item-container width-100" [class.input-error]="Form?.get('Subject')?.invalid && validateFrom ">
          <ion-input autocomplete="on" autocorrect="on" spellcheck="true" placeholder="Assunto*" [(ngModel)]="postData.Subject"></ion-input>
        </div>

        <div *ngIf="Form && validateFrom" >
          <div *ngIf="Form.get('Subject').invalid " class="input-errror-message">
            <div *ngIf="Form.get('Subject').errors?.required">

            </div>
            <div *ngIf="Form.get('Subject').errors?.minlength">
              O campo deve ter pelo menos 4 caracteres.
            </div>
          </div>
        </div>

        <div *ngIf="(sharedCalendar | async) as calendarData" class="container-div" >
          <div class="ion-item-class-2 d-flex">
            <div class="ion-icon-class">
              <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
            </div>
            <div class="ion-input-class flex-grow-1 justify-center align-center material-inputs " [class.input-error]="Form?.get('CalendarName')?.invalid  && validateFrom ">

              <mat-form-field appearance="none"  class="width-100" color="ion-color-secondary"  placeholder="Selecione agenda">
                <div *ngIf="utils.hasPrCalendar(calendarData) && !hasChangeCalendar  " style="margin-bottom: -20px;">PR</div>
                <div *ngIf="selectedUserCalendar == sessionStore.user.UserId && !hasChangeCalendar && sessionStore.user.Profile != 'PR' " style="margin-bottom: -20px;">
                  Minha agenda
                </div>

                <mat-select [(value)]="selectedUserCalendar" (selectionChange)="changeAgenda();changeSegmentCalendar()">

                  <mat-option *ngFor="let calendars of calendarData" value="{{calendars.wxUserId}}">

                    <div *ngIf="calendars.roleId == RoleIdService.PRES">PR </div>
                    <div *ngIf="calendars.roleId == RoleIdService.MD && calendars.roleId != sessionStore.user.RoleID">AGENDA DO MDGPR</div>
                    <div *ngIf="calendars.roleId != RoleIdService.MD && calendars.roleId != RoleIdService.PRES && calendars.wxFullName && calendars.wxUserId != sessionStore.user.UserId"> Agenda do {{calendars.wxFullName}} </div>
                    <div *ngIf="calendars.wxUserId == sessionStore.user.UserId && sessionStore.user.Profile != 'PR'">
                      Minha agenda
                    </div>

                  </mat-option>

                </mat-select>
              </mat-form-field>

            </div>
          </div>
        </div>

        <div class="container-div width-100">
          <div class="ion-item-class-2 width-100">
            <div class="ion-icon-class">
              <ion-icon slot="start" src="assets/images/icons-location.svg"></ion-icon>
            </div>
            <div class="ion-input-class" [class.input-error]="Form?.get('Location')?.invalid  && validateFrom ">
              <ion-input autocomplete="on" autocorrect="on" spellcheck="true" placeholder="Localização*" [(ngModel)]="postData.Location"></ion-input>
            </div>
          </div>
        </div>

        <div class="container-div"  >
          <div class="ion-item-class-2 d-flex">
            <div class="ion-icon-class">
              <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
            </div>
            <div class="ion-input-class flex-grow-1 justify-center align-center material-inputs " [class.input-error]="Form?.get('CalendarName')?.invalid  && validateFrom ">
              <mat-form-field appearance="none"  class="width-100" color="ion-color-secondary"  placeholder="Selecione agenda">
                <mat-select [(value)]="postData.CalendarName" >
                  <mat-option *ngFor="let calendars of CalendarNamesOptions" value="{{calendars}}">
                    Agenda {{ calendars }}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="container-div">
          <div class="ion-item-class-2">
            <div class="ion-icon-class">
              <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
            </div>
            <div class="ion-input-class" [class.input-error]="Form?.get('Categories')?.invalid  && validateFrom ">

              <mat-form-field appearance="none" class="width-100" placeholder="Sample Type" required>
                <!-- <input matInput type="text" > -->
                <mat-select [(ngModel)]="postData.Category" >
                  <mat-option value="Reunião">
                    Reunião
                  </mat-option>
                  <mat-option value="Viagem">
                    Viagem
                  </mat-option>
                  <mat-option value="Conferência">
                    Conferência
                  </mat-option>
                  <mat-option value="Encontro">
                    Encontro
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>
        </div>




      <div class="container-div">
        <div>
          <ion-item>
            <ion-label>Todo dia</ion-label>
            <ion-checkbox [(ngModel)]="allDayCheck" (ionChange)="onCheckboxChange($event)"></ion-checkbox>
          </ion-item>
        </div>
      </div>

      <div class="container-div">
        <div class="ion-item-class-2 d-flex">
          <div class="ion-icon-class">
            <ion-icon slot="start" src="assets/images/icons-reapet.svg"></ion-icon>
          </div>
          <div class="ion-input-class flex-grow-1 justify-center align-center material-inputs">

            <mat-form-field appearance="none" class="width-100" placeholder="Sample Type" required>
              <!-- <input matInput type="text" > -->
              <mat-select [(value)]="postData.EventRecurrence.frequency" >
                <mat-option value="never">
                  Nunca
                </mat-option>
                <mat-option value="daily">
                  Diário
                </mat-option>
                <mat-option value="weekly">
                  Semanalmente
                </mat-option>
                <mat-option value="monthly">
                  Mensal
                </mat-option>
                <mat-option value="yearly">
                  Anual
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
      </div>

      <div *ngIf="postData.EventRecurrence.frequency != 'never'" class="container-div">

        <div class="ion-item-class-2 d-flex" >
          <div class="ion-icon-class">
            <ion-icon slot="start" src="assets/images/icons-reapet.svg"></ion-icon>
          </div>

          <div  class="ion-input-class flex-grow-1 justify-center align-center material-inputs materia-top" >

            <mat-form-field appearance="none" class="date-hour-picker">
              <input matInput [ngxMatDatetimePicker]="picker1"
                placeholder="Data Fim de Recorrência*"
                [(ngModel)]="postData.EventRecurrence.until"
                [disabled]="disabled"
              >
              <mat-datepicker-toggle id="new-inicio" matSuffix [for]="picker1" ></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker1
                [showSpinners]="showSpinners"
                [showSeconds]="showSeconds"
                [stepHour]="stepHour" [stepMinute]="stepMinute"
                [stepSecond]="stepSecond"
                [touchUi]="touchUi"
                [hideTime]="true"
                >
              </ngx-mat-datetime-picker>
            </mat-form-field>

          </div>
      </div>
    </div>

        <div class="container-div">
        <div class="ion-item-class-2">
          <div class="ion-icon-class">
            <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
          </div>
          <div class="ion-input-class" [class.input-error]="Form?.get('Date')?.invalid  && validateFrom ">

            <mat-form-field appearance="none" class="width-100 date-hour-picker">
              <input matInput [ngxMatDatetimePicker]="picker1"
                placeholder="Choose a date*"
                [(ngModel)]="postData.StartDate"
                [disabled]="disabled"
              >
              <mat-datepicker-toggle id="new-inicio" matSuffix [for]="picker1" ></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker1
                [showSpinners]="showSpinners"
                [showSeconds]="showSeconds"
                [stepHour]="stepHour" [stepMinute]="stepMinute"
                [stepSecond]="stepSecond"
                [touchUi]="touchUi">
              </ngx-mat-datetime-picker>
            </mat-form-field>

          </div>
        </div>
        </div>

        <div class="container-div">
          <div class="ion-item-class-2">
            <div class="ion-icon-class">
              <ion-icon slot="start" src="assets/images/icons-calendar.svg"></ion-icon>
            </div>
            <div class="ion-input-class  flex-grow-1" [class.input-error]="Form?.get('Date')?.invalid  && validateFrom ">

              <mat-form-field appearance="none" class="date-hour-picker width-100 ">
                <input matInput [ngxMatDatetimePicker]="fim"
                  placeholder="Fim*"
                  [(ngModel)]="postData.EndDate"
                  [disabled]="disabled"
                >
                <mat-datepicker-toggle id="new-fim" matSuffix [for]="fim" ></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #fim
                  [showSpinners]="showSpinners"
                  [showSeconds]="showSeconds"
                  [stepHour]="stepHour" [stepMinute]="stepMinute"
                  [stepSecond]="stepSecond"
                  [touchUi]="touchUi">
                </ngx-mat-datetime-picker>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="container-div">
          <div class="ion-item-class-2">
            <div class="ion-icon-class">
              <ion-icon slot="start" src="assets/images/icons-person.svg"></ion-icon>
            </div>
            <div class="ion-input-class-no-height cursor-pointer" (click)="addParticipants()" [class.input-error]="Form?.get('participantes')?.invalid  && validateFrom ">
              <div class="list-people">
                <ion-item lines="none">
                  <ion-list>
                    <ion-label *ngIf="taskParticipants?.length < 1" class="list-people-title">Adicionar Intervenientes*</ion-label>
                    <ion-label *ngFor="let participant of taskParticipants">{{participant.Name}}</ion-label>
                  </ion-list>
                </ion-item>
              </div>
              <div class="add-people" >
                <ion-icon *ngIf="ThemeService.currentTheme == 'default' "  slot="start" src="assets/images/icons-arrow-forward.svg"></ion-icon>
                <ion-icon *ngIf="ThemeService.currentTheme == 'gov' "  slot="start" src="assets/images/theme/gov/icons-arrow-forward.svg"></ion-icon>
                <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' "  slot="start" src="assets/images/theme/{{ThemeService.currentTheme}}/icons-arrow-forward.svg"></ion-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="container-div">
          <div class="ion-item-class-2">
            <div class="ion-icon-class">
              <ion-icon slot="start" src="assets/images/icons-people-cc.svg"></ion-icon>
            </div>
            <div class="ion-input-class-no-height cursor-pointer" (click)="addParticipantsCc()">
              <div class="list-people">
                <ion-item lines="none">
                  <ion-list>
                    <ion-label *ngIf="taskParticipantsCc?.length < 1" class="list-people-title">Com conhecimento</ion-label>
                    <ion-label *ngFor="let participant of taskParticipantsCc">{{participant.Name}}</ion-label>
                  </ion-list>
                </ion-item>
              </div>
              <div class="add-people" >
                <ion-icon *ngIf="ThemeService.currentTheme == 'default' "  slot="start" src="assets/images/icons-arrow-forward.svg"></ion-icon>
                <ion-icon *ngIf="ThemeService.currentTheme == 'gov' "  slot="start" src="assets/images/theme/gov/icons-arrow-forward.svg"></ion-icon>
                <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' "  slot="start" src="assets/images/theme/{{ThemeService.currentTheme}}/icons-arrow-forward.svg"></ion-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="container-div">
          <div class="ion-item-class-2 d-flex">
            <div class="ion-icon-class">
              <ion-icon slot="start" src="assets/images/icons-description.svg"></ion-icon>
            </div>
            <div class="ion-input-class-no-height width-100">
              <ion-textarea class="heigh-200" autoGrow="true" [(ngModel)]="postData.Body.Text" placeholder="Detalhes" rows="6" cols="20"></ion-textarea>
            </div>
          </div>
        </div>

        <div class="ion-item-container-no-border cursor-pointer" (click)="getDoc()">
          <ion-label>
            <div class="attach-icon">
              <ion-icon *ngIf="ThemeService.currentTheme == 'default' " src="assets/images/icons-attach-doc.svg"></ion-icon>
              <ion-icon *ngIf="ThemeService.currentTheme == 'gov' " src="assets/images/theme/gov/icons-attach-doc.svg"></ion-icon>
                <ion-icon *ngIf="ThemeService.currentTheme == 'doneIt' " src="assets/images/theme/{{ThemeService.currentTheme}}/icons-attach-doc.svg"></ion-icon>
            </div>
            <div class="attach-document">
              <ion-label>Anexar Documentos</ion-label>
            </div>
          </ion-label>
        </div>

        <div class="list "  *ngFor="let document of documents; let i = index" >
          <ion-list>
            <ion-item>
            <ion-label>
              <p class="d-flex ion-justify-content-between">
                <span class="attach-title-item">{{document.Assunto}}</span>
                <span class="app-name" *ngIf="!document.appName">{{ TaskService.attachmentAppName(document)}}</span>
                <span class="app-name" *ngIf="document.appName">{{document.appName}}</span>
                <span class="close-button text-black" (click)="removeAttachment(i)" >
                  <ion-icon class="font-20" src="assets/images/icons-delete-25.svg"></ion-icon>
                </span>
              </p>
              <p><span class="span-left">{{( document.EntidadeOrganicaNome || document.Sender)}}</span><span class="span-right"> {{(document.Data || document.DocDate) | date: 'dd-MM-yyyy HH:mm'}} </span></p>
            </ion-label>
          </ion-item>
          </ion-list>
        </div>
      </div>
    </div>

    <div class="aside-righ flex-grow-1">
      <app-empty-container
          [texto]="emptyTextDescription"
          *ngIf="!showAttendees"
          class="d-flex height-100 flex-column">
      </app-empty-container>
      <app-attendee-modal class=" d-flex flex-column height-100"
        *ngIf="showAttendees"
        [footer]="false"
        [taskParticipants]="taskParticipants"
        [taskParticipantsCc]="taskParticipantsCc"
        [adding]="adding"
        (dynamicSetIntervenient)="dynamicSetIntervenient($event)"
      >
      </app-attendee-modal>
    </div>
  </div>
</ion-content>
<ion-footer>
  <div class="buttons">
    <button class="btn-ok" shape="round" (click)="saveTask()">Gravar</button>
    <button class="btn-cancel" shape="round" (click)="close()">Cancelar</button>
  </div>
</ion-footer>
